import { FormEvent, useState } from 'react';
import { FaEdit } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';

import { Modal } from '~/components/ui/Modal';
import {
  ModalContentContainer,
  StyledTextInput,
  ChangeEmailForm,
  StrongText,
  ConfirmCodeContainer,
  ConfirmCodeInput,
  StyledBootstrapButton,
} from './styles';
import {
  getEmailChangeValues,
  getIsLoadingChangeEmail,
  getShouldShowEmailChangeCodeField,
  getUserProfile,
} from '~/store/modules/user/selectors';
import { TextRenderContainer } from '~/components/ui/TextRender/styles';
import { toast } from 'react-toastify';
import { changeEmailAction, setEmailsForChangeEmail } from '~/store/modules/user/actions';

interface IChangeEmailModal {
  toggleModal: () => void;
}

export function ChangeEmailModal({ toggleModal }: IChangeEmailModal) {
  const ModalContent = () => {
    const emailChangeValues = useSelector(getEmailChangeValues);
    const [newEmail, setNewEmail] = useState(emailChangeValues.newEmail);
    const [confirmNewEmail, setConfirmNewEmail] = useState(emailChangeValues.confirmNewEmail);
    const [changeEmailCode, setChangeEmailCode] = useState('');
    const profile = useSelector(getUserProfile);
    const shouldShowConfirmationCode = useSelector(getShouldShowEmailChangeCodeField);
    const isLoadingChangeEmail = useSelector(getIsLoadingChangeEmail);
    const shouldDisableEmailFields = shouldShowConfirmationCode;

    const dispatch = useDispatch();
    const { email } = profile;

    const handleChangeEmailSubmit = (event: FormEvent) => {
      event.preventDefault();
      if (newEmail !== confirmNewEmail) {
        toast.error(
          () => (
            <>
              <StrongText>{newEmail}</StrongText> and <StrongText>{confirmNewEmail}</StrongText> don't match!
            </>
          ),
          {
            autoClose: 7000,
          }
        );
        return;
      }

      if (shouldShowConfirmationCode && !changeEmailCode) {
        toast.error('Please inform the code!', {
          autoClose: 7000,
        });
        return;
      }

      dispatch(
        setEmailsForChangeEmail({
          newEmail,
          confirmNewEmail,
        })
      );

      dispatch(changeEmailAction({ newEmail, confirmNewEmail, code: changeEmailCode, closeModal: toggleModal }));
    };

    const handleNewEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = String(event.target.value).toLocaleLowerCase();
      setNewEmail(inputValue);
    };

    const handleConfirmNewEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = String(event.target.value).toLocaleLowerCase();
      setConfirmNewEmail(inputValue);
    };

    const handleEmailChangeCode = (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = String(event.target.value).toLocaleLowerCase();
      setChangeEmailCode(inputValue);
    };

    const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
      event.preventDefault();
      toast.error('Pasting is not allowed in this field!', {
        autoClose: 3000,
      });
    };

    return (
      <ModalContentContainer>
        <ChangeEmailForm onSubmit={handleChangeEmailSubmit}>
          <TextRenderContainer>
            Here you can change your <StrongText>current email address</StrongText>
          </TextRenderContainer>
          <StyledTextInput name="email" value={email} disabled />
          <StyledTextInput
            name="newEmail"
            value={newEmail}
            onChange={handleNewEmailChange}
            placeholder="NEW EMAIL"
            type="email"
            disabled={shouldDisableEmailFields}
            onPaste={handlePaste}
            required
          />
          <StyledTextInput
            name="confirmNewEmail"
            value={confirmNewEmail}
            onChange={handleConfirmNewEmailChange}
            placeholder="CONFIRM NEW EMAIL"
            type="email"
            disabled={shouldDisableEmailFields}
            onPaste={handlePaste}
            required
          />
          {shouldShowConfirmationCode && (
            <ConfirmCodeContainer>
              <StrongText>Code</StrongText>
              <ConfirmCodeInput maxLength={6} onChange={handleEmailChangeCode} />
            </ConfirmCodeContainer>
          )}
          <StyledBootstrapButton variant="success" type="submit" disabled={isLoadingChangeEmail}>
            <FaEdit size={30} />
            CONFIRM
          </StyledBootstrapButton>
        </ChangeEmailForm>
      </ModalContentContainer>
    );
  };
  return (
    <Modal
      bodyContent={<ModalContent />}
      title="CHANGE EMAIL"
      show={true}
      onHide={toggleModal}
      hasConfirmation={false}
      size="lg"
      submitButtonIcon={<FaEdit size={30} />}
    />
  );
}
