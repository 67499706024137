import { useState, useEffect } from 'react';
import { TimerDisplay } from './styles';

interface ICountdown {
  timestamp: number;
  color?: string;
  onTimeIsOver?: () => void;
}
export function Countdown({
  timestamp,
  color = 'red',
  onTimeIsOver,
  ...props
}: ICountdown) {
  const [countdownUnits, setCountdownUnits] = useState({
    hour: '00',
    min: '00',
    sec: '00',
  });
  const timeToExpire = Date.now() + timestamp;
  const initialTime = timeToExpire - Date.now();
  const [currentTimeStamp, setCurrentTimestamp] = useState(initialTime);

  useEffect(() => {
    if (onTimeIsOver && currentTimeStamp === 0) {
      onTimeIsOver();
    }
  }, [currentTimeStamp]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTimestamp((currentTimeStampLatestState) => {
        if (currentTimeStampLatestState < 1000) {
          clearInterval(interval);
          return 0;
        }
        const timeElapsed = timeToExpire - Date.now();
        return timeElapsed;
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const hour = Math.floor((currentTimeStamp / (1000 * 60 * 60)) % 24);
    const min = Math.floor((currentTimeStamp / (1000 * 60)) % 60);
    const sec = Math.floor((currentTimeStamp / 1000) % 60);

    setCountdownUnits({
      hour: String(hour).padStart(2, '0'),
      min: String(min).padStart(2, '0'),
      sec: String(sec).padStart(2, '0'),
    });
  }, [currentTimeStamp]);

  return (
    <TimerDisplay
      color={color}
      {...props}
    >{`${countdownUnits?.hour}:${countdownUnits?.min}:${countdownUnits?.sec}`}</TimerDisplay>
  );
}
