import styled, { css } from 'styled-components/macro';
import {
  DISPLAY_FLEX_COLUMN_DEFAULT,
  HIGLAN_BLACK_01,
  HIGLAN_ORANGE_01,
  HIGLAN_RED_02,
  HIGLAN_WHITE_01,
} from '~/style/constants';

const LINK_HOVER_CSS = css`
  a {
    text-decoration: underline;
  }
`;

const OVERDUE_CSS = css`
  background-color: ${HIGLAN_RED_02};
  color: ${HIGLAN_WHITE_01};
  ${LINK_HOVER_CSS}
`;

const NOT_OVERDUE_CSS = css`
  background-color: ${HIGLAN_ORANGE_01};
  color: ${HIGLAN_BLACK_01};
  ${LINK_HOVER_CSS}
`;

export const OverdueChargesContainer = styled.div<{ isOverdue: boolean }>`
  ${DISPLAY_FLEX_COLUMN_DEFAULT};
  text-align: center;
  padding: 15px;
  font-size: 17px;
  margin-top: 10px;
  gap: 15px;
  ${({ isOverdue }) => (isOverdue ? OVERDUE_CSS : NOT_OVERDUE_CSS)}
`;

export const DepositText = styled.div`
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    font-weight: bold;
  }
`;
