import styled from 'styled-components/macro';
import { DISPLAY_FLEX_COLUMN_DEFAULT, HIGLAN_WHITE_01 } from '~/style/constants';

export const TermsTitle = styled.h4`
  text-align: center;
  width: 100%;
`;

export const TermsContainer = styled.div`
  max-width: 900px;
  background-color: ${HIGLAN_WHITE_01};
  padding: 25px;
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  font-size: 16px;
  gap: 25px;
  border-radius: 0.3rem;
`;
