import { IShowToastMessage } from '~/services/socket';
import { IAdminData, INodeData } from '../interfaces';

const prefix = '@user';

interface IUserActionTypes {
  UPDATE_PROFILE_REQUEST: string;
  UPDATE_PROFILE_SUCCESS: string;
  UPDATE_PROFILE_FAILURE: string;
  UPDATE_PROFILE_MASTERNODE_TO_START_NOTIFICATION_REQUEST: string;
  UPDATE_PROFILE_MASTERNODE_TO_START_NOTIFICATION_SUCCESS: string;
  GET_NODE_DATA: string;
  GET_ADMIN_DATA: string;
  SET_NODE_DATA: string;
  SET_ADMIN_DATA: string;
  SET_ADMIN_CODE: string;
  SET_IS_ADMIN_CODE_VALID: string;
  SUBMIT_ADMIN_CODE: string;
  STOP_LOADING_ADMIN_CODE: string;
  RESET_ADMIN_CODE_DATA: string;
  RESET_INDEX_TO_DELETE: string;
  GET_USER_BALANCES: string;
  SET_USER_BALANCES: string;
  REFRESH_USER_BALANCES: string;
  SEND_PROMOTION_CODE: string;
  STOP_LOADING_ON_PROMOTION_CODE: string;
  TOGGLE_LOADING_ON_RESET_INDEX: string;
  TOGGLE_LOADING_ADMIN_DATA: string;
  TOGGLE_NOTIFICATION_WARNING_MAIL: string;
  UPDATE_NOTIFICATION_WARNING_MAIL: string;
  STOP_LOADING_NOTIFICATION_WARNING_MAIL: string;
  RECEIVE_FUNDS: string;
  RESTORE_NODE: string;
  RESET_RESTORATION_LOADING_ID: string;
  VERIFY_DIFFERENT_ACCOUNT_TO_TRANSFER_FUNDS: string;
  TOGGLE_DIFFERENT_ACCOUNT_VERIFICATION_LOADING: string;
  SET_DIFFERENT_ACCOUNT_TO_TRANSFER_FUNDS: string;
  RESET_DIFFERENT_ACCOUNT_TO_TRANSFER_FUNDS: string;
  CONFIRM_TRANSFER_FUNDS: string;
  TOGGLE_CONFIRM_TRANSFER_FUNDS_LOADING: string;
  SHOW_TRANSFER_FUNDS_CODE_FIELD: string;
  DELETE_DECOMMISSION_DATA: string;
  DELETE_DECOMMISSION_ITEM: string;
  TOGGLE_LOADING_DELETING_DECOMMISSION_DATA: string;
  TOGGLE_LOADING_DELETING_DECOMMISSION_ITEM: string;
  REMOVE_DECOMMISSION_ITEM: string;
  SHOW_TOAST_MESSAGE: string;
  TOGGLE_SERVER_MOUNTING: string;
  TOGGLE_SERVER_DELETION: string;
  CHARGE_NODES: string;
  TOGGLE_CHARGE_NODES_LOADING: string;
  EXECUTE_NODE_DELETION: string;
  TOGGLE_NODE_DELETION_LOADING: string;
  TOGGLE_READY_NODE_DELETION_LOADING: string;
  CHANGE_EMAIL: string;
  TOGGLE_CHANGE_EMAIL_LOADING: string;
  UPDATE_EMAIL_ADDRESS: string;
  SET_EMAILS_FOR_CHANGE_EMAIL: string;
  RESET_EMAIL_CHANGE_VALUES: string;
  SET_SHOULD_SHOW_EMAIL_CHANGE_CODE_FIELD: string;
  CREATE_ONCHAIN_PAYMENT_LINK: string;
  TOGGLE_ONCHAIN_PAYMENT_LINK_LOADING: string;
  SET_ONCHAIN_PAYMENT_LINK_DATA: string;
  DISMISS_PROMOTION_MODAL: string;
}

export const userActionTypes: IUserActionTypes = {
  UPDATE_PROFILE_REQUEST: `${prefix}/UPDATE_PROFILE_REQUEST`,
  UPDATE_PROFILE_SUCCESS: `${prefix}/UPDATE_PROFILE_SUCCESS`,
  UPDATE_PROFILE_FAILURE: `${prefix}/UPDATE_PROFILE_FAILURE`,
  UPDATE_PROFILE_MASTERNODE_TO_START_NOTIFICATION_REQUEST: `${prefix}/UPDATE_PROFILE_MASTERNODE_TO_START_NOTIFICATION_REQUEST`,
  UPDATE_PROFILE_MASTERNODE_TO_START_NOTIFICATION_SUCCESS: `${prefix}/UPDATE_PROFILE_MASTERNODE_TO_START_NOTIFICATION_SUCCESS`,
  GET_NODE_DATA: `${prefix}/GET_NODE_DATA`,
  SET_NODE_DATA: `${prefix}/SET_NODE_DATA`,
  GET_ADMIN_DATA: `${prefix}/GET_ADMIN_DATA`,
  SET_ADMIN_DATA: `${prefix}/SET_ADMIN_DATA`,
  SET_ADMIN_CODE: `${prefix}/SET_ADMIN_CODE`,
  RESET_ADMIN_CODE_DATA: `${prefix}/RESET_ADMIN_CODE_DATA`,
  RESET_INDEX_TO_DELETE: `${prefix}/RESET_INDEX_TO_DELETE`,
  SET_IS_ADMIN_CODE_VALID: `${prefix}/SET_IS_ADMIN_CODE_VALID`,
  SUBMIT_ADMIN_CODE: `${prefix}/SUBMIT_ADMIN_CODE`,
  STOP_LOADING_ADMIN_CODE: `${prefix}/STOP_LOADING_ADMIN_CODE`,
  GET_USER_BALANCES: `${prefix}/GET_USER_BALANCES`,
  SET_USER_BALANCES: `${prefix}/SET_USER_BALANCES`,
  REFRESH_USER_BALANCES: `${prefix}/REFRESH_USER_BALANCES`,
  SEND_PROMOTION_CODE: `${prefix}/SEND_PROMOTION_CODE`,
  STOP_LOADING_ON_PROMOTION_CODE: `${prefix}/STOP_LOADING_ON_PROMOTION_CODE`,
  TOGGLE_LOADING_ON_RESET_INDEX: `${prefix}/STOP_LOADING_ON_RESET_INDEX`,
  TOGGLE_LOADING_ADMIN_DATA: `${prefix}/TOGGLE_LOADING_ADMIN_DATA`,
  TOGGLE_NOTIFICATION_WARNING_MAIL: `${prefix}/TOGGLE_NOTIFICATION_WARNING_MAIL`,
  STOP_LOADING_NOTIFICATION_WARNING_MAIL: `${prefix}/STOP_LOADING_NOTIFICATION_WARNING_MAIL`,
  UPDATE_NOTIFICATION_WARNING_MAIL: `${prefix}/UPDATE_NOTIFICATION_WARNING_MAIL`,
  RECEIVE_FUNDS: `${prefix}/RECEIVE_FUNDS`,
  RESTORE_NODE: `${prefix}/RESTORE_FUNDS`,
  RESET_RESTORATION_LOADING_ID: `${prefix}/RESET_RESTORATION_LOADING_ID`,
  VERIFY_DIFFERENT_ACCOUNT_TO_TRANSFER_FUNDS: `${prefix}/VERIFY_DIFFERENT_HIGLAN_ACCOUNT_TO_TRANSFER_FUNDS`,
  TOGGLE_DIFFERENT_ACCOUNT_VERIFICATION_LOADING: `${prefix}/TOGGLE_DIFFERENT_ACCOUNT_VERIFICATION_LOADING`,
  SET_DIFFERENT_ACCOUNT_TO_TRANSFER_FUNDS: `${prefix}/SET_DIFFERENT_ACCOUNT_TO_TRANSFER_FUNDS`,
  RESET_DIFFERENT_ACCOUNT_TO_TRANSFER_FUNDS: `${prefix}/RESET_DIFFERENT_ACCOUNT_TO_TRANSFER_FUNDS`,
  CONFIRM_TRANSFER_FUNDS: `${prefix}/CONFIRM_TRANSFER_FUNDS`,
  TOGGLE_CONFIRM_TRANSFER_FUNDS_LOADING: `${prefix}/TOGGLE_CONFIRM_TRANSFER_FUNDS_LOADING`,
  SHOW_TRANSFER_FUNDS_CODE_FIELD: `${prefix}/SHOW_TRANSFER_FUNDS_CODE_FIELD`,
  DELETE_DECOMMISSION_DATA: `${prefix}/DELETE_DECOMMISSION_DATA`,
  DELETE_DECOMMISSION_ITEM: `${prefix}/DELETE_DECOMMISSION_ITEM`,
  TOGGLE_LOADING_DELETING_DECOMMISSION_DATA: `${prefix}/TOGGLE_LOADING_DELETING_DECOMMISSION_DATA`,
  TOGGLE_LOADING_DELETING_DECOMMISSION_ITEM: `${prefix}/TOGGLE_LOADING_DELETING_DECOMMISSION_ITEM`,
  REMOVE_DECOMMISSION_ITEM: `${prefix}/REMOVE_DECOMMISSION_ITEM`,
  SHOW_TOAST_MESSAGE: `${prefix}/SHOW_TOAST_MESSAGE`,
  TOGGLE_SERVER_MOUNTING: `${prefix}/TOGGLE_SERVER_MOUNTING`,
  TOGGLE_SERVER_DELETION: `${prefix}/TOGGLE_SERVER_DELETION`,
  CHARGE_NODES: `${prefix}/CHARGE_NODES`,
  TOGGLE_CHARGE_NODES_LOADING: `${prefix}/TOGGLE_CHARGE_NODES_LOADING`,
  EXECUTE_NODE_DELETION: `${prefix}/EXECUTE_NODE_DELETION`,
  TOGGLE_NODE_DELETION_LOADING: `${prefix}/TOGGLE_NODE_DELETION_LOADING`,
  TOGGLE_READY_NODE_DELETION_LOADING: `${prefix}/TOGGLE_READY_NODE_DELETION_LOADING`,
  CHANGE_EMAIL: `${prefix}/CHANGE_EMAIL`,
  TOGGLE_CHANGE_EMAIL_LOADING: `${prefix}/TOGGLE_CHANGE_EMAIL_LOADING`,
  UPDATE_EMAIL_ADDRESS: `${prefix}/UPDATE_EMAIL_ADDRESS`,
  SET_EMAILS_FOR_CHANGE_EMAIL: `${prefix}/SET_EMAILS_FOR_CHANGE_EMAIL`,
  RESET_EMAIL_CHANGE_VALUES: `${prefix}/RESET_EMAIL_CHANGE_VALUES`,
  SET_SHOULD_SHOW_EMAIL_CHANGE_CODE_FIELD: `${prefix}/SET_SHOULD_SHOW_EMAIL_CHANGE_CODE_FIELD`,
  CREATE_ONCHAIN_PAYMENT_LINK: `${prefix}/CREATE_ONCHAIN_PAYMENT_LINK`,
  TOGGLE_ONCHAIN_PAYMENT_LINK_LOADING: `${prefix}/TOGGLE_ONCHAIN_PAYMENT_LINK_LOADING`,
  SET_ONCHAIN_PAYMENT_LINK_DATA: `${prefix}/SET_ONCHAIN_PAYMENT_LINK_DATA`,
  DISMISS_PROMOTION_MODAL: `${prefix}/DISMISS_PROMOTION_MODAL`,
};

export type IUserAction = IUpdateProfileAction;

export interface IUpdateProfileAction {
  payload: any;
  type: typeof userActionTypes.UPDATE_PROFILE_REQUEST;
}
export interface ISendPromotionCodeAction {
  payload: ISendPromotionCodePayload;
  type: typeof userActionTypes.SEND_PROMOTION_CODE;
}

export interface IDismissPromotionModalSaga {
  payload: IDismissPromotionModalPayload;
  type: typeof userActionTypes.DISMISS_PROMOTION_MODAL;
}

export interface IShowToastMessageAction {
  payload: IShowToastMessage;
  type: typeof userActionTypes.SHOW_TOAST_MESSAGE;
}

export interface IToggleServerMountingAction {
  payload: IToggleServerMounting;
  type: typeof userActionTypes.TOGGLE_SERVER_MOUNTING;
}

export interface IExecuteNodeDeletionAction {
  payload: IExecuteNodeDeletion;
  type: typeof userActionTypes.EXECUTE_NODE_DELETION;
}

export interface IExecuteNodeDeletion {
  shouldDeleteOnlyReadyNodes: boolean;
}

export interface IToggleServerDeletionAction {
  payload: IToggleServerDeletion;
  type: typeof userActionTypes.TOGGLE_SERVER_DELETION;
}

export interface IReceiveFundsAction {
  payload: IReceiveFunds;
  type: typeof userActionTypes.RECEIVE_FUNDS;
}

export interface IRestoreNodeAction {
  payload: {
    deletedNodeId: number;
    shouldRestore: boolean;
  };
  type: typeof userActionTypes.RESTORE_NODE;
}

export interface IVerifyAccountToTransferFundsAction {
  payload: {
    accountEmail: string;
  };
  type: typeof userActionTypes.VERIFY_DIFFERENT_ACCOUNT_TO_TRANSFER_FUNDS;
}

export interface IConfirmTransferAction {
  payload: IConfirmTransferFunds;
  type: typeof userActionTypes.CONFIRM_TRANSFER_FUNDS;
}

export interface IDeleteDecommissionItemAction {
  payload: {
    decommissionItemId: number;
  };
  type: typeof userActionTypes.DELETE_DECOMMISSION_ITEM;
}

export interface ICreateOnchainPaymentLinkAction {
  payload: ICreateOnchainPaymentLink;
  type: typeof userActionTypes.CREATE_ONCHAIN_PAYMENT_LINK;
}

interface ISendPromotionCodePayload {
  promotionCode: string;
}

interface ICreateOnchainPaymentLink {
  showPaymentLinkModalFunction: () => void;
}

export interface IReceiveFunds {
  payment_id?: string;
  currencyName: string;
  currencyAmount: string;
  valueReceived: string;
}

export interface IChangeEmail {
  newEmail: string;
  confirmNewEmail: string;
  code: string;
  closeModal: () => void;
}

export interface IGetAdminDataSaga {
  type: typeof userActionTypes.GET_ADMIN_DATA;
  payload: IGetAdminData;
}

interface IOnchainPaymentLinkData {
  hosted_url: string;
  expires_at: string;
}

export type INotificationWarningType =
  | 'decommision_warning_mail'
  | 'delayed_blocks_warning_mail'
  | 'script_warning_mail'
  | 'charge_warning_mail'
  | 'should_allow_user_delete_nodes'
  | 'is_running_general_deletion'
  | 'should_run_node_charges'
  | 'servers_ping_mail';

interface IToggleNotificationWarningMailPayload {
  warningMailType: INotificationWarningType;
}
export interface IToggleNotificationWarningSaga {
  type: typeof userActionTypes.TOGGLE_NOTIFICATION_WARNING_MAIL;
  payload: IToggleNotificationWarningMailPayload;
}

export interface IChangeEmailSaga {
  type: typeof userActionTypes.CHANGE_EMAIL;
  payload: IChangeEmail;
}

interface IGetAdminData {
  shouldShowToastMessage?: boolean;
}

interface IUpdateNotificationWarning {
  type: INotificationWarningType;
  isEnabled: boolean;
}

interface ISetDifferentAccountToTransferFunds {
  username: string;
  email: string;
}

interface IToggleServerMounting {
  node_base: string;
  should_mount: boolean;
}

interface ISetEmailsForChangeEmail {
  newEmail: string;
  confirmNewEmail: string;
}

interface IToggleServerDeletion {
  node_base: string;
  should_delete: boolean;
}

export interface IDismissPromotionModalPayload {
  shouldSeePromotionModal: boolean;
  freePriceCode: string;
}

export interface IConfirmTransferFunds {
  balance: number;
  bonus: number;
  differentAccountEmail: string;
  code?: string;
  toggleTransferFundsModal: () => void;
}

export function updateProfileRequest(data: any) {
  return {
    type: userActionTypes.UPDATE_PROFILE_REQUEST,
    payload: { data },
  };
}

export function updateProfileSuccess(profile: any) {
  return {
    type: userActionTypes.UPDATE_PROFILE_SUCCESS,
    payload: { profile },
  };
}

export function dismissPromotionModal({ shouldSeePromotionModal, freePriceCode }: IDismissPromotionModalPayload) {
  return {
    type: userActionTypes.DISMISS_PROMOTION_MODAL,
    payload: { shouldSeePromotionModal, freePriceCode },
  };
}

export function updateProfileFailure() {
  return {
    type: userActionTypes.UPDATE_PROFILE_FAILURE,
  };
}

export function updateProfileMasternodeToStartNotificationRequest(data: any) {
  return {
    type: userActionTypes.UPDATE_PROFILE_MASTERNODE_TO_START_NOTIFICATION_REQUEST,
    payload: { data },
  };
}

export function updateProfileMasternodeToStartNotificationSuccess(masternode_to_start_notification: boolean) {
  return {
    type: userActionTypes.UPDATE_PROFILE_MASTERNODE_TO_START_NOTIFICATION_SUCCESS,
    payload: { masternode_to_start_notification },
  };
}

export function getNodeData() {
  return {
    type: userActionTypes.GET_NODE_DATA,
  };
}

export function getAdminData({ shouldShowToastMessage = false }: IGetAdminData = {}) {
  return {
    type: userActionTypes.GET_ADMIN_DATA,
    payload: {
      shouldShowToastMessage,
    },
  };
}

export function getUserBalances() {
  return {
    payload: {
      shouldUseLoadingAppData: true,
    },
    type: userActionTypes.GET_USER_BALANCES,
  };
}

export function refreshUserBalances() {
  return {
    payload: {
      shouldUseLoadingAppData: false,
    },
    type: userActionTypes.REFRESH_USER_BALANCES,
  };
}

export function setUserBalances({ balance, bonus }: { balance: number; bonus: number }) {
  return {
    payload: {
      balance,
      bonus,
    },
    type: userActionTypes.SET_USER_BALANCES,
  };
}

export function receiveFunds({ currencyName, payment_id, valueReceived, currencyAmount }: IReceiveFunds) {
  return {
    payload: {
      currencyName: currencyName.toUpperCase(),
      currencyAmount,
      payment_id,
      valueReceived,
    },
    type: userActionTypes.RECEIVE_FUNDS,
  };
}

export function setNodeData(nodeData: INodeData) {
  return {
    type: userActionTypes.SET_NODE_DATA,
    payload: { nodeData },
  };
}

export function restoreNode(deletedNodeId: number, shouldRestore: boolean, loadingId: string) {
  return {
    type: userActionTypes.RESTORE_NODE,
    payload: { deletedNodeId, shouldRestore, restorationLoadingId: loadingId },
  };
}

export function resetRestorationLoadingId() {
  return {
    type: userActionTypes.RESET_RESTORATION_LOADING_ID,
  };
}

export function setAdminData(adminData: IAdminData) {
  return {
    type: userActionTypes.SET_ADMIN_DATA,
    payload: { adminData },
  };
}

export function setIsAdminCodeValid() {
  return {
    type: userActionTypes.SET_IS_ADMIN_CODE_VALID,
  };
}

export function setAdminCode(code: string) {
  return {
    type: userActionTypes.SET_ADMIN_CODE,
    payload: { code },
  };
}

export function submitAdminCode() {
  return {
    type: userActionTypes.SUBMIT_ADMIN_CODE,
  };
}

export function stopLoadingAdminCode() {
  return {
    type: userActionTypes.STOP_LOADING_ADMIN_CODE,
  };
}

export function resetAdminCodeData() {
  return {
    type: userActionTypes.RESET_ADMIN_CODE_DATA,
  };
}

export function resetIndexToDelete() {
  return {
    type: userActionTypes.RESET_INDEX_TO_DELETE,
  };
}

export function toggleLoadingOnResetIndex() {
  return {
    type: userActionTypes.TOGGLE_LOADING_ON_RESET_INDEX,
  };
}

export function toggleLoadingAdminData() {
  return {
    type: userActionTypes.TOGGLE_LOADING_ADMIN_DATA,
  };
}

export function toggleNotificationWarningMail({ warningMailType }: IToggleNotificationWarningMailPayload) {
  return {
    type: userActionTypes.TOGGLE_NOTIFICATION_WARNING_MAIL,
    payload: {
      warningMailType,
    },
  };
}

export function stopLoadingWarningMail() {
  return {
    type: userActionTypes.STOP_LOADING_NOTIFICATION_WARNING_MAIL,
  };
}

export function updateNotificationWarning({ type, isEnabled }: IUpdateNotificationWarning) {
  return {
    type: userActionTypes.UPDATE_NOTIFICATION_WARNING_MAIL,
    payload: {
      type,
      isEnabled,
    },
  };
}

export function stopLoadingOnPromoCode() {
  return {
    type: userActionTypes.STOP_LOADING_ON_PROMOTION_CODE,
    payload: {},
  };
}

export function sendPromotionCode({ promotionCode }: ISendPromotionCodePayload) {
  return {
    type: userActionTypes.SEND_PROMOTION_CODE,
    payload: { promotionCode },
  };
}

export function verifyDifferentAccountEmailToTransferFunds(accountEmail: string) {
  return {
    type: userActionTypes.VERIFY_DIFFERENT_ACCOUNT_TO_TRANSFER_FUNDS,
    payload: { accountEmail },
  };
}

export function toggleDifferentAccountVerificationLoading() {
  return {
    type: userActionTypes.TOGGLE_DIFFERENT_ACCOUNT_VERIFICATION_LOADING,
  };
}

export function toggleConfirmTransferFundsLoading() {
  return {
    type: userActionTypes.TOGGLE_CONFIRM_TRANSFER_FUNDS_LOADING,
  };
}

export function setDifferentAccountToTransferFunds({ username, email }: ISetDifferentAccountToTransferFunds) {
  return {
    type: userActionTypes.SET_DIFFERENT_ACCOUNT_TO_TRANSFER_FUNDS,
    payload: {
      username,
      email,
    },
  };
}

export function resetDifferentAccountToTransferFunds() {
  return {
    type: userActionTypes.RESET_DIFFERENT_ACCOUNT_TO_TRANSFER_FUNDS,
  };
}

export function confirmTransferFunds(payload: IConfirmTransferFunds) {
  return {
    payload,
    type: userActionTypes.CONFIRM_TRANSFER_FUNDS,
  };
}

export function showTransferFundsCodeField() {
  return {
    type: userActionTypes.SHOW_TRANSFER_FUNDS_CODE_FIELD,
  };
}

export function deleteDecommissionDataAction() {
  return {
    type: userActionTypes.DELETE_DECOMMISSION_DATA,
  };
}

export function deleteDecommissionItemAction(decommissionItemId: number) {
  return {
    payload: {
      decommissionItemId,
    },
    type: userActionTypes.DELETE_DECOMMISSION_ITEM,
  };
}

export function toggleLoadingDecommissionData() {
  return {
    type: userActionTypes.TOGGLE_LOADING_DELETING_DECOMMISSION_DATA,
  };
}

export function toggleLoadingDecommissionItem(decommissionItemId: number, type: 'add' | 'remove') {
  return {
    payload: {
      decommissionItemId,
      type,
    },
    type: userActionTypes.TOGGLE_LOADING_DELETING_DECOMMISSION_ITEM,
  };
}

export function removeDecommissionItem(decommissionItemId: number) {
  return {
    payload: {
      decommissionItemId,
    },
    type: userActionTypes.REMOVE_DECOMMISSION_ITEM,
  };
}

export function showToastMessageAction(payload: IShowToastMessage) {
  return {
    payload,
    type: userActionTypes.SHOW_TOAST_MESSAGE,
  };
}

export function toggleServerMounting(payload: IToggleServerMounting) {
  return {
    payload,
    type: userActionTypes.TOGGLE_SERVER_MOUNTING,
  };
}

export function toggleServerDeletion(payload: IToggleServerDeletion) {
  return {
    payload,
    type: userActionTypes.TOGGLE_SERVER_DELETION,
  };
}

export function executeChargeNodes() {
  return {
    type: userActionTypes.CHARGE_NODES,
  };
}

export function executeNodeDeletion({ shouldDeleteOnlyReadyNodes }: IExecuteNodeDeletion) {
  return {
    payload: {
      shouldDeleteOnlyReadyNodes,
    },
    type: userActionTypes.EXECUTE_NODE_DELETION,
  };
}

export function toggleChargeNodesLoading() {
  return {
    type: userActionTypes.TOGGLE_CHARGE_NODES_LOADING,
  };
}

export function toggleNodeDeletionLoading() {
  return {
    type: userActionTypes.TOGGLE_NODE_DELETION_LOADING,
  };
}

export function toggleReadyNodeDeletionLoading() {
  return {
    type: userActionTypes.TOGGLE_READY_NODE_DELETION_LOADING,
  };
}

export function toggleOnchainPaymentLinkLoading() {
  return {
    type: userActionTypes.TOGGLE_ONCHAIN_PAYMENT_LINK_LOADING,
  };
}

export function createOnchainPaymentLink({ showPaymentLinkModalFunction }: ICreateOnchainPaymentLink) {
  return {
    payload: {
      showPaymentLinkModalFunction,
    },
    type: userActionTypes.CREATE_ONCHAIN_PAYMENT_LINK,
  };
}

export function setOnchainPaymentLinkData({ hosted_url, expires_at }: IOnchainPaymentLinkData) {
  return {
    payload: {
      hosted_url,
      expires_at,
    },
    type: userActionTypes.SET_ONCHAIN_PAYMENT_LINK_DATA,
  };
}

export function changeEmailAction(payload: IChangeEmail) {
  return {
    payload,
    type: userActionTypes.CHANGE_EMAIL,
  };
}

export function toggleChangeEmailLoading() {
  return {
    type: userActionTypes.TOGGLE_CHANGE_EMAIL_LOADING,
  };
}

export function updateEmailAddress(email: string) {
  return {
    payload: {
      email,
    },
    type: userActionTypes.UPDATE_EMAIL_ADDRESS,
  };
}

export function setEmailsForChangeEmail({ newEmail, confirmNewEmail }: ISetEmailsForChangeEmail) {
  return {
    payload: {
      newEmail,
      confirmNewEmail,
    },
    type: userActionTypes.SET_EMAILS_FOR_CHANGE_EMAIL,
  };
}

export function resetEmailChangeValues() {
  return {
    type: userActionTypes.RESET_EMAIL_CHANGE_VALUES,
  };
}

export function setShouldShowEmailChangeCodeField(shouldShowEmailChangeCodeField: boolean) {
  return {
    payload: {
      shouldShowEmailChangeCodeField,
    },
    type: userActionTypes.SET_SHOULD_SHOW_EMAIL_CHANGE_CODE_FIELD,
  };
}
