import dapsCoinLogoPng from 'assets/daps-logo.png';
import prcyLogoPng from 'assets/prcy-logo.png';
import btcLogoPng from 'assets/bitcoin-logo.png';
import ltcLogoPng from 'assets/litecoin-logo.png';
import ethLogoPng from 'assets/ethereum-logo.png';
import usdcLogoPng from 'assets/usdc-logo.png';
import paypalLogoPng from 'assets/paypal-logo.png';
import blkcLogo from 'assets/blkc-logo.webp';
import usdtLogo from 'assets/usdt-logo.webp';
import bdxLogo from 'assets/beldex.png';
import polygonLogo from 'assets/polygon-logo.webp';

import { Image } from './styles';

const LOGO_IMAGE_MAP: {
  [key: string]: string;
} = {
  daps: dapsCoinLogoPng,
  prcy: prcyLogoPng,
  btc: btcLogoPng,
  ltc: ltcLogoPng,
  eth: ethLogoPng,
  usdc: usdcLogoPng,
  usdt: usdtLogo,
  paypal: paypalLogoPng,
  blkc: blkcLogo,
  bdx: bdxLogo,
  matic: polygonLogo,
  pusdc: usdcLogoPng,
};

interface ICoinLogo {
  coin: string;
  width?: number;
  shouldUseBorderRadius?: boolean;
}

export function CoinLogo({
  coin,
  width = 50,
  shouldUseBorderRadius = true,
  ...props
}: ICoinLogo) {
  return (
    <Image
      src={LOGO_IMAGE_MAP[coin]}
      alt={coin}
      width={width}
      shouldUseBorderRadius={shouldUseBorderRadius}
      {...props}
    />
  );
}
