import { HiCommandLine } from 'react-icons/hi2';

interface IQuestion06 {
  collateralAmount: number;
  coin: string;
  confirmationsNeeded: number;
  collateralCommand: string;
}

const QUESTIONS = {
  question00: 'Is there a video tutorial?',
  question01: 'Should I send my coins to Higlan?',
  question02: 'What data do I need to build my node?',
  question03: 'What data do I need to use on Higlan?',
  question04: 'What data will Higlan give me?',
  question05: 'What do I need to do after I get my node data?',
  question06: (coinsDisplayName: string) => {
    const value = `How can I create my ${coinsDisplayName} node collateral?`;
    return value;
  },
  question07: 'What is the fattest way to build many nodes?',
  question08: 'I have questions, where can I get some help?',
};

const ANSWERS = {
  answer00: () => (
    <>
      Yes! Watch the{' '}
      <a href="https://www.youtube.com/watch?v=a89lIfWuUgw" target="_blank" rel="noopener noreferrer">
        YouTube video tutorial here
      </a>
      .
    </>
  ),
  answer01: 'No! Your coins will always be in your own wallet.',
  answer02: () => (
    <>
      All you need is the <strong>TxId</strong> and <strong>OutputID</strong> to enable your nodes.
    </>
  ),
  answer02_02: ({ collateral, coin }: { collateral: number; coin: string }) => (
    <>
      You need to have{' '}
      <strong>
        {collateral} {coin} + 1 (for transaction fee)
      </strong>{' '}
      as spendable in your <strong>desktop wallet</strong>.
    </>
  ),
  answer03: 'None. Higlan will give you all data to your nodes.',
  answer03_02: () => (
    <>
      You need to inform your <strong>wallet address</strong> at the moment you order your node.
    </>
  ),
  answer04: 'Your unique node private key and the ip address where your node is hosted.',
  answer04_02: () => (
    <>
      <p>
        Your <strong>node activation command</strong>. You will get it on your <strong>Dashboard</strong> by clicking on
        the icon "<HiCommandLine size={17} />" and you have to run this command in your <strong>desktop wallet</strong>{' '}
        to enable your node.
      </p>
      <p>
        On your desktop wallet click on <strong>MASTER NODES</strong> {'>'} <strong>REGISTRATION</strong> past the
        command and click on <strong>REGISTER MASTER NODE</strong>.
      </p>
    </>
  ),
  answer05: (port: number) => (
    <>
      <p>
        On your Dashboard page, click on the button <strong>DATA TO MY WALLET</strong> copy the entire line to your
        masternode.conf file in your wallet folder and replace TxId and OutputID.
      </p>
      <p>
        <strong>Example:</strong>
      </p>
      <p>
        <span>Line from your Dashboard page:</span>
        <br />
        <br />
        <span>node01 127.0.0.1:{port} 892WPpkqbr7sr6Si4fdsfssjjapuFzAXwETCrpPJubnrmU6aKzh "TxId" "OutputID"</span>
      </p>
      <p>
        <span>Line that you need in your masternode.conf:</span>
        <br />
        <br />
        <span>
          {`node01 127.0.0.1:${port}
          892WPpkqbr7sr6Si4fdsfssjjapuFzAXwETCrpPJubnrmU6aKzh
          c8f4965ea57a68d0e6dd384324dfd28cfbe0c801015b973e7331db8ce018716999 1`}
        </span>
      </p>
      <p>Save your masternode.conf, open your wallet, make sure your wallet is fully synchronized, start your node.</p>
    </>
  ),
  answer06: ({ collateralAmount, coin, confirmationsNeeded, collateralCommand }: IQuestion06) => (
    <>
      Send exact{' '}
      <strong>
        {collateralAmount} {coin}
      </strong>{' '}
      to your wallet, then in the wallet console type <strong>{collateralCommand}</strong>. Once the transaction has
      <strong> {confirmationsNeeded} confirmations</strong>, you can use it to enable your node.
    </>
  ),
  answer07: () => (
    <>
      <p>Let's take as an example you want to build 30 nodes.</p>
      <p>
        <strong>Main wallet: </strong> The wallet where you run the nodes.
      </p>
      <p>
        Send 30x the required collateral amount from one wallet/exchange to your main wallet. You don't have to wait for
        one transaction to finish before you start another one.
      </p>
      <p>
        When all 30 transactions arrive in your main wallet, you will have 30 node outputs, then you just need to order
        30 nodes on Higlan. You can use the TOOLS page here on Higlan to help you indentify the new node outputs.
      </p>
      <p>
        <strong>Important: </strong>
        Don't send 30x the required collateral amount from your main wallet to your main wallet, because you won't get
        30 node outputs. The wallet that is sending the amount <strong>must be different</strong> than the wallet that
        is receiving the coins. <strong>Turn staking off in both wallets</strong> before send/receive the coins, only
        turn staking on again after you put the new node outputs in your masternode.conf.
      </p>
    </>
  ),
  answer08:
    'Go to Higlan contact page and send your question on the form or via Telegram channel (this one you get a faster response).',
};

export const COIN_INFO = {
  prcy: {
    instructions: [
      {
        title: QUESTIONS.question01,
        answer: ANSWERS.answer01,
      },
      {
        title: QUESTIONS.question02,
        answer: ANSWERS.answer02,
      },
      {
        title: QUESTIONS.question03,
        answer: ANSWERS.answer03,
      },
      {
        title: QUESTIONS.question04,
        answer: ANSWERS.answer04,
      },
      {
        title: QUESTIONS.question05,
        answer: ANSWERS.answer05(59682),
      },
      {
        title: QUESTIONS.question06('PRivaCY'),
        answer: ANSWERS.answer06({
          coin: 'PRivaCY',
          collateralAmount: 5000,
          collateralCommand: 'masternode outputs',
          confirmationsNeeded: 15,
        }),
      },
      {
        title: QUESTIONS.question07,
        answer: ANSWERS.answer07,
      },
      {
        title: QUESTIONS.question08,
        answer: ANSWERS.answer08,
      },
    ],
  },
  daps: {
    instructions: [
      {
        title: QUESTIONS.question01,
        answer: ANSWERS.answer01,
      },
      {
        title: QUESTIONS.question02,
        answer: ANSWERS.answer02,
      },
      {
        title: QUESTIONS.question03,
        answer: ANSWERS.answer03,
      },
      {
        title: QUESTIONS.question04,
        answer: ANSWERS.answer04,
      },
      {
        title: QUESTIONS.question05,
        answer: ANSWERS.answer05(53572),
      },
      {
        title: QUESTIONS.question06('DAPS'),
        answer: ANSWERS.answer06({
          coin: 'DAPS',
          collateralAmount: 1000000,
          collateralCommand: 'masternode outputs',
          confirmationsNeeded: 15,
        }),
      },
      {
        title: QUESTIONS.question07,
        answer: ANSWERS.answer07,
      },
      {
        title: QUESTIONS.question08,
        answer: ANSWERS.answer08,
      },
    ],
  },
  blkc: {
    instructions: [
      {
        title: QUESTIONS.question01,
        answer: ANSWERS.answer01,
      },
      {
        title: QUESTIONS.question02,
        answer: ANSWERS.answer02,
      },
      {
        title: QUESTIONS.question03,
        answer: ANSWERS.answer03,
      },
      {
        title: QUESTIONS.question04,
        answer: ANSWERS.answer04,
      },
      {
        title: QUESTIONS.question05,
        answer: ANSWERS.answer05(7147),
      },
      {
        title: QUESTIONS.question06('BlackHat'),
        answer: ANSWERS.answer06({
          coin: 'BLKC',
          collateralAmount: 5000,
          collateralCommand: 'getmasternodeoutputs',
          confirmationsNeeded: 15,
        }),
      },
      {
        title: QUESTIONS.question07,
        answer: ANSWERS.answer07,
      },
      {
        title: QUESTIONS.question08,
        answer: ANSWERS.answer08,
      },
    ],
  },
  bdx: {
    instructions: [
      {
        title: QUESTIONS.question00,
        answer: ANSWERS.answer00,
      },
      {
        title: QUESTIONS.question01,
        answer: ANSWERS.answer01,
      },
      {
        title: QUESTIONS.question02,
        answer: ANSWERS.answer02_02({ collateral: 10000, coin: 'BDX' }),
      },
      {
        title: QUESTIONS.question03,
        answer: ANSWERS.answer03_02,
      },
      {
        title: QUESTIONS.question04,
        answer: ANSWERS.answer04_02,
      },
      {
        title: QUESTIONS.question08,
        answer: ANSWERS.answer08,
      },
    ],
  },
};
