import styled from 'styled-components/macro';
import { DISPLAY_FLEX_COLUMN_DEFAULT } from '~/style/constants';
import { TextInput } from '~/components/ui/TextInput';
import { BootstrapButton } from '~/components/ui/BootstrapButton';

export const ModalContentContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  font-size: 16px;
`;

export const StyledTextInput = styled(TextInput)`
  max-width: 400px;
`;

export const ChangeEmailForm = styled.form`
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  gap: 15px;
`;

export const StrongText = styled.strong``;

export const ConfirmCodeContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  gap: 7px;
`;

export const ConfirmCodeInput = styled(TextInput)`
  width: 80px;
  text-align: center;
  padding: 0;
`;

export const StyledBootstrapButton = styled(BootstrapButton)`
  min-width: 130px;
`;
