import styled from 'styled-components/macro';
import { DISPLAY_FLEX_COLUMN_DEFAULT } from '~/style/constants';
import { Help } from '../Help';

export const Container = styled.div`
  color: #fff;
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  padding-bottom: 15px;
`;

export const StyledHelp = styled(Help)`
  margin-top: 0;
`;
