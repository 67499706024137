import { authActionTypes } from '../auth/actions';
import { appActionTypes, IAppAction } from './actions';

const INITIAL_STATE = {
  isLoadingAppData: true,
  wasSomeRequestSent: false,
  loadingAppDataRequests: [],
  supportedCoins: [],
  isSendingMessage: false,
};

export const appReducer = (state = INITIAL_STATE, action: IAppAction) => {
  const { type, payload } = action;

  switch (type) {
    case appActionTypes.SET_SUPPORTED_COINS: {
      return {
        ...state,
        supportedCoins: [...payload],
      };
    }
    case appActionTypes.PUSH_LOADING_APP_DATA_REQUEST: {
      return {
        ...state,
        wasSomeRequestSent: true,
        loadingAppDataRequests: [...state.loadingAppDataRequests, payload],
      };
    }
    case appActionTypes.SPLICE_LOADING_APP_DATA_REQUEST: {
      const appDataRequests = [...state.loadingAppDataRequests];
      const requestIndex = appDataRequests.findIndex((requestName) => requestName === payload);
      if (requestIndex !== -1) {
        appDataRequests.splice(requestIndex, 1);
        return {
          ...state,
          loadingAppDataRequests: [...appDataRequests],
        };
      }
    }
    case appActionTypes.RESET_REQUESTS: {
      return {
        ...state,
        wasSomeRequestSent: false,
      };
    }
    case appActionTypes.START_LOADING_APP_DATA: {
      return {
        ...state,
        isLoadingAppData: true,
      };
    }
    case appActionTypes.STOP_LOADING_APP_DATA: {
      return {
        ...state,
        isLoadingAppData: false,
      };
    }
    case appActionTypes.SEND_MESSAGE: {
      return {
        ...state,
        isSendingMessage: true,
      };
    }
    case appActionTypes.STOP_LOADING_MESSAGE: {
      return {
        ...state,
        isSendingMessage: false,
      };
    }
    case authActionTypes.SIGN_OUT: {
      const stateToReset = {
        ...INITIAL_STATE,
        isLoadingAppData: false,
      };
      return stateToReset;
    }
    default: {
      return state;
    }
  }
};
