import { css } from 'styled-components/macro';

export const REDUCER_KEY = `Higlan-website-v3.0.0`;

// HIGLAN COLORS
export const HIGLAN_PURPLE_01 = '#7159c1';
export const HIGLAN_PURPLE_02 = '#ab59c1';
export const HIGLAN_PURPLE_03 = '#3b9eff';
export const HIGLAN_BLUE_01 = '#0000ff';
export const HIGLAN_BLUE_02 = '#007bff';
export const HIGLAN_GREEN_01 = '#28a745';
export const HIGLAN_GREEN_02 = '#90ee90';
export const HIGLAN_GREEN_03 = '#c9aa4c';
export const HIGLAN_GREEN_04 = '#008000';
export const HIGLAN_WHITE_01 = '#fff';
export const HIGLAN_WHITE_02 = '#ecedef';
export const HIGLAN_BLACK_01 = '#000000';
export const HIGLAN_RED_01 = '#df2c14';
export const HIGLAN_RED_02 = '#ff0000';
export const HIGLAN_ORANGE_01 = '#ffa500';
export const HIGLAN_YELLOW_01 = '#ffff00';
export const HIGLAN_GOLDEN_01 = '#FFD700';
export const HIGLAN_GRAY_01 = '#989898';
export const HIGLAN_GRAY_02 = '#656565';
export const HIGLAN_GRAY_03 = '#333333';
export const HIGLAN_GRAY_04 = '#f9f9f9';

const MOBILE_MAX_WIDTH_PX = 896;
const DESKTOP_MIN_WIDTH_PX = MOBILE_MAX_WIDTH_PX + 1;

export const MOBILE_MAX_WIDTH_WITH_UNITS = `${MOBILE_MAX_WIDTH_PX}px`;
export const DESKTOP_MIN_WIDTH_WITH_UNITS = `${DESKTOP_MIN_WIDTH_PX}px`;

export const DASHED_BORDER = css`
  border: 1px dashed ${HIGLAN_PURPLE_02};
`;

export const DISPLAY_FLEX_LINE_DEFAULT = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DISPLAY_FLEX_COLUMN_DEFAULT = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
