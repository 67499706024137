import { useDispatch, useSelector } from 'react-redux';
import { Modal } from '~/components/ui/Modal';
import { displayPriceWithCurrency } from '~/shared/string-utils';
import { withdraw } from '~/store/modules/faucet/actions';
import { getWithdrawLoading } from '~/store/modules/faucet/selectors';
import { BodyContainer, DescriptionText, FaucetBalanceText } from './styles';

interface IWithdrawModal {
  onHideModal: () => void;
  faucetBalance: number;
}

export function WithdrawModal({ onHideModal, faucetBalance }: IWithdrawModal) {
  const withdrawLoading = useSelector(getWithdrawLoading);
  const dispatch = useDispatch();

  const handleModalSubmit = () => {
    dispatch(withdraw({ onHideModal }));
  };

  const BodyContent = () => (
    <BodyContainer>
      <DescriptionText>Total to Withdraw</DescriptionText>
      <FaucetBalanceText>{displayPriceWithCurrency(faucetBalance, 4)}</FaucetBalanceText>
    </BodyContainer>
  );

  return (
    <Modal
      isSubmitting={withdrawLoading}
      size="sm"
      onHide={onHideModal}
      show={true}
      title="WITHDRAW"
      bodyContent={<BodyContent />}
      handleModalSubmit={handleModalSubmit}
    />
  );
}
