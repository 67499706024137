import styled, { css } from 'styled-components/macro';
import { darken } from 'polished';

import {
  DISPLAY_FLEX_COLUMN_DEFAULT,
  HIGLAN_WHITE_01,
  HIGLAN_PURPLE_01,
  HIGLAN_WHITE_02,
  HIGLAN_BLACK_01,
  HIGLAN_RED_01,
  HIGLAN_PURPLE_03,
  HIGLAN_GRAY_01,
  HIGLAN_GRAY_02,
} from '~/style/constants';

const ELEMENTS_WIDTH = '100%';

const DISABLED_INPUT_CSS = css`
  background: rgba(0, 0, 0, 0.1) !important;
  color: ${HIGLAN_GRAY_02} !important;
  cursor: not-allowed;
`;

const INVALID_VALUE_CSS = css`
  border: 2px solid ${HIGLAN_RED_01} !important;
`;

export const FormElement = styled.form`
  ${DISPLAY_FLEX_COLUMN_DEFAULT};
  gap: 15px;
  width: 100%;
  padding: 20px;
  border: 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background: linear-gradient(to bottom, ${HIGLAN_WHITE_01} 0%, ${HIGLAN_PURPLE_01} 100%);
`;

export const FormButton = styled.button<{ isRequestSent: boolean }>`
  margin: 5px 0;
  height: 44px;
  background: ${({ isRequestSent }) => (isRequestSent ? darken(0.3, HIGLAN_PURPLE_03) : HIGLAN_PURPLE_03)};
  font-weight: bold;
  color: #fff;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  transition: background 0.2s;
  width: ${ELEMENTS_WIDTH};
  &:hover {
    background: ${darken(0.1, HIGLAN_PURPLE_03)} !important;
  }
  &:disabled {
    cursor: not-allowed;
    background: ${darken(0.1, HIGLAN_PURPLE_03)} !important;
  }
`;

export const FormTitle = styled.div`
  font-weight: 500;
  font-size: 1.25rem;
`;

export const FormInput = styled.input<{ hasError: boolean }>`
  height: 44px;
  color: ${HIGLAN_BLACK_01} !important;
  background: linear-gradient(to bottom, ${HIGLAN_WHITE_01} 5%, ${HIGLAN_WHITE_02} 100%) !important;
  border: 0;
  border-radius: 4px;
  padding: 0 15px;
  margin: 0 0 10px;
  width: ${ELEMENTS_WIDTH};
  &:disabled {
    ${DISABLED_INPUT_CSS}
  }
  &::placeholder {
    color: ${HIGLAN_GRAY_01} !important;
  }
  ${({ hasError }) => hasError && INVALID_VALUE_CSS}
`;

export const InputLabel = styled.div`
  font-weight: 600;
`;

export const InputContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  width: 100%;
`;

export const InputTextArea = styled.textarea<{ hasError: boolean }>`
  background: linear-gradient(to bottom, ${HIGLAN_WHITE_01} 5%, ${HIGLAN_WHITE_02} 100%);
  margin: 0 0 10px;
  padding: 15px 15px;
  border: 0;
  border-radius: 4px;
  &:disabled {
    ${DISABLED_INPUT_CSS}
  }
  width: ${ELEMENTS_WIDTH};

  &::placeholder {
    color: ${HIGLAN_GRAY_01};
  }

  ${({ hasError }) => hasError && INVALID_VALUE_CSS}
`;
