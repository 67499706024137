import { darken } from 'polished';
import styled from 'styled-components/macro';
import { DISPLAY_FLEX_LINE_DEFAULT, HIGLAN_PURPLE_03 } from '~/style/constants';

export const ButtonContainer = styled.div`
  ${DISPLAY_FLEX_LINE_DEFAULT}
  cursor: pointer;
  width: 100%;
  color: #fff;
  opacity: 1;
  background-color: ${HIGLAN_PURPLE_03};
  height: 44px;
  font-size: 16px;
  border-radius: 4px;
  transition: background 0.2s;

  &:hover {
    background: ${darken(0.3, HIGLAN_PURPLE_03)};
    color: #fff;
  }
`;
