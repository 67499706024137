import { FiAlertOctagon } from 'react-icons/fi';
import history from '~/services/history';
import { displayPriceWithCurrency } from '~/shared/string-utils';
import { NormalText } from '../styles';
import { DepositText, OverdueChargesContainer } from './styles';

interface IOverdueAlert {
  totalOverdueCharges?: number;
  balance: number;
  balanceNeeded: number;
}
export function OverdueAlert({ totalOverdueCharges, balance, balanceNeeded }: IOverdueAlert) {
  const shouldShowAsOverdue = !!totalOverdueCharges;
  const redirectToAddFunds = () => {
    history.push('addfunds');
  };
  return (
    <OverdueChargesContainer isOverdue={shouldShowAsOverdue}>
      <FiAlertOctagon size={40} />
      {shouldShowAsOverdue ? (
        <>
          <NormalText
            dangerouslySetInnerHTML={{
              __html: `You have <strong>${totalOverdueCharges} nodes</strong> that will be deleted in the next hours`,
            }}
          />
          <NormalText
            dangerouslySetInnerHTML={{
              __html: `Balance needed to renew your <strong>${totalOverdueCharges} nodes</strong> this month: <strong>${displayPriceWithCurrency(
                balanceNeeded
              )}</strong>.`,
            }}
          />
        </>
      ) : (
        <>
          <NormalText
            dangerouslySetInnerHTML={{
              __html: `Your current balance <strong>${displayPriceWithCurrency(
                balance
              )}</strong> is not enough to renew your nodes this month.`,
            }}
          />
          <NormalText>{`To prevent your nodes from being deleted in the next billing cycle.`}</NormalText>
          <NormalText
            dangerouslySetInnerHTML={{
              __html: `Consider adding at least more <strong>${displayPriceWithCurrency(
                balanceNeeded
              )}</strong> to your balance.`,
            }}
          />
        </>
      )}
      <DepositText onClick={redirectToAddFunds}>ADD FUNDS</DepositText>
    </OverdueChargesContainer>
  );
}
