import { AiFillPauseCircle } from 'react-icons/ai';
import { FaCheckCircle, FaRegQuestionCircle } from 'react-icons/fa';
import { HiCommandLine } from 'react-icons/hi2';
import { IoIosCopy } from 'react-icons/io';
import { MdDelete } from 'react-icons/md';
import { BootstrapButton } from '~/components/ui/BootstrapButton';
import { Modal } from '~/components/ui/Modal';
import { Tooltip } from '~/components/ui/Tooltip';
import { copyTextToClipboard } from '~/shared/string-utils';
import { INodeStatus } from '~/store/modules/interfaces';
import { EditType } from '..';
import { INodeToEdit } from '../DataTable';
import {
  BolderText,
  ItemContainer,
  ItemsContainer,
  ModalBody,
  NormalText,
  StyledTextArea,
  SuccessfullyActivatedMessage,
  TextWarning,
} from '../styles';
import {
  ContactLink,
  DeletionCodeTextArea,
  RefundContainer,
  WalletAddressContainer,
  WalletAddressInfoContainer,
} from './styles';
import { Checkbox } from '~/components/ui/Checkbox';
import { useLocation } from 'react-router-dom';

interface IModalContent {
  type?: EditType;
  alias?: string;
  coin?: string;
  privateKey?: string;
  nodeStatus: INodeStatus;
  activationCommand?: string;
  wallet_address?: string;
}

interface INodeModal {
  editedNode: INodeToEdit;
  handleModalSubmit: () => void;
  handleHideModal: () => void;
  handleRefund?: () => void;
  isEditingNode: boolean;
  shouldRefund?: boolean;
  shouldShowDeletionCode?: boolean;
  onChangeDeletionCode?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

interface IModalFooter {
  handleRefund?: () => void;
  hasRefundOption?: boolean;
  shouldRefund?: boolean;
  shouldShowDeletionCode?: boolean;
  onChangeDeletionCode?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const ModalContent = ({
  alias,
  privateKey,
  coin,
  type,
  activationCommand,
  nodeStatus,
  wallet_address,
}: IModalContent) => {
  const getSubtitleText = () => {
    let textToShow = '';
    const pauseOrDeleteText = `You are about to <strong>${String(type).toUpperCase()}</strong> this node.`;
    const activationText = 'Bellow you can find the activation command for this node.';

    switch (type) {
      case 'pause':
      case 'delete': {
        textToShow = pauseOrDeleteText;
        break;
      }
      case 'getActivationCommand': {
        textToShow = nodeStatus === 'activated' ? '' : activationText;
        break;
      }
      default: {
      }
    }

    return textToShow;
  };

  const getDescriptionText = () => {
    let textToShow: any = '';
    const deleteText = `After you delete this node, it will be automatically stopped in our
    servers and you will no longer get more rewards from it.`;
    const pauseText = (
      <>
        Pausing the node will make it to stay offline of the blockchain network during <strong>1 hour</strong>. With
        this time the node will be automatically disabled by the network and it will be possible to enable this node
        again using a different collateral when it gets to the status <strong>ready</strong>. You may still get rewards
        from this node during that time.
      </>
    );
    const activationText = (
      <>
        Copy the command above and paste it in your <strong>desktop wallet</strong> to enable your node to get rewards.
      </>
    );
    const successfullyActivatedMessage = (
      <SuccessfullyActivatedMessage>
        <FaCheckCircle color="green" size={25} />
        <NormalText>Your node is successfully enabled!</NormalText>
        <NormalText>Enjoy your rewards.</NormalText>
      </SuccessfullyActivatedMessage>
    );
    switch (type) {
      case 'delete': {
        textToShow = deleteText;
        break;
      }
      case 'pause': {
        textToShow = pauseText;
        break;
      }
      case 'getActivationCommand': {
        textToShow = nodeStatus === 'activated' ? successfullyActivatedMessage : activationText;
        break;
      }
      default: {
      }
    }

    return textToShow;
  };
  const handleCopyClick = (activationCommand: string) => {
    copyTextToClipboard(activationCommand);
  };

  const shouldShowActivationCommand = nodeStatus !== 'activated' && type === 'getActivationCommand';

  return (
    <ModalBody>
      <NormalText
        dangerouslySetInnerHTML={{
          __html: getSubtitleText(),
        }}
      />
      <ItemsContainer>
        <ItemContainer>
          <BolderText>Coin</BolderText>
          <NormalText>{coin}</NormalText>
        </ItemContainer>
        <ItemContainer>
          <BolderText>Alias</BolderText>
          <NormalText>{`node${alias}`}</NormalText>
        </ItemContainer>
        {privateKey && (
          <ItemContainer>
            <BolderText>Node Key</BolderText>
            <StyledTextArea minHeight={30} defaultValue={privateKey} disabled />
          </ItemContainer>
        )}
        {wallet_address && (
          <ItemContainer>
            <WalletAddressContainer>
              <BolderText>Wallet Address</BolderText>
              {nodeStatus === 'ready' && shouldShowActivationCommand && (
                <Tooltip
                  content={
                    <WalletAddressInfoContainer>
                      The activation command bellow only works for this wallet, if you need to make it work in a
                      different wallet, <ContactLink to="contact">contact support</ContactLink>.
                    </WalletAddressInfoContainer>
                  }
                  shouldUseZIndex
                >
                  <FaRegQuestionCircle />
                </Tooltip>
              )}
            </WalletAddressContainer>
            <StyledTextArea minHeight={50} defaultValue={wallet_address} disabled />
          </ItemContainer>
        )}
        {shouldShowActivationCommand && (
          <ItemContainer>
            <BolderText>Activation Command</BolderText>
            {activationCommand ? (
              <>
                <StyledTextArea minHeight={170} defaultValue={activationCommand} disabled />
                <BootstrapButton
                  variant="info"
                  onClick={() => handleCopyClick(activationCommand)}
                  shouldShowTooltip={true}
                  tooltipText="Copied!"
                >
                  COPY COMMAND
                  <IoIosCopy />
                </BootstrapButton>
              </>
            ) : (
              <StyledTextArea
                minHeight={50}
                textAlign="center"
                defaultValue="(The activation command for this node is being generated and should be available here in the next 5
                minutes)."
                disabled
              />
            )}
          </ItemContainer>
        )}
      </ItemsContainer>
      {type === 'delete' ? (
        <TextWarning>{getDescriptionText()}</TextWarning>
      ) : (
        <NormalText>{getDescriptionText()}</NormalText>
      )}
    </ModalBody>
  );
};

const ModalFooter = ({
  handleRefund,
  hasRefundOption,
  shouldRefund = false,
  shouldShowDeletionCode,
  onChangeDeletionCode,
}: IModalFooter) => {
  if (hasRefundOption) {
    return (
      <RefundContainer>
        <Checkbox onClick={handleRefund} label="REFUND?" checked={shouldRefund} />
      </RefundContainer>
    );
  }

  if (shouldShowDeletionCode) {
    return (
      <ItemContainer>
        <BolderText>Deletion Code</BolderText>
        <DeletionCodeTextArea maxLength={6} onChange={onChangeDeletionCode} />
      </ItemContainer>
    );
  }

  return null;
};

export function NodeModal({
  editedNode,
  handleModalSubmit,
  handleRefund,
  handleHideModal,
  isEditingNode,
  shouldRefund,
  shouldShowDeletionCode,
  onChangeDeletionCode,
}: INodeModal) {
  const hasDeletionCode = editedNode.editType === 'delete' && shouldShowDeletionCode;
  const location = useLocation();
  const isAtDashboardRoute = location.pathname === '/dashboard';
  const hasRefundOption =
    editedNode.coin === 'Beldex' &&
    editedNode.status === 'ready' &&
    editedNode.editType === 'delete' &&
    !isAtDashboardRoute;

  const getModalIcon = () => {
    const editType = editedNode?.editType;

    switch (editType) {
      case 'pause': {
        return <AiFillPauseCircle size={30} />;
      }
      case 'delete': {
        return <MdDelete size={30} />;
      }
      case 'getActivationCommand': {
        return <HiCommandLine size={30} />;
      }
      default: {
        return;
      }
    }
  };

  const getModalTitle = () => {
    const editType = editedNode?.editType;

    switch (editType) {
      case 'pause': {
        return 'PAUSE NODE';
      }
      case 'delete': {
        return 'DELETE NODE';
      }
      case 'getActivationCommand': {
        return 'ACTIVATION COMMAND';
      }
      default: {
        return '';
      }
    }
  };

  return (
    <Modal
      bodyContent={
        <ModalContent
          type={editedNode?.editType}
          alias={editedNode?.alias}
          coin={editedNode?.coin}
          privateKey={editedNode?.private_key}
          activationCommand={editedNode?.activationCommand}
          nodeStatus={editedNode?.status}
          wallet_address={editedNode?.wallet_address}
        />
      }
      footerContent={
        <ModalFooter
          hasRefundOption={hasRefundOption}
          handleRefund={handleRefund}
          shouldRefund={shouldRefund}
          shouldShowDeletionCode={hasDeletionCode}
          onChangeDeletionCode={onChangeDeletionCode}
        />
      }
      handleModalSubmit={handleModalSubmit}
      title={getModalTitle()}
      show={true}
      onHide={handleHideModal}
      submitButtonIcon={getModalIcon()}
      isSubmitting={isEditingNode}
      submitButtonVariant={editedNode?.editType === 'delete' ? 'danger' : 'info'}
      hasConfirmation={editedNode?.editType !== 'getActivationCommand'}
    />
  );
}
