import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

export const LinkText = styled(Link)`
  font-size: 16px;
  color: #fff;

  &:hover {
    color: #fff;
  }
`;
