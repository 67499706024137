import { IFaucetHistory } from '../interfaces';

const prefix = '@faucet';

interface IFaucetActionTypes {
  SEND_FAUCET_CAPTCHA: string;
  START_LOADING: string;
  STOP_LOADING: string;
  SET_TIME_FOR_NEXT_ROUND: string;
  GET_FAUCET_VALUES: string;
  SET_FAUCET_BASE_VALUE: string;
  SET_FAUCET_BALANCE: string;
  SET_FAUCET_HISTORY: string;
  SET_MIN_VALUE_FOR_WITHDRAW: string;
  SET_ALL_TIME_EARNED: string;
  WITHDRAW: string;
  TOGGLE_WITHDRAW_LOADING: string;
}

export const faucetActionTypes: IFaucetActionTypes = {
  SEND_FAUCET_CAPTCHA: `${prefix}/SEND_FAUCET_CAPTCHA`,
  START_LOADING: `${prefix}/START_LOADING`,
  STOP_LOADING: `${prefix}/STOP_LOADING`,
  SET_TIME_FOR_NEXT_ROUND: `${prefix}/SET_TIME_FOR_NEXT_ROUND`,
  GET_FAUCET_VALUES: `${prefix}/GET_FAUCET_VALUES`,
  SET_FAUCET_BASE_VALUE: `${prefix}/SET_FAUCET_BASE_VALUE`,
  SET_FAUCET_BALANCE: `${prefix}/SET_FAUCET_BALANCE`,
  SET_FAUCET_HISTORY: `${prefix}/SET_FAUCET_HISTORY`,
  SET_MIN_VALUE_FOR_WITHDRAW: `${prefix}/SET_MIN_VALUE_FOR_WITHDRAW`,
  SET_ALL_TIME_EARNED: `${prefix}/SET_ALL_TIME_EARNED`,
  WITHDRAW: `${prefix}/WITHDRAW`,
  TOGGLE_WITHDRAW_LOADING: `${prefix}/TOGGLE_WITHDRAW_LOADING`,
};

export type IFaucetAction = ISendFaucetCaptcha | ISetTimeForNextRound;

interface ISetTimeForNextRound {
  payload: number;
  type: typeof faucetActionTypes.SET_TIME_FOR_NEXT_ROUND;
}

export interface ISendFaucetCaptcha {
  payload: string;
  type: typeof faucetActionTypes.SEND_FAUCET_CAPTCHA;
}

export interface IWithdraw {
  payload: IWithdrawPayload;
  type: typeof faucetActionTypes.WITHDRAW;
}

export interface IWithdrawPayload {
  onHideModal: () => void;
}

export const sendFaucetCaptcha = (captchaToken: string) => ({
  type: faucetActionTypes.SEND_FAUCET_CAPTCHA,
  payload: captchaToken,
});

export const startFaucetApiLoading = () => ({
  type: faucetActionTypes.START_LOADING,
});

export const stopFaucetApiLoading = () => ({
  type: faucetActionTypes.STOP_LOADING,
});

export const setTimeForNextRound = (time: number) => ({
  type: faucetActionTypes.SET_TIME_FOR_NEXT_ROUND,
  payload: time,
});

export const getFaucetValues = () => ({
  type: faucetActionTypes.GET_FAUCET_VALUES,
});

export const setFaucetBaseValue = (faucetValue: number) => ({
  type: faucetActionTypes.SET_FAUCET_BASE_VALUE,
  payload: faucetValue,
});

export const setFaucetBalance = (faucetBalance: number) => ({
  type: faucetActionTypes.SET_FAUCET_BALANCE,
  payload: faucetBalance,
});

export const setMinValueForWithdraw = (minValueForWithdraw: number) => ({
  type: faucetActionTypes.SET_MIN_VALUE_FOR_WITHDRAW,
  payload: minValueForWithdraw,
});

export const setAllTimeEarned = (allTimeEarned: number) => ({
  type: faucetActionTypes.SET_ALL_TIME_EARNED,
  payload: allTimeEarned,
});

export const setFaucetHistory = (faucetHistory: IFaucetHistory[]) => ({
  type: faucetActionTypes.SET_FAUCET_HISTORY,
  payload: faucetHistory,
});

export const withdraw = ({ onHideModal }: IWithdrawPayload) => ({
  type: faucetActionTypes.WITHDRAW,
  payload: {
    onHideModal
  },
});

export const toggleWithdrawLoading = () => ({
  type: faucetActionTypes.TOGGLE_WITHDRAW_LOADING,
});
