import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uuid from 'react-uuid';
import { MdDelete } from 'react-icons/md';

import { BootstrapButton } from '~/components/ui/BootstrapButton';
import { DataTable } from '~/components/ui/DataTable';
import { Modal } from '~/components/ui/Modal';
import {
  displayNodeAlias,
  displayPriceWithCurrency,
  emptyFunction,
  formatDate,
  reduceKey,
} from '~/shared/string-utils';
import {
  IAllAddresses,
  IAllUsers,
  IDecommissionHistory,
  IDeletedNodes,
  IFaucetHistory,
  INodesCreated,
  INodesEnabledLast30Days,
  INodeRestoration,
  IUserActivity,
  IUserFunds,
  IFundsTransfers,
} from '~/store/modules/interfaces';
import { ItemContainer, NormalItem, StrongItem } from '../styles';
import {
  ActionContainer,
  AllAddressesContainer,
  AllUserContainer,
  ButtonsContainer,
  DataTableContainer,
  DataTableSection,
  ExplorerLink,
  ItemsInLineContainer,
  UserFundsContainer,
} from './styles';
import { BELDEX_EXPLORER_LINK } from '~/shared/constants';
import { renderNodeKey } from '~/pages/Dashboard/shared';
import { DepositChart } from './DepositChart';
import { deleteDecommissionDataAction, deleteDecommissionItemAction } from '~/store/modules/user/actions';
import { getIsDeletingDecommissionData, getLoadingIdsForDecommissionItems } from '~/store/modules/user/selectors';
import { Tooltip } from '~/components/ui/Tooltip';
import { Spinner } from 'react-bootstrap';

interface IModalContent {
  userFunds: IUserFunds;
  allUsers: IAllUsers;
  nodesEnabledLast30Days: INodesEnabledLast30Days[];
  nodesCreated: {
    thisMonth: INodesCreated[];
    last30Days: INodesCreated[];
  };
  allAddresses: IAllAddresses;
  deletedNodes: IDeletedNodes;
  userActivity: IUserActivity[];
  faucetHistory: IFaucetHistory;
  decommissionHistory: IDecommissionHistory[];
  nodeRestoration: INodeRestoration;
  fundsTransfers: IFundsTransfers;
}

interface IExtraDataModal {
  handleHideModal: () => void;
  userFunds: IUserFunds;
  allUsers: IAllUsers;
  nodesEnabledLast30Days: INodesEnabledLast30Days[];
  nodesCreated: {
    thisMonth: INodesCreated[];
    last30Days: INodesCreated[];
  };
  allAddresses: IAllAddresses;
  deletedNodes: IDeletedNodes;
  userActivity: IUserActivity[];
  faucetHistory: IFaucetHistory;
  decommissionHistory: IDecommissionHistory[];
  nodeRestoration: INodeRestoration;
  fundsTransfers: IFundsTransfers;
}

const USER_FUNDS_COLUMNS = ['ID', 'Name', 'Email', 'Balance', 'Bonus', 'Faucet Balance'];
const NODES_ENABLED_COLUMNS = ['ID', 'Coin', 'Alias', 'Node Key', 'User Email', 'User Name', 'Enabled At'];
const NODES_CREATED_COLUMNS = [
  'ID',
  'Coin',
  'Alias',
  'Months',
  'Status',
  'Node Key',
  'User Email',
  'User Name',
  'Created At',
];
const NEW_USERS_COLUMNS = ['ID', 'Email', 'Name', 'Balance', 'Bonus', 'Created At'];
const DEPOSIT_COLUMNS = ['Name', 'Email', 'Coin', 'Credited', 'Amount', 'TxID', 'Updated At'];
const FAUCET_HISTORY_COLUMNS = ['ID', 'Name', 'Email', 'Bonus Credit', 'Faucet Balance', 'Created At'];
const FAUCET_HISTORY_TODAY_COLUMNS = ['Name', 'Email', 'Faucet Balance'];
const DECOMMISSION_HISTORY_COLUMNS = [
  'Node ID',
  'Node Alias',
  'Decommission Date',
  'Is Unlock Initiated',
  'IP',
  'Server Alias',
  'Name',
  'Email',
  'Action',
];
const DELETED_NODES_COLUMNS = [
  'ID',
  'Coin',
  'Alias',
  'Status',
  'Node Key',
  'Email',
  'Price',
  'Server Alias',
  'Restoration Status',
  'Deletion Source',
  'Created At',
];
const USER_ACTIVITY_COLUMNS = [
  'ID',
  'Name',
  'Email',
  'Balance',
  'Bonus',
  'Last Active Time Ago',
  'Last Active Date and Time',
];
const RESTORED_NODES_COLUMNS = [
  'User Email',
  'User Name',
  'Last Active',
  'Coin',
  'Alias',
  'Price',
  'Node Key',
  'Server Alias',
  'Created At',
];

const NODES_THAT_CAN_BE_RESTORED_COLUMNS = [
  'User Email',
  'User Name',
  'Last Active',
  'Coin',
  'Node Key',
  'Price',
  'Server Alias',
  'Deletion Source',
  'Created At',
];

const TRANSFERED_FUNDS_COLUMNS = [
  'ID',
  'Transferor Email',
  'Receiver Email',
  'Balance Transfered',
  'Bonus Transfered',
  'Created At',
];

type DATA_OPTIONS =
  | 'user-funds'
  | 'deposits'
  | 'deleted-nodes'
  | 'enabled-nodes'
  | 'created-nodes'
  | 'new-users'
  | 'user-activity'
  | 'faucet-history'
  | 'decommission-history'
  | 'node-restoration'
  | 'funds-transfers';

function ModalContent({
  userFunds,
  allUsers,
  nodesEnabledLast30Days,
  allAddresses,
  deletedNodes,
  nodesCreated,
  userActivity,
  faucetHistory,
  decommissionHistory,
  nodeRestoration,
  fundsTransfers,
}: IModalContent) {
  const dispatch = useDispatch();
  const isDeletingDecommissionData = useSelector(getIsDeletingDecommissionData);
  const loadingIdsForDecommissionItems = useSelector(getLoadingIdsForDecommissionItems);

  const getShouldShowLoadingForDeletingDecommissionItem = (decommissionItemId: number) => {
    const isIdInTheArray = loadingIdsForDecommissionItems.includes(decommissionItemId);
    return isIdInTheArray;
  };
  const handleDeleteDecommissionItem = (decommissionItemId: number) => () => {
    dispatch(deleteDecommissionItemAction(decommissionItemId));
  };
  const [dataOption, setDataOption] = useState<DATA_OPTIONS>('user-funds');
  const { totalBalance, totalBonus, generalTotal, usersWithBalance, usersWithBonus, users } = userFunds;
  const {
    totalAddresses,
    concluded,
    pending,
    expired,
    earnedToday,
    earnedThisMonth,
    earnedLast30Days,
    totalEarned,
    annualDeposits,
  } = allAddresses;
  const {
    total,
    verifiedEmails,
    notVerifiedEmails,
    receivePromotions,
    notReceivePromotions,
    masternodesToStartNotificationEnabled,
    masternodesToStartNotificationNotEnabled,
    newUsersLast30days,
    newUsersLast7Days,
    newUsersToday,
  } = allUsers;
  const { restoredNodesLast30Days, nodesThatCanBeRestored } = nodeRestoration;
  const { fundsTransfersLast30Days } = fundsTransfers;

  const userFundsTableLines = users.map((userItem) => {
    const { balance, bonus, email, faucet_balance, id, name } = userItem;

    return [
      id,
      name,
      email,
      displayPriceWithCurrency(balance),
      displayPriceWithCurrency(bonus),
      displayPriceWithCurrency(faucet_balance, 3),
    ];
  });
  const nodesEnabledLast30DaysLines = nodesEnabledLast30Days.map((nodeItem) => {
    const { id, coin, alias, private_key, enabled_at, user } = nodeItem;

    return [
      id,
      coin.toUpperCase(),
      displayNodeAlias(alias),
      reduceKey(private_key),
      user.email,
      user.name,
      formatDate(new Date(enabled_at)),
    ];
  });
  const nodesCreatedLast30DaysLines = nodesCreated.last30Days.map((nodeItem) => {
    const { id, status, monthChargeDifference, coin, alias, private_key, created_at, user } = nodeItem;

    return [
      id,
      coin.toUpperCase(),
      displayNodeAlias(alias),
      monthChargeDifference,
      status,
      reduceKey(private_key),
      user.email,
      user.name,
      formatDate(new Date(created_at)),
    ];
  });
  const newUsersLast30DaysLines = newUsersLast30days.map((userItem) => {
    const { id, email, name, balance, bonus, created_at } = userItem;

    return [
      id,
      email,
      name,
      displayPriceWithCurrency(balance),
      displayPriceWithCurrency(bonus),
      formatDate(new Date(created_at)),
    ];
  });
  const addressesLast30DaysLines = allAddresses.addressesLast30Days.map((addressItem) => {
    const { name, email, coin, amount_received, dollar_amount, transaction_id, updated_at } = addressItem;

    return [
      name,
      email,
      coin,
      displayPriceWithCurrency(dollar_amount),
      `${amount_received} ${coin}`,
      reduceKey(transaction_id),
      formatDate(new Date(updated_at)),
    ];
  });
  const userActivityLast30DaysLines = faucetHistory.userActivityLast30Days.map((faucetActivityItem) => {
    const { userId, name, email, bonus_credit, faucet_balance, created_at } = faucetActivityItem;

    return [
      userId,
      name,
      email,
      displayPriceWithCurrency(bonus_credit, 3),
      displayPriceWithCurrency(faucet_balance, 3),
      formatDate(new Date(created_at)),
    ];
  });
  const faucetTodayUsersLines = faucetHistory.todayTotals.users.map((faucetTodayItem) => {
    const { name, email, faucet_balance } = faucetTodayItem;

    return [name, email, displayPriceWithCurrency(faucet_balance, 3)];
  });
  const deletedNodesLast30DaysLines = deletedNodes.nodesDeletedLast30Days.map((deletedNodeItem) => {
    const {
      id,
      email,
      coin,
      alias,
      private_key_used,
      price,
      restoration_status,
      server_alias,
      created_at,
      deletion_source,
      status,
    } = deletedNodeItem;

    return [
      id,
      coin.toLocaleUpperCase(),
      displayNodeAlias(alias),
      status,
      renderNodeKey(coin, private_key_used),
      email,
      displayPriceWithCurrency(price),
      server_alias,
      restoration_status || '-',
      deletion_source,
      formatDate(new Date(created_at)),
    ];
  });
  const decommissionHistoryLines = decommissionHistory.map((decommissionHistoryItem) => {
    const { node_id, node_alias, decommission_date, is_unlock_initiated, ip, server_alias, name, email, id } =
      decommissionHistoryItem;
    const isDeletingDecommissionItem = getShouldShowLoadingForDeletingDecommissionItem(id);

    return [
      node_id,
      node_alias,
      formatDate(new Date(decommission_date)),
      is_unlock_initiated ? 'true' : 'false',
      ip,
      server_alias,
      name,
      email,
      <ActionContainer>
        {isDeletingDecommissionItem ? (
          <Spinner size="sm" animation="border" variant="black" />
        ) : (
          <Tooltip content="Delete Item" key={uuid()}>
            <MdDelete size={17} cursor="pointer" onClick={handleDeleteDecommissionItem(id)} />
          </Tooltip>
        )}
      </ActionContainer>,
    ];
  });
  const userActivityLines = userActivity.map((userActivityItem) => {
    const { id, name, email, balance, bonus, last_active_time_ago, last_active_date_and_time } = userActivityItem;

    return [id, name, email, balance, bonus, last_active_time_ago, last_active_date_and_time];
  });
  const restoredNodesLast30DaysLines = restoredNodesLast30Days.map((restoredNodeItem) => {
    const { coin, alias, price, private_key, server_alias, created_at } = restoredNodeItem;
    const { name, email, last_active_time_ago } = restoredNodeItem.user;

    return [
      email,
      name,
      last_active_time_ago,
      String(coin).toUpperCase(),
      displayNodeAlias(alias),
      displayPriceWithCurrency(price),
      <ExplorerLink href={`${BELDEX_EXPLORER_LINK}/mn/${private_key}`} target="_blank">
        {reduceKey(private_key)}
      </ExplorerLink>,
      server_alias,
      formatDate(new Date(created_at)),
    ];
  });

  const nodesThatCanBeRestoredLines = nodesThatCanBeRestored.map((nodeThatCanBeRestoreItem) => {
    const { coin, private_key_used, price, server_alias, deletion_source, created_at } = nodeThatCanBeRestoreItem;
    const { name, email, last_active_time_ago } = nodeThatCanBeRestoreItem.user;

    return [
      name,
      email,
      last_active_time_ago,
      String(coin).toLocaleUpperCase(),
      <ExplorerLink href={`${BELDEX_EXPLORER_LINK}/mn/${private_key_used}`} target="_blank">
        {reduceKey(private_key_used)}
      </ExplorerLink>,
      displayPriceWithCurrency(price),
      server_alias,
      deletion_source,
      formatDate(new Date(created_at)),
    ];
  });

  const fundsTransfersLast30DaysLines = fundsTransfersLast30Days.map((fundsTransfersLast30DaysItem) => {
    const { id, balance_transfered, bonus_transfered, createdAt } = fundsTransfersLast30DaysItem;
    const { email: whoTransferedFundsEmail } = fundsTransfersLast30DaysItem.who_transfered_funds;
    const { email: whoGotFundsEmail } = fundsTransfersLast30DaysItem.who_got_funds;

    return [
      id,
      whoTransferedFundsEmail,
      whoGotFundsEmail,
      displayPriceWithCurrency(balance_transfered),
      displayPriceWithCurrency(bonus_transfered),
      formatDate(new Date(createdAt)),
    ];
  });

  const handleSelectOption = (value: DATA_OPTIONS) => {
    switch (value) {
      case 'deposits': {
        setDataOption('deposits');
        break;
      }
      case 'enabled-nodes': {
        setDataOption('enabled-nodes');
        break;
      }
      case 'created-nodes': {
        setDataOption('created-nodes');
        break;
      }
      case 'node-restoration': {
        setDataOption('node-restoration');
        break;
      }
      case 'user-funds': {
        setDataOption('user-funds');
        break;
      }
      case 'deleted-nodes': {
        setDataOption('deleted-nodes');
        break;
      }
      case 'decommission-history': {
        setDataOption('decommission-history');
        break;
      }
      case 'new-users': {
        setDataOption('new-users');
        break;
      }
      case 'user-activity': {
        setDataOption('user-activity');
        break;
      }
      case 'funds-transfers': {
        setDataOption('funds-transfers');
        break;
      }
      case 'faucet-history': {
        setDataOption('faucet-history');
        break;
      }
    }
  };

  const handleDeleteDecommissionData = () => {
    dispatch(deleteDecommissionDataAction());
  };

  return (
    <>
      <UserFundsContainer>
        <ItemContainer>
          <StrongItem>{displayPriceWithCurrency(totalBalance)}</StrongItem>
          <NormalItem>Total Balance</NormalItem>
        </ItemContainer>
        <ItemContainer>
          <StrongItem>{displayPriceWithCurrency(totalBonus)}</StrongItem>
          <NormalItem>Total Bonus</NormalItem>
        </ItemContainer>
        <ItemContainer>
          <StrongItem>{displayPriceWithCurrency(generalTotal)}</StrongItem>
          <NormalItem>General Total (Balance + Bonus)</NormalItem>
        </ItemContainer>
        <ItemContainer>
          <StrongItem>{usersWithBalance}</StrongItem>
          <NormalItem>Users with Balance</NormalItem>
        </ItemContainer>
        <ItemContainer>
          <StrongItem>{usersWithBonus}</StrongItem>
          <NormalItem>Users with Bonus</NormalItem>
        </ItemContainer>
      </UserFundsContainer>
      <AllUserContainer>
        <ItemContainer>
          <StrongItem>{total}</StrongItem>
          <NormalItem>Total users</NormalItem>
        </ItemContainer>
        <ItemContainer>
          <StrongItem>{verifiedEmails}</StrongItem>
          <NormalItem>Verified Emails</NormalItem>
        </ItemContainer>
        <ItemContainer>
          <StrongItem>{notVerifiedEmails}</StrongItem>
          <NormalItem>Not Verified Emails</NormalItem>
        </ItemContainer>
        <ItemContainer>
          <StrongItem>{receivePromotions}</StrongItem>
          <NormalItem>Receive Promotions</NormalItem>
        </ItemContainer>
        <ItemContainer>
          <StrongItem>{notReceivePromotions}</StrongItem>
          <NormalItem>Not Receive Promotions</NormalItem>
        </ItemContainer>
        <ItemContainer>
          <StrongItem>{masternodesToStartNotificationEnabled}</StrongItem>
          <NormalItem>Nodes Notification Enabled</NormalItem>
        </ItemContainer>
        <ItemContainer>
          <StrongItem>{masternodesToStartNotificationNotEnabled}</StrongItem>
          <NormalItem>Nodes Notification Not Enabled</NormalItem>
        </ItemContainer>
        <ItemContainer>
          <StrongItem>{newUsersLast30days.length}</StrongItem>
          <NormalItem>New Users Last 30 days</NormalItem>
        </ItemContainer>
        <ItemContainer>
          <StrongItem>{newUsersLast7Days}</StrongItem>
          <NormalItem>New Users Last 7 days</NormalItem>
        </ItemContainer>
        <ItemContainer>
          <StrongItem>{newUsersToday}</StrongItem>
          <NormalItem>New Users Today</NormalItem>
        </ItemContainer>
      </AllUserContainer>
      <AllAddressesContainer>
        <ItemContainer>
          <StrongItem>{displayPriceWithCurrency(totalEarned)}</StrongItem>
          <NormalItem>Total Earned</NormalItem>
        </ItemContainer>
        <ItemContainer>
          <StrongItem>{displayPriceWithCurrency(earnedToday)}</StrongItem>
          <NormalItem>Earned Today</NormalItem>
        </ItemContainer>
        <ItemContainer>
          <StrongItem>{displayPriceWithCurrency(earnedThisMonth)}</StrongItem>
          <NormalItem>Earned This Month</NormalItem>
        </ItemContainer>
        <ItemContainer>
          <StrongItem>{displayPriceWithCurrency(earnedLast30Days)}</StrongItem>
          <NormalItem>Earned Last 30 Days</NormalItem>
        </ItemContainer>
        <ItemContainer>
          <StrongItem>{totalAddresses}</StrongItem>
          <NormalItem>Total Addresses</NormalItem>
        </ItemContainer>
        <ItemContainer>
          <StrongItem>{concluded}</StrongItem>
          <NormalItem>Concluded</NormalItem>
        </ItemContainer>
        <ItemContainer>
          <StrongItem>{pending}</StrongItem>
          <NormalItem>Pending</NormalItem>
        </ItemContainer>
        <ItemContainer>
          <StrongItem>{expired}</StrongItem>
          <NormalItem>Expired</NormalItem>
        </ItemContainer>
      </AllAddressesContainer>
      <ButtonsContainer>
        <BootstrapButton
          onClick={() => handleSelectOption('user-funds')}
          variant={dataOption === 'user-funds' ? 'success' : 'primary'}
        >
          User Funds
        </BootstrapButton>
        <BootstrapButton
          onClick={() => handleSelectOption('new-users')}
          variant={dataOption === 'new-users' ? 'success' : 'primary'}
        >
          New Users
        </BootstrapButton>
        <BootstrapButton
          onClick={() => handleSelectOption('user-activity')}
          variant={dataOption === 'user-activity' ? 'success' : 'primary'}
        >
          User Activity
        </BootstrapButton>
        <BootstrapButton
          onClick={() => handleSelectOption('faucet-history')}
          variant={dataOption === 'faucet-history' ? 'success' : 'primary'}
        >
          Faucet History
        </BootstrapButton>
        <BootstrapButton
          onClick={() => handleSelectOption('deposits')}
          variant={dataOption === 'deposits' ? 'success' : 'primary'}
        >
          Deposit History
        </BootstrapButton>
        <BootstrapButton
          onClick={() => handleSelectOption('created-nodes')}
          variant={dataOption === 'created-nodes' ? 'success' : 'primary'}
        >
          Nodes Created
        </BootstrapButton>
        <BootstrapButton
          onClick={() => handleSelectOption('deleted-nodes')}
          variant={dataOption === 'deleted-nodes' ? 'success' : 'primary'}
        >
          Deleted Nodes
        </BootstrapButton>
        <BootstrapButton
          onClick={() => handleSelectOption('node-restoration')}
          variant={dataOption === 'node-restoration' ? 'success' : 'primary'}
        >
          Node Restoration
        </BootstrapButton>
        <BootstrapButton
          onClick={() => handleSelectOption('funds-transfers')}
          variant={dataOption === 'funds-transfers' ? 'success' : 'primary'}
        >
          Funds Transfers
        </BootstrapButton>
        <BootstrapButton
          onClick={() => handleSelectOption('enabled-nodes')}
          variant={dataOption === 'enabled-nodes' ? 'success' : 'primary'}
        >
          Nodes Enabled
        </BootstrapButton>
        <BootstrapButton
          onClick={() => handleSelectOption('decommission-history')}
          variant={dataOption === 'decommission-history' ? 'success' : 'primary'}
        >
          Decommission History
        </BootstrapButton>
      </ButtonsContainer>
      {dataOption === 'user-funds' && (
        <DataTableContainer>
          <ItemContainer>
            <StrongItem>{userFundsTableLines.length}</StrongItem>
            <NormalItem>Total users with balance or bonus</NormalItem>
          </ItemContainer>
          <DataTable columns={USER_FUNDS_COLUMNS} lines={userFundsTableLines} />
        </DataTableContainer>
      )}
      {dataOption === 'faucet-history' && (
        <DataTableContainer>
          <ItemsInLineContainer>
            <ItemContainer>
              <StrongItem>{faucetHistory.total}</StrongItem>
              <NormalItem>All Time User Activity</NormalItem>
            </ItemContainer>
            <ItemContainer>
              <StrongItem>{faucetHistory.userActivityLast30Days.length}</StrongItem>
              <NormalItem>User Activity Last 30 Days</NormalItem>
            </ItemContainer>
            <ItemContainer>
              <StrongItem>{displayPriceWithCurrency(faucetHistory.allTimeEarned, 3)}</StrongItem>
              <NormalItem>All Time Earned</NormalItem>
            </ItemContainer>
            <ItemContainer>
              <StrongItem>{displayPriceWithCurrency(faucetHistory.totalLast30Days, 3)}</StrongItem>
              <NormalItem>Total Last 30 Days</NormalItem>
            </ItemContainer>
          </ItemsInLineContainer>
          <ItemsInLineContainer>
            <ItemContainer>
              <StrongItem>{faucetHistory.todayTotals.totalUsers}</StrongItem>
              <NormalItem>Today's User Activity</NormalItem>
            </ItemContainer>
            <ItemContainer>
              <StrongItem>{displayPriceWithCurrency(faucetHistory.todayTotals.totalEarned)}</StrongItem>
              <NormalItem>Today's Total Earned</NormalItem>
            </ItemContainer>
          </ItemsInLineContainer>
          <DataTableSection>
            <StrongItem>Today's Users:</StrongItem>
            <DataTable columns={FAUCET_HISTORY_TODAY_COLUMNS} lines={faucetTodayUsersLines} />
          </DataTableSection>
          <DataTableSection>
            <StrongItem>Last 30 Days User's Activity:</StrongItem>
            <DataTable columns={FAUCET_HISTORY_COLUMNS} lines={userActivityLast30DaysLines} />
          </DataTableSection>
        </DataTableContainer>
      )}
      {dataOption === 'deposits' && (
        <DataTableContainer>
          <DepositChart annualDeposits={annualDeposits} />
          <ItemContainer>
            <StrongItem>{addressesLast30DaysLines.length}</StrongItem>
            <NormalItem>Deposits concluded Last 30 days</NormalItem>
          </ItemContainer>
          <DataTable columns={DEPOSIT_COLUMNS} lines={addressesLast30DaysLines} />
        </DataTableContainer>
      )}
      {dataOption === 'created-nodes' && (
        <DataTableContainer>
          <ItemsInLineContainer>
            <ItemContainer>
              <StrongItem>{nodesCreated.thisMonth.length}</StrongItem>
              <NormalItem>Nodes created this month</NormalItem>
            </ItemContainer>
            <ItemContainer>
              <StrongItem>{nodesCreatedLast30DaysLines.length}</StrongItem>
              <NormalItem>Nodes created last 30 days</NormalItem>
            </ItemContainer>
          </ItemsInLineContainer>
          <DataTable
            shouldUseCheckDataType={false}
            columns={NODES_CREATED_COLUMNS}
            lines={nodesCreatedLast30DaysLines}
          />
        </DataTableContainer>
      )}
      {dataOption === 'node-restoration' && (
        <DataTableContainer>
          <ItemsInLineContainer>
            <ItemContainer>
              <StrongItem>{nodesThatCanBeRestoredLines.length}</StrongItem>
              <NormalItem>Nodes that can be restored</NormalItem>
            </ItemContainer>
            <ItemContainer>
              <StrongItem>{restoredNodesLast30DaysLines.length}</StrongItem>
              <NormalItem>Restored nodes last 30 days</NormalItem>
            </ItemContainer>
          </ItemsInLineContainer>
          <DataTableSection>
            <StrongItem>Nodes that can be restored:</StrongItem>
            <DataTable
              shouldUseCheckDataType={false}
              columns={NODES_THAT_CAN_BE_RESTORED_COLUMNS}
              lines={nodesThatCanBeRestoredLines}
            />
          </DataTableSection>
          <DataTableSection>
            <StrongItem>Nodes restored last 30 days:</StrongItem>
            <DataTable
              shouldUseCheckDataType={false}
              columns={RESTORED_NODES_COLUMNS}
              lines={restoredNodesLast30DaysLines}
            />
          </DataTableSection>
        </DataTableContainer>
      )}
      {dataOption === 'funds-transfers' && (
        <DataTableContainer>
          <ItemsInLineContainer>
            <ItemContainer>
              <StrongItem>{fundsTransfersLast30DaysLines.length}</StrongItem>
              <NormalItem>Transfered Funds last 30 days</NormalItem>
            </ItemContainer>
          </ItemsInLineContainer>
          <DataTable
            shouldUseCheckDataType={false}
            columns={TRANSFERED_FUNDS_COLUMNS}
            lines={fundsTransfersLast30DaysLines}
          />
        </DataTableContainer>
      )}
      {dataOption === 'enabled-nodes' && (
        <DataTableContainer>
          <ItemContainer>
            <StrongItem>{nodesEnabledLast30DaysLines.length}</StrongItem>
            <NormalItem>Nodes enabled last 30 days</NormalItem>
          </ItemContainer>
          <DataTable columns={NODES_ENABLED_COLUMNS} lines={nodesEnabledLast30DaysLines} />
        </DataTableContainer>
      )}
      {dataOption === 'deleted-nodes' && (
        <DataTableContainer>
          <ItemsInLineContainer>
            <ItemContainer>
              <StrongItem>{deletedNodes.total}</StrongItem>
              <NormalItem>Total nodes deleted</NormalItem>
            </ItemContainer>
            <ItemContainer>
              <StrongItem>{deletedNodes.nodesDeletedThisMonth.length}</StrongItem>
              <NormalItem>Nodes deleted this month</NormalItem>
            </ItemContainer>
            <ItemContainer>
              <StrongItem>{deletedNodesLast30DaysLines.length}</StrongItem>
              <NormalItem>Nodes deleted last 30 days</NormalItem>
            </ItemContainer>
          </ItemsInLineContainer>
          <DataTable columns={DELETED_NODES_COLUMNS} lines={deletedNodesLast30DaysLines} />
        </DataTableContainer>
      )}
      {dataOption === 'decommission-history' && (
        <DataTableContainer>
          {decommissionHistoryLines.length > 0 && (
            <>
              <BootstrapButton
                variant="danger"
                onClick={handleDeleteDecommissionData}
                disabled={isDeletingDecommissionData}
              >
                DELETE ALL
              </BootstrapButton>
              <ItemContainer>
                <StrongItem>{decommissionHistoryLines.length}</StrongItem>
                <NormalItem>Total Decommission History</NormalItem>
              </ItemContainer>
            </>
          )}
          <DataTable columns={DECOMMISSION_HISTORY_COLUMNS} lines={decommissionHistoryLines} />
        </DataTableContainer>
      )}
      {dataOption === 'new-users' && (
        <DataTableContainer>
          <ItemContainer>
            <StrongItem>{newUsersLast30days.length}</StrongItem>
            <NormalItem>New users last 30 days</NormalItem>
          </ItemContainer>
          <DataTable columns={NEW_USERS_COLUMNS} lines={newUsersLast30DaysLines} />
        </DataTableContainer>
      )}
      {dataOption === 'user-activity' && (
        <DataTableContainer>
          <ItemContainer>
            <StrongItem>{userActivityLines.length}</StrongItem>
            <NormalItem>Total User Activity</NormalItem>
          </ItemContainer>
          <DataTable columns={USER_ACTIVITY_COLUMNS} lines={userActivityLines} />
        </DataTableContainer>
      )}
    </>
  );
}

export function ExtraDataModal({
  handleHideModal,
  userFunds,
  allUsers,
  nodesEnabledLast30Days,
  allAddresses,
  deletedNodes,
  nodesCreated,
  userActivity,
  faucetHistory,
  decommissionHistory,
  nodeRestoration,
  fundsTransfers,
}: IExtraDataModal) {
  return (
    <Modal
      bodyContent={
        <ModalContent
          userFunds={userFunds}
          allUsers={allUsers}
          nodesCreated={nodesCreated}
          nodesEnabledLast30Days={nodesEnabledLast30Days}
          allAddresses={allAddresses}
          deletedNodes={deletedNodes}
          userActivity={userActivity}
          faucetHistory={faucetHistory}
          decommissionHistory={decommissionHistory}
          nodeRestoration={nodeRestoration}
          fundsTransfers={fundsTransfers}
        />
      }
      title="EXTRA DATA"
      show={true}
      handleModalSubmit={emptyFunction}
      onHide={handleHideModal}
      hasConfirmation={false}
      size="xl"
    />
  );
}
