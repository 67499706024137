import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import user from './user/sagas';
import app from './app/saga';
import coinChainData from './coin-chain-data/saga';
import faucet from './faucet/saga';

export default function* rootSaga() {
  return yield all([auth, user, app, coinChainData, faucet]);
}
