import styled from 'styled-components/macro';

import { DISPLAY_FLEX_COLUMN_DEFAULT, DISPLAY_FLEX_LINE_DEFAULT } from '~/style/constants';
import { PAYMENT_CONTAINER_HEIGHT } from '../styles';

export const OnchainContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  justify-content: space-between;
  min-height: ${PAYMENT_CONTAINER_HEIGHT};
`;

export const DescriptionContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  gap: 30px;
`;

export const Description = styled.div`
  font-size: 16px;
  text-align: center;
`;
export const BolderText = styled.strong``;

export const ImageContainer = styled.div`
  ${DISPLAY_FLEX_LINE_DEFAULT}
  gap: 17px;
`;

export const Image = styled.img`
  border-radius: 50%;
  width: 30px;
  margin: 0 auto;
`;

export const PaymentContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  margin-top: 50px;
`;
