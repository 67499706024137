import styled from 'styled-components/macro';
import { mobileOnly } from 'style/mixins';
import { Modal } from 'components/ui/Modal';
import { PromoBanner } from '~/components/PromoBanner';

export const Content = styled.div`
  text-align: center;

  header {
    h1 {
      font-size: 35px;
    }
  }
`;

export const CoinContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 30px;

  ${mobileOnly`
    justify-content: center;
    align-items: flex-start;
  `}
`;

export const Header = styled.header`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 10px;
  position: relative;
`;

export const CoinSuggestion = styled.button`
  background: none;
  border: 0;
  font-size: 80%;
  color: #7159c1;

  &:hover {
    text-decoration: underline;
  }
`;

export const ItemStrong = styled.strong``;

export const StyledModal = styled(Modal)``;

export const StyledPromoBanner = styled(PromoBanner)`
  position: absolute;
  right: 0;
  top: 0;
`
