import { coinChainDataTypes, ICoinChainDataAction } from './actions';
import { ICoinChainData } from '../interfaces';
import { authActionTypes } from '../auth/actions';

const INITIAL_STATE: IInitialState = {
  allCoinChainData: [],
};

interface IInitialState {
  allCoinChainData: ICoinChainData[];
}

export const coinChainData = (state = INITIAL_STATE, { type, payload }: ICoinChainDataAction) => {
  switch (type) {
    case coinChainDataTypes.SET_LATEST_BLOCK_AND_TOTAL_NODES: {
      return {
        ...state,
        allCoinChainData: [...payload],
      };
    }
    case authActionTypes.SIGN_OUT: {
      return {
        ...INITIAL_STATE,
      };
    }
    default: {
      return state;
    }
  }
};
