import { useDispatch, useSelector } from 'react-redux';
import { Modal } from '~/components/ui/Modal';
import { TextInput } from '~/components/ui/TextInput';
import { setAdminCode } from '~/store/modules/user/actions';
import { getIsSubmittingAdminCode } from '~/store/modules/user/selectors';
import { StrongItem } from '../styles';
import { ModalBodyContainer } from './styles';

interface IAdminCodeModal {
  handleHideModal: () => void;
  handleSubmit: () => void;
}

interface IModalBody {
  onAdminCodeChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

function ModalBody({ onAdminCodeChange }: IModalBody) {
  return (
    <ModalBodyContainer>
      <StrongItem>To use an action, it is necessary to inform your ADMIN CODE first.</StrongItem>
      <TextInput onChange={onAdminCodeChange} />
    </ModalBodyContainer>
  );
}
export function AdminCodeModal({ handleHideModal, handleSubmit }: IAdminCodeModal) {
  const dispatch = useDispatch();
  const isSubmitting = useSelector(getIsSubmittingAdminCode);

  const onAdminCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    dispatch(setAdminCode(inputValue));
  };

  return (
    <Modal
      bodyContent={<ModalBody onAdminCodeChange={onAdminCodeChange} />}
      title="ADMIN CODE"
      show={true}
      handleModalSubmit={handleSubmit}
      onHide={handleHideModal}
      hasConfirmation={true}
      isSubmitting={isSubmitting}
      size="sm"
    />
  );
}
