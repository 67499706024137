import styled from 'styled-components/macro';

export const Container = styled.div`
  background: linear-gradient(to bottom, #fff 5%, #ecedef 100%);
  color: black;
  font-weight: normal;

  .status-data {
      display: grid;
      grid-template-columns: 270px 230px;
      grid-template-rows: 50px 50px 80px 50px;

      .item1 {
        display: flex;
        align-items: center;
        justify-content: center;
        grid-column-start: 1;
        grid-column-end: 1;
      }
      .item2 {
        display: flex;
        align-items: center;
        justify-content: center;
        grid-column-start: 2;
        grid-column-end: 2;
      }
      .item3 {
        border-top: 2px solid #7D7E76;
        border-bottom: 2px solid #7D7E76;
        border-right: 2px solid #7D7E76;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        grid-column-start: 1;
        grid-column-end: 1;
      }
      .item4 {
        border-top: 2px solid #7D7E76;
        border-bottom: 2px solid #7D7E76;
        display: flex;
        align-items: center;
        justify-content: center;
        grid-column-start: 2;
        grid-column-end: 2;
      }
      .item5 {
        border-bottom: 2px solid #7D7E76;
        border-right: 2px solid #7D7E76;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        grid-column-start: 1;
        grid-column-end: 1;
      }
      .item6 {
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        border-bottom: 2px solid #7D7E76;
        grid-column-start: 2;
        grid-column-end: 2;
      }
      .item7 {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        border-right: 2px solid #7D7E76;
        grid-column-start: 1;
        grid-column-end: 1;
      }
      .item8 {
        display: flex;
        align-items: center;
        justify-content: center;
        grid-column-start: 2;
        grid-column-end: 2;
      }

      #mounting {
        color: gray;
      }

      #ready {
        color: orange;
      }

      #activated {
        color: green;
      }

      svg {
        margin-right: 5px;
      }
    }
`;
