import styled from 'styled-components/macro';
import { Input } from '@rocketseat/unform';
import { HIGLAN_WHITE_01 } from '~/style/constants';

export const StyledUnformInput = styled(Input)`
  background: rgba(0, 0, 0, 0.1);
  border: 0;
  border-radius: 4px;
  height: 44px;
  padding: 0 15px;
  color: ${HIGLAN_WHITE_01};
  width: 100%;

  &::placeholder {
    color: rgba(255, 255, 255, 0.7);
  }
`;
