import { formatDate, reduceKey } from '~/shared/string-utils';
import { INodeDetails } from '~/store/modules/interfaces';
import { OrderedTextContainer } from '../DataTable/styles';
import { ExplorerLink, NormalText } from '../styles';
import { FaCheckCircle } from 'react-icons/fa';
import { MdQueryBuilder } from 'react-icons/md';
import { FiAlertTriangle } from 'react-icons/fi';
import { Tooltip } from '~/components/ui/Tooltip';
import { BELDEX_EXPLORER_LINK } from '~/shared/constants';

export const getStatusIcon = (node: INodeDetails, isForAdmin: boolean) => {
  const { status, createdAt, enabled_at } = node;
  const formattedDate = formatDate(new Date(createdAt));
  const formattedLastEnabledDate = enabled_at ? formatDate(new Date(enabled_at)) : null;
  const orderedText = `Ordered at: ${formattedDate}`;
  const lastEnabledText = `Last enabled at: ${formattedLastEnabledDate}`;
  const renderOrderedText = (statusText: string) => {
    return (
      <OrderedTextContainer>
        <NormalText>{statusText}</NormalText>
        <NormalText>{orderedText}</NormalText>
        {isForAdmin && (
          <NormalText>{lastEnabledText}</NormalText>
        )}
      </OrderedTextContainer>
    );
  };
  if (status === 'mounting') {
    return (
      <Tooltip content={renderOrderedText('Mounting node.')}>
        <MdQueryBuilder color="grey" />
      </Tooltip>
    );
  }
  if (status === 'ready') {
    return (
      <Tooltip content={renderOrderedText('Node is ready.')}>
        <FiAlertTriangle color="orange" />
      </Tooltip>
    );
  }
  if (status === 'activated') {
    return (
      <Tooltip content={renderOrderedText('Node successfully enabled!')}>
        <FaCheckCircle color="green" />
      </Tooltip>
    );
  }
  return;
};

export const renderNodeKey = (coin: string, private_key: string) => {
  if (!private_key) {
    return '-';
  }

  if (coin !== 'bdx') {
    return reduceKey(private_key);
  }

  return (
    <ExplorerLink href={`${BELDEX_EXPLORER_LINK}/mn/${private_key}`} target="_blank">
      {reduceKey(private_key)}
    </ExplorerLink>
  );
};
