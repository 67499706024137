import { ReactElement, useEffect } from 'react';
import { Button, Modal as ModalBootstrap, ModalProps } from 'react-bootstrap';
import { useExistScrollBar } from '~/hooks/use-exist-scroll-bar';
import { BootstrapButton } from '../BootstrapButton';

import { GlobalStylesForModal, Title, Footer, ButtonsContainer } from './styles';

interface IModal extends ModalProps {
  handleModalSubmit?: () => void;
  title: string | ReactElement;
  submitButtonVariant?: string;
  submitButtonIcon?: ReactElement;
  shouldShowFooter?: boolean;
  bodyContent: ReactElement;
  footerContent?: ReactElement;
  orderTotal?: number;
  isSubmitting?: boolean;
  submitText?: string;
  hasConfirmation?: boolean;
}

const FOOTER_STYLES = {
  justifyContent: 'center',
};

export function Modal({
  handleModalSubmit,
  title,
  bodyContent,
  footerContent,
  orderTotal,
  submitButtonVariant = 'success',
  submitText = 'CONFIRM',
  isSubmitting,
  submitButtonIcon,
  shouldShowFooter = true,
  hasConfirmation = true,
  ...props
}: IModal) {
  const { isThereYScrollBar } = useExistScrollBar();

  const onPressEnter = (e: KeyboardEvent) => {
    if (!hasConfirmation) return;
    const { key } = e;
    if (key === 'Enter' && handleModalSubmit) {
      handleModalSubmit();
    }
  };

  useEffect(() => {
    document.addEventListener('keypress', onPressEnter);

    return () => {
      document.removeEventListener('keypress', onPressEnter);
    };
  }, []);
  return (
    <>
      <GlobalStylesForModal shouldHideScrollBarY={!isThereYScrollBar} />
      <ModalBootstrap {...props} aria-labelledby="contained-modal-title-vcenter" centered>
        <ModalBootstrap.Header closeButton>
          <Title>
            <ModalBootstrap.Title>
              {submitButtonIcon && submitButtonIcon}
              {title}
            </ModalBootstrap.Title>
          </Title>
        </ModalBootstrap.Header>
        <ModalBootstrap.Body>{bodyContent}</ModalBootstrap.Body>
        {shouldShowFooter && (
          <ModalBootstrap.Footer style={FOOTER_STYLES}>
            <Footer>
              {footerContent}
              <ButtonsContainer>
                <Button onClick={props.onHide}>CLOSE</Button>
                {hasConfirmation && (
                  <BootstrapButton variant={submitButtonVariant} onClick={handleModalSubmit} disabled={isSubmitting}>
                    {submitButtonIcon && submitButtonIcon}
                    {submitText}
                  </BootstrapButton>
                )}
              </ButtonsContainer>
            </Footer>
          </ModalBootstrap.Footer>
        )}
      </ModalBootstrap>
    </>
  );
}
