import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { ChartOptions } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export interface DatasetItem {
  label: string;
  data: number[];
  backgroundColor: string;
}
interface ChartProps {
  chartTitle: string;
  labels: string[];
  datasets: DatasetItem[];
  scaleXText: string;
  scaleYText: string;
}

// example data: https://codesandbox.io/s/github/reactchartjs/react-chartjs-2/tree/master/sandboxes/bar/vertical?from-embed=&file=/App.tsx:1010-1047
export function Chart({ chartTitle, labels, datasets, scaleXText, scaleYText }: ChartProps) {
  const options: ChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: chartTitle,
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        title: {
          display: true,
          text: scaleXText,
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: scaleYText,
        },
      },
    },
  };

  const data = {
    labels,
    datasets,
  };

  return (
    <>
      {/* @ts-ignore */}
      <Bar type="bar" options={options} data={data} />
    </>
  );
}
