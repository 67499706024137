import { MainContent } from '../Home';
import { FaqContainer, FaqTitle, StyledInstructions } from './styles';
import history from 'services/history';

const redirectToPublicContact = () => {
  history.push('/public-contact');
};

const HIGLAN_FAQ = [
  {
    title: 'Is it necessary to send my coins to Higlan?',
    answer:
      'No! When you use Higlan to make your nodes work for you, your coins will always be in your wallet, after all "not your keys, not your coins".',
  },
  {
    title: 'How does the Higlan billing cycle work?',
    answer:
      'The billing cycle is every month, each node has its own charge date, when that date has come, the node will be automatically renewed for 1 more month if you have enough balance.',
  },
  {
    title: `What happens if I don't have enough balance to renew a node?`,
    answer: `When your Higlan balance is not enough to renew a node, you will see a red warning on your Dashboard, you will also get email warnings about it. You have 24 hours to deposit the necessary value to renew the node, if that doesn't happen, the node will be deleted`,
  },
  {
    title: `Can I restore my deleted node?`,
    answer: () => (
      <>
        If it is a <strong>BDX node that is still active in the BDX network</strong>, yes you can recover the node and
        keep getting rewards from it.
      </>
    ),
  },
  {
    title: `What can I do with my funds on Higlan?`,
    answer: () => (
      <>
        Besides creating new nodes and paying for the hosting fee, your funds (balance and bonus) can also be transfered
        to a <strong>different Higlan account</strong>, that gives you the opportunity to negotiate your funds with
        other users. This feature is available on your <strong>profile</strong>.
      </>
    ),
  },
  {
    title: `Is it possible to get free credits to deploy and test a node?`,
    answer: () => (
      <>
        Yes! From time to time Higlan has some promotions which can give you some free credits, so you may want to stay
        tuned on our{' '}
        <a href="https://t.me/higlan_support" target="_blank">
          Telegram Channel
        </a>
        . Higlan also has a <strong>Faucet</strong> program where you can claim some free credits in a small interval of
        time.
      </>
    ),
  },
  {
    title: `How can I get more clarification?`,
    answer: () => (
      <>
        You can send your question on the{' '}
        <a href="https://t.me/higlan_support" target="_blank">
          Telegram Channel
        </a>
        . You can also send it in our <a onClick={redirectToPublicContact}>Contact Form</a>.
      </>
    ),
  },
];

export function FAQ() {
  return (
    <MainContent>
      <FaqContainer>
        <FaqTitle>Higlan FAQ</FaqTitle>
        <StyledInstructions instructions={HIGLAN_FAQ} />
      </FaqContainer>
    </MainContent>
  );
}
