import styled from 'styled-components/macro';
import { DISPLAY_FLEX_COLUMN_DEFAULT, HIGLAN_WHITE_01 } from '~/style/constants';

export const NoMatchContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  gap: 25px;
  color: ${HIGLAN_WHITE_01};
`;

export const NotFoundContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  font-size: 20px;
`;
