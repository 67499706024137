import styled from 'styled-components/macro';

export const Content = styled.div<{ isRequestSent: boolean }>`
  margin: 5px 5px 5px 5px;
  text-align: center;

  header {
    p {
      font-weight: 600;
    }
  }

  main {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
