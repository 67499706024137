// @ts-check
import { useRef, useEffect, useState, useCallback, Fragment } from 'react';
import { isSameMonth, isSameDay, isAfter, subDays } from 'date-fns';
import { Table, Button, Overlay, Tooltip, Spinner } from 'react-bootstrap';
import { FaCheckCircle } from 'react-icons/fa';
import { MdQueryBuilder } from 'react-icons/md';
import { IoIosCopy } from 'react-icons/io';
import prettyMilliseconds from 'pretty-ms';

import api from 'services/api';

import { Container } from 'pages/_layouts/default/styles';
import { Content } from './styles';
import { displayDefaultDateFormat, displayPriceWithCurrency } from '~/shared/string-utils';

export default function Admin() {
  const [mnData, setMnData] = useState([]);
  const [showMnData, setShowMnData] = useState(true);
  const [show, setShow] = useState(false);
  const [mnDataText, setMnDataText] = useState('');
  const [mnDataNumbers, setmnDataNumbers] = useState({
    dolarTotal: 0,
    chargesMonth: 0,
    createdToday: 0,
    totalUsers: 0,
    blockDelayed: 0,
    freeGenkeys: 0,
    delayedCharges: 0,
    chargesToday: 0,
  });
  const [serversData, setServersData] = useState([]);
  const [coinsData, setCoinsData] = useState([]);
  const [mnStatus, setMnStatus] = useState({
    total: 0,
    mounting: 0,
    ready: 0,
    activated: 0,
  });
  const [loading, setLoading] = useState(true);
  const target = useRef(null);
  const [userIdsShowData, setUsersIdsShowData] = useState([]);

  const blockRange = 200;

  const fillDataCallback = useCallback((results) => {
    const [adminData] = results;

    const { allUsersMasternodes: userMnData, freeGenkeys, allServers, allCoins } = adminData.value.data;

    let dolarTotal = 0;
    let chargesMonth = 0;
    let createdToday = 0;
    const totalUsers = userMnData.length;
    let blockDelayed = 0;
    let total = 0;
    let mounting = 0;
    let ready = 0;
    let activated = 0;
    let stringMnData = '';
    let delayedCharges = 0;
    let chargesToday = 0;

    setLoading(false);
    const rightNow = new Date();

    const checkLatestBlock = (coin, block) => {
      for (const item of allCoins) {
        if (coin === item.coin) {
          if (item.latestBlock - block > blockRange) return true;
        }
      }

      return false;
    };

    for (const i in userMnData) {
      const { user_id, masternodes } = userMnData[i];

      for (const k in masternodes) {
        const { price, next_charge, createdAt, block, coin } = masternodes[k];
        const { alias, real_ip, private_key, txid, outputid } = masternodes[k];

        total += 1;
        dolarTotal += price;
        if (txid) {
          stringMnData += `ID${user_id}_mn${alias} ${real_ip}:53572 ${private_key} ${txid} ${outputid}\n`;
        } else {
          stringMnData += `#ID${user_id}_mn${alias} ${real_ip}:53572 ${private_key} ${txid} ${outputid}\n`;
        }

        if (checkLatestBlock(coin, block)) blockDelayed += 1;

        if (isSameMonth(rightNow, new Date(next_charge))) chargesMonth += price;

        if (isAfter(subDays(rightNow, 1), new Date(next_charge))) delayedCharges += 1;

        if (isSameDay(rightNow, new Date(createdAt))) createdToday += 1;

        if (isSameDay(rightNow, new Date(next_charge))) chargesToday += price;

        if (masternodes[k].status && masternodes[k].status === 'mounting') mounting += 1;
        else if (masternodes[k].status && masternodes[k].status === 'ready') ready += 1;
        else if (masternodes[k].status && masternodes[k].status === 'activated') activated += 1;
      }
    }

    setMnDataText(stringMnData);

    setmnDataNumbers({
      dolarTotal,
      chargesMonth,
      createdToday,
      totalUsers,
      blockDelayed,
      freeGenkeys,
      delayedCharges,
      chargesToday,
    });
    setServersData(allServers);
    setCoinsData(allCoins);
    setMnData(userMnData);

    setMnStatus({
      total,
      mounting,
      ready,
      activated,
    });
  }, []);

  useEffect(() => {
    async function getMnData() {
      try {
        const apiMnData = await Promise.allSettled([api.get('all_masternodes')]);

        fillDataCallback(apiMnData);
      } catch (err) {
        getMnData();
      }
    }

    getMnData();
  }, [fillDataCallback]);

  function reducePrivateKey(privaKey) {
    const chNumber = privaKey.length;

    const subPrivateKey = `${privaKey.substr(0, 6)}...${privaKey.substr(chNumber - 6, chNumber)}`;

    return subPrivateKey;
  }

  function getDelayedOrLatestBlock(block, coin) {
    for (const item of coinsData) {
      if (coin === item.coin) {
        if (!block) return item.latestBlock;

        if (item.latestBlock - block > blockRange) return true;
        return false;
      }
    }
  }

  // function getStatusIcon(status) {
  //   if (status === 'mounting')
  //     return (
  //       <MdQueryBuilder mounting="mounting" title="Mounting masternode." />
  //     );
  //   else if (status === 'ready')
  //     return <FaCheckCircle className="ready" title="Masternode is ready." />;
  //   else if (status === 'activated')
  //     return (
  //       <FaCheckCircle
  //         className="activated"
  //         title="Masternode successfully started!"
  //       />
  //     );
  // }

  function handleCopyClick(data) {
    setShow(true);

    const textArea = document.createElement('textarea');

    textArea.value = data;
    document.body.appendChild(textArea);
    textArea.select();

    document.execCommand('copy');
    textArea.remove();

    setTimeout(() => setShow(false), 800);
  }

  function handleShowUserMasternodeData(user_id) {
    const arrUserIds = [...userIdsShowData];

    if (Array.isArray(user_id)) {
      if (arrUserIds.length === user_id.length) {
        setUsersIdsShowData([]);
      } else {
        user_id.forEach((user) => {
          arrUserIds.indexOf(user.user_id) === -1 && arrUserIds.push(user.user_id);
        });

        setUsersIdsShowData([...arrUserIds]);
      }
    } else {
      const userIdIndex = arrUserIds.indexOf(user_id);

      if (userIdIndex !== -1) {
        arrUserIds.splice(userIdIndex, 1);
        setUsersIdsShowData([...arrUserIds]);
      } else {
        setUsersIdsShowData([...arrUserIds, user_id]);
      }
    }
  }

  return (
    <Container style={{ maxWidth: 1100 }}>
      <Content>
        <header>
          <div className="user-data">
            <div className="items-data">
              <strong>{displayPriceWithCurrency(mnDataNumbers.dolarTotal)}</strong>
              <span>Total</span>
            </div>
            <div className="items-data">
              <strong>{displayPriceWithCurrency(mnDataNumbers.chargesMonth)}</strong>
              <span>Monthly Charges</span>
            </div>
            <div className="items-data">
              <strong>{displayPriceWithCurrency(mnDataNumbers.chargesToday)}</strong>
              <span>Charges Today</span>
            </div>
            <div className="items-data">
              <strong>{mnDataNumbers.createdToday}</strong>
              <span>Created Today</span>
            </div>
            <div className="items-data">
              <strong>{mnDataNumbers.totalUsers}</strong>
              <span>Total Users</span>
            </div>
            <div className="items-data">
              <strong>{mnDataNumbers.freeGenkeys}</strong>
              <span>Free Genkeys</span>
            </div>
          </div>
          <hr />
          <div className="user-data">
            {serversData &&
              serversData.map((server) => (
                <div key={server.ip} className="items-data">
                  <strong>{server.ip}</strong>
                  <span>{server.total}</span>
                </div>
              ))}
          </div>
          <div className="total-masternodes">
            <div>
              <strong>{mnStatus.total}</strong>
              <span>Total masternodes</span>
            </div>
            <div className="status-masternode">
              <div>
                <strong>{mnStatus.mounting}</strong>
                <MdQueryBuilder className="mounting" title="Mounting masternode." />
              </div>
              <div>
                <strong>{mnStatus.ready}</strong>
                <FaCheckCircle className="ready" title="Masternode is ready." />
              </div>
              <div>
                <strong>{mnStatus.activated}</strong>
                <FaCheckCircle className="activated" title="Masternode successfully started!" />
              </div>
            </div>
          </div>
          <div className="all-coins">
            {coinsData.length > 0 &&
              coinsData.map((item) => (
                <div key={item.coin}>
                  <strong>{String(item.coin).toUpperCase()}</strong>
                  <span>{item.totalMasternodes}</span>
                </div>
              ))}
          </div>
          <div className="delayed-masternodes">
            <p>
              <small>Overdue charge</small>
              {mnDataNumbers.delayedCharges}
            </p>
            <p>
              <small>Delayed block</small>
              <span>{mnDataNumbers.blockDelayed}</span>
            </p>
          </div>
          <div className="buttons">
            {mnDataText !== '' && (
              <>
                <Button variant="info" onClick={() => handleShowUserMasternodeData(mnData)}>
                  {userIdsShowData.length !== mnData.length ? 'Show all Data' : 'Hide all Data'}
                </Button>
                <Button variant="secondary" onClick={() => setShowMnData(!showMnData)}>
                  {showMnData ? 'DATA TO MY WALLET' : 'MASTERNODES DATA'}
                </Button>
              </>
            )}

            {!showMnData && (
              <Button variant="info" className="copy-data" ref={target} onClick={() => handleCopyClick(mnDataText)}>
                COPY ALL DATA
                <IoIosCopy />
                <Overlay target={target.current} show={show} placement="right">
                  {(props) => (
                    <Tooltip {...props} show={show.toString()}>
                      Copied!
                    </Tooltip>
                  )}
                </Overlay>
              </Button>
            )}
          </div>
        </header>

        <main>
          {showMnData ? (
            <Table responsive striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>Coin</th>
                  <th>Alias</th>
                  <th>Price</th>
                  <th>IP</th>
                  <th>Real IP</th>
                  <th>Private Key</th>
                  <th>Public Key</th>
                  <th>Block</th>
                  <th>Charged at</th>
                  <th>Next Charge</th>
                  <th>Active Time</th>
                  <th>Last Paid</th>
                  <th id="status">Status</th>
                </tr>
              </thead>
              <tbody>
                {mnData.length > 0 &&
                  mnData.map((user) => (
                    <Fragment key={user.user_id}>
                      <tr>
                        <td colSpan="13" className="info">
                          <p>
                            ID
                            {user.user_id} - {user.name} - {user.email} - {user.masternodes.length} masternodes
                          </p>
                          <dl>
                            <dt>Bonus:</dt>
                            <dd>{displayPriceWithCurrency(user.bonus)}</dd>

                            <dt>Balance:</dt>
                            <dd>{displayPriceWithCurrency(user.balance)}</dd>

                            <dt>Total Charges:</dt>
                            <dd>{displayPriceWithCurrency(user.totalCharges)}</dd>

                            <dt>Paid this month:</dt>
                            <dd>{displayPriceWithCurrency(user.paidThisMonth)}</dd>

                            <dt>Will Pay this month:</dt>
                            <dd>{displayPriceWithCurrency(user.willPayThisMonth)}</dd>
                          </dl>
                          <div className="status-masternode">
                            <div>
                              <div>
                                <strong>{user.statusMasternodes.mounting}</strong>
                                <MdQueryBuilder className="mounting" title="Mounting masternode." />
                              </div>
                              <div>
                                <strong>{user.statusMasternodes.ready}</strong>
                                <FaCheckCircle className="ready" title="Masternode is ready." />
                              </div>
                              <div>
                                <strong>{user.statusMasternodes.activated}</strong>
                                <FaCheckCircle className="activated" title="Masternode successfully started!" />
                              </div>
                            </div>
                            <Button variant="info" onClick={() => handleShowUserMasternodeData(user.user_id)}>
                              {userIdsShowData.indexOf(user.user_id) === -1 ? 'Show Data' : 'Hide Data'}
                            </Button>
                          </div>
                        </td>
                      </tr>
                      {user.masternodes.map((m) => (
                        <tr
                          key={m.id}
                          style={{
                            display: userIdsShowData.indexOf(user.user_id) === -1 && 'none',
                          }}
                        >
                          <td className="item">{String(m.coin).toUpperCase()}</td>
                          <td className="item">{`mn${m.alias}`}</td>
                          <td className="item">{displayPriceWithCurrency(m.price)}</td>
                          <td className="item ip">{m.ip ? m.ip : '-'}</td>
                          <td className="item ip">{m.real_ip ? m.real_ip : '-'}</td>
                          <td className="item">{m.private_key ? reducePrivateKey(m.private_key) : '-'}</td>
                          <td className="item">
                            <small>{m.address}</small>
                          </td>
                          <td className={getDelayedOrLatestBlock(m.block, m.coin) ? 'item warning' : 'item'}>
                            {m.block ? (
                              <>
                                {getDelayedOrLatestBlock(m.block, m.coin) && <small>Delayed block</small>}
                                {`${m.block} / ${getDelayedOrLatestBlock(null, m.coin)}`}
                              </>
                            ) : (
                              '-'
                            )}
                          </td>
                          <td className="item">{displayDefaultDateFormat(new Date(m.charged_at))}</td>
                          <td
                            className={
                              isAfter(subDays(new Date(), 1), new Date(m.next_charge)) ? 'item warning' : 'item'
                            }
                          >
                            {m.next_charge ? (
                              <>
                                {isAfter(subDays(new Date(), 1), new Date(m.next_charge)) && (
                                  <small>Overdue charge</small>
                                )}
                                {displayDefaultDateFormat(new Date(m.next_charge))}
                              </>
                            ) : (
                              '-'
                            )}
                          </td>
                          <td className="item">
                            {m.activetime
                              ? prettyMilliseconds(m.activetime * 1000, {
                                  unitCount: 3,
                                })
                              : '-'}
                          </td>
                          <td className="item">
                            {m.lastpaid ? displayDefaultDateFormat(new Date(m.lastpaid * 1000)) : '-'}
                          </td>
                          <td>{m.status}</td>
                        </tr>
                      ))}
                    </Fragment>
                  ))}
              </tbody>
            </Table>
          ) : (
            <ul className="inline-multiple-data">
              {mnDataText.split('\n').map((d) => (
                <li key={d}>{d}</li>
              ))}
            </ul>
          )}

          {loading ? (
            <Spinner animation="border" />
          ) : (
            mnData.length === 0 && <small>There are no masternodes yet.</small>
          )}
        </main>
      </Content>
    </Container>
  );
}
