import api from 'services/api';

interface ISendMessageRequest {
  message: string;
  captchaToken: string;
  isPublicMessage: boolean;
}

export const getSupportedCoinsValue = async (): Promise<any> => {
  try {
    const { data } = await api.get('/prices');

    return data;
  } catch (err) {
    console.error('Something went wrong when calling supported coins api.');
    throw err;
  }
};

export const sendMessageRequest = async ({
  message,
  captchaToken,
  isPublicMessage,
}: ISendMessageRequest): Promise<any> => {
  const endPoint = isPublicMessage ? 'public-message' : 'messages';
  try {
    await api.post(endPoint, { message, captchaToken });

    return;
  } catch (err) {
    console.error('Something went wrong on sendMessageRequest.');
    throw err;
  }
};
