import styled from 'styled-components/macro';
import {
  DISPLAY_FLEX_COLUMN_DEFAULT,
} from '~/style/constants';

export const LoadingContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT};
  gap: 10px;
`;

export const LoadingTextContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT};
  color: white;
  font-size: 18px;
  gap: 5px;
`;

export const HiglanLogo = styled.img`
  width: 70px !important;
`;
