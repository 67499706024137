import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import history from 'services/history';
import api from 'services/api';

import {
  signInSuccess,
  signFailure,
  signUpSuccess,
  authActionTypes,
  signInRequest,
  ISignUpRequestAction,
} from './actions';
import {
  pushLoadingAppDataRequest,
  resetAppDataRequests,
  spliceLoadingAppDataRequest,
  startLoadingAppData,
} from '../app/actions';

export function* signIn({ payload }: any) {
  const requestName = 'signIn';
  const { email, password, captchaToken, isNewUser, resetCaptcha } = payload;
  try {
    yield put(pushLoadingAppDataRequest(requestName));

    const { data } = yield call(api.post, 'sessions', {
      email,
      password,
      captchaToken,
      isNewUser,
    });

    const { token, user } = data;

    api.defaults.headers.Authorization = `Bearer ${token}`;

    yield put(resetAppDataRequests());
    yield put(startLoadingAppData());
    yield put(signInSuccess(token, user));

    history.push('/dashboard');
  } catch (err) {
    const error: any = { err };
    const errorMessage = error.err.response.data.error;
    toast.error(errorMessage);
    if (resetCaptcha) {
      resetCaptcha();
    }
    yield put(signFailure());
  } finally {
    yield put(spliceLoadingAppDataRequest(requestName));
  }
}

export function* signUp({ payload }: ISignUpRequestAction) {
  const { name, email, password, captchaToken, resetCaptcha } = payload;
  try {
    yield call(api.post, 'users', {
      name,
      email,
      password,
      captchaToken,
    });
    toast.success('Account successfully created!');
    yield put(signUpSuccess());
    yield put(signInRequest(email, password, captchaToken, true));
  } catch (err) {
    const error: any = { err };
    const errorMessage = error.err.response.data.error;
    toast.error(errorMessage);
    resetCaptcha();
    yield put(signFailure());
  }
}

export function setToken({ payload }: any) {
  if (!payload) return;

  const { token } = payload.auth;

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }
}

export function signOut() {
  history.push('/login');
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest(authActionTypes.SIGN_IN_REQUEST, signIn),
  takeLatest(authActionTypes.SIGN_UP_REQUEST, signUp),
  takeLatest(authActionTypes.SIGN_OUT, signOut),
]);
