import prettyMilliseconds from 'pretty-ms';
import { useEffect, useState } from 'react';
import { Switch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Route from './Route';
import { getAdminData, getNodeData, resetAdminCodeData } from 'store/modules/user/actions';
import { getSupportedCoins, stopLoadingAppData } from 'store/modules/app/actions';
import { getSupportedCoins as getSupportedCoinsSelector } from 'store/modules/app/selectors';
import { getUserBalances } from 'store/modules/user/actions';

import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
import ResetPassword from '../pages/ResetPassword';
import CreateNewPassword from '../pages/CreateNewPassword';
import { Dashboard } from '../pages/Dashboard';
import { Nodes } from '../pages/Nodes';
import Addfunds from '../pages/Addfunds';
import History from '../pages/History';
import Contact from '../pages/Contact';
import { Profile } from '../pages/Profile';
import Unsubscribe from '../pages/Unsubscribe';
import Admin from '../pages/Admin';
import { Home } from '../pages/Home';
import { HomeStatic } from '../pages/HomeStatic';
import { Terms } from '../pages/Terms';
import { FAQ } from '../pages/FAQ';
import { PublicContact } from '../pages/PublicContact';
import { PublicTools } from '../pages/PublicTools';
import { SuggestCoin } from '../pages/SuggestCoin';
import { NoMatch } from '../pages/NoMatch';
import { Admin as Admin_v2 } from '../pages/Admin_v2';
import { Tools } from '../pages/Tools';
import { NodeSteps } from 'pages/NodeSteps';
import { VerifyEmail } from 'pages/VerifyEmail';
import { Loading } from 'pages/Loading';
import { Faucet } from 'pages/Faucet';
import { FreeNodesRules } from 'pages/FreeNodesRules';

import { getAuth } from 'store/modules/auth/selectors';
import { getUserProfile } from 'store/modules/user/selectors';

import { connect, disconnect } from 'services/socket';
import {
  getWasSomeRequestSent,
  getLoadingAppDataRequests,
  isLoadingAppData as isLoadingAppDataSelector,
} from '~/store/modules/app/selectors';
import { getBlockAndTotalNodes } from '~/store/modules/coin-chain-data/actions';
import { getFaucetValues, setTimeForNextRound } from '~/store/modules/faucet/actions';
import { getTimeForNextRound } from '~/store/modules/faucet/selectors';

const PAGE_COMPONENTS_NAMES = {
  sign: 'SignIn',
  signup: 'SignUp',
  resetPassword: 'ResetPassword',
  createNewPassword: 'CreateNewPassword',
  dashboard: 'Dashboard',
  nodes: 'Nodes',
  addFunds: 'Addfunds',
  history: 'History',
  contact: 'Contact',
  profile: 'Profile',
  unsubscribe: 'Unsubscribe',
  admin: 'Admin',
  admin_v2: 'Admin_v2',
  tools: 'Tools',
  nodeSteps: 'NodeSteps',
  verifyEmail: 'VerifyEmail',
  faucet: 'Faucet',
  home: 'Home',
  homeStatic: 'HomeStatic',
  terms: 'Terms',
  faq: 'FAQ',
  publicContact: 'PublicContact',
  publicTools: 'PublicTools',
  suggestCoin: 'SuggestCoin',
  noMatch: 'NoMatch',
  freeNodesRules: 'FreeNodesRules',
};

export default function Routes() {
  const dispatch = useDispatch();
  const profile = useSelector(getUserProfile);
  const auth = useSelector(getAuth);
  const isLoadingAppData = useSelector(isLoadingAppDataSelector);
  const loadingAppDataRequests = useSelector(getLoadingAppDataRequests);
  const supportedCoins = useSelector(getSupportedCoinsSelector);
  const wasSomeRequestSent = useSelector(getWasSomeRequestSent);
  const timeForNextRound = useSelector(getTimeForNextRound);
  const [shouldResetFaucetTime, setShouldResetFaucetTime] = useState(false);
  const { signed } = auth;
  const { id, email, name, admin } = profile;

  useEffect(() => {
    dispatch(getSupportedCoins());
  }, []);

  useEffect(() => {
    supportedCoins.forEach(({ is_active }) => {
      if (is_active) {
        dispatch(getBlockAndTotalNodes({ shouldUseLoadingAppData: true }));
      }
    });
  }, [supportedCoins]);

  useEffect(() => {
    if (loadingAppDataRequests.length === 0 && wasSomeRequestSent) {
      dispatch(stopLoadingAppData());
    }
  }, [loadingAppDataRequests]);

  useEffect(() => {
    if (signed) {
      dispatch(getUserBalances());
      dispatch(getNodeData());
      dispatch(getFaucetValues());
      if (admin) {
        dispatch(resetAdminCodeData());
        dispatch(getAdminData());
      }

      connect({
        userId: id,
        userName: name,
        userEmail: email,
        isAdmin: admin,
        lastActive: Date.now(),
        dispatch,
      });
    } else {
      disconnect();
    }
  }, [signed]);

  useEffect(() => {
    if (timeForNextRound > 0) {
      setShouldResetFaucetTime(false);
      const timeToExpire = Date.now() + timeForNextRound;
      let timeLeft = timeToExpire;

      const interval = setInterval(async () => {
        if (timeLeft < 1000) {
          clearInterval(interval);
          document.title = 'Higlan';
          setShouldResetFaucetTime(true);
          return 0;
        }
        timeLeft = timeToExpire - Date.now();
        const prettyTime = prettyMilliseconds(timeLeft, { secondsDecimalDigits: 0 });
        document.title = `Next Faucet Round in ${prettyTime} - Higlan.com`;
        return timeLeft;
      }, 1000);
    }
  }, [timeForNextRound]);

  useEffect(() => {
    if (shouldResetFaucetTime) {
      dispatch(setTimeForNextRound(0));
    }
  }, [shouldResetFaucetTime]);

  const getComponentToRender = (componentName: string) => {
    if (isLoadingAppData) {
      return Loading;
    }

    switch (componentName) {
      case PAGE_COMPONENTS_NAMES.sign: {
        return SignIn;
      }
      case PAGE_COMPONENTS_NAMES.signup: {
        return SignUp;
      }
      case PAGE_COMPONENTS_NAMES.resetPassword: {
        return ResetPassword;
      }
      case PAGE_COMPONENTS_NAMES.createNewPassword: {
        return CreateNewPassword;
      }
      case PAGE_COMPONENTS_NAMES.dashboard: {
        return Dashboard;
      }
      case PAGE_COMPONENTS_NAMES.nodes: {
        return Nodes;
      }
      case PAGE_COMPONENTS_NAMES.addFunds: {
        return Addfunds;
      }
      case PAGE_COMPONENTS_NAMES.history: {
        return History;
      }
      case PAGE_COMPONENTS_NAMES.contact: {
        return Contact;
      }
      case PAGE_COMPONENTS_NAMES.profile: {
        return Profile;
      }
      case PAGE_COMPONENTS_NAMES.unsubscribe: {
        return Unsubscribe;
      }
      case PAGE_COMPONENTS_NAMES.admin: {
        return Admin;
      }
      case PAGE_COMPONENTS_NAMES.admin_v2: {
        return Admin_v2;
      }
      case PAGE_COMPONENTS_NAMES.tools: {
        return Tools;
      }
      case PAGE_COMPONENTS_NAMES.nodeSteps: {
        return NodeSteps;
      }
      case PAGE_COMPONENTS_NAMES.verifyEmail: {
        return VerifyEmail;
      }
      case PAGE_COMPONENTS_NAMES.faucet: {
        return Faucet;
      }
      case PAGE_COMPONENTS_NAMES.home: {
        return Home;
      }
      case PAGE_COMPONENTS_NAMES.homeStatic: {
        return HomeStatic;
      }
      case PAGE_COMPONENTS_NAMES.terms: {
        return Terms;
      }
      case PAGE_COMPONENTS_NAMES.faq: {
        return FAQ;
      }
      case PAGE_COMPONENTS_NAMES.publicContact: {
        return PublicContact;
      }
      case PAGE_COMPONENTS_NAMES.publicTools: {
        return PublicTools;
      }
      case PAGE_COMPONENTS_NAMES.suggestCoin: {
        return SuggestCoin;
      }
      case PAGE_COMPONENTS_NAMES.noMatch: {
        return NoMatch;
      }
      case PAGE_COMPONENTS_NAMES.freeNodesRules: {
        return FreeNodesRules;
      }
      default: {
        return Dashboard;
      }
    }
  };

  return (
    <Switch>
      <Route path="/" exact component={getComponentToRender(PAGE_COMPONENTS_NAMES.home)} />
      <Route path="/home" component={getComponentToRender(PAGE_COMPONENTS_NAMES.home)} isGeneric />
      <Route path="/static-home" component={getComponentToRender(PAGE_COMPONENTS_NAMES.homeStatic)} isGeneric />
      <Route path="/terms" component={getComponentToRender(PAGE_COMPONENTS_NAMES.terms)} isGeneric />
      <Route path="/faq" component={getComponentToRender(PAGE_COMPONENTS_NAMES.faq)} isGeneric />
      <Route path="/public-contact" component={getComponentToRender(PAGE_COMPONENTS_NAMES.publicContact)} isGeneric />
      <Route path="/public-tools" component={getComponentToRender(PAGE_COMPONENTS_NAMES.publicTools)} isGeneric />
      <Route path="/suggest-coin" component={getComponentToRender(PAGE_COMPONENTS_NAMES.suggestCoin)} isGeneric />
      <Route path="/free-node-rules" component={getComponentToRender(PAGE_COMPONENTS_NAMES.freeNodesRules)} isGeneric />
      <Route path="/login" component={getComponentToRender(PAGE_COMPONENTS_NAMES.sign)} />
      <Route path="/register" component={getComponentToRender(PAGE_COMPONENTS_NAMES.signup)} />
      <Route path="/reset-password" component={getComponentToRender(PAGE_COMPONENTS_NAMES.resetPassword)} />
      <Route path="/create-new-password" component={getComponentToRender(PAGE_COMPONENTS_NAMES.createNewPassword)} />
      <Route path="/unsubscribe" component={getComponentToRender(PAGE_COMPONENTS_NAMES.unsubscribe)} isGeneric />
      <Route path="/verify-email" component={getComponentToRender(PAGE_COMPONENTS_NAMES.verifyEmail)} isGeneric />
      <Route path="/dashboard" component={getComponentToRender(PAGE_COMPONENTS_NAMES.dashboard)} isPrivate />
      <Route path="/nodes" exact component={getComponentToRender(PAGE_COMPONENTS_NAMES.nodes)} isPrivate />
      <Route path="/nodes/:coinName" component={getComponentToRender(PAGE_COMPONENTS_NAMES.nodeSteps)} isPrivate />
      <Route path="/addfunds" component={getComponentToRender(PAGE_COMPONENTS_NAMES.addFunds)} isPrivate />
      <Route path="/history" component={getComponentToRender(PAGE_COMPONENTS_NAMES.history)} isPrivate />
      <Route path="/contact" component={getComponentToRender(PAGE_COMPONENTS_NAMES.contact)} isPrivate />
      <Route path="/profile" component={getComponentToRender(PAGE_COMPONENTS_NAMES.profile)} isPrivate />
      <Route path="/faucet" component={getComponentToRender(PAGE_COMPONENTS_NAMES.faucet)} isPrivate />
      {profile.admin && <Route path="/admin" component={getComponentToRender(PAGE_COMPONENTS_NAMES.admin)} isPrivate />}
      {profile.admin && (
        <Route path="/admin_v2" component={getComponentToRender(PAGE_COMPONENTS_NAMES.admin_v2)} isPrivate />
      )}
      <Route path="/tools" component={getComponentToRender(PAGE_COMPONENTS_NAMES.tools)} isPrivate />
      <Route component={getComponentToRender(PAGE_COMPONENTS_NAMES.noMatch)} isGeneric />
    </Switch>
  );
}
