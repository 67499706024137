import { TextareaHTMLAttributes, useEffect, useState } from 'react';

import { TextAreaContainer, LengthText, TextAreaElement } from './styles';

interface ITextArea extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  minLength?: number;
  handleError?: (value: boolean) => void;
}

export function TextArea({
  value,
  handleError,
  minLength,
  ...props
}: ITextArea) {
  const [characteresLeft, setCharacteresLeft] = useState(minLength || 0);

  useEffect(() => {
    if (!minLength) return;

    const valueLength = String(value).trim().length;
    const currentCharacteresLeft = minLength - valueLength;
    setCharacteresLeft(currentCharacteresLeft);

    if (handleError) {
      const hasError = characteresLeft > 0;
      handleError(hasError);
    }
  }, [value, characteresLeft]);

  return (
    <TextAreaContainer>
      <TextAreaElement {...props} />
      {characteresLeft && characteresLeft > 0 ? (
        <LengthText>{`${characteresLeft} more characters at least.`}</LengthText>
      ) : null}
    </TextAreaContainer>
  );
}
