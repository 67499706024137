import styled from 'styled-components/macro';
import { DISPLAY_FLEX_COLUMN_DEFAULT, DISPLAY_FLEX_LINE_DEFAULT } from 'style/constants';
import { TextArea } from '~/components/ui/TextArea';

export const Content = styled.div``;

export const HeaderImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
`;

export const BackButton = styled.button`
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background-color: transparent;

  &:hover {
    text-decoration: underline;
  }
`;

export const ChargesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;

  div:first-child {
    margin-right: 20px;
  }
  border-bottom: 1px solid rgba(222, 226, 230);
  padding-bottom: 20px;
`;

export const ChargeItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const BolderText = styled.strong``;

export const NormalText = styled.div``;

export const NodesNotEnabledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 10px auto;
  padding: 5px;
  max-width: 700px;
`;

export const NodesNotEnabledInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const NodesNotEnabledItemsContainer = styled.div`
  ${DISPLAY_FLEX_LINE_DEFAULT};
  text-align: center;
  flex-wrap: wrap;
`;

export const NodeNotEnabled = styled.div`
  margin-right: 5px;
  margin-top: 5px;
  width: 70px;
  border: 1px solid #dee2e6;
  padding: 5px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  button:first-child {
    margin-right: 10px;
  }
`;

export const NoNodesText = styled.div`
  text-align: center;
  font-size: small;
`;

export const StringToWalletContainer = styled.ul`
  background: rgba(0, 0, 0, 0.1);
  font-size: 13px;
  text-align: left;
  word-wrap: break-word;
  margin-top: 20px;
`;

export const StringToWalletItem = styled.li``;

export const ModalBody = styled.div`
  text-align: center;
  ${DISPLAY_FLEX_COLUMN_DEFAULT};
  gap: 15px;
  font-size: 16px;
`;

export const TextWarning = styled.strong`
  color: red;
`;

export const ItemsContainer = styled.div`
  border: 1px solid black;
  width: 100%;
  padding: 10px;
  ${DISPLAY_FLEX_COLUMN_DEFAULT};
  gap: 20px;
`;

export const ItemContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT};
  gap: 5px;
  width: 100%;
`;

export const StyledTextArea = styled(TextArea)<{ minHeight: number; textAlign?: string }>`
  min-height: ${({ minHeight }) => `${minHeight}px`};
  text-align: ${({ textAlign }) => (textAlign ? textAlign : 'left')};
  font-size: 80%;
  padding: 10px;
`;

export const SuccessfullyActivatedMessage = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT};
  gap: 7px;
  color: green;
  font-weight: bold;
`;

export const ExplorerLink = styled.a``;
