import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { darken } from 'polished';
import { DISPLAY_FLEX_COLUMN_DEFAULT, HIGLAN_WHITE_01, HIGLAN_WHITE_02 } from '~/style/constants';

export const LinkText = styled(Link)`
  font-size: 16px;
  color: #fff;

  &:hover {
    color: #fff;
  }
`;

export const ResetLoginPasswordText = styled.h3`
  font-size: 20px;
  color: ${HIGLAN_WHITE_01};
`;

export const EmailConfirmationText = styled.h3`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
`;

export const BolderText = styled.strong``;

export const LinkButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: #fff;
  opacity: 1;
  background-color: #3b9eff;
  height: 44px;
  font-size: 16px;
  border-radius: 4px;
  transition: background 0.2s;
  text-decoration: none;

  &:hover {
    background: ${darken(0.3, '#3b9eff')};
    color: #fff;
  }
`;

export const InstructionDescription = styled.p``;

export const EmailConfirmationContainer = styled.div`
  margin-top: 10px;
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  gap: 7px;
  background: linear-gradient(to bottom, ${HIGLAN_WHITE_01} 5%, ${HIGLAN_WHITE_02} 100%);
  text-align: left;
  padding: 15px;
  border-radius: 4px;
`;
