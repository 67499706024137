const prefix = '@auth';

interface IAuthActionTypes {
  SIGN_IN_REQUEST: string;
  SIGN_IN_SUCCESS: string;
  SIGN_UP_SUCCESS: string;
  SIGN_UP_REQUEST: string;
  SIGN_FAILURE: string;
  SIGN_OUT: string;
}

export const authActionTypes: IAuthActionTypes = {
  SIGN_IN_REQUEST: `${prefix}/SIGN_IN_REQUEST`,
  SIGN_IN_SUCCESS: `${prefix}/SIGN_IN_SUCCESS`,
  SIGN_UP_SUCCESS: `${prefix}/SIGN_UP_SUCCESS`,
  SIGN_UP_REQUEST: `${prefix}/SIGN_UP_REQUEST`,
  SIGN_FAILURE: `${prefix}/SIGN_FAILURE`,
  SIGN_OUT: `${prefix}/SIGN_OUT`,
};

export type IAuthAction = ISignInRequestAction;

export interface ISignInRequestAction {
  payload: any;
  type: typeof authActionTypes.SIGN_IN_REQUEST;
}

export interface ISignUpRequestAction {
  payload: ISignUpRequest;
  type: typeof authActionTypes.SIGN_UP_REQUEST;
}

interface ISignUpRequest {
  name: string;
  email: string;
  password: string;
  captchaToken: string;
  resetCaptcha: Function;
}

export function signInRequest(
  email: string,
  password: string,
  captchaToken: string,
  isNewUser: boolean,
  resetCaptcha?: Function
) {
  return {
    type: authActionTypes.SIGN_IN_REQUEST,
    payload: { email, password, captchaToken, isNewUser, resetCaptcha },
  };
}

export function signInSuccess(token: string, user: any) {
  return {
    type: authActionTypes.SIGN_IN_SUCCESS,
    payload: { token, user },
  };
}

export function signUpSuccess() {
  return {
    type: authActionTypes.SIGN_UP_SUCCESS,
  };
}

export function signUpRequest({ name, email, password, captchaToken, resetCaptcha }: ISignUpRequest) {
  return {
    type: authActionTypes.SIGN_UP_REQUEST,
    payload: { name, email, password, captchaToken, resetCaptcha },
  };
}

export function signFailure() {
  return {
    type: authActionTypes.SIGN_FAILURE,
  };
}

export function signOut() {
  return {
    type: authActionTypes.SIGN_OUT,
  };
}
