import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { signUpRequest } from 'store/modules/auth/actions';
import { LinkText } from './styles';
import HCaptchaConfig from '~/config/HCaptcha';
import { isAuthLoading } from '~/store/modules/auth/selectors';
import { Terms } from '~/components/Terms';
import { HiglanLogo } from '../SignIn/shared/HiglanLogo';
import { GeneralContainer } from '../SignIn/shared/GeneralContainer';
import { UnformForm } from '../SignIn/shared/UnformForm';
import { UnformInput } from '../SignIn/shared/UnformInput';
import { SubmitButton } from '../SignIn/shared/SubmitButton';

const schema = Yup.object().shape({
  name: Yup.string().required('Username is required'),
  email: Yup.string().email('Your email is not valid').required('Email is required'),
  password: Yup.string().min(6, 'At least 6 characters for password').required('Password is required'),
});

export default function SignUp() {
  const dispatch = useDispatch();
  const isLoading = useSelector(isAuthLoading);
  const [captchaToken, setCaptchaToken] = useState<string>();
  const captchaRef = useRef(null);

  const resetCaptcha = () => {
    // @ts-ignore
    captchaRef.current?.resetCaptcha();
    setCaptchaToken('');
  };

  const handleSubmit = ({ name, email, password }: any) => {
    if (!captchaToken) {
      toast.error(`Please resolve the captcha.`, {
        pauseOnFocusLoss: false,
      });
      return;
    }
    dispatch(signUpRequest({ name, email, password, captchaToken, resetCaptcha }));
  };

  const onHCaptchaVerify = (token: string) => {
    setCaptchaToken(token);
  };

  return (
    <GeneralContainer>
      <HiglanLogo />
      {/**@ts-ignore */}
      <UnformForm schema={schema} onSubmit={handleSubmit}>
        <UnformInput name="name" placeholder="Your username" />
        <UnformInput name="email" type="email" placeholder="Your email" />
        <UnformInput name="password" type="password" placeholder="Your password" />
        <HCaptcha size="normal" sitekey={HCaptchaConfig.siteKey} onVerify={onHCaptchaVerify} ref={captchaRef} />
        <SubmitButton type="submit" disabled={isLoading} isLoading={isLoading}>
          Create account
        </SubmitButton>
        <LinkText to="/login">I already have an account</LinkText>
        <Terms />
      </UnformForm>
    </GeneralContainer>
  );
}
