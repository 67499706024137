import styled from 'styled-components/macro';

export const Wrapper = styled.div<{ hasYScroll: boolean }>`
  min-height: ${({ hasYScroll }) => (hasYScroll ? 'auto' : '100%')};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
`;

export const Content = styled.div`
  max-width: 900px;
  width: 90%;
  text-align: center;
`;
