import { FaTag } from 'react-icons/fa';
import styled from 'styled-components/macro';
import { Modal } from '~/components/ui/Modal';
import { TextRender } from '~/components/ui/TextRender';
import { DISPLAY_FLEX_COLUMN_DEFAULT, DISPLAY_FLEX_LINE_DEFAULT } from '~/style/constants';

export const StyledModal = styled(Modal)`
  color: rgba(147, 119, 16, 1);
`;

export const PromotionIcon = styled(FaTag)``;

export const ItemContainer = styled.div`
  ${DISPLAY_FLEX_LINE_DEFAULT}
  gap: 10px;
`;

export const TextRenderLine = styled(TextRender)`
  display: inline;
`;

export const MoreInfoButton = styled(TextRender)`
  cursor: pointer;
  font-weight: bold;
  border: 2px solid rgba(147, 119, 16, 1);
  padding: 10px;
  border-radius: 5px;

  :hover {
    text-decoration: underline;
  }
`;

export const PromotionModalBodyContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  text-align: center;
  font-size: 16px;
  padding-top: 30px;
  gap: 30px;
`;

export const ModalFooterContainer = styled.div`
  ${DISPLAY_FLEX_LINE_DEFAULT}
  padding: 10px;
`;
