import styled from 'styled-components/macro';
import { DISPLAY_FLEX_COLUMN_DEFAULT, HIGLAN_WHITE_01 } from '~/style/constants';

export const FreeNodesRulesContainer = styled.div`
  max-width: 900px;
  background-color: ${HIGLAN_WHITE_01};
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  font-size: 16px;
  gap: 25px;
  border-radius: 0.3rem;
  padding: 25px;
`;

export const RulesTitle = styled.h4`
  text-align: center;
  width: 100%;
`;
