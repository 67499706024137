import { ReactComponent as StarSvgIcon } from 'assets/icons/star.svg';
import { IconContainer } from './styles';

export function LabelNew({ ...props }) {
  return (
    <IconContainer {...props}>
      <StarSvgIcon />
      NEW
    </IconContainer>
  );
}
