import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import api from 'services/api';

import { Container } from 'pages/_layouts/default/styles';
import { Content } from './styles';

import { getUserProfile } from '~/store/modules/user/selectors';
import { ContactForm } from '~/components/ContactForm';

export default function Contact() {
  const profile = useSelector(getUserProfile);
  const { email, name } = profile;
  const [loading, setLoading] = useState(false);
  const [isRequestSent, setIsRequestSent] = useState(false);
  const [captchaToken, setCaptchaToken] = useState<string>();
  const captchaRef = useRef(null);

  const resetCaptcha = () => {
    // @ts-ignore
    captchaRef.current?.resetCaptcha();
    setCaptchaToken('');
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // @ts-ignore
    const message = event.target[2].value;

    if (!captchaToken) {
      toast.error(`Please resolve the captcha.`, {
        pauseOnFocusLoss: false,
      });
      return;
    }
    try {
      setLoading(true);
      await api.post('messages', { message, captchaToken });

      toast.success('Your message was successfully sent!');

      setIsRequestSent(true);
    } catch (err) {
      const error: any = { err };
      const errorMessage = error.err.response.data.error;
      toast.error(errorMessage);
    } finally {
      setLoading(false);
      resetCaptcha();
    }
  };

  const handleCaptchaChange = (captchaToken: string) => {
    setCaptchaToken(captchaToken);
  };

  return (
    <Container>
      <Content isRequestSent={isRequestSent}>
        <header>
          <p>
            Questions, compliments or complaints?
            <br />
            Any feedback is appreciated.
          </p>
        </header>
        <main>
          <ContactForm
            userEmail={email}
            userName={name}
            handleSubmit={handleSubmit}
            isLoading={loading}
            isRequestSent={isRequestSent}
            handleCaptchaChange={handleCaptchaChange}
          />
        </main>
      </Content>
    </Container>
  );
}
