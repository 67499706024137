import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import history from 'services/history';
import * as Yup from 'yup';
import api from 'services/api';

import { CreateNewPasswordText, LinkText } from './styles';

import { useQuery } from 'shared/string-utils';
import { GeneralContainer } from '../SignIn/shared/GeneralContainer';
import { HiglanLogo } from '../SignIn/shared/HiglanLogo';
import { UnformForm } from '../SignIn/shared/UnformForm';
import { SubmitButton } from '../SignIn/shared/SubmitButton';
import { UnformInput } from '../SignIn/shared/UnformInput';

const schema = Yup.object().shape({
  newPassword: Yup.string().min(6, 'At least 6 characters for password'),
  confirmNewPassword: Yup.string().min(6, 'At least 6 characters for password'),
});

interface IHandleSubmit {
  newPassword: string;
  confirmNewPassword: string;
}

export default function CreateNewPassword() {
  const query = useQuery();
  const [isLoading, setIsLoading] = useState(false);
  const queryEmail = query.get('e');
  const queryHash = query.get('h');

  async function handleSubmit({ newPassword, confirmNewPassword }: IHandleSubmit) {
    if (newPassword !== confirmNewPassword) {
      return toast.error('Password does not match.');
    }

    try {
      setIsLoading(true);
      await api.post('confirm-reset-password', {
        queryEmail,
        queryHash,
        newPassword,
        confirmNewPassword,
      });
      toast.success('Password successfully updated!');
      history.push('/login');
    } catch (error) {
      const err: any = { error };
      const { response } = err.error;
      toast.error(response.data.error);
    } finally {
      setIsLoading(false);
    }

    return;
  }

  useEffect(() => {
    async function checkValidData() {
      try {
        await api.get('confirm-reset-password', {
          params: { queryEmail, queryHash },
        });
      } catch (error) {
        const err: any = { error };
        const { response } = err.error;
        toast.error(response.data.error);
        history.push('/login');
      }
    }
    checkValidData();
  }, []);

  return (
    <GeneralContainer>
      <HiglanLogo />
      {/** @ts-ignore */}
      <UnformForm schema={schema} onSubmit={handleSubmit}>
        <CreateNewPasswordText>Create New Password</CreateNewPasswordText>
        <UnformInput name="newPassword" type="password" placeholder="New password" required />
        <UnformInput name="confirmNewPassword" type="password" placeholder="Confirm new password" required />
        <SubmitButton isLoading={isLoading} type="submit" disabled={isLoading}>
          Submit
        </SubmitButton>
        <LinkText to="/login">Back to login page</LinkText>
      </UnformForm>
    </GeneralContainer>
  );
}
