import styled from 'styled-components/macro';
import { IoIosInformationCircle } from 'react-icons/io';

import { BootstrapButton } from '~/components/ui/BootstrapButton';
import { TextInput } from '~/components/ui/TextInput';
import { DISPLAY_FLEX_COLUMN_DEFAULT, DISPLAY_FLEX_LINE_DEFAULT } from '~/style/constants';
import { CONTAINER_MIN_HEIGHT } from '../styles';
import { TextRender } from '~/components/ui/TextRender';

export const CreateOrderForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  min-height: 480px;
  width: 100%;
`;

export const PriceItemsContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
`;

export const PriceValueContainer = styled.div`
  ${DISPLAY_FLEX_LINE_DEFAULT}
  gap: 5px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const InputControlsContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const NodeNumberInput = styled.input`
  text-align: center;
  padding: 5px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  border: 0;
`;

export const TotalOrderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const NodeNumberButton = styled.button`
  border: 0;
  background: none;

  &:disabled {
    cursor: not-allowed;
  }
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const WalletAddressContainer = styled.div`
  width: 100%;
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  text-align: center;
  gap: 7px;
  margin-bottom: 10px;
`;

export const StyledTextInput = styled(TextInput)`
  &::placeholder {
    color: gray;
    opacity: 0.5;
  }
`;

export const WalletAddressTextAlert = styled.strong`
  color: red;
`;

export const DiscountContainer = styled.div`
  ${DISPLAY_FLEX_LINE_DEFAULT}
  gap: 7px;
`;

export const DiscountInput = styled(TextInput)`
  width: 170px;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
`;

export const DiscountButton = styled(BootstrapButton)`
  height: 100%;
`;

export const CreateOrderContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  justify-content: space-between;
  min-height: ${CONTAINER_MIN_HEIGHT};
  width: 100%;
`;

export const RulesModalTextContainer = styled.div`
  text-align: center;
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  gap: 5px;
`;

export const RuleTextContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  text-align: left;
  gap: 25px;
  font-size: 16px;
  align-items: flex-start;
`;

export const RuleText = styled(TextRender)`
  text-align: left;
`;

export const StyledIoIcon = styled(IoIosInformationCircle)`
  cursor: pointer;
  color: rgba(147, 119, 16, 1);
`;
