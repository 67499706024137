import { MdSearchOff } from 'react-icons/md';
import { HiglanLogo } from '../SignIn/shared/HiglanLogo';
import { NoMatchContainer, NotFoundContainer } from './styles';

export function NoMatch() {
  return (
    <NoMatchContainer>
      <HiglanLogo />
      <NotFoundContainer>
        <MdSearchOff size={50} />
        Page Not Found
      </NotFoundContainer>
    </NoMatchContainer>
  );
}
