import { memo } from 'react';
import uuid from 'react-uuid';
import { displayPriceWithCurrency } from '~/shared/string-utils';
import { IAllUserNodes } from '~/store/modules/interfaces';
import { IHandleToggleSingleData, INodeDetailsToShow } from '..';
import DataTable from '../DataTable';
import { ItemContainer, NormalItem, StrongItem, UserDataItems } from '../styles';
import { UserDataContainer, UserDataHeader } from './styles';

interface IUserData {
  allUserNodes: IAllUserNodes[];
  handleToggleSingleData: (data: IHandleToggleSingleData) => void;
  toggleAdminCodeModal: () => void;
  isAllNodeDataShown: boolean;
  nodeDetailsToShow: INodeDetailsToShow[];
}

function UserDataComponent({
  allUserNodes,
  handleToggleSingleData,
  toggleAdminCodeModal,
  nodeDetailsToShow,
  isAllNodeDataShown,
}: IUserData) {
  return (
    <>
      {allUserNodes.map((userNodeData) => {
        const {
          coinData,
          user_id,
          name,
          email,
          balance,
          bonus,
          last_active_time_ago,
          last_active_date_and_time,
          totalCharges,
        } = userNodeData;
        const { paid, willPay } = userNodeData.generalData.charges;
        const totalNodes = userNodeData.generalData.status.total;

        return (
          <UserDataContainer key={uuid()}>
            <UserDataHeader>
              <StrongItem>{`ID${user_id} - ${name} - ${email} - ${totalNodes} nodes`}</StrongItem>
              <StrongItem>{`Last Active: ${last_active_date_and_time} (${last_active_time_ago})`}</StrongItem>
              <UserDataItems>
                <ItemContainer>
                  <StrongItem>{displayPriceWithCurrency(bonus)}</StrongItem>
                  <NormalItem>Bonus</NormalItem>
                </ItemContainer>
                <ItemContainer>
                  <StrongItem>{displayPriceWithCurrency(balance)}</StrongItem>
                  <NormalItem>Balance</NormalItem>
                </ItemContainer>
                <ItemContainer>
                  <StrongItem>{displayPriceWithCurrency(totalCharges)}</StrongItem>
                  <NormalItem>Total Charges</NormalItem>
                </ItemContainer>
                <ItemContainer>
                  <StrongItem>{displayPriceWithCurrency(willPay)}</StrongItem>
                  <NormalItem>Will pay this month</NormalItem>
                </ItemContainer>
                <ItemContainer>
                  <StrongItem>{displayPriceWithCurrency(paid)}</StrongItem>
                  <NormalItem>Paid this month</NormalItem>
                </ItemContainer>
              </UserDataItems>
            </UserDataHeader>
            <DataTable
              coinData={coinData}
              nodeDetailsToShow={nodeDetailsToShow}
              user_id={user_id}
              handleToggleSingleData={handleToggleSingleData}
              toggleAdminCodeModal={toggleAdminCodeModal}
              isAllNodeDataShown={isAllNodeDataShown}
            />
          </UserDataContainer>
        );
      })}
    </>
  );
}

export const UserData = memo(UserDataComponent, (prevProps, nextProps) => {
  const result: boolean = prevProps === nextProps;

  return result;
});
