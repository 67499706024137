import styled from "styled-components/macro";

export const IconContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  color: yellow;
  background-color: black;
  align-items: center;
  justify-content: center;
  right: -20px;
  top: -10px;
  padding: 3px;
`;