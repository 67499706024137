import styled, { createGlobalStyle, css } from 'styled-components/macro';
import { DISPLAY_FLEX_LINE_DEFAULT } from '~/style/constants';

const SCROLL_Y_CSS = css`
  // Because react-bootstrap modal hides the scroll bar
  .modal-open {
    overflow-y: scroll !important;
  }
`;

export const GlobalStylesForModal = createGlobalStyle<{
  shouldHideScrollBarY: boolean;
}>`
  ${({ shouldHideScrollBarY }) => !shouldHideScrollBarY && SCROLL_Y_CSS}
`;

export const Title = styled.div`
  text-align: center;
  width: 100%;
  div {
    ${DISPLAY_FLEX_LINE_DEFAULT};
    gap: 7px;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ItemStrong = styled.strong``;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
`;
