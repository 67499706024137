import { useState } from 'react';
import { toast } from 'react-toastify';
import api from '~/services/api';
import { MainContent } from '../Home';
import { StyledContactForm } from './styles';

interface ISendDataToApi {
  username: string;
  email: string;
  message: string;
  captchaToken: string;
}
export function PublicContact() {
  const [captchaToken, setCaptchaToken] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [isRequestSent, setIsRequestSent] = useState(false);

  const handleCaptchaChange = (captchaToken: string) => {
    setCaptchaToken(captchaToken);
  };

  const sendDataToApi = async (body: ISendDataToApi) => {
    try {
      setIsLoading(true);
      await api.post('public-message', { ...body });

      toast.success('Your message was successfully sent!');

      setIsRequestSent(true);
    } catch (err) {
      const error: any = { err };
      const errorMessage = error.err.response.data.error;
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!captchaToken) {
      toast.error(`Please resolve the captcha.`, {
        pauseOnFocusLoss: false,
      });
      return;
    }
    const eventValues: any = event;
    const [usernameElement, emailElement, messageElement] = eventValues.target;
    const bodyData = {
      username: usernameElement.value,
      email: emailElement.value,
      message: messageElement.value,
      captchaToken,
    };
    sendDataToApi(bodyData);
  };

  return (
    <MainContent>
      <StyledContactForm
        shouldDisableInputs={false}
        handleSubmit={handleSubmit}
        isLoading={isLoading}
        isRequestSent={isRequestSent}
        handleCaptchaChange={handleCaptchaChange}
      />
    </MainContent>
  );
}
