import api from '~/services/api';
import { ICoinDataItem } from './actions';

export const getAllCoinsData = async (): Promise<ICoinDataItem[]> => {
  const { data } = await api.get(`prices`);

  const coinData = data.map((item: ICoinDataItem) => {
    const { coin, latest_block, total_nodes } = item;

    return {
      coin,
      latestBlock: latest_block,
      totalNodes: total_nodes,
    };
  });

  return coinData;
};
