import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input } from '@rocketseat/unform';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { FaMoneyBillTransfer } from 'react-icons/fa6';

import { signOut } from 'store/modules/auth/actions';
import {
  updateProfileRequest,
  updateProfileMasternodeToStartNotificationRequest,
  sendPromotionCode,
  resetDifferentAccountToTransferFunds,
  resetEmailChangeValues,
  setShouldShowEmailChangeCodeField,
} from 'store/modules/user/actions';

import {
  ChangeEmailButton,
  Container,
  EmailContainer,
  NodesToStartLabel,
  NotificationNodesToStartContainer,
  TransferBalanceContainer,
} from './styles';
import {
  getUserProfile,
  isLoadingUser as isLoadingUserSelector,
  isSendingPromoCode as isSendingPromoCodeSelector,
} from 'store/modules/user/selectors';
import { useQuery } from 'shared/string-utils';
import { BootstrapButton } from '~/components/ui/BootstrapButton';
import { TransferFundsModal } from './TransferFundsModal';
import { Tooltip } from '~/components/ui/Tooltip';
import { ChangeEmailModal } from './ChangeEmailModal';

const schema = Yup.object().shape({
  oldPassword: Yup.string().min(6),
  password: Yup.string().min(6),
  confirmPassword: Yup.string().min(6),
});
const schemaPromo = Yup.object().shape({
  promoCode: Yup.string().required('Promotion code is required'),
});

interface ISubmitPromoForm {
  promoCode: string;
}

export function Profile() {
  const dispatch = useDispatch();
  const profile = useSelector(getUserProfile);
  const isLoadingUser = useSelector(isLoadingUserSelector);
  const isSendingPromoCode = useSelector(isSendingPromoCodeSelector);
  const queryParams = useQuery();
  const promotion_code = String(queryParams.get('promotion_code') || '').toUpperCase();
  const [promoCode, setPromoCode] = useState(promotion_code);
  const [shouldShowTransferFundsModal, setShouldShowTransferFundsModal] = useState(false);
  const [shouldShowChangeEmailModal, setShouldShowChangeEmailModal] = useState(false);

  const toggleTransferFundsModal = () => {
    setShouldShowTransferFundsModal(!shouldShowTransferFundsModal);
  };
  const toggleChangeEmailModal = () => {
    setShouldShowChangeEmailModal(!shouldShowChangeEmailModal);
    dispatch(resetEmailChangeValues());
    dispatch(setShouldShowEmailChangeCodeField(false));
  };

  async function handleSubmit(data: any) {
    data.email = profile.email;

    if (data.name === '') toast.error("Username can't be empty");
    else if (data.oldPassword === '') dispatch(updateProfileRequest(data));
    else if (await schema.isValid(data)) dispatch(updateProfileRequest(data));
    else toast.error('At least 6 characters in all password fields.');
  }

  async function handleSubmitPromoForm({ promoCode }: ISubmitPromoForm) {
    dispatch(sendPromotionCode({ promotionCode: promoCode }));
  }

  function handleSignOut() {
    dispatch(signOut());
  }

  function handleMasternodeNotifications() {
    const data = {
      name: profile.name,
      email: profile.email,
      masternode_to_start_notification: !profile.masternode_to_start_notification,
    };

    dispatch(updateProfileMasternodeToStartNotificationRequest(data));
  }

  useEffect(() => {
    dispatch(resetDifferentAccountToTransferFunds());
  }, []);

  return (
    <Container isLoadingUser={isLoadingUser} isSendingPromoCode={isSendingPromoCode}>
      <div className="main">
        <p>{`Hello dear, ${profile.name} 👋`}</p>
        <EmailContainer>
          {`EMAIL: ${profile.email}`}
          <Tooltip placement="top" content={`Change Email`}>
            <ChangeEmailButton onClick={toggleChangeEmailModal} />
          </Tooltip>
        </EmailContainer>
        <NotificationNodesToStartContainer>
          <NodesToStartLabel htmlFor="notification-masternodes-to-start">
            Notifications About Nodes to Start?
          </NodesToStartLabel>
          <input
            onClick={handleMasternodeNotifications}
            id="notification-masternodes-to-start"
            type="checkbox"
            defaultChecked={profile.masternode_to_start_notification}
          />
        </NotificationNodesToStartContainer>
        <TransferBalanceContainer>
          <BootstrapButton variant="success" onClick={toggleTransferFundsModal}>
            <FaMoneyBillTransfer size={20} />
            Transfer Funds
          </BootstrapButton>
        </TransferBalanceContainer>
      </div>
      <Form
        // @ts-ignore
        schema={schemaPromo}
        className="promotion-form"
        // @ts-ignore
        onSubmit={handleSubmitPromoForm}
      >
        <Input
          onChange={(event: any) => {
            setPromoCode(String(event.target.value).toUpperCase());
          }}
          value={promoCode}
          name="promoCode"
          type="text"
          placeholder="PROMOTION CODE"
        />
        <BootstrapButton type="submit" variant="success" disabled={isSendingPromoCode}>
          Activate Code
        </BootstrapButton>
      </Form>

      <Form initialData={profile} onSubmit={handleSubmit}>
        <Input name="name" placeholder="Your username" />
        <Input type="password" name="oldPassword" placeholder="Current Password" />
        <Input type="password" name="password" placeholder="New password" />
        <Input type="password" name="confirmPassword" placeholder="Confirm password" />
        <button id="update-profile" type="submit" disabled={isLoadingUser}>
          {isLoadingUser ? 'Loading...' : 'Update Profile'}
        </button>
      </Form>
      <button onClick={handleSignOut}>Logout</button>
      {shouldShowTransferFundsModal && <TransferFundsModal toggleModal={toggleTransferFundsModal} />}
      {shouldShowChangeEmailModal && <ChangeEmailModal toggleModal={toggleChangeEmailModal} />}
    </Container>
  );
}
