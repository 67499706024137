import styled from 'styled-components/macro';
import { DISPLAY_FLEX_COLUMN_DEFAULT, DISPLAY_FLEX_LINE_DEFAULT } from 'style/constants';
import { TextInput } from 'components/ui/TextInput';
import { BootstrapButton } from '~/components/ui/BootstrapButton';

export const PAYMENT_CONTAINER_HEIGHT = `432px`;

export const PaymentMethodsContainer = styled.div`
  min-height: ${PAYMENT_CONTAINER_HEIGHT};
`;

export const Header = styled.header`
  ${DISPLAY_FLEX_COLUMN_DEFAULT};
  margin-top: 10px;
`;

export const PaymentTitle = styled.h3`
  text-align: center;
`;

export const CurrencyContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  gap: 25px;
  margin-top: 30px;
  font-size: 18px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 30px;
`;

export const CurrencyItemsContainer = styled.div`
  ${DISPLAY_FLEX_LINE_DEFAULT}
  gap: 10px;
`;

export const CurrencyPrice = styled.div`
  font-weight: bold;
`;

export const QuoteText = styled.div``;

export const Form = styled.form<{ isPaypalMethod: boolean }>`
  ${DISPLAY_FLEX_COLUMN_DEFAULT};
  margin-top: ${({ isPaypalMethod }) => (isPaypalMethod ? '210px' : '30px')};
  gap: 10px;
`;

export const StyledTextInput = styled(TextInput)`
  max-width: 200px;
  text-align: center;
`;

export const AddressTextInput = styled(TextInput)`
  text-align: center;
`;

export const CountdownContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT};
  gap: 10px;
`;

export const TimeForOrderText = styled.div`
  color: red;
  font-weight: 500;
`;

export const BolderText = styled.strong`
  text-align: center;
  font-size: 18px;
`;

export const NormalText = styled.div``;

export const OrderContainer = styled.div`
  font-size: 18px;
  text-align: center;
  ${DISPLAY_FLEX_COLUMN_DEFAULT};
  gap: 15px;
  margin-top: 10px;
`;

export const OrderTitleText = styled.div``;

export const QrCodeContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT};
  gap: 10px;
  width: 100%;
`;

export const OrderDetailsContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT};
  gap: 10px;
`;

export const DetailItem = styled.div`
  ${DISPLAY_FLEX_LINE_DEFAULT};
  gap: 5px;
`;

export const ItemText = styled.div<{ isBold?: boolean }>`
  font-weight: ${({ isBold }) => (isBold ? 'bold' : 'normal')};
`;

export const PaypalOrderContainer = styled.div`
  font-size: 18px;
  text-align: center;
  margin-top: 110px;
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  gap: 10px;
`;

export const PaypalOrderTextContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  gap: 10px;
  margin-bottom: 100px;
`;

export const OrderExpiredContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  font-size: 25px;
  margin-top: 110px;
`;

export const OrderExpiredText = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT};
  gap: 5px;
  font-weight: 500;
  color: red;
  margin-bottom: 110px;
`;

export const StyledBootstrapButton = styled(BootstrapButton)`
  min-width: 200px;
`;
