import { combineReducers } from 'redux';

import { auth } from './auth/reducer';
import { user } from './user/reducer';
import { appReducer } from './app/reducer';
import { coinChainData } from './coin-chain-data/reducer';
import { faucet } from './faucet/reducer';

export default combineReducers({
  auth,
  user,
  app: appReducer,
  coinChainData,
  faucet,
});
