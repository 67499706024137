import { takeLatest, call, put, all } from 'redux-saga/effects';

import {
  coinChainDataTypes,
  ICoinDataItem,
  IGetBlockAndTotalNodesAction,
  setBlockAndTotalNodes,
} from './actions';

import {
  pushLoadingAppDataRequest,
  spliceLoadingAppDataRequest,
} from '../app/actions';

import { getAllCoinsData } from './api';

export function* getLatestBlockAndTotalNodesSaga({
  payload,
}: IGetBlockAndTotalNodesAction) {
  const requestName = 'getLatestBlockAndTotalNodesSaga';
  const { shouldUseLoadingAppData } = payload;

  try {
    if (shouldUseLoadingAppData) {
      yield put(pushLoadingAppDataRequest(requestName));
    }

    const coinData: ICoinDataItem[] = yield call(
      getAllCoinsData
    );

    yield put(
      setBlockAndTotalNodes(coinData)
    );
  } catch (err) {
    console.error(`Something went wrong on getLatestBlockAndTotalNodesSaga.`);
  } finally {
    if (shouldUseLoadingAppData) {
      yield put(spliceLoadingAppDataRequest(requestName));
    }
  }
}

export default all([
  takeLatest(
    coinChainDataTypes.GET_LATEST_BLOCK_AND_TOTAL_NODES,
    getLatestBlockAndTotalNodesSaga
  ),
]);
