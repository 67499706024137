import { DISPLAY_FLEX_LINE_DEFAULT } from 'style/constants';
import styled from 'styled-components/macro';

export const Container = styled.div`
  margin-top: 30px;
  text-align: center;
  width: 100%;
  ${DISPLAY_FLEX_LINE_DEFAULT};
  color: #fff;
  font-size: 16px;
  gap: 10px;
`;

export const SocialMediaLink = styled.a`
  color: #fff;

  &:hover {
    color: #fff;
  }

  svg {
    margin-right: 5px;
  }
`;
