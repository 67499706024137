import { toast } from 'react-toastify';
import { useState, useRef } from 'react';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { signInRequest } from 'store/modules/auth/actions';

import { LinkText, LinkContainer } from './styles';
import { isAuthLoading } from 'store/modules/auth/selectors';

import HCaptchaConfig from '~/config/HCaptcha';
import { HiglanLogo } from './shared/HiglanLogo';
import { GeneralContainer } from './shared/GeneralContainer';
import { UnformInput } from './shared/UnformInput';
import { UnformForm } from './shared/UnformForm';
import { SubmitButton } from './shared/SubmitButton';

const schema: any = Yup.object().shape({
  email: Yup.string().email('Your email is not valid').required('Email is required'),
  password: Yup.string().required('Password is required'),
});

interface IHandleSubmit {
  email: string;
  password: string;
}

export default function SignIn() {
  const dispatch = useDispatch();
  const isLoading = useSelector(isAuthLoading);
  const [captchaToken, setCaptchaToken] = useState<string>('');
  const captchaRef = useRef(null);

  const resetCaptcha = () => {
    // @ts-ignore
    captchaRef.current?.resetCaptcha();
    setCaptchaToken('');
  };

  const handleSubmit = ({ email, password }: IHandleSubmit) => {
    if (!captchaToken) {
      toast.error(`Please resolve the captcha.`, {
        pauseOnFocusLoss: false,
      });
      return;
    }
    dispatch(signInRequest(email, password, captchaToken, false, resetCaptcha));
  };

  const onHCaptchaVerify = (token: string) => {
    setCaptchaToken(token);
  };

  return (
    <GeneralContainer>
      <HiglanLogo />
      {/* @ts-ignore */}
      <UnformForm schema={schema} onSubmit={handleSubmit}>
        <UnformInput name="email" type="email" placeholder="Your email" />
        <UnformInput name="password" type="password" placeholder="Your password" />
        <HCaptcha size="normal" sitekey={HCaptchaConfig.siteKey} onVerify={onHCaptchaVerify} ref={captchaRef} />
        <SubmitButton type="submit" disabled={isLoading} isLoading={isLoading}>
          Login
        </SubmitButton>
        <LinkContainer>
          <LinkText to="/reset-password">Forgot password?</LinkText>
          <LinkText to="/register">Create account</LinkText>
        </LinkContainer>
      </UnformForm>
    </GeneralContainer>
  );
}
