import { Button } from 'react-bootstrap';
import styled from 'styled-components/macro';

export const StyledBootstrapButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  min-width: 90px;
  min-height: 40px;

  :disabled {
    cursor: not-allowed;
  }
`;
