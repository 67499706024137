import api from 'services/api';
import { IFaucetHistory } from '../interfaces';

export const sendFaucetCaptchaRequest = async (captchaToken: string): Promise<number> => {
  try {
    const { data } = await api.post('faucet', { captchaToken });

    const { timeForNextRound } = data;

    return timeForNextRound;
  } catch (err) {
    console.error('Something went wrong on sendFaucetCaptchaRequest.');
    throw err;
  }
};

export const getFaucetValuesRequest = async (): Promise<{
  faucetBaseValue: number;
  faucetBalance: number;
  timeForNextRound: number;
  allTimeEarned: number;
  faucetHistory: IFaucetHistory[];
  minValueForWithdraw: number;
}> => {
  try {
    const { data } = await api.get('faucet');
    return data;
  } catch (err) {
    console.error('Something went wrong on getFaucetBaseValueRequest.');
    throw err;
  }
};

export const withdrawRequest = async () => {
  try {
    await api.post('faucet/withdraw');
  } catch (err) {
    console.error('Something went wrong on withdrawRequest.');
    throw err;
  }
};
