import { Container } from 'pages/_layouts/default/styles';
import { NodeOutputs } from '~/components/NodeOutputs';

export function Tools() {
  return (
    <Container>
      <NodeOutputs />
    </Container>
  );
}
