import styled from 'styled-components/macro';
import { DISPLAY_FLEX_COLUMN_DEFAULT } from '~/style/constants';

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const NormalText = styled.div``;

export const BolderText = styled.strong``;

export const ResetIndexMessageContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  gap: 7px;
`;

export const DivElement = styled.div``;
