import styled from 'styled-components';
import { DISPLAY_FLEX_COLUMN_DEFAULT } from '~/style/constants';

export const UserDataContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  border: 1px solid black;
  margin-bottom: 12px;
`;

export const UserDataHeader = styled.div`
  background-color: lightgray;
  width: 100%;
  padding: 17px;
  border: 1px solid #dee2e6;
  border-bottom: 0;
  ${DISPLAY_FLEX_COLUMN_DEFAULT};
  gap: 17px;
`;
