import { MdDateRange } from 'react-icons/md';

import { Modal } from '~/components/ui/Modal';
import { displayDefaultDateFormat, displayNodeAlias, displayPriceWithCurrency } from '~/shared/string-utils';
import { INodeToChangeChargeDate } from '../DataTable';
import { ChargeDateContainer, StyledItemsContainer } from './styles';
import { BolderText, ItemContainer, NormalText, StyledTextArea } from '../styles';
import { SUPORTED_COINS } from '~/shared/constants';
import MonthAmounts, { IMonthData } from '~/pages/NodeSteps/CreateOrder/shared/MonthAmounts';
import { useState } from 'react';
import { addMonths } from 'date-fns';
import api from '~/services/api';
import { toast } from 'react-toastify';

interface IChargeDateModal {
  hideChargeDateModal: () => void;
  node: INodeToChangeChargeDate;
}

export function ChargeDateModal({ hideChargeDateModal, node }: IChargeDateModal) {
  const { id: nodeId, private_key, coin, price, alias, next_charge, charged_at } = node;
  const coinName = SUPORTED_COINS[coin].name;
  const [desiredMonthAmounts, setDesiredMonthAmounts] = useState(1);
  const [totalToBeCharged, setTotalToBeCharged] = useState(price * desiredMonthAmounts);
  const extendedNextChargeDateInitialValue = addMonths(new Date(next_charge), desiredMonthAmounts);
  const nodeAlias = displayNodeAlias(alias);
  const [extendedNextChargeDate, setExtendedNextChargeDate] = useState(extendedNextChargeDateInitialValue);
  const [isSendingExtendRequest, setIsSendingExtendRequest] = useState(false);

  const formattedNextChargeDate = displayDefaultDateFormat(new Date(next_charge));
  const formattedChargedAtDate = displayDefaultDateFormat(new Date(charged_at));
  const formattedExtendedNextChargeDate = displayDefaultDateFormat(extendedNextChargeDate);

  const onSelectMonthAmount = ({ monthAmountNumber }: IMonthData) => {
    setDesiredMonthAmounts(monthAmountNumber);
    setTotalToBeCharged(price * monthAmountNumber);
    const newChargeDate = addMonths(new Date(next_charge), monthAmountNumber);
    setExtendedNextChargeDate(newChargeDate);
  };

  const ModalContent = () => {
    const automaticallyChargeText = `The charge for this node will happen automatically at <strong>${formattedNextChargeDate}</strong>. If you like you can extend it manually.`;
    return (
      <ChargeDateContainer>
        <NormalText
          dangerouslySetInnerHTML={{
            __html: automaticallyChargeText,
          }}
        />
        <StyledItemsContainer>
          <ItemContainer>
            <BolderText>Coin</BolderText>
            <NormalText>{coinName}</NormalText>
          </ItemContainer>
          <ItemContainer>
            <BolderText>Alias</BolderText>
            <NormalText>{nodeAlias}</NormalText>
          </ItemContainer>
          <ItemContainer>
            <BolderText>Node Key</BolderText>
            <StyledTextArea minHeight={30} defaultValue={private_key} disabled />
          </ItemContainer>
          <ItemContainer>
            <BolderText>Price per Month</BolderText>
            <NormalText>{displayPriceWithCurrency(price)}</NormalText>
          </ItemContainer>
          <ItemContainer>
            <BolderText>Charged at</BolderText>
            <NormalText>{formattedChargedAtDate}</NormalText>
          </ItemContainer>
          <ItemContainer>
            <BolderText>Next Charge</BolderText>
            <NormalText>{formattedNextChargeDate}</NormalText>
          </ItemContainer>
        </StyledItemsContainer>

        <BolderText>How many months would you like to extend for this node?</BolderText>

        <MonthAmounts
          numberOfMonths={desiredMonthAmounts}
          onSelectMonthAmount={onSelectMonthAmount}
          shouldShowMonthTitle={false}
        />

        <ItemContainer>
          <BolderText>The next charge date for this node will be at</BolderText>
          <NormalText>{formattedExtendedNextChargeDate}</NormalText>
        </ItemContainer>

        <ItemContainer>
          <BolderText>Total to be paid</BolderText>
          <NormalText>{displayPriceWithCurrency(totalToBeCharged)}</NormalText>
        </ItemContainer>
      </ChargeDateContainer>
    );
  };

  const SuccessfullyToastMessage = () => {
    const coinName = coin.toUpperCase();
    return (
      <>
        The next charge date for your{' '}
        <BolderText>
          {coinName} {nodeAlias}
        </BolderText>{' '}
        has been extended to <BolderText>{formattedExtendedNextChargeDate}</BolderText>!
      </>
    );
  };

  const handleModalSubmit = async () => {
    const params = {
      months: desiredMonthAmounts,
    };
    try {
      setIsSendingExtendRequest(true);
      await api.put(`nodes/extend_charge_date/${nodeId}`, null, {
        params,
      });
      toast.success(<SuccessfullyToastMessage />, {
        autoClose: 10000,
      });

      hideChargeDateModal();
    } catch (e) {
      const err: any = e;
      const { data } = err.response;

      const { error } = data;

      toast.error(error);
    } finally {
      setIsSendingExtendRequest(false);
    }
  };

  return (
    <Modal
      bodyContent={<ModalContent />}
      handleModalSubmit={handleModalSubmit}
      title={`Node Charge Date`}
      show={true}
      submitButtonIcon={<MdDateRange size={30} />}
      onHide={hideChargeDateModal}
      isSubmitting={isSendingExtendRequest}
    />
  );
}
