import styled from 'styled-components';

export const Thead = styled.thead``;

export const Tr = styled.tr``;

export const Th = styled.th`
  vertical-align: baseline !important;
  text-align: center;
`;

export const Tbody = styled.tbody``;

export const Td = styled.td``;

export const TdNoActivity = styled.td`
  text-align: center;
`;

export const DataTableContainer = styled.div`
  width: 100%;
`;
