import React from 'react';
import { MdQueryBuilder } from 'react-icons/md';
import { FaCheckCircle } from 'react-icons/fa';
import { FiAlertTriangle } from 'react-icons/fi';

import { Container } from './styles';

export default function Status() {
  return (
    <Container>
      <div className="status-data">
        <div className="item1">
          <strong>Status</strong>
        </div>
        <div className="item2">
          <strong>Your Action</strong>
        </div>
        <div className="item3">
          <MdQueryBuilder id="mounting" />
          Mounting node.
        </div>
        <div className="item4">
          <span>Just wait.</span>
        </div>
        <div className="item5">
          <FiAlertTriangle id="ready" />
          Node is ready.
        </div>
        <div className="item6">
          <span>
            Activate it in your wallet and keep your wallet open for some
            minutes.
          </span>
        </div>
        <div className="item7">
          <FaCheckCircle id="activated" />
          Node successfully enabled!
        </div>
        <div className="item8">
          <span>Enjoy your rewards.</span>
        </div>
      </div>
    </Container>
  );
}
