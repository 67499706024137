import styled, { css } from 'styled-components/macro';
import {
  DISPLAY_FLEX_COLUMN_DEFAULT,
  DISPLAY_FLEX_LINE_DEFAULT,
  HIGLAN_BLACK_01,
  HIGLAN_BLUE_01,
  HIGLAN_GRAY_02,
  HIGLAN_GREEN_04,
} from '~/style/constants';
import { TextInput } from '~/components/ui/TextInput';
import { BootstrapButton } from '~/components/ui/BootstrapButton';

const BALANCES_CONTAINER_STYLE = css`
  gap: 7px;
  padding: 7px;
  border-radius: 10px;
  width: 150px;
  font-weight: bold;
`;

export const TransferFundsModalContent = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  gap: 20px;
  font-size: 16px;
`;

export const TransferFundsDescription = styled.div`
  text-align: center;
`;

export const BolderText = styled.strong``;

export const NormalText = styled.div``;

export const UserAccountContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  gap: 10px;
  padding: 10px;
  border: 1px solid ${HIGLAN_BLACK_01};
`;

export const UserAccountDescription = styled.div``;

export const BalancesContainer = styled.div`
  ${DISPLAY_FLEX_LINE_DEFAULT}
  gap: 25px;
  text-align: center;
`;

export const BalanceContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT};
  color: ${HIGLAN_GREEN_04};
  ${BALANCES_CONTAINER_STYLE}
  border: 1px solid ${HIGLAN_GREEN_04};
`;

export const BonusContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT};
  color: ${HIGLAN_BLUE_01};
  ${BALANCES_CONTAINER_STYLE}
  border: 1px solid ${HIGLAN_BLUE_01};
`;

export const HiglanAccountToTransferContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  padding: 10px;
  border-top: 1px dashed ${HIGLAN_BLACK_01};
  width: 100%;
  gap: 10px;
`;

export const StyledTextInput = styled(TextInput)`
  max-width: 200px;
`;

export const AccountToTransferForm = styled.form`
  ${DISPLAY_FLEX_LINE_DEFAULT}
  gap: 7px;
  height: 48px;
`;

export const VerificationButton = styled(BootstrapButton)`
  height: 95%;
`;

export const DifferentAccountDataContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  text-align: center;
  gap: 15px;
`;

export const InputContainer = styled.div`
  ${DISPLAY_FLEX_LINE_DEFAULT}
  gap: 7px;
`;

export const MaxText = styled.small`
  text-align: center;
  cursor: pointer;
  font-weight: bold;
  color: ${HIGLAN_GRAY_02};
  :hover {
    text-decoration: underline;
  }
`;

export const StyledFundsInput = styled(TextInput)`
  width: 80px;
  text-align: center;
  padding: 0;
`;

export const TransferFundsCodeContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  gap: 7px;
`;

export const TransferFundsCodeInput = styled(TextInput)`
  width: 80px;
  text-align: center;
  padding: 0;
`;

export const FundsTextContainer = styled.div`
  ${DISPLAY_FLEX_LINE_DEFAULT}
  font-weight: bold;
  gap: 7px;
`;

export const BalanceText = styled.div`
  color: ${HIGLAN_GREEN_04};
`;

export const BonusText = styled.div`
  color: ${HIGLAN_BLUE_01};
`;

export const ConfirmTransferButton = styled(BootstrapButton)``;
