import { useState } from 'react';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import api from 'services/api';
import { GeneralContainer } from '../SignIn/shared/GeneralContainer';
import { HiglanLogo } from '../SignIn/shared/HiglanLogo';
import { UnformForm } from '../SignIn/shared/UnformForm';
import { SubmitButton } from '../SignIn/shared/SubmitButton';
import { TitleText } from './styles';

const useQuery = () => new URLSearchParams(useLocation().search);

export default function Unsubscribe() {
  const [requestSent, setRequestSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const query = useQuery();
  const queryEmail = query.get('u');
  const queryHash = query.get('h');

  async function handleSubmit() {
    try {
      setIsLoading(true);
      await api.put('promotions', {
        u: queryEmail,
        h: queryHash,
      });
      setRequestSent(true);
    } catch (error) {
      const err: any = { error };
      const { response } = err.error;
      toast.error(response.data.error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <GeneralContainer>
      <HiglanLogo />
      {!requestSent ? (
        // @ts-ignore
        <UnformForm onSubmit={handleSubmit}>
          <TitleText>
            Click on the OK button <br /> to confirm this action
          </TitleText>
          <SubmitButton isLoading={isLoading} type="submit" disabled={isLoading}>
            OK
          </SubmitButton>
        </UnformForm>
      ) : (
        <TitleText>You have successfully unsubscribed.</TitleText>
      )}
    </GeneralContainer>
  );
}
