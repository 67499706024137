import styled, { css } from 'styled-components/macro';
import {
  DISPLAY_FLEX_COLUMN_DEFAULT,
  DISPLAY_FLEX_LINE_DEFAULT,
  HIGLAN_BLACK_01,
  HIGLAN_RED_01,
  HIGLAN_WHITE_01,
} from '~/style/constants';
import { TextRender } from '../TextRender';

interface NormalTextProps {
  isGreen?: boolean;
}

const HOVER_CSS = css`
  &:hover {
    border: 2px solid #7159c1;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
`;

const HOVER_CSS_WHITE = css`
  &:hover {
    border: 2px solid ${HIGLAN_WHITE_01};
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
`;

export const FrozenWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(128, 128, 128, 0.7);
  cursor: not-allowed;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  ${DISPLAY_FLEX_LINE_DEFAULT}
`;

export const FrozenText = styled.div`
  font-size: 16px;
  color: white;
  font-weight: bold;
  opacity: 1;
  background-color: red;
  width: 100%;
  padding: 10px;
`;

export const Container = styled.div<{ isFrozen: boolean; shouldUseWhiteHover: boolean }>`
  ${DISPLAY_FLEX_COLUMN_DEFAULT};
  gap: 10px;
  position: relative;
  width: 210px;
  /* height: 230px; */
  position: relative;
  background: linear-gradient(to bottom, #fff 5%, #d4d4d4 100%);
  border: 1px solid #7159c1;
  border-radius: 5px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.35);
  cursor: pointer;
  padding: 10px;
  ${({ isFrozen }) => !isFrozen && HOVER_CSS}
  ${({ shouldUseWhiteHover }) => shouldUseWhiteHover && HOVER_CSS_WHITE}
`;

export const CoinName = styled.span`
  font-weight: bold;
`;

export const CoinInfoContainer = styled.div`
  display: flex;
  gap: 20px;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ItemLabel = styled(TextRender)`
  font-weight: bold;
`;

export const ItemDescription = styled(TextRender)``;

export const Header = styled.header`
  display: flex;
  flex-direction: column;
`;

export const Footer = styled.footer`
  display: flex;
  flex: 1;
  gap: 30px;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
`;

export const IconWrapper = styled.div`
  z-index: 99;
`;

export const IconLink = styled.a`
  color: black;

  &:hover {
    color: #7159c1;
  }
`;

export const NormalText = styled(TextRender)<NormalTextProps>`
  color: ${({ isGreen }) => (isGreen ? 'rgba(147, 119, 16, 1)' : HIGLAN_BLACK_01)};
`;

export const PriceContainer = styled.div`
  ${DISPLAY_FLEX_LINE_DEFAULT}
  gap: 3px;
`;

export const FreeNodeContainer = styled.div<{ isVisible: boolean }>`
  ${DISPLAY_FLEX_LINE_DEFAULT}
  background-color: rgba(255, 251, 214, 1);
  padding: 5px;
  color: rgba(147, 119, 16, 1);
  border-radius: 7px;
  text-align: center;
  visibility: ${({ isVisible }) => !isVisible && 'hidden'};
`;

export const FreeNodeText = styled(TextRender)`
  font-weight: bold;
`;

export const TextWithLine = styled(TextRender)`
  text-decoration: line-through;
  color: ${HIGLAN_RED_01};
`;

export const MonthLabelContainer = styled.div`
  ${DISPLAY_FLEX_LINE_DEFAULT}
  color: rgba(147, 119, 16, 1);
`;
