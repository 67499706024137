import styled, { css } from 'styled-components/macro';
import { mobileOnly } from 'style/mixins';
import { CoinLogo } from 'components/CoinLogo';
import { DISPLAY_FLEX_COLUMN_DEFAULT, DISPLAY_FLEX_LINE_DEFAULT, HIGLAN_WHITE_01 } from '~/style/constants';

const IS_PAUSED_CSS = css`
  cursor: auto;
  color: #7159c1;
`;

const IS_OVERDUE_CSS = css`
  background-color: red !important;
  color: white;
  svg {
    color: ${HIGLAN_WHITE_01} !important;
  }
  &:hover {
    color: ${HIGLAN_WHITE_01} !important;
  }
  a {
    color: ${HIGLAN_WHITE_01};
  }
`;

export const Container = styled.div`
  margin-top: 20px;
`;

export const Thead = styled.thead``;

export const Tr = styled.tr<{ isOverdue?: boolean }>`
  text-align: center;
  ${({ isOverdue }) => (isOverdue ? IS_OVERDUE_CSS : null)}
`;

export const Th = styled.th`
  vertical-align: middle !important;
  font-size: 13.7px;
`;

export const ThStatus = styled(Th)`
  ${mobileOnly`
    svg {
      display: none;
    }
  `}
`;

export const Tbody = styled.tbody`
  text-align: center;
  font-size: 14px;
`;

export const Td = styled.td``;

export const TdActions = styled.td``;

export const ActionsContainer = styled.div<{ isPaused: boolean; isMounting: boolean }>`
  ${DISPLAY_FLEX_LINE_DEFAULT};
  gap: 7px;
  justify-content: ${({ isMounting }) => (isMounting ? 'center' : 'flex-end')};

  svg {
    &:hover {
      color: #7159c1;
    }
  }

  svg.pause-button {
    ${({ isPaused }) => isPaused && IS_PAUSED_CSS}
  }
`;

export const TdSmaller = styled.td`
  font-size: smaller;
`;

export const TdImageContainer = styled.td`
  text-align: center;
  font-size: 12px;
  font-weight: bold;
`;

export const TdButtonContainer = styled.td`
  text-align: center;
`;

export const TdImage = styled(CoinLogo)`
  width: 25px;
  margin-right: 5px;
`;

export const TdActiveTime = styled.td`
  min-width: 100px;
`;

export const OrderedTextContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT};
  gap: 5px;
`;
