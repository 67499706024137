import { Spinner } from 'react-bootstrap';
import { Modal } from '~/components/ui/Modal';
import { LuArchiveRestore } from 'react-icons/lu';
import { INodesThatCanBeRestored } from '~/store/modules/interfaces';
import { DataTable } from '~/components/ui/DataTable';
import { displayDefaultDateFormat, displayPriceWithCurrency, reduceKey } from '~/shared/string-utils';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { restoreNode } from '~/store/modules/user/actions';
import { NormalText } from '../styles';
import { BELDEX_EXPLORER_LINK } from '~/shared/constants';
import { getRestorationLoadingId } from '~/store/modules/user/selectors';
import { Tooltip } from '~/components/ui/Tooltip';

import { ActionsContainer, ExplorerLink, RestoreNodesModalContainer } from './styles';

interface IRestoreNodesModal {
  toggleRestoreNodesModal: () => void;
  nodesThatCanBeRestored: INodesThatCanBeRestored[];
}

const RESTORE_NODES_COLUMNS = ['Coin', 'Node Key', 'Price', 'Fee', 'Deleted at', 'Action'];
const ACTION_ICON_SIZE = 17;

export function RestoreNodesModal({ toggleRestoreNodesModal, nodesThatCanBeRestored }: IRestoreNodesModal) {
  const dispatch = useDispatch();
  const restorationLoadingId = useSelector(getRestorationLoadingId);

  const handleRestoreNode = (deletedNodeId: number, shouldRestore: boolean, loadingId: string) => {
    dispatch(restoreNode(deletedNodeId, shouldRestore, loadingId));
  };

  const renderActions = (deletedNodeId: number) => {
    const restoreButtonId = `${deletedNodeId}_restore_button`;
    const ignoreButtonId = `${deletedNodeId}_ignore_button`;
    return (
      <ActionsContainer>
        {restorationLoadingId === restoreButtonId ? (
          <Spinner animation="border" variant="black" size="sm" />
        ) : (
          <Tooltip content="Restore Node" key={restoreButtonId} shouldUseZIndex={false}>
            <LuArchiveRestore
              onClick={() => handleRestoreNode(deletedNodeId, true, restoreButtonId)}
              size={ACTION_ICON_SIZE}
            />
          </Tooltip>
        )}
        {restorationLoadingId === ignoreButtonId ? (
          <Spinner animation="border" variant="black" size="sm" />
        ) : (
          <Tooltip content={`Ignore`} key={ignoreButtonId} shouldUseZIndex={false}>
            <AiOutlineCloseCircle
              onClick={() => handleRestoreNode(deletedNodeId, false, ignoreButtonId)}
              size={ACTION_ICON_SIZE}
            />
          </Tooltip>
        )}
      </ActionsContainer>
    );
  };

  const RESTORE_NODES_LINES = nodesThatCanBeRestored.map((restoreNodeItem) => {
    const { id, coin, price, private_key_used, created_at } = restoreNodeItem;

    return [
      String(coin).toUpperCase(),
      <ExplorerLink href={`${BELDEX_EXPLORER_LINK}/mn/${private_key_used}`} target="_blank">
        {reduceKey(private_key_used)}
      </ExplorerLink>,
      displayPriceWithCurrency(price),
      displayPriceWithCurrency(price / 2),
      displayDefaultDateFormat(new Date(created_at)),
      renderActions(id),
    ];
  });

  const ModalContent = () => {
    return (
      <RestoreNodesModalContainer>
        <NormalText>Restoring a node will keep you getting rewards from that node.</NormalText>
        <DataTable columns={RESTORE_NODES_COLUMNS} lines={RESTORE_NODES_LINES} />
      </RestoreNodesModalContainer>
    );
  };

  return (
    <Modal
      hasConfirmation={false}
      bodyContent={<ModalContent />}
      title={`RESTORE NODES`}
      show={true}
      submitButtonIcon={<LuArchiveRestore size={30} />}
      onHide={toggleRestoreNodesModal}
    />
  );
}
