import { useState } from 'react';
import { FaCartArrowDown } from 'react-icons/fa';

import { Modal } from '~/components/ui/Modal';
import {
  LoadingContainer,
  LoadingText,
  ModalBodyContainer,
  ModalNetworkText,
  PaymentIframe,
  StyledSpinner,
} from './styles';
import { BolderText } from '../styles';

interface IPaymentPage {
  handleHideModal: () => void;
  url: string;
}

export function PaymentModal({ handleHideModal, url }: IPaymentPage) {
  const [isIframeLoading, setIsIframeLoading] = useState(true);

  const handleIframeLoading = () => {
    setIsIframeLoading(false);
  };

  const renderBodyContent = () => {
    return (
      <ModalBodyContainer>
        <ModalNetworkText>
          Make sure to use one of the supported network: <BolderText>Base</BolderText>,{' '}
          <BolderText>Ethereum</BolderText> or <BolderText>Polygon</BolderText>.
        </ModalNetworkText>
        {isIframeLoading && (
          <LoadingContainer>
            <LoadingText>Loading...</LoadingText>
            <StyledSpinner animation="border" variant="black" />
          </LoadingContainer>
        )}
        <PaymentIframe src={url} onLoad={handleIframeLoading} isIframeLoading={isIframeLoading} />
      </ModalBodyContainer>
    );
  };

  return (
    <Modal
      bodyContent={renderBodyContent()}
      title="ONCHAIN PAYMENT"
      show={true}
      onHide={handleHideModal}
      hasConfirmation={false}
      size="xl"
      submitButtonIcon={<FaCartArrowDown />}
    />
  );
}
