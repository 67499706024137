import { useSelector } from 'react-redux';
import { DataTable } from '~/components/ui/DataTable';
import { Modal } from '~/components/ui/Modal';
import { displayPriceWithCurrency, emptyFunction, formatDate } from '~/shared/string-utils';
import { getAllTimeEarned } from '~/store/modules/faucet/selectors';
import { IFaucetHistory } from '~/store/modules/interfaces';
import { AllTimeEarned, BodyContainer, NormalText } from './styles';

interface IHistoryModal {
  onHideModal: () => void;
  faucetHistory: IFaucetHistory[];
}

const HISTORY_COLUMNS = ['Earned', 'Earned at'];

export function HistoryModal({ onHideModal, faucetHistory }: IHistoryModal) {
  const allTimeEarned = useSelector(getAllTimeEarned);

  const tableLines = faucetHistory.map(({ bonus_credit, created_at }) => [
    displayPriceWithCurrency(bonus_credit, 4),
    formatDate(new Date(created_at)),
  ]);

  const bodyContent = (
    <BodyContainer>
      <AllTimeEarned>
        <NormalText>All Time Earned: {displayPriceWithCurrency(allTimeEarned, 4)}</NormalText>
      </AllTimeEarned>
      <NormalText>Last 30 Days History</NormalText>
      <DataTable lines={tableLines} columns={HISTORY_COLUMNS} />
    </BodyContainer>
  );
  
  return (
    <Modal
      show={true}
      bodyContent={bodyContent}
      title="FAUCET HISTORY"
      handleModalSubmit={emptyFunction}
      hasConfirmation={false}
      onHide={onHideModal}
    />
  );
}
