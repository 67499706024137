import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { Accordion } from 'react-bootstrap';

export const StyledButton = styled(Button)`
  text-align: left;
`;

export const StyledAccordion = styled(Accordion)`
  width: 100%;
`;
