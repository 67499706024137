import { HTMLAttributes } from 'react';
import uuid from 'react-uuid';
import { emptyFunction } from 'shared/string-utils';
import { Container, InputElement, LabelElement } from './styles';

interface IRadioButton extends HTMLAttributes<HTMLDivElement> {
  label: string;
  checked: boolean;
}

export function RadioButton({ label, checked, ...props }: IRadioButton) {
  const idForElement = uuid();

  return (
    <Container {...props}>
      <InputElement
        id={idForElement}
        type="radio"
        onChange={emptyFunction}
        checked={checked}
      />
      <LabelElement htmlFor={idForElement}>{label}</LabelElement>
    </Container>
  );
}
