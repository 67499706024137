import { MainContent } from '../Home';
import { StyledSuggestCoin, SuggestCoinContainer, SuggestCoinTitle } from './styles';
import history from 'services/history';

export function SuggestCoin() {
  const executeAfterSend = () => {
    history.push(`/home`);
  };

  return (
    <MainContent>
      <SuggestCoinContainer>
        <SuggestCoinTitle>SUGGEST A COIN</SuggestCoinTitle>
        <StyledSuggestCoin isPublicMessage executeAfterSend={executeAfterSend} />
      </SuggestCoinContainer>
    </MainContent>
  );
}
