import styled from 'styled-components/macro';
import { TextInput } from 'components/ui/TextInput';
import { DISPLAY_FLEX_LINE_DEFAULT } from '~/style/constants';

export const SuggestCoinContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
`;


export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const StyledTextInput = styled(TextInput)`
  text-align: center;
`;

export const ButtonsContainer = styled.div`
  ${DISPLAY_FLEX_LINE_DEFAULT}
  gap: 7px;
`;