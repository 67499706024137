import { FormButton, FormElement, FormInput, FormTitle, InputContainer, InputLabel, InputTextArea } from './styles';
import HCaptchaConfig from '~/config/HCaptcha';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { useRef, useState } from 'react';
import { Spinner } from 'react-bootstrap';

interface IContactForm {
  handleSubmit: (data: any) => void;
  isRequestSent: boolean;
  isLoading: boolean;
  userName?: string;
  userEmail?: string;
  shouldDisableInputs?: boolean;
  handleCaptchaChange?: (captchaToken: string) => void;
}
export function ContactForm({
  handleSubmit,
  userEmail = '',
  userName = '',
  isLoading,
  isRequestSent,
  handleCaptchaChange,
  shouldDisableInputs = true,
  ...props
}: IContactForm) {
  const captchaRef = useRef(null);
  const [userNameValue, setUserNameValue] = useState(userName);
  const [userEmailValue, setUserEmailValue] = useState(userEmail);
  const [message, setMessage] = useState('');

  const onHCaptchaVerify = (captchaToken: string) => {
    if (handleCaptchaChange) {
      handleCaptchaChange(captchaToken);
    }
  };

  const checkIfValueIsInvalid = (value?: string) => {
    if (!value || value.trim() === '') {
      return true;
    }

    return false;
  };

  const onChangeUserName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setUserNameValue(value);
  };

  const onChangeUserEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setUserEmailValue(value);
  };

  const onChangeMessage = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    setMessage(value);
  };

  return (
    <FormElement onSubmit={handleSubmit} {...props}>
      <FormTitle>Contact Form</FormTitle>
      <InputContainer>
        <InputLabel>Username</InputLabel>
        <FormInput
          hasError={checkIfValueIsInvalid(userNameValue)}
          name="name"
          placeholder="How would you like to be called?"
          value={userNameValue}
          disabled={shouldDisableInputs || isRequestSent}
          type="text"
          onChange={onChangeUserName}
          required
        />
      </InputContainer>
      <InputContainer>
        <InputLabel>Email</InputLabel>
        <FormInput
          hasError={checkIfValueIsInvalid(userEmailValue)}
          placeholder="Your best email to get your answer"
          name="email"
          value={userEmailValue}
          disabled={shouldDisableInputs || isRequestSent}
          type="email"
          onChange={onChangeUserEmail}
          required
        />
      </InputContainer>
      <InputContainer>
        <InputLabel>Message</InputLabel>
        <InputTextArea
          hasError={checkIfValueIsInvalid(message)}
          name="message"
          rows={7}
          placeholder="What would you like to say to us?"
          disabled={isRequestSent}
          onChange={onChangeMessage}
          required
        />
      </InputContainer>
      {handleCaptchaChange && (
        <HCaptcha ref={captchaRef} size="normal" sitekey={HCaptchaConfig.siteKey} onVerify={onHCaptchaVerify} />
      )}
      <FormButton isRequestSent={isRequestSent} type="submit" disabled={isLoading || isRequestSent}>
        {isLoading ? <Spinner size="sm" animation="grow" /> : 'SEND'}
      </FormButton>
    </FormElement>
  );
}
