import styled from 'styled-components/macro';

export const NodeStatusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StatusItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 30px;
`;

export const TotalItemContainer = styled.div`
  display: flex;
`;

export const StatusItemContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 200px;
  align-items: center;
`;

export const AllNodesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
`;
