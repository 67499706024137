import styled from 'styled-components/macro';
import { Root, Indicator } from '@radix-ui/react-checkbox';
import { CheckIcon } from '@radix-ui/react-icons';
import {
  DISPLAY_FLEX_LINE_DEFAULT,
  HIGLAN_GRAY_01,
  HIGLAN_GREEN_01,
  HIGLAN_WHITE_01,
  HIGLAN_WHITE_02,
} from '~/style/constants';

export const Container = styled.div`
  ${DISPLAY_FLEX_LINE_DEFAULT}
  gap: 7px;
`;

export const StyledCheckboxRoot = styled(Root)`
  background-color: ${HIGLAN_WHITE_01};
  width: 25px;
  height: 25px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 3px ${HIGLAN_GRAY_01};

  :hover {
    background-color: ${HIGLAN_WHITE_02};
  }
`;

export const StyledCheckboxIndicator = styled(Indicator)`
  color: ${HIGLAN_GREEN_01};
`;

export const StyledCheckIcon = styled(CheckIcon)``;

export const Label = styled.label`
  font-size: 17px;
  margin: 0;
  font-weight: bold;
`;
