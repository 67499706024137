import styled, { css } from 'styled-components/macro';
import { DISPLAY_FLEX_COLUMN_DEFAULT } from '~/style/constants';

const BASE_STYLES = css`
  background: rgba(0, 0, 0, 0.1);
  border: 0;
  border-radius: 4px;
  min-height: 80px;
  padding: 15px;
  width: 100%;
  resize: none;
`;

export const TextAreaContainer = styled.div`
  width: 100%;
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
`;

export const LengthText = styled.div`
  font-size: 80%;
  text-align: right;
  color: red;
  width: 100%;
  margin-top: 7px;
`

export const TextAreaElement = styled.textarea`
  ${BASE_STYLES}
`;
