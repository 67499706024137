import { toast } from 'react-toastify';
import { takeLatest, call, put, all } from 'redux-saga/effects';

import { getSupportedCoinsValue, sendMessageRequest } from './api';

import {
  appActionTypes,
  setSupportedCoins,
  pushLoadingAppDataRequest,
  spliceLoadingAppDataRequest,
  ISendMessageAction,
  stopLoadingMessage,
} from './actions';
import { ISupportedCoins } from '../interfaces';

function* getSupportedCoins() {
  const requestName = 'getSupportedCoins';
  try {
    yield put(pushLoadingAppDataRequest(requestName));
    const supportedCoins: ISupportedCoins[] = yield call(getSupportedCoinsValue);

    yield put(setSupportedCoins(supportedCoins));
  } catch (err) {
    console.error('Something went wrong in getSupportedCoins saga.');
  } finally {
    yield put(spliceLoadingAppDataRequest(requestName));
  }
}

function* sendMessage({ payload }: ISendMessageAction) {
  const { message, feedbackMessage, isPublicMessage, captchaToken, resetCaptcha, executeAfterSend } = payload;
  try {
    yield call(sendMessageRequest, { message, isPublicMessage, captchaToken });
    toast.success(feedbackMessage, { autoClose: 7000 });
  } catch (err) {
    toast.error(`Something went wrong when sending your message.`);
    console.error('Something went wrong in sendMessage saga.');
  } finally {
    yield put(stopLoadingMessage());
    resetCaptcha();
    if (executeAfterSend) {
      executeAfterSend();
    }
  }
}

export default all([
  takeLatest(appActionTypes.GET_SUPPORTED_COINS, getSupportedCoins),
  takeLatest(appActionTypes.SEND_MESSAGE, sendMessage),
]);
