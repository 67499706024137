import styled from 'styled-components/macro';
import { Form } from '@rocketseat/unform';
import { DISPLAY_FLEX_COLUMN_DEFAULT, HIGLAN_RED_01, HIGLAN_WHITE_01 } from '~/style/constants';

export const StyledUnformForm = styled(Form)`
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  margin-top: 10px;
  gap: 10px;
  span {
    width: 100%;
    color: ${HIGLAN_WHITE_01};
    background-color: ${HIGLAN_RED_01};
    border-radius: 4px;

    font-weight: bold;
    padding: 7px;
  }
`;
