import HCaptcha from '@hcaptcha/react-hcaptcha';
import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import api from 'services/api';

import {
  LinkText,
  LinkButton,
  ResetLoginPasswordText,
  EmailConfirmationContainer,
  EmailConfirmationText,
  BolderText,
  InstructionDescription,
} from './styles';
import HCaptchaConfig from '~/config/HCaptcha';
import { HiglanLogo } from '../SignIn/shared/HiglanLogo';
import { GeneralContainer } from '../SignIn/shared/GeneralContainer';
import { UnformForm } from '../SignIn/shared/UnformForm';
import { UnformInput } from '../SignIn/shared/UnformInput';
import { SubmitButton } from '../SignIn/shared/SubmitButton';

const schema: any = Yup.object().shape({
  email: Yup.string().email('Account email is not valid').required('Account email is required'),
});

interface IHandleSubmit {
  email: string;
}

export default function ResetPassword() {
  const [userEmail, setUserEmail] = useState('');
  const [dataSent, setDataSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [captchaToken, setCaptchaToken] = useState<string>('');
  const captchaRef = useRef(null);

  const resetCaptcha = () => {
    // @ts-ignore
    captchaRef.current?.resetCaptcha();
    setCaptchaToken('');
  };

  const onHCaptchaVerify = (token: string) => {
    setCaptchaToken(token);
  };

  const handleSubmit = async ({ email }: IHandleSubmit) => {
    if (!captchaToken) {
      toast.error(`Please resolve the captcha.`, {
        pauseOnFocusLoss: false,
      });
      return;
    }
    setUserEmail(email);
    setIsLoading(true);

    try {
      await api.post('reset-password', { email, captchaToken });
      setDataSent(true);
      setIsLoading(false);
    } catch (err) {
      const error: any = { err };
      const errorMessage = error.err.response.data.error;
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
      resetCaptcha();
    }
  };

  return (
    <GeneralContainer>
      <HiglanLogo />
      {!dataSent ? (
        <>
          {/** @ts-ignore */}
          <UnformForm schema={schema} onSubmit={handleSubmit}>
            <ResetLoginPasswordText>Reset Login Password</ResetLoginPasswordText>
            <UnformInput name="email" type="email" placeholder="Account email" />
            <HCaptcha size="normal" sitekey={HCaptchaConfig.siteKey} onVerify={onHCaptchaVerify} ref={captchaRef} />
            <SubmitButton isLoading={isLoading} type="submit" disabled={isLoading}>
              Submit
            </SubmitButton>
            <LinkText to="/login">I know my login password</LinkText>
          </UnformForm>
        </>
      ) : (
        <EmailConfirmationContainer>
          <EmailConfirmationText>Email confirmation required</EmailConfirmationText>
          <BolderText>{userEmail}</BolderText>
          <InstructionDescription>
            If this account exists, an email will be sent detailing the next steps for reset your password.
          </InstructionDescription>
          <InstructionDescription>Please follow the instructions in the email.</InstructionDescription>
          <LinkButton to="/login">Back to login page</LinkButton>
        </EmailConfirmationContainer>
      )}
    </GeneralContainer>
  );
}
