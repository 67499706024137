import styled from 'styled-components/macro';
import { DISPLAY_FLEX_COLUMN_DEFAULT, DISPLAY_FLEX_LINE_DEFAULT, HIGLAN_WHITE_01 } from '~/style/constants';
import { mobileOnly } from '~/style/mixins';
import { Button } from '~/components/ui/Button';

export const HomeContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  gap: 30px;
  padding: 10px;
`;

export const FeaturesContainer = styled.div`
  ${DISPLAY_FLEX_LINE_DEFAULT}
  color: ${HIGLAN_WHITE_01};
  font-size: 16px;
  gap: 7px;

  ${mobileOnly`
    ${DISPLAY_FLEX_COLUMN_DEFAULT}
    align-items: flex-start;
  `}
`;

export const FeatureItem = styled.div`
  ${DISPLAY_FLEX_LINE_DEFAULT}
  text-align: left;
  gap: 5px;
`;

export const SupportedCoinsContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  gap: 20px;
  width: 100%;
`;

export const Separator = styled.div`
  border-bottom: 1px solid ${HIGLAN_WHITE_01};
  opacity: 0.5;
  width: 100%;
`;

export const SupportedCoinsText = styled.div`
  font-size: 18px;
  color: ${HIGLAN_WHITE_01};
`;

export const SuggestCoin = styled.small`
  color: ${HIGLAN_WHITE_01};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

export const CoinContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  align-items: flex-start;
  justify-content: space-around;
`;

export const HiglanDescription = styled.div`
  font-size: 22px;
  color: ${HIGLAN_WHITE_01};
`;

export const StyledButton = styled(Button)`
  max-width: 350px;
  font-weight: bold;
`;

export const ItemsContainer = styled.div`
  ${DISPLAY_FLEX_LINE_DEFAULT}
  div:last-child {
    border-right: 0;
  }
`;

export const ItemIconContainer = styled.div`
  ${DISPLAY_FLEX_LINE_DEFAULT}
  gap: 5px;
  color: ${HIGLAN_WHITE_01};
  font-size: 17px;
  cursor: pointer;
  margin-left: 10px;
  padding-right: 10px;
  border-right: 1px solid ${HIGLAN_WHITE_01};

  &:hover {
    text-decoration: underline;
  }
`;
