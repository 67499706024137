import { Container, StyledHelp } from './styles';
const STARTED_YEAR = 2020

export default function Footer() {
  const YEAR = new Date().getFullYear();
  return (
    <Container>
      <p>© {STARTED_YEAR} - {YEAR}, Higlan.com - All Rights Reserved.</p>
      <StyledHelp />
    </Container>
  );
}
