import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

import logo from 'assets/logo-purple.svg';
import { Container, Content, Profile, StyledBootstrapDropdown, StyledNavLink } from './styles';
import { getUserProfile } from 'store/modules/user/selectors';
import { displayPriceWithCurrency } from 'shared/string-utils';
import { Tooltip } from '../ui/Tooltip';

export default function Header() {
  const [mobileMenu, setMobileMenu] = useState(false);
  const [scrollPosition, setScrollposition] = useState(0);
  const style = { fontWeight: 'bold', color: '#3b9eff' };
  const styleMobile = {
    fontWeight: 'bold',
    color: '#ecedef',
    textDecoration: 'underline',
  };
  const userProfile = useSelector(getUserProfile);
  const { balances, admin } = userProfile;
  const { balance, bonus } = balances;
  const shouldDisplayBonus = bonus > 0;

  useEffect(() => {
    function handleScroll() {
      const { scrollY } = window;
      // console.log(scrollY)
      setScrollposition(scrollY);
    }

    window.addEventListener('scroll', handleScroll);
  }, [scrollPosition]);

  return (
    <Container scroll={scrollPosition}>
      <Content mobileMenu={mobileMenu}>
        <div>
          <nav>
            <img src={logo} alt="Higlan" />
            {admin && (
              <StyledBootstrapDropdown title="ADMIN" className="menu-item">
                <NavLink activeStyle={style} to="/admin" className="admin">
                  ADMIN_v1
                </NavLink>
                <NavLink activeStyle={style} to="/admin_v2" className="admin">
                  ADMIN_v2
                </NavLink>
              </StyledBootstrapDropdown>
            )}
            <NavLink activeStyle={style} to="/dashboard" className="menu-item">
              DASHBOARD
            </NavLink>
            <NavLink activeStyle={style} to="/addfunds" className="menu-item">
              ADD FUNDS
            </NavLink>
            <NavLink activeStyle={style} to="/nodes" className="menu-item">
              NODES
            </NavLink>
            <StyledNavLink activeStyle={style} to="/faucet" className="menu-item">
              FAUCET
            </StyledNavLink>
            <NavLink activeStyle={style} to="/contact" className="menu-item">
              CONTACT
            </NavLink>
            <NavLink activeStyle={style} to="/history" className="menu-item">
              HISTORY
            </NavLink>
            <NavLink activeStyle={style} to="/tools" className="menu-item">
              TOOLS
            </NavLink>
          </nav>
        </div>

        <aside>
          <Profile>
            <div>
              <div>
                <Tooltip placement="bottom" content={`Balance: ${displayPriceWithCurrency(balance, 4)}`}>
                  <div className="balance">
                    <strong>{displayPriceWithCurrency(balance)}</strong>
                    <small>Balance</small>
                  </div>
                </Tooltip>

                {shouldDisplayBonus && (
                  <Tooltip placement="bottom" content={`Bonus: ${displayPriceWithCurrency(bonus, 4)}`}>
                    <div className="bonus">
                      <strong>{displayPriceWithCurrency(bonus)}</strong>
                      <small>Bonus</small>
                    </div>
                  </Tooltip>
                )}
              </div>

              <NavLink activeStyle={style} to="/profile">
                PROFILE
              </NavLink>
            </div>
          </Profile>

          <div className="menu-toogle" onClick={() => setMobileMenu(!mobileMenu)}>
            <div className="one" />
            <div className="two" />
            <div className="three" />

            <div className="menu-mobile">
              <div className="triangle">
                <div className="arrow-up" />
              </div>
              {admin && (
                <NavLink activeStyle={styleMobile} to="/admin" onClick={() => setMobileMenu(!mobileMenu)}>
                  ADMIN_v1
                </NavLink>
              )}
              {admin && (
                <NavLink activeStyle={styleMobile} to="/admin_v2" onClick={() => setMobileMenu(!mobileMenu)}>
                  ADMIN_v2
                </NavLink>
              )}
              <NavLink activeStyle={styleMobile} to="/dashboard" onClick={() => setMobileMenu(!mobileMenu)}>
                DASHBOARD
              </NavLink>
              <NavLink activeStyle={styleMobile} to="/addfunds" onClick={() => setMobileMenu(!mobileMenu)}>
                ADD FUNDS
              </NavLink>
              <NavLink activeStyle={styleMobile} to="/nodes" onClick={() => setMobileMenu(!mobileMenu)}>
                NODES
              </NavLink>
              <NavLink activeStyle={styleMobile} to="/faucet" onClick={() => setMobileMenu(!mobileMenu)}>
                FAUCET
              </NavLink>
              <NavLink activeStyle={styleMobile} to="/contact" onClick={() => setMobileMenu(!mobileMenu)}>
                CONTACT
              </NavLink>
              <NavLink activeStyle={styleMobile} to="/tools" onClick={() => setMobileMenu(!mobileMenu)}>
                TOOLS
              </NavLink>
              <NavLink activeStyle={styleMobile} to="/history" onClick={() => setMobileMenu(!mobileMenu)}>
                HISTORY
              </NavLink>
            </div>
          </div>
        </aside>
      </Content>
    </Container>
  );
}
