import styled, { css } from 'styled-components/macro';
import { DISPLAY_FLEX_COLUMN_DEFAULT } from '~/style/constants';
import { ItemsContainer } from '../styles';

const GAP_FOR_CONTAINERS = css`
  gap: 15px;
`;

export const ChargeDateContainer = styled.div`
  text-align: center;
  ${DISPLAY_FLEX_COLUMN_DEFAULT};
  font-size: 16px;
  ${GAP_FOR_CONTAINERS}
`;

export const StyledItemsContainer = styled(ItemsContainer)`
  ${GAP_FOR_CONTAINERS}
`;
