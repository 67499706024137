import styled from 'styled-components/macro';
import { BootstrapButton } from '~/components/ui/BootstrapButton';
import { TextRender } from '~/components/ui/TextRender';
import { DISPLAY_FLEX_COLUMN_DEFAULT, DISPLAY_FLEX_LINE_DEFAULT } from '~/style/constants';

export const NodesProblemModalContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  font-size: small;
  text-align: center;
  gap: 10px;
`;

export const TotalText = styled.div`
  font-weight: bold;
  font-size: 17px;
`;

export const ToggleMailNotificationButton = styled(BootstrapButton)``;

export const ExplorerLink = styled.a``;

export const ServerItemsContainer = styled(TextRender)`
  ${DISPLAY_FLEX_LINE_DEFAULT}
  gap: 5px;
  flex-wrap: wrap;
`;

export const ServerItem = styled.div`
  padding: 10px;
  font-size: 14px;
  border: 1px solid #dee2e6;
`;
