import { NormalText } from '../styles';
import { ManageNodesText, RestoreNodesContainer } from './styles';
import { LuArchiveRestore } from 'react-icons/lu';

interface IRestoreNodes {
  totalNodesThatCanBeRestored: number;
  toggleRestoreNodesModal: () => void;
}

export function RestoreNodesAlert({ totalNodesThatCanBeRestored, toggleRestoreNodesModal }: IRestoreNodes) {
  return (
    <RestoreNodesContainer>
      <LuArchiveRestore size={40} />
      <NormalText
        dangerouslySetInnerHTML={{
          __html: `You have <strong>${totalNodesThatCanBeRestored} nodes</strong> that can be restored.`,
        }}
      />
      <ManageNodesText onClick={toggleRestoreNodesModal}>MANAGE</ManageNodesText>
    </RestoreNodesContainer>
  );
}
