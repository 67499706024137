import styled from 'styled-components/macro';
import {
  DISPLAY_FLEX_COLUMN_DEFAULT,
  DISPLAY_FLEX_LINE_DEFAULT,
  HIGLAN_GREEN_01,
  HIGLAN_RED_01,
} from '~/style/constants';
import { mobileOnly } from '~/style/mixins';

export const NodeOutputsContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT};
  gap: 15px;
`;

export const Description = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT};
  gap: 10px;
`;

export const BolderText = styled.strong``;

export const InputsForm = styled.form`
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  gap: 7px;
  width: 100%;
`;

export const AllInputsContainer = styled.div`
  ${DISPLAY_FLEX_LINE_DEFAULT}
  width: 100%;
  gap: 7px;
  ${mobileOnly`
    ${DISPLAY_FLEX_COLUMN_DEFAULT}
  `}
`;

export const InputContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  width: 100%;
`;

export const TextAreaElement = styled.textarea`
  width: 100%;
  padding: 7px;
`;

export const ButtonsContainer = styled.div`
  ${DISPLAY_FLEX_LINE_DEFAULT}
  gap: 7px;
`;

export const NewTxidsContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT};
  margin-top: 20px;
  width: 100%;
`;

export const NewTxidItem = styled.div`
  background-color: rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 5px;
  word-wrap: break-word;
  width: 100%;
`;

export const StatusErrorContainer = styled.div`
  ${DISPLAY_FLEX_LINE_DEFAULT};
  gap: 5px;
  color: ${HIGLAN_RED_01};
`;

export const StatusValidContainer = styled.div`
  ${DISPLAY_FLEX_LINE_DEFAULT};
  gap: 5px;
  color: ${HIGLAN_GREEN_01};
`;
