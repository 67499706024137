import { useSelector } from 'react-redux';
import uuid from 'react-uuid';

import history from 'services/history';
import {
  CoinContainer,
  ItemIconContainer,
  FeatureItem,
  FeaturesContainer,
  HiglanDescription,
  HomeContainer,
  StyledButton,
  SupportedCoinsContainer,
  SupportedCoinsText,
  ItemsContainer,
  Separator,
  SuggestCoin,
} from './styles';
import { NodeCard } from '~/components/ui/NodeCard';
import { HiglanLogo } from '../SignIn/shared/HiglanLogo';
import { AiOutlineCheckSquare, AiOutlineForm } from 'react-icons/ai';
import { ReactNode } from 'react';
import { MdOutlineQuestionAnswer } from 'react-icons/md';
import { HiOutlineClipboardDocumentCheck } from 'react-icons/hi2';
import { ISupportedCoinsNames } from '~/store/modules/interfaces';
import { getSupportedCoins } from '~/store/modules/app/selectors';

const FEATURES = ['Easy Single Node Setup', 'Easy Multi Node Setup', 'Faucet Earnings', 'Crypto and Paypal Payments'];

const redirectToLogin = () => {
  history.push('/login');
};

const redirectToCoinPage = (coin: ISupportedCoinsNames) => {
  history.push(`nodes/${coin}`);
};

const redirectToFAQ = () => {
  history.push('/faq');
};

const redirectToTerms = () => {
  history.push('/terms');
};

const redirectToPublicContact = () => {
  history.push('/public-contact');
};

const redirectToSuggestCoin = () => {
  history.push('/suggest-coin');
};

interface IMainContent {
  children: ReactNode;
}
export function MainContent({ children }: IMainContent) {
  return (
    <HomeContainer>
      <HiglanLogo />
      <HiglanDescription>The easiest and most profitable platform to make your nodes work for you!</HiglanDescription>
      <FeaturesContainer>
        {FEATURES.map((feature) => (
          <FeatureItem key={uuid()}>
            <AiOutlineCheckSquare size={25} />
            {feature}
          </FeatureItem>
        ))}
      </FeaturesContainer>
      <StyledButton onClick={redirectToLogin}>START NOW</StyledButton>
      {children}
    </HomeContainer>
  );
}
export function HomeStatic() {
  const supportedCoins = useSelector(getSupportedCoins);
  const coinBdx = supportedCoins.find((coinItem) => coinItem.coin === 'bdx');
  const coinPrcy = supportedCoins.find((coinItem) => coinItem.coin === 'prcy');
  const coinBlkc = supportedCoins.find((coinItem) => coinItem.coin === 'blkc');
  return (
    <MainContent>
      <SupportedCoinsContainer>
        <SupportedCoinsText>Supported Coins</SupportedCoinsText>
        <SuggestCoin onClick={redirectToSuggestCoin}>Would you like to suggest a coin?</SuggestCoin>
        <CoinContainer>
          <NodeCard supportedCoin={coinBdx!} onClick={() => redirectToCoinPage('bdx')} shouldUseWhiteHover />
          <NodeCard supportedCoin={coinPrcy!} onClick={() => redirectToCoinPage('prcy')} shouldUseWhiteHover />
          <NodeCard supportedCoin={coinBlkc!} onClick={() => redirectToCoinPage('blkc')} shouldUseWhiteHover />
        </CoinContainer>
      </SupportedCoinsContainer>
      <Separator />
      <ItemsContainer>
        <ItemIconContainer onClick={redirectToFAQ}>
          <MdOutlineQuestionAnswer />
          FAQ
        </ItemIconContainer>
        <ItemIconContainer onClick={redirectToTerms}>
          <HiOutlineClipboardDocumentCheck />
          Terms
        </ItemIconContainer>
        <ItemIconContainer onClick={redirectToPublicContact}>
          <AiOutlineForm />
          Contact
        </ItemIconContainer>
      </ItemsContainer>
    </MainContent>
  );
}
