import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { Container } from '../_layouts/default/styles';
import {
  AdSenseContainer,
  BonusText,
  CaptchaContainer,
  CaptchaText,
  CountdownContainer,
  Earned,
  FaucetContainer,
  FaucetDescription,
  FaucetTitle,
  FreeBonusContainer,
  FreeBonusHeader,
  FreeBonusRate,
  TimeLeftText,
} from './styles';
import HCaptchaConfig from '~/config/HCaptcha';
import { BootstrapButton } from '~/components/ui/BootstrapButton';
import { toast } from 'react-toastify';
import { displayPriceWithCurrency } from '~/shared/string-utils';
import { Tooltip } from '~/components/ui/Tooltip';
import { FaFaucet, FaRegQuestionCircle } from 'react-icons/fa';
import { GiReceiveMoney } from 'react-icons/gi';
import { MdOutlineHistory } from 'react-icons/md';
import { Countdown } from '~/components/Countdown';
import { HIGLAN_BLUE_01 } from '~/style/constants';
import { sendFaucetCaptcha } from '~/store/modules/faucet/actions';
import {
  getFaucetBaseValue,
  getTimeForNextRound,
  isLoadingApi,
  getFaucetBalance,
  getFaucetHistory,
  getMinValueForWithdraw,
} from '~/store/modules/faucet/selectors';
import { HistoryModal } from './HistoryModal';
import { WithdrawModal } from './WithdrawModal';

export function Faucet() {
  const [captchaToken, setCaptchaToken] = useState<string>('');
  const [shouldShowHistoryModal, setShouldShowHistoryModal] = useState(false);
  const [shouldShowWithdrawModal, setShouldShowWithdrawModal] = useState(false);
  const captchaRef = useRef(null);
  const isLoading = useSelector(isLoadingApi);
  const timeForNextRound = useSelector(getTimeForNextRound);
  const faucetBaseValue = useSelector(getFaucetBaseValue);
  const faucetBalance = useSelector(getFaucetBalance);
  const faucetHistory = useSelector(getFaucetHistory);
  const minValueForWithdraw = useSelector(getMinValueForWithdraw);
  const dispatch = useDispatch();

  const onHCaptchaVerify = (token: string) => {
    setCaptchaToken(token);
  };

  const resetCaptcha = () => {
    // @ts-ignore
    captchaRef.current?.resetCaptcha();
    setCaptchaToken('');
  };

  const handleSubmit = async () => {
    if (!captchaToken) {
      toast.error(`Please resolve the captcha.`, {
        pauseOnFocusLoss: false,
      });
      return;
    }
    dispatch(sendFaucetCaptcha(captchaToken));
    try {
    } catch (err) {
      const error: any = { err };
      const errorMessage = error.err.response.data.error;
      toast.error(errorMessage);
    }
  };

  const handleToggleHistoryModal = () => {
    setShouldShowHistoryModal(!shouldShowHistoryModal);
  };

  const handleToggleWithdrawModal = () => {
    setShouldShowWithdrawModal(!shouldShowWithdrawModal);
  };

  useEffect(() => {
    if (!isLoading) {
      resetCaptcha();
    }
  }, [isLoading]);

  return (
    <Container>
      <FaucetContainer>
        <FaucetTitle>
          Higlan Faucet <FaFaucet />
        </FaucetTitle>
        <FaucetDescription>
          Earn Free <BonusText>Bonus Credit</BonusText>
        </FaucetDescription>
        <AdSenseContainer>
          <FreeBonusContainer>
            <FreeBonusHeader>
              <Earned>
                Earned: {displayPriceWithCurrency(faucetBalance, 4)}
                {faucetBalance < minValueForWithdraw && (
                  <Tooltip
                    content={`Withdraw is available when you reach at least ${displayPriceWithCurrency(
                      minValueForWithdraw
                    )}`}
                  >
                    <FaRegQuestionCircle />
                  </Tooltip>
                )}
              </Earned>
              {faucetBalance >= minValueForWithdraw && (
                <BootstrapButton size="sm" variant="success" onClick={handleToggleWithdrawModal}>
                  WITHDRAW
                </BootstrapButton>
              )}
              <BootstrapButton variant="info" color="white" onClick={handleToggleHistoryModal}>
                HISTORY
                <MdOutlineHistory />
              </BootstrapButton>
            </FreeBonusHeader>
            <FreeBonusRate>
              <BonusText>Free Bonus</BonusText> you can get in next round:{' '}
              <BonusText>{displayPriceWithCurrency(faucetBaseValue, 4)}</BonusText>
            </FreeBonusRate>
            {timeForNextRound <= 0 ? (
              <CaptchaContainer>
                <CaptchaText>
                  Solve the captcha bellow to claim your free <BonusText>Bonus Credit</BonusText>
                </CaptchaText>
                <HCaptcha ref={captchaRef} size="normal" sitekey={HCaptchaConfig.siteKey} onVerify={onHCaptchaVerify} />
                <BootstrapButton variant="success" onClick={handleSubmit} disabled={isLoading}>
                  CLAIM
                  <GiReceiveMoney />
                </BootstrapButton>
              </CaptchaContainer>
            ) : (
              <CountdownContainer>
                <TimeLeftText>Time to wait for the next round:</TimeLeftText>
                <Countdown timestamp={timeForNextRound} color={HIGLAN_BLUE_01} />
              </CountdownContainer>
            )}
          </FreeBonusContainer>
        </AdSenseContainer>
      </FaucetContainer>
      {shouldShowHistoryModal && <HistoryModal onHideModal={handleToggleHistoryModal} faucetHistory={faucetHistory} />}
      {shouldShowWithdrawModal && (
        <WithdrawModal onHideModal={handleToggleWithdrawModal} faucetBalance={faucetBalance} />
      )}
    </Container>
  );
}
