import { Wrapper, Content } from './styles';
import Footer from '~/components/Footer';
import { useExistScrollBar } from '~/hooks/use-exist-scroll-bar';

export default function AuthLayout({ children }: any) {
  const { isThereYScrollBar } = useExistScrollBar();

  return (
    <Wrapper hasYScroll={isThereYScrollBar}>
      <Content>{children}</Content>
      <Footer />
    </Wrapper>
  );
}
