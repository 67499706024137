import { ISupportedCoins } from '../interfaces';

const prefix = '@app';

interface IAppActionTypes {
  GET_SUPPORTED_COINS: string;
  SET_SUPPORTED_COINS: string;
  PUSH_LOADING_APP_DATA_REQUEST: string;
  SPLICE_LOADING_APP_DATA_REQUEST: string;
  STOP_LOADING_APP_DATA: string;
  START_LOADING_APP_DATA: string;
  RESET_REQUESTS: string;
  SEND_MESSAGE: string;
  STOP_LOADING_MESSAGE: string;
}

export const appActionTypes: IAppActionTypes = {
  PUSH_LOADING_APP_DATA_REQUEST: `${prefix}/PUSH_LOADING_APP_DATA_REQUEST`,
  SPLICE_LOADING_APP_DATA_REQUEST: `${prefix}/SPLICE_LOADING_APP_DATA_REQUEST`,
  STOP_LOADING_APP_DATA: `${prefix}/STOP_LOADING_APP_DATA`,
  START_LOADING_APP_DATA: `${prefix}/START_LOADING_APP_DATA`,
  GET_SUPPORTED_COINS: `${prefix}/GET_SUPPORTED_COINS`,
  SET_SUPPORTED_COINS: `${prefix}/SET_SUPPORTED_COINS`,
  RESET_REQUESTS: `${prefix}/RESET_REQUESTS`,
  SEND_MESSAGE: `${prefix}/SEND_MESSAGE`,
  STOP_LOADING_MESSAGE: `${prefix}/STOP_LOADING_MESSAGE`,
};

export type IAppAction = IGetSupportedCoinsAction;

export interface ISendMessagePayload {
  message: string;
  captchaToken: string;
  resetCaptcha: () => void;
  executeAfterSend?: () => void;
  isPublicMessage: boolean;
  feedbackMessage: string;
}

interface IGetSupportedCoinsAction {
  payload: ISupportedCoins[];
  type: typeof appActionTypes.GET_SUPPORTED_COINS;
}

export interface ISendMessageAction {
  payload: ISendMessagePayload;
  type: typeof appActionTypes.SEND_MESSAGE;
}

export const getSupportedCoins = () => ({
  type: appActionTypes.GET_SUPPORTED_COINS,
});

export const pushLoadingAppDataRequest = (requestName: string) => ({
  payload: requestName,
  type: appActionTypes.PUSH_LOADING_APP_DATA_REQUEST,
});

export const spliceLoadingAppDataRequest = (requestName: string) => ({
  payload: requestName,
  type: appActionTypes.SPLICE_LOADING_APP_DATA_REQUEST,
});

export const stopLoadingAppData = () => ({
  type: appActionTypes.STOP_LOADING_APP_DATA,
});

export const startLoadingAppData = () => ({
  type: appActionTypes.START_LOADING_APP_DATA,
});

export const resetAppDataRequests = () => ({
  type: appActionTypes.RESET_REQUESTS,
});

export const setSupportedCoins = (payload: ISupportedCoins[]) => ({
  type: appActionTypes.SET_SUPPORTED_COINS,
  payload,
});

export const sendMessage = (payload: ISendMessagePayload) => ({
  type: appActionTypes.SEND_MESSAGE,
  payload,
});

export const stopLoadingMessage = () => ({
  type: appActionTypes.STOP_LOADING_MESSAGE,
});
