import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { TextInput } from '~/components/ui/TextInput';
import { DISPLAY_FLEX_LINE_DEFAULT, HIGLAN_WHITE_01 } from '~/style/constants';

export const LinkContainer = styled.div`
  ${DISPLAY_FLEX_LINE_DEFAULT}
  justify-content: space-between;
  width: 100%;
  font-size: 16px;
`;

export const LinkText = styled(Link)`
  color: #fff;

  &:hover {
    color: #fff;
  }
`;

export const StyledTextInput = styled(TextInput)`
  color: ${HIGLAN_WHITE_01};

  &::placeholder {
    color: rgba(255, 255, 255, 0.7);
  }
`;
