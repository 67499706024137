import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { IoIosCopy } from 'react-icons/io';
import { AiOutlineDashboard } from 'react-icons/ai';
import { FaArrowLeft } from 'react-icons/fa';
import { FiAlertTriangle } from 'react-icons/fi';
import { Container } from 'pages/_layouts/default/styles';
import DataTable, { DATA_TABLE_TYPE, INodeToChangeChargeDate, INodeToEdit } from './DataTable';
import NodeStatus from '../../components/NodeStatus';
import {
  Content,
  HeaderImageContainer,
  BackButton,
  ChargesContainer,
  ChargeItem,
  BolderText,
  NormalText,
  NodesNotEnabledContainer,
  NodesNotEnabledInfo,
  NodesNotEnabledItemsContainer,
  NodeNotEnabled,
  ButtonContainer,
  NoNodesText,
  StringToWalletContainer,
  StringToWalletItem,
} from './styles';
import { copyTextToClipboard, displayPriceWithCurrency } from 'shared/string-utils';
import { SUPORTED_COINS } from 'shared/constants';
import { getNodesThatCanBeRestored, getUserNodeData, getUserProfile } from 'store/modules/user/selectors';

import { CoinLogo } from 'components/CoinLogo';
import api from 'services/api';
import { BootstrapButton } from '~/components/ui/BootstrapButton';
import { ISupportedCoinsNames } from '~/store/modules/interfaces';
import { NodeModal } from './NodeModal';
import { OverdueAlert } from './OverdueAlert';
import { ChargeDateModal } from './ChargeDateModal';
import { RestoreNodesAlert } from './RestoreNodesAlert';
import { RestoreNodesModal } from './RestoreNodesModal';

const SELECTED_DETAILS_INITIAL_VALUES = {
  renderFor: 'dashboard',
};

const DELETION_CODE_ERROR_REGEX = /confirm the code/gim;

export type EditType = 'pause' | 'delete' | 'getActivationCommand';

export function Dashboard() {
  const nodeData = useSelector(getUserNodeData);
  const [showMnData, setShowMnData] = useState(true);
  const [mnDataToWallet, setMnDataToWallet] = useState('');
  const [largerContainer, setLargerContainer] = useState(false);
  const [currentCoinDataIndex, setCurrentCoinDataIndex] = useState<number | undefined>();
  const [editedNode, setEditedNode] = useState<INodeToEdit | undefined>();
  const [nodeToChangeChargeDate, setNodeToChangeChargeDate] = useState<INodeToChangeChargeDate | undefined>();
  const [selectedDetails, setSelectedDetails] = useState<any>({
    ...SELECTED_DETAILS_INITIAL_VALUES,
    ...nodeData.generalData,
  });
  const [dataTableType, setDataTableType] = useState<any>(DATA_TABLE_TYPE.coin_list);
  const [isEditingNode, setIsEditingNode] = useState(false);
  const [shouldShowDeletionCode, setShouldShowDeletionCode] = useState(false);
  const [deletionCode, setDeletionCode] = useState('');
  const [shouldShowRestoreNodesModal, setShouldShowRestoreNodesModal] = useState(false);
  const { balanceNeeded, totalOverdueCharges } = nodeData.generalData.overdueCharges;
  const userProfile = useSelector(getUserProfile);
  const { allNodesThatCanBeRestored, hasNodesThatCanBeRestored } = useSelector(getNodesThatCanBeRestored);
  const { balances } = userProfile;
  const { balance } = balances;

  const handleCopyClick = (text: string) => {
    copyTextToClipboard(text);
  };

  const handleBack = () => {
    const { charges, status, overdueCharges } = nodeData.generalData;
    setDataTableType(DATA_TABLE_TYPE.coin_list);
    setSelectedDetails({
      ...SELECTED_DETAILS_INITIAL_VALUES,
      charges,
      status,
      overdueCharges,
    });
    setCurrentCoinDataIndex(undefined);
    setShowMnData(true);
    setLargerContainer(false);
  };

  const handleNodeToChangeChargeDate = (node: INodeToChangeChargeDate) => {
    setNodeToChangeChargeDate(node);
  };

  const hideChargeDateModal = () => {
    setNodeToChangeChargeDate(undefined);
  };

  const renderHeaderImageElements = () => {
    const coinName = selectedDetails.renderFor;

    if (coinName === SELECTED_DETAILS_INITIAL_VALUES.renderFor) {
      return (
        <>
          <AiOutlineDashboard size={40} />
          DASHBOARD
        </>
      );
    }

    return (
      <>
        <CoinLogo coin={coinName} />
        {SUPORTED_COINS[coinName].name}
      </>
    );
  };

  const isDashboardView = () => selectedDetails.renderFor === SELECTED_DETAILS_INITIAL_VALUES.renderFor;

  const shouldShowOrangeOverdueAlert = balance < nodeData.generalData.charges.willPay;
  const shouldShowGeneralOverdueAlert = balance < balanceNeeded;
  const shouldShowCoinOverdueAlert = balance < selectedDetails.overdueCharges.balanceNeeded;

  const handleSelectDetails = (index: number) => {
    if (!nodeData.coinData[index]) {
      setSelectedDetails({
        ...SELECTED_DETAILS_INITIAL_VALUES,
        ...nodeData.generalData,
      });
      return;
    }
    const { coin, charges, status, nodes, nodesNotEnabled, latestBlock, overdueCharges } = nodeData.coinData[index];
    setSelectedDetails({
      renderFor: coin,
      charges,
      status,
      nodes,
      nodesNotEnabled,
      overdueCharges,
      latestBlock,
    });
    setCurrentCoinDataIndex(index);
    setDataTableType(DATA_TABLE_TYPE.node_details);
    handleStringToWallet(nodes, coin);
  };

  const handleStringToWallet = (nodes: any, currentSelectedCoin: ISupportedCoinsNames) => {
    let stringMnDataToWallet = '';
    const { hasDataToMyWallet } = SUPORTED_COINS[currentSelectedCoin].features;

    for (const node of nodes) {
      const { ip, coin, alias, private_key, txid, outputid } = node;
      const port = SUPORTED_COINS[coin].port;
      if (ip) {
        stringMnDataToWallet += `node${alias} ${ip}:${port} ${private_key} ${
          txid ? `${txid} ${outputid}` : '<TxId> <OutputID>'
        }\n`;
      }
    }
    localStorage.setItem('higlanMasternodes', hasDataToMyWallet ? stringMnDataToWallet : '');
    setMnDataToWallet(stringMnDataToWallet);
  };

  const handleDataToWalletClick = () => {
    setLargerContainer(!largerContainer);
    setShowMnData(!showMnData);
  };

  const handleHideModal = () => {
    setEditedNode(undefined);
    setDeletionCode('');
  };

  const onChangeDeletionCode = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    setDeletionCode(value);
  };

  const handleEditNode = async () => {
    if (editedNode) {
      const { coin, alias, id, editType } = editedNode;
      setIsEditingNode(true);

      try {
        if (editType === 'delete') {
          if (shouldShowDeletionCode && !deletionCode) {
            toast.error(`Please insert the deletion code.`);
            return;
          }
          await api.delete(`masternodes/${id}`, {
            params: {
              ...(deletionCode ? { deletion_code: deletionCode } : {}),
            },
          });

          toast.success(`Your ${coin} node${alias} has been deleted!`, {
            autoClose: 6000,
          });
          setShouldShowDeletionCode(false);
        } else if (editType === 'pause') {
          await api.put(`masternodes/${id}/pause`);

          toast.success(`Your ${coin} node${alias} has been paused!`, {
            autoClose: 6000,
          });
        }

        handleHideModal();
      } catch (e) {
        const err: any = e;
        const { data } = err.response;

        const { error } = data;

        if (error.match(DELETION_CODE_ERROR_REGEX)) {
          setShouldShowDeletionCode(true);
          toast.warning(error, {
            autoClose: 7000,
          });
          return;
        }

        toast.error(error, {
          autoClose: 7000,
        });
      } finally {
        setIsEditingNode(false);
      }
    }
  };

  const shouldRenderDataToMyWallet = () => {
    const coin = nodeData.coinData[currentCoinDataIndex!]?.coin;
    if (!coin) {
      return false;
    }
    const { hasDataToMyWallet } = SUPORTED_COINS[coin].features;

    return hasDataToMyWallet;
  };

  const renderOverdueAlert = () => {
    if (isDashboardView() && shouldShowGeneralOverdueAlert) {
      return <OverdueAlert balance={balance} totalOverdueCharges={totalOverdueCharges} balanceNeeded={balanceNeeded} />;
    }

    if (!isDashboardView() && shouldShowCoinOverdueAlert) {
      return (
        <OverdueAlert
          balance={balance}
          totalOverdueCharges={selectedDetails.overdueCharges.totalOverdueCharges}
          balanceNeeded={selectedDetails.overdueCharges.balanceNeeded}
        />
      );
    }

    if (isDashboardView() && shouldShowOrangeOverdueAlert) {
      const balanceNeeded = nodeData.generalData.charges.willPay - balance;
      return <OverdueAlert balance={balance} balanceNeeded={balanceNeeded} />;
    }

    return null;
  };

  const renderRestoreNodesAlert = () => {
    if (!hasNodesThatCanBeRestored || !isDashboardView()) {
      return null;
    }

    return (
      <RestoreNodesAlert
        toggleRestoreNodesModal={toggleRestoreNodesModal}
        totalNodesThatCanBeRestored={allNodesThatCanBeRestored.length}
      />
    );
  };

  const toggleRestoreNodesModal = () => {
    setShouldShowRestoreNodesModal(!shouldShowRestoreNodesModal);
  };

  useEffect(() => {
    if (currentCoinDataIndex !== undefined) {
      handleSelectDetails(currentCoinDataIndex);
    }
  }, [nodeData]);

  useEffect(() => {
    if (allNodesThatCanBeRestored.length === 0) {
      setShouldShowRestoreNodesModal(false);
    }
  }, [allNodesThatCanBeRestored]);

  return (
    <Container larger={largerContainer}>
      <Content>
        {!isDashboardView() && (
          <BackButton onClick={handleBack}>
            <FaArrowLeft />
            BACK
          </BackButton>
        )}
        <HeaderImageContainer>{renderHeaderImageElements()}</HeaderImageContainer>
        <ChargesContainer>
          <ChargeItem>
            <BolderText>{displayPriceWithCurrency(selectedDetails.charges.willPay)}</BolderText>
            <NormalText>Will pay this month</NormalText>
          </ChargeItem>
          <ChargeItem>
            <BolderText>{displayPriceWithCurrency(selectedDetails.charges.paid)}</BolderText>
            <NormalText>Paid this month</NormalText>
          </ChargeItem>
        </ChargesContainer>
        <NodeStatus
          total={isDashboardView() ? nodeData.generalData.status.total : selectedDetails.status.total}
          mounting={selectedDetails.status.mounting}
          ready={selectedDetails.status.ready}
          activated={selectedDetails.status.activated}
          allNodesOnly={isDashboardView()}
        />
        {renderRestoreNodesAlert()}
        {renderOverdueAlert()}
        {!isDashboardView() && selectedDetails.nodesNotEnabled.length > 0 && (
          <NodesNotEnabledContainer>
            <NodesNotEnabledInfo>
              <FiAlertTriangle size={25} color="orange" />
              <BolderText>Nodes to be enabled in your wallet</BolderText>
            </NodesNotEnabledInfo>
            <NodesNotEnabledItemsContainer>
              {selectedDetails.nodesNotEnabled.map((node: string) => (
                <NodeNotEnabled key={node}>{node}</NodeNotEnabled>
              ))}
            </NodesNotEnabledItemsContainer>
          </NodesNotEnabledContainer>
        )}
        {!isDashboardView() && shouldRenderDataToMyWallet() && (
          <ButtonContainer>
            <Button variant="secondary" onClick={handleDataToWalletClick}>
              {showMnData ? 'DATA TO MY WALLET' : 'NODE DATA'}
            </Button>
            {!showMnData && (
              <BootstrapButton
                variant="info"
                onClick={() => handleCopyClick(mnDataToWallet)}
                shouldShowTooltip={true}
                tooltipText="Copied!"
              >
                COPY ALL DATA
                <IoIosCopy />
              </BootstrapButton>
            )}
          </ButtonContainer>
        )}
        {showMnData && (
          <DataTable
            type={dataTableType}
            setEditedNode={setEditedNode}
            nodeDetails={selectedDetails.nodes}
            latestBlock={selectedDetails.latestBlock}
            coinList={nodeData.coinData}
            handleSelectDetails={handleSelectDetails}
            handleNodeToChangeChargeDate={handleNodeToChangeChargeDate}
          />
        )}
        {nodeData.coinData.length === 0 && isDashboardView() && (
          <NoNodesText>
            {`You haven't built any node yet. Fast and simple, `}
            <Link to={`/nodes`}>start now!</Link>
          </NoNodesText>
        )}
        {!showMnData && (
          <StringToWalletContainer>
            {mnDataToWallet.split('\n').map((line) => (
              <StringToWalletItem key={line}>{line}</StringToWalletItem>
            ))}
          </StringToWalletContainer>
        )}
      </Content>
      {!!editedNode && (
        <NodeModal
          isEditingNode={isEditingNode}
          editedNode={editedNode}
          handleHideModal={handleHideModal}
          handleModalSubmit={handleEditNode}
          shouldShowDeletionCode={shouldShowDeletionCode}
          onChangeDeletionCode={onChangeDeletionCode}
        />
      )}
      {!!nodeToChangeChargeDate && (
        <ChargeDateModal node={nodeToChangeChargeDate} hideChargeDateModal={hideChargeDateModal} />
      )}
      {shouldShowRestoreNodesModal && (
        <RestoreNodesModal
          nodesThatCanBeRestored={allNodesThatCanBeRestored}
          toggleRestoreNodesModal={toggleRestoreNodesModal}
        />
      )}
    </Container>
  );
}
