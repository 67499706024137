import { Accordion, Card } from 'react-bootstrap';
import uuid from 'react-uuid';

import { StyledButton, StyledAccordion } from './styles';
interface IInstructions {
  instructions: {
    title: any;
    answer: any;
  }[];
}

export function Instructions({ instructions, ...props }: IInstructions) {
  return (
    <StyledAccordion {...props}>
      {instructions.map((item, index) => {
        const idForCar = uuid();
        const { title, answer } = item;
        let titleValue: any = title;
        let answerValue: any = answer;

        if (typeof title === 'function') {
          titleValue = title();
        }
        if (typeof answer === 'function') {
          answerValue = answer();
        }

        return (
          <Card key={idForCar}>
            <Card.Header>
              <Accordion.Toggle as={StyledButton} variant="link" eventKey={String(index)}>
                {titleValue}
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={String(index)}>
              <Card.Body>{answerValue}</Card.Body>
            </Accordion.Collapse>
          </Card>
        );
      })}
    </StyledAccordion>
  );
}
