import styled from 'styled-components/macro';
import { DISPLAY_FLEX_COLUMN_DEFAULT, HIGLAN_WHITE_01 } from '~/style/constants';
import { SuggestCoin } from '../Nodes/shared/SuggestCoin';

export const StyledSuggestCoin = styled(SuggestCoin)`
  width: 80%;
`;

export const SuggestCoinContainer = styled.div`
  max-width: 900px;
  width: 100%;
  background-color: ${HIGLAN_WHITE_01};
  padding: 25px;
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  font-size: 16px;
  gap: 25px;
  border-radius: 0.3rem;
`;

export const SuggestCoinTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
`;
