import styled, { css } from 'styled-components/macro';
import { darken } from 'polished';
import { DISPLAY_FLEX_COLUMN_DEFAULT, DISPLAY_FLEX_LINE_DEFAULT, HIGLAN_GREEN_01 } from '~/style/constants';
import { FaEdit } from 'react-icons/fa';

export const TransferBalanceContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
`;

export const FIELD_CSS = css`
  background: linear-gradient(to bottom, #fff 5%, #ecedef 100%);
  text-align: center;
  padding: 8px;
  font-weight: bold;
  border-radius: 4px;
  color: #7159c1;
  ${DISPLAY_FLEX_LINE_DEFAULT}
  gap: 7px;
`;

export const Container = styled.div<{
  isLoadingUser: boolean;
  isSendingPromoCode: boolean;
}>`
  max-width: 600px;
  margin: 80px auto 30px;

  .main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: 15px;

    p {
      color: white;
      font-size: 20px;
    }
  }
  .main + div + div {
    margin-top: 10px;
  }

  span + span {
    margin-left: 3px;
  }
  form {
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    input {
      background: rgba(0, 0, 0, 0.1);
      border: 0;
      border-radius: 4px;
      height: 44px;
      padding: 0 15px;
      color: #fff;
      margin: 0 0 10px;

      &::placeholder {
        color: rgba(255, 255, 255, 0.7);
      }
    }

    span {
      color: black;
      background-color: red;
      /* align-self: flex-start; */
      margin: 0 0 10px;
      font-weight: bold;
      padding: 3px;
    }
  }

  #update-profile {
    margin: 5px 0 0;
    height: 44px;
    background: #3b9eff;
    background: ${({ isLoadingUser }) => (isLoadingUser ? darken(0.3, '#3b9eff') : '#3b9eff')};
    font-weight: bold;
    color: #fff;
    border: 0;
    outline: none;
    border-radius: 4px;
    font-size: 16px;
    transition: backgound 0.2s;
    cursor: ${({ isLoadingUser }) => (isLoadingUser ? 'not-allowed' : 'pointer')};

    &:hover {
      background: ${darken(0.1, '#3b9eff')};
    }
  }
  > button {
    width: 100%;
    margin: 10px 0 0;
    height: 44px;
    background: #f64c75;
    font-weight: bold;
    color: #fff;
    border: 0;
    outline: none;
    border-radius: 4px;
    font-size: 16px;
    transition: backgound 0.2s;

    &:hover {
      background: ${darken(0.1, '#f64c75')};
    }
  }

  .promotion-form {
    border-bottom: 1px dashed white;
    border-top: 1px dashed white;
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: center;
  }
`;

export const EmailContainer = styled.div`
  ${FIELD_CSS}
`;

export const NotificationNodesToStartContainer = styled.div`
  ${FIELD_CSS}
`;

export const ChangeEmailButton = styled(FaEdit)`
  cursor: pointer;
  width: 25px;
  transition: color 0.3s ease-in-out;

  :hover {
    color: ${HIGLAN_GREEN_01};
  }
`;

export const NodesToStartLabel = styled.label`
  margin: 0;
`;
