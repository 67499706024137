import { ReactNode } from 'react';

import Header from 'components/Header';
import Footer from 'components/Footer';

import { Wrapper, Placeholder, Main } from './styles';

interface IDefaultLayout {
  children: ReactNode;
}

export default function DefaultLayout({ children }: IDefaultLayout) {
  return (
    <Wrapper>
      <Header />
      <Placeholder />
      <Main>{children}</Main>
      <Footer />
    </Wrapper>
  );
}
