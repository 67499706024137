import { toast } from 'react-toastify';
import api from 'services/api';
import { useQuery } from 'shared/string-utils';
import history from 'services/history';

import { Form, EmailText, Title } from './styles';
import { GeneralContainer } from '../SignIn/shared/GeneralContainer';
import { HiglanLogo } from '../SignIn/shared/HiglanLogo';
import { SubmitButton } from '../SignIn/shared/SubmitButton';
import { useState } from 'react';

export function VerifyEmail() {
  const [isLoading, setIsLoading] = useState(false);
  const query = useQuery();
  const verify_email_code = query.get('c');
  const email = query.get('e');

  async function handleSubmit(event: React.FormEvent) {
    event.preventDefault();
    try {
      setIsLoading(true);
      const { data } = await api.put(
        'verify_email',
        {},
        {
          params: {
            verify_email_code,
            email,
          },
        }
      );
      toast.success(data.message);
      history.push('/dashboard');
    } catch (error) {
      const err: any = { error };
      const { response } = err.error;
      toast.error(response.data.error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <GeneralContainer>
      <HiglanLogo />
      <Form onSubmit={handleSubmit}>
        <Title>EMAIL CONFIRMATION</Title>
        <EmailText>{email}</EmailText>
        <SubmitButton isLoading={isLoading} type="submit" disabled={isLoading}>
          Confirm my Email
        </SubmitButton>
      </Form>
    </GeneralContainer>
  );
}
