import styled from 'styled-components/macro';
import { DISPLAY_FLEX_LINE_DEFAULT, HIGLAN_BLUE_02 } from '~/style/constants';
import { NavLink } from 'react-router-dom';
import { TextArea } from '~/components/ui/TextArea';

export const WalletAddressContainer = styled.div`
  ${DISPLAY_FLEX_LINE_DEFAULT}
  gap: 5px;
`;

export const WalletAddressInfoContainer = styled.div`
  max-width: 400px;
`;

export const ContactLink = styled(NavLink)`
  color: ${HIGLAN_BLUE_02};
`;

export const RefundContainer = styled.div`
  ${DISPLAY_FLEX_LINE_DEFAULT}
  margin-bottom: 10px;
`;

export const DeletionCodeTextArea = styled(TextArea)`
  text-transform: uppercase;
  max-height: 30px;
  min-height: 40px;
  max-width: 100px;
  padding: 10px 7px 7px 7px;
  text-align: center;
  margin: 0 auto;
  overflow-y: hidden;
`;
