import axios from 'axios';

export const API_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3333'
    : 'https://api.higlan.com';

const api = axios.create({
  baseURL: API_URL,
});

export const apiCoinbase = axios.create({
  baseURL: 'https://api.coinbase.com/v2',
});

export const apiStex = axios.create({
  baseURL: 'https://api3.stex.com',
});

export const apiCoinGecko = axios.create({
  baseURL: 'https://api.coingecko.com/api/v3',
});

export default api;
