import { authActionTypes, IAuthAction } from './actions';

const INITIAL_STATE = {
  token: null,
  signed: false,
  loading: false,
};

export function auth(state = INITIAL_STATE, action: IAuthAction) {
  const { type, payload } = action;

  switch (type) {
    case authActionTypes.SIGN_IN_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case authActionTypes.SIGN_UP_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case authActionTypes.SIGN_IN_SUCCESS: {
      return {
        ...state,
        token: payload.token,
        signed: true,
        loading: false,
      };
    }
    case authActionTypes.SIGN_UP_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case authActionTypes.SIGN_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case authActionTypes.SIGN_OUT: {
      return {
        ...INITIAL_STATE,
      };
    }
    default: {
      return state;
    }
  }
}
