import styled, { createGlobalStyle, css } from 'styled-components/macro';
import { Tooltip as TooltipReactBootstrap } from 'react-bootstrap';

interface IGlobalTooltipStyles {
  forcedWidth?: number;
}

const Z_INDEX_TOOLTIP_CSS = css`
  z-index: 9999;
`;

export const TooltipContainer = styled.div`
  display: inline-block;
`;

export const StyledTooltipReactBootstrap = styled(TooltipReactBootstrap)`
  ${({ shouldUseZIndex }) => shouldUseZIndex && Z_INDEX_TOOLTIP_CSS}
`;

export const GlobalStylesForTooltip = createGlobalStyle<{ styles: IGlobalTooltipStyles }>`
  .tooltip-inner {
    max-width: ${({ styles }) => (styles.forcedWidth ? `${styles.forcedWidth}px` : 'none')};
  }
  
  .tooltip.show {
    opacity: 100%;
  }
`;
