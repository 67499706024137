import { FaToggleOff, FaToggleOn } from 'react-icons/fa';
import { IoMdNotificationsOff, IoMdNotifications } from 'react-icons/io';
import styled, { css } from 'styled-components/macro';
import { BootstrapButton } from '~/components/ui/BootstrapButton';
import {
  DISPLAY_FLEX_COLUMN_DEFAULT,
  DISPLAY_FLEX_LINE_DEFAULT,
  HIGLAN_BLACK_01,
  HIGLAN_GOLDEN_01,
  HIGLAN_GRAY_03,
  HIGLAN_GREEN_01,
  HIGLAN_GREEN_02,
  HIGLAN_PURPLE_01,
} from '~/style/constants';
import { mobileOnly } from '~/style/mixins';

export const WARNING_BACKGROUND = '#ff9d00';

const WARNING_CSS = css`
  background-color: ${WARNING_BACKGROUND};
  font-weight: bold;
`;

const SEPARATOR_BORDER = css`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

const NOTIFICATION_ICON_CSS = css`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;

  &:hover {
    color: ${HIGLAN_PURPLE_01};
  }
`;

export const SECTION_DEFAULT_STYLES = css`
  ${DISPLAY_FLEX_LINE_DEFAULT};
  justify-content: space-between;
  gap: 5px;
  margin-bottom: 17px;
  padding: 10px;
  ${SEPARATOR_BORDER}
  flex-wrap: wrap;
`;

export const GeneralTotals = styled.div`
  ${SECTION_DEFAULT_STYLES}
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  gap: 15px;
`;

export const TodayTotals = styled.div`
  ${SECTION_DEFAULT_STYLES}
  justify-content: center;
  gap: 15px;
`;

export const AllCoins = styled.div`
  ${SECTION_DEFAULT_STYLES}
  flex-direction: column;
`;

export const AllServers = styled.div`
  ${SECTION_DEFAULT_STYLES}
  align-items: flex-start;
  justify-content: center;
  gap: 25px;
  ${mobileOnly`
    justify-content: center;
  `}
`;

export const GeneralDeletion = styled.div`
  ${DISPLAY_FLEX_LINE_DEFAULT}
  gap: 25px;
`;

export const NoMountingRunningWarning = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  ${WARNING_CSS}
  padding: 10px;
  gap: 7px;
  max-width: 200px;
  margin: 10px auto;
`;

export const GeneralButtons = styled.div`
  ${SECTION_DEFAULT_STYLES}
  justify-content: center;
  border-bottom: 0;
`;

export const ItemContainer = styled.div<{ shouldUseBorder?: boolean }>`
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  gap: 5px;
  ${({ shouldUseBorder = true }) => shouldUseBorder && `border: 1px solid grey;`}
  padding: 7px;
  text-align: center;
`;

export const HighestEarning = styled(ItemContainer)`
  color: ${HIGLAN_GOLDEN_01};
  background-color: ${HIGLAN_PURPLE_01};
  border-radius: 15px;
  border: 2px solid ${HIGLAN_BLACK_01};
  font-weight: bold;
  padding: 10px;
`;

export const NormalItem = styled.div``;

export const ShowNodesText = styled.div`
  padding: 7px;
  border-radius: 3px;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: ${HIGLAN_GRAY_03};
  }
`;

export const StrongItem = styled.strong``;

export const ServerInfoContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  text-align: center;
  gap: 5px;
  padding-top: 5px;
`;

export const StorageContainer = styled.div`
  ${DISPLAY_FLEX_LINE_DEFAULT}
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  text-align: center;
  gap: 5px;
  padding-top: 5px;
  flex-wrap: wrap;
`;

export const StorageItem = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 7px;
`;

export const StorageItemTitle = styled.small`
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  font-weight: bold;
  margin-bottom: 5px;
`;

export const InfoItem = styled.small`
  ${DISPLAY_FLEX_LINE_DEFAULT}
  gap: 7px;
`;

export const UserDataItems = styled.div`
  ${DISPLAY_FLEX_LINE_DEFAULT}
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
`;

export const CoinContainer = styled.div`
  ${DISPLAY_FLEX_LINE_DEFAULT}
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  gap: 7px;
  ${mobileOnly`
    justify-content: center;
  `}
  align-items: flex-start;
`;

export const CoinItem = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  position: relative;
  border: 1px solid #dee2e6;
  margin-top: 7px;
  padding: 7px;
  gap: 7px;
`;

export const TotalEarnings = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT};
  position: absolute;
  top: 0;
  right: 0;
  font-size: small;
  background-color: ${HIGLAN_GRAY_03};
  color: ${HIGLAN_GREEN_02};

  div {
    gap: 0;
  }
`;

export const ServerItem = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  border: 1px solid #dee2e6;
  margin-top: 7px;
  padding: 7px;
  gap: 7px;
`;

export const WarningItem = styled(ItemContainer)<{ isWarning: boolean }>`
  ${({ isWarning }) => isWarning && WARNING_CSS}
  position: relative;
`;

export const EnabledNotificationIcon = styled(IoMdNotifications)`
  ${NOTIFICATION_ICON_CSS}
`;

export const DisabledNotificationIcon = styled(IoMdNotificationsOff)`
  ${NOTIFICATION_ICON_CSS}
`;

export const ItemsContainer = styled.div`
  ${DISPLAY_FLEX_LINE_DEFAULT};
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  gap: 7px;
  text-align: center;
  flex-wrap: wrap;
`;

export const WarningContainer = styled.div`
  ${DISPLAY_FLEX_LINE_DEFAULT}
  align-items: baseline;
  gap: 10px;
  flex-wrap: wrap;
`;

export const TimeText = styled.div`
  font-size: 20px;
  font-weight: 600;
`;

export const TimeContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT};
  gap: 7px;
`;

export const ResetIndexButton = styled(BootstrapButton)`
  min-width: 207px;
`;

export const ChargeNodesButton = styled(BootstrapButton)`
  min-width: 143px;
`;

export const ExecuteDeletionButton = styled(BootstrapButton)`
  min-width: 170px;
`;

export const CoinItemTotals = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT};
  gap: 7px;
  width: 100%;
  border: 1px solid #dee2e6;
  padding: 10px;
`;

export const ServerButtonsContainer = styled.div`
  ${DISPLAY_FLEX_LINE_DEFAULT};
  width: 100%;
  justify-content: center;
  gap: 25px;
  padding: 7px 0;

  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

export const ServerButtonText = styled.small`
  font-weight: bold;
  text-align: center;
`;

export const ServerButtonItem = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT};
`;

export const ToggleOffButton = styled(FaToggleOff)`
  cursor: pointer;
  color: ${HIGLAN_GRAY_03};
`;

export const ToggleOnButton = styled(FaToggleOn)`
  color: ${HIGLAN_GREEN_01};
  cursor: pointer;
`;
