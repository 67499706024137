import { IState } from '../../interfaces';

export const getSupportedCoins = (state: IState) => state.app.supportedCoins;

export const isLoadingAppData = (state: IState) => state.app.isLoadingAppData;

export const getLoadingAppDataRequests = (state: IState) =>
  state.app.loadingAppDataRequests;

export const getWasSomeRequestSent = (state: IState) => state.app.wasSomeRequestSent;

export const getIsSendingMessage = (state: IState) => state.app.isSendingMessage;
