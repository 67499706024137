import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { HIGLAN_WHITE_01 } from '~/style/constants';

export const LinkText = styled(Link)`
  color: #fff;
  font-size: 16px;

  &:hover {
    color: #fff;
  }
`;

export const CreateNewPasswordText = styled.h3`
  font-size: 20px;
  color: ${HIGLAN_WHITE_01};
`;