import styled from 'styled-components';
import { DISPLAY_FLEX_COLUMN_DEFAULT, DISPLAY_FLEX_LINE_DEFAULT, HIGLAN_PURPLE_01 } from '~/style/constants';

export const RestoreNodesModalContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  gap: 10px;
  text-align: center;
`;

export const ActionsContainer = styled.div`
  ${DISPLAY_FLEX_LINE_DEFAULT}
  justify-content: space-around;

  svg {
    cursor: pointer;

    &:hover {
      color: ${HIGLAN_PURPLE_01};
    }
  }
`;

export const ExplorerLink = styled.a``;
