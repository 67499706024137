import { Fragment, useState } from 'react';
import { Table } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { FaRegQuestionCircle } from 'react-icons/fa';
import NodeStatus from '~/components/NodeStatus';
import Status from '~/components/Status';
import {
  Tbody,
  Thead,
  Td,
  TdImageContainer,
  TdImage,
  Th,
  Tr,
  TdButtonContainer,
  TdSmaller,
  TdActiveTime,
  WarningItem,
  WarningText,
  ServerIpContainer,
  ActionsContainer,
  StatusContainer,
} from './styles';
import { Tooltip } from 'components/ui/Tooltip';
import uuid from 'react-uuid';
import { ICoinData, INodeDetails, INodeStatus } from '~/store/modules/interfaces';
import {
  displayDefaultDateFormat,
  displayNodeAlias,
  displayPriceWithCurrency,
  emptyFunction,
  formatDate,
} from '~/shared/string-utils';
import prettyMilliseconds from 'pretty-ms';
import { getStatusIcon, renderNodeKey } from '~/pages/Dashboard/shared';
import { IHandleToggleSingleData, INodeDetailsToShow } from '..';
import { NormalItem, StrongItem } from '../styles';
import { NodeModal } from '~/pages/Dashboard/NodeModal';
import { INodeToEdit } from '~/pages/Dashboard/DataTable';
import { SUPORTED_COINS } from '~/shared/constants';
import { HiCommandLine } from 'react-icons/hi2';
import { AiFillPauseCircle, AiFillWarning } from 'react-icons/ai';
import { MdDelete } from 'react-icons/md';
import { toast } from 'react-toastify';
import api from '~/services/api';
import { useSelector } from 'react-redux';
import { getIsAdminCode } from '~/store/modules/user/selectors';

interface IDataTable {
  user_id: number;
  isAllNodeDataShown: boolean;
  nodeDetailsToShow: INodeDetailsToShow[];
  coinData: ICoinData[];
  handleToggleSingleData: (data: IHandleToggleSingleData) => void;
  toggleAdminCodeModal: () => void;
}

interface INodeDetailsTable {
  nodes: INodeDetails[];
  coinDisplayName: string;
  latestBlock: number;
  handleEditedNode: (nodeToEdit: INodeToEdit) => void;
}

interface IDisplayActiveTime {
  activetime: number | null;
  status: INodeStatus;
}

const NODE_DETAIL_COLUMNS = [
  'ID',
  'Coin',
  'Alias',
  'Price',
  'Server / IP',
  'Node Key',
  'Blocks',
  'Charged at',
  'Next Charge',
  'Active Time',
  'Status',
  'Actions',
];

const COL_SPAN = 9;

const TOAST_AUTO_CLOSE = 10000;

const userCoinIndexesToShow: {
  user_id: number;
  coinDetailsIndexesToShow: number[];
} = {
  user_id: 0,
  coinDetailsIndexesToShow: [],
};

export const displayActiveTime = ({ activetime, status }: IDisplayActiveTime) => {
  if (activetime && status === 'activated') {
    return prettyMilliseconds(activetime * 1000, {
      unitCount: 3,
    });
  }

  return '-';
};

export const displayServerIp = (node: INodeDetails) => {
  return (
    <ServerIpContainer>
      <NormalItem>{node.server_alias}</NormalItem>
      <NormalItem>{node.ip}</NormalItem>
    </ServerIpContainer>
  );
};

export const displayNodeStatus = (node: INodeDetails) => {
  const { is_decommissioned } = node;
  if (is_decommissioned) {
    return (
      <StatusContainer>
        <AiFillWarning color="orange" title="Node is decommissioned" />
        <StrongItem>Decommissioned</StrongItem>
      </StatusContainer>
    );
  }

  return <StatusContainer>{getStatusIcon(node, true)}</StatusContainer>;
};

export const displayNodeBlock = (node: INodeDetails) => {
  const { is_node_delayed, block, latest_block } = node;
  return (
    <WarningItem isWarning={is_node_delayed}>
      {block ? `${block} / ${latest_block}` : '-'}
      {is_node_delayed && <WarningText>Delayed Block</WarningText>}
    </WarningItem>
  );
};

function NodeDetailsTable({ nodes, coinDisplayName, handleEditedNode }: INodeDetailsTable) {
  const renderTColumns = () => {
    const columnLines = NODE_DETAIL_COLUMNS.map((column) => <Th key={uuid()}>{column}</Th>);
    return columnLines;
  };

  const renderActions = (node: any) => {
    const { is_paused, paused_until, coin, status } = node;
    const { hasActivationCommandOption, hasDeleteOption, hasPauseOption } = SUPORTED_COINS[coin].features;
    const pausedFormattedDate = formatDate(new Date(paused_until));
    const pausedText = !is_paused ? 'Pause node' : `This node is currently paused until: ${pausedFormattedDate}`;
    const allActions = [];
    if (status === 'mounting') return '-';

    if (hasActivationCommandOption) {
      allActions.push(
        <Tooltip content="Activation command" key={uuid()}>
          <HiCommandLine
            size={17}
            cursor="pointer"
            onClick={() =>
              handleEditedNode({
                id: node.id,
                coin: SUPORTED_COINS[node.coin].name,
                alias: node.alias,
                private_key: node.private_key,
                activationCommand: node.activation_command,
                editType: 'getActivationCommand',
                status: node.status,
                wallet_address: node.wallet_address,
              })
            }
          />
        </Tooltip>
      );
    }

    if (status === 'activated' && hasPauseOption) {
      allActions.push(
        <Tooltip content={pausedText} key={uuid()}>
          <AiFillPauseCircle
            size={17}
            cursor="pointer"
            className="pause-button"
            onClick={() =>
              !is_paused
                ? handleEditedNode({
                    id: node.id,
                    coin: SUPORTED_COINS[node.coin].name,
                    alias: node.alias,
                    private_key: node.private_key,
                    activationCommand: node.activation_command,
                    editType: 'pause',
                    status: node.status,
                    wallet_address: node.wallet_address,
                  })
                : emptyFunction
            }
          />
        </Tooltip>
      );
    }

    if (hasDeleteOption) {
      allActions.push(
        <Tooltip content="Delete node" key={uuid()}>
          <MdDelete
            size={17}
            cursor="pointer"
            onClick={() =>
              handleEditedNode({
                id: node.id,
                coin: SUPORTED_COINS[node.coin].name,
                alias: node.alias,
                private_key: node.private_key,
                activationCommand: node.activation_command,
                editType: 'delete',
                status: node.status,
                wallet_address: node.wallet_address,
              })
            }
          />
        </Tooltip>
      );
    }

    return <>{allActions.map((action) => action)}</>;
  };

  const renderCoinNodes = (nodes: INodeDetails[]) => {
    const nodeLines = nodes.map((node) => {
      const { is_overdue_charge, is_paused, activetime, status } = node;
      const isMounting = node.status === 'mounting';
      return (
        <Tr key={uuid()}>
          <Td>{node.id}</Td>
          <Td>{coinDisplayName}</Td>
          <Td>{`${displayNodeAlias(node.alias)}`}</Td>
          <Td>{displayPriceWithCurrency(node.price)}</Td>
          <Td>{displayServerIp(node)}</Td>
          <TdSmaller>{renderNodeKey(node.coin, node.private_key)}</TdSmaller>
          <Td>{displayNodeBlock(node)}</Td>
          <Td>{displayDefaultDateFormat(new Date(node.charged_at))}</Td>
          <Td>
            <WarningItem isWarning={is_overdue_charge}>
              {node.next_charge ? displayDefaultDateFormat(new Date(node.next_charge)) : '-'}
              {is_overdue_charge && <WarningText>Overdue Charge</WarningText>}
            </WarningItem>
          </Td>
          <TdActiveTime>{displayActiveTime({ activetime, status })}</TdActiveTime>
          <Td style={{ textAlign: 'center' }}>{displayNodeStatus(node)}</Td>
          <Td>
            <ActionsContainer isMounting={isMounting} isPaused={is_paused}>
              {renderActions(node)}
            </ActionsContainer>
          </Td>
        </Tr>
      );
    });

    return <>{nodeLines}</>;
  };

  return (
    <Table striped bordered hover size="sm">
      <Thead>
        <Tr>{renderTColumns()}</Tr>
      </Thead>
      <Tbody>{renderCoinNodes(nodes)}</Tbody>
    </Table>
  );
}

export default function DataTable({
  coinData,
  user_id,
  nodeDetailsToShow,
  handleToggleSingleData,
  isAllNodeDataShown,
  toggleAdminCodeModal,
}: IDataTable) {
  const savedCoinIndexesToShow =
    userCoinIndexesToShow.user_id === user_id ? userCoinIndexesToShow.coinDetailsIndexesToShow : [];
  const [coinDetailsIndexesToShow, setCoinDetailsIndexesToShow] = useState<number[]>(savedCoinIndexesToShow);
  const [editedNode, setEditedNode] = useState<INodeToEdit | undefined>();
  const [isEditingNode, setIsEditingNode] = useState(false);
  const [shouldRefund, setShouldRefund] = useState(false);
  const isAdminCodeValid = useSelector(getIsAdminCode);

  const shouldShowNodeDetails = (index: number) =>
    coinDetailsIndexesToShow.includes(index) ||
    nodeDetailsToShow.find((item) => item.user_id === user_id)?.coinDataIndexes.includes(index);

  const handleShowDetails = (index: number) => {
    if (isAllNodeDataShown) {
      handleToggleSingleData({ user_id, coinIndex: index });
      return;
    }
    const tempCoinDetailsIndexesToShow = [...new Set(coinDetailsIndexesToShow)];

    const coinIndex = tempCoinDetailsIndexesToShow.findIndex((itemIndex) => itemIndex === index);

    if (coinIndex === -1) {
      tempCoinDetailsIndexesToShow.push(index);
    } else {
      tempCoinDetailsIndexesToShow.splice(coinIndex, 1);
    }

    userCoinIndexesToShow.user_id = user_id;
    userCoinIndexesToShow.coinDetailsIndexesToShow = [...tempCoinDetailsIndexesToShow];

    setCoinDetailsIndexesToShow(tempCoinDetailsIndexesToShow);
  };

  const handleEditedNode = (nodeToEdit: INodeToEdit) => {
    if (!isAdminCodeValid) {
      toggleAdminCodeModal();
      return;
    }
    setEditedNode(nodeToEdit);
  };

  const renderTbody = () => {
    return coinData.map((item, index) => {
      const { display_name, nodes, latestBlock } = item;
      const coinName = item.coin;
      const { total, mounting, ready, activated, decommissioned } = item.status;
      return (
        <Fragment key={uuid()}>
          <Tr>
            <TdImageContainer>
              <TdImage coin={coinName} />
              {display_name}
            </TdImageContainer>
            <Td colSpan={COL_SPAN}>
              <NodeStatus
                total={total}
                mounting={mounting}
                ready={ready}
                activated={activated}
                decommissioned={decommissioned}
              />
            </Td>
            <TdButtonContainer>
              <Button variant="primary" size="sm" onClick={() => handleShowDetails(index)}>
                {shouldShowNodeDetails(index) ? 'HIDE DATA' : 'SHOW DATA'}
              </Button>
            </TdButtonContainer>
          </Tr>
          <Tr>
            <Td colSpan={NODE_DETAIL_COLUMNS.length}>
              {shouldShowNodeDetails(index) && (
                <NodeDetailsTable
                  nodes={nodes}
                  latestBlock={latestBlock}
                  coinDisplayName={display_name}
                  handleEditedNode={handleEditedNode}
                />
              )}
            </Td>
          </Tr>
        </Fragment>
      );
    });
  };

  const handleHideModal = () => {
    setEditedNode(undefined);
  };

  const handleRefund = () => {
    setShouldRefund(!shouldRefund);
  };

  const handleEditNode = async () => {
    if (editedNode) {
      const { coin, alias, id, editType } = editedNode;
      setIsEditingNode(true);

      try {
        if (editType === 'delete') {
          await api.delete(`admin/nodes/${id}`, {
            params: {
              ...(shouldRefund && { refundByAdmin: shouldRefund }),
            },
          });

          toast.success(`User ID: ${user_id}. ${coin} node${alias} has been deleted!`, {
            autoClose: TOAST_AUTO_CLOSE,
          });
        } else if (editType === 'pause') {
          await api.put(`masternodes/${id}/pause`);

          toast.success(`User ID: ${user_id}. ${coin} node${alias} has been paused!`, {
            autoClose: TOAST_AUTO_CLOSE,
          });
        }

        handleHideModal();
      } catch (e) {
        const err: any = e;
        const { data } = err.response;

        const { error } = data;

        toast.error(error);
      } finally {
        setIsEditingNode(false);
      }
    }
  };

  return (
    <>
      <Table responsive striped bordered hover size="sm">
        <Thead>
          <Tr>
            <Th>Coin</Th>
            <Th colSpan={COL_SPAN}>
              Status
              <Tooltip content={<Status />}>
                <FaRegQuestionCircle style={{ marginLeft: 5 }} />
              </Tooltip>
            </Th>
            <Th>Data Management</Th>
          </Tr>
        </Thead>
        <Tbody>{renderTbody()}</Tbody>
      </Table>
      {!!editedNode && (
        <NodeModal
          isEditingNode={isEditingNode}
          editedNode={editedNode}
          handleHideModal={handleHideModal}
          handleModalSubmit={handleEditNode}
          handleRefund={handleRefund}
          shouldRefund={shouldRefund}
        />
      )}
    </>
  );
}
