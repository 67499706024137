import { IState, ISupportedCoinsNames } from 'store/modules/interfaces';

export const getCoinChainDataSelector =
  (currentSelectedCoin: ISupportedCoinsNames) =>
  ({ coinChainData }: IState) => {
    const { allCoinChainData } = coinChainData;
    const coinData = allCoinChainData.find(
      (coinItem) => coinItem.coin === currentSelectedCoin
    );

    return coinData;
  };
