import styled from 'styled-components/macro';
import { DISPLAY_FLEX_COLUMN_DEFAULT, HIGLAN_ORANGE_01 } from '~/style/constants';

export const RestoreNodesContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  background-color: ${HIGLAN_ORANGE_01};
  text-align: center;
  padding: 15px;
  font-size: 17px;
  margin-top: 10px;
  gap: 15px;
`;

export const ManageNodesText = styled.div`
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    font-weight: bold;
  }
`;
