import { ItemStrong } from '~/pages/NodeSteps/styles';
import { MonthAmount, MonthAmountContainer, MonthAmountItemsContainer } from './styled';

interface IMonthAmounts {
  numberOfMonths: number;
  onSelectMonthAmount: (data: IMonthData) => void;
  shouldShowMonthTitle?: boolean;
}

export interface IMonthData {
  monthAmountText: string;
  monthAmountNumber: 1 | 3 | 6 | 12;
}

export default function MonthAmounts({
  numberOfMonths = 1,
  onSelectMonthAmount,
  shouldShowMonthTitle = true,
}: IMonthAmounts) {
  const MONTH_AMOUNT: IMonthData[] = [
    {
      monthAmountText: '1 month',
      monthAmountNumber: 1,
    },
    {
      monthAmountText: '3 months',
      monthAmountNumber: 3,
    },
    {
      monthAmountText: '6 months',
      monthAmountNumber: 6,
    },
    {
      monthAmountText: '12 months',
      monthAmountNumber: 12,
    },
  ];

  const handleMonthClick = (monthData: IMonthData) => () => {
    onSelectMonthAmount(monthData);
  };

  return (
    <MonthAmountContainer>
      {shouldShowMonthTitle && <ItemStrong>Months</ItemStrong>}
      <MonthAmountItemsContainer>
        {MONTH_AMOUNT.map(({ monthAmountText, monthAmountNumber }) => (
          <MonthAmount
            key={monthAmountText}
            isSelected={numberOfMonths === monthAmountNumber}
            onClick={handleMonthClick({ monthAmountNumber, monthAmountText })}
          >
            {monthAmountText}
          </MonthAmount>
        ))}
      </MonthAmountItemsContainer>
    </MonthAmountContainer>
  );
}
