import { DataTable } from '~/components/ui/DataTable';
import { Modal } from '~/components/ui/Modal';
import { NodesProblemModalContainer, ServerItem, ServerItemsContainer, TotalText } from './styles';
import { IWarnings, IWarningsNodeDetails } from '~/store/modules/interfaces';
import { displayDefaultDateFormat, displayNodeAlias, displayPriceWithCurrency, reduceKey } from '~/shared/string-utils';
import { displayActiveTime, displayNodeBlock, displayNodeStatus, displayServerIp } from '../DataTable';
import { ExplorerLink } from './styles';
import { BELDEX_EXPLORER_LINK } from '~/shared/constants';
import { INodeProblems } from '..';

const NODE_PROBLEMS_COLUMNS = [
  'User Email',
  'Node ID',
  'Coin',
  'Alias',
  'Price',
  'Server/IP',
  'Node Key',
  'Blocks',
  'Charged at',
  'Next Charge',
  'Active Time',
  'Index to Delete',
  'Status',
];

const OVERDUE_NODES_USER_ACTIVITY_COLUMNS = [
  'Name',
  'Email',
  'Last Active',
  'Total Nodes',
  'Current Balance',
  'Total to Pay',
];

const DELAYED_SCRIPT_COLUMNS = ['IP', 'ALIAS', 'MAIN SCRIPT PING', 'UPDATE SCRIPT PING'];
const DELAYED_SERVERS_PING_COLUMNS = ['IP', 'ALIAS', 'Last PING'];

interface INodesProblemModal {
  toggleModal: () => void;
  modalTitle: string;
  warnings: IWarnings;
  nodeProblemsModalRenderType: INodeProblems | undefined;
}

export function NodesProblemModal({
  toggleModal,
  modalTitle,
  warnings,
  nodeProblemsModalRenderType,
}: INodesProblemModal) {
  const getDelayedScriptLines = () => {
    const { delayedScriptPingServers } = warnings.scriptWarnings.data;
    const delayedScriptLines = delayedScriptPingServers.map((delayedScriptDataItem) => {
      const { alias, ip, delayed_script_pings } = delayedScriptDataItem;

      const scriptsData = [];
      let mainScriptData = null;
      let updateBlocksScriptData = null;

      delayed_script_pings.forEach((scriptPingItem) => {
        const { last_ping_time_ago, name } = scriptPingItem;

        if (name === 'main_script') {
          mainScriptData = `${name}, ${last_ping_time_ago}`;
        } else if (name === 'update_blocks_script') {
          updateBlocksScriptData = `${name}, ${last_ping_time_ago}`;
        } else {
          scriptsData.push(`${name}, ${last_ping_time_ago}`);
        }
      });

      // If "main_script" is missing, add it with a "-" placeholder
      if (!mainScriptData) {
        mainScriptData = '-';
      }

      // If "update_blocks_script" is missing, add it with a "-" placeholder
      if (!updateBlocksScriptData) {
        updateBlocksScriptData = '-';
      }

      // Push the scripts data into the final array ensuring the order
      scriptsData.unshift(mainScriptData);
      scriptsData.push(updateBlocksScriptData);

      return [ip, alias, ...scriptsData];
    });

    return delayedScriptLines;
  };

  const getNodeProblemLines = (nodes: any) => {
    const nodeProblemLines = nodes.map((nodeProblemItem: IWarningsNodeDetails) => {
      const { id, coin, alias, price, private_key, charged_at, next_charge, activetime, status, index_to_delete } =
        nodeProblemItem;
      const { email } = nodeProblemItem.user;
      const isBdx = String(coin).toLowerCase() === 'bdx';

      const renderNodeKey = () => {
        if (isBdx) {
          return (
            <ExplorerLink href={`${BELDEX_EXPLORER_LINK}/mn/${private_key}`} target="_blank">
              {reduceKey(private_key)}
            </ExplorerLink>
          );
        }
        return reduceKey(private_key);
      };

      return [
        email,
        id,
        String(coin).toUpperCase(),
        displayNodeAlias(alias),
        displayPriceWithCurrency(price),
        displayServerIp(nodeProblemItem),
        renderNodeKey(),
        displayNodeBlock(nodeProblemItem),
        displayDefaultDateFormat(new Date(charged_at)),
        displayDefaultDateFormat(new Date(next_charge)),
        displayActiveTime({ activetime, status }),
        index_to_delete,
        displayNodeStatus(nodeProblemItem),
      ];
    });

    return nodeProblemLines;
  };

  const getDelayedServersPingLines = () => {
    const { delayedPingServers } = warnings.serversPingWarning.data;
    const lines = delayedPingServers.map((delayedServer) => {
      const { alias, ip, last_ping_when, last_ping_time_ago } = delayedServer;

      return [ip, alias, `${last_ping_when} (${last_ping_time_ago})`];
    });

    return lines;
  };

  const renderModalContent = () => {
    switch (nodeProblemsModalRenderType) {
      case 'overdueNodes': {
        const { nodes, userActivities, perServers } = warnings.overdueNodes;
        const nodeProblemLines = getNodeProblemLines(nodes);
        let totalToBePaidOverdueNodes = 0;
        const overdueNodesUserActivitiesLines = userActivities.map((userActivityItem) => {
          const { name, email, balance, last_active_time_ago, totalOverdueNodes, totalToBePaid } = userActivityItem;
          totalToBePaidOverdueNodes = Number(totalToBePaidOverdueNodes) + Number(totalToBePaid);

          return [
            name,
            email,
            last_active_time_ago,
            totalOverdueNodes,
            displayPriceWithCurrency(balance),
            displayPriceWithCurrency(totalToBePaid),
          ];
        });

        return (
          <>
            <TotalText>{`Total Users for the Overdue Nodes: ${
              overdueNodesUserActivitiesLines.length
            } | Total to be paid: ${displayPriceWithCurrency(totalToBePaidOverdueNodes)}`}</TotalText>
            <DataTable
              columns={OVERDUE_NODES_USER_ACTIVITY_COLUMNS}
              lines={overdueNodesUserActivitiesLines}
              shouldUseCheckDataType={false}
            />
            <ServerItemsContainer>
              {Object.entries(perServers).map(([serverName, value]) => {
                return <ServerItem key={serverName}>{`${serverName}: ${value}`}</ServerItem>;
              })}
            </ServerItemsContainer>
            <TotalText>{`Total: ${nodeProblemLines.length}`}</TotalText>
            <DataTable columns={NODE_PROBLEMS_COLUMNS} lines={nodeProblemLines} shouldUseCheckDataType={false} />
          </>
        );
      }
      case 'delayedBlockNodes': {
        const { nodes, perServers } = warnings.delayedBlockNodes;
        const nodeProblemLines = getNodeProblemLines(nodes);
        return (
          <>
            <ServerItemsContainer>
              {Object.entries(perServers).map(([serverName, value]) => {
                return <ServerItem key={serverName}>{`${serverName}: ${value}`}</ServerItem>;
              })}
            </ServerItemsContainer>
            <TotalText>{`Total: ${nodeProblemLines.length}`}</TotalText>
            <DataTable columns={NODE_PROBLEMS_COLUMNS} lines={nodeProblemLines} shouldUseCheckDataType={false} />
          </>
        );
      }

      case 'decommissionedNodes': {
        const { nodes, perServers } = warnings.decommissionedNodes;
        const nodeProblemLines = getNodeProblemLines(nodes);
        return (
          <>
            <ServerItemsContainer>
              {Object.entries(perServers).map(([serverName, value]) => {
                return <ServerItem key={serverName}>{`${serverName}: ${value}`}</ServerItem>;
              })}
            </ServerItemsContainer>
            <TotalText>{`Total: ${nodeProblemLines.length}`}</TotalText>
            <DataTable columns={NODE_PROBLEMS_COLUMNS} lines={nodeProblemLines} shouldUseCheckDataType={false} />
          </>
        );
      }
      case 'delayedScripts': {
        const delayedScriptsLines = getDelayedScriptLines();
        return (
          <>
            <DataTable columns={DELAYED_SCRIPT_COLUMNS} lines={delayedScriptsLines} shouldUseCheckDataType={false} />
          </>
        );
      }
      case 'delayedServersPing': {
        const delayedServersPingLines = getDelayedServersPingLines();
        return (
          <>
            <DataTable
              columns={DELAYED_SERVERS_PING_COLUMNS}
              lines={delayedServersPingLines}
              shouldUseCheckDataType={false}
            />
          </>
        );
      }
    }

    return;
  };

  const ModalContent = () => {
    return <NodesProblemModalContainer>{renderModalContent()}</NodesProblemModalContainer>;
  };

  return (
    <Modal
      bodyContent={<ModalContent />}
      title={modalTitle}
      show={true}
      onHide={toggleModal}
      hasConfirmation={false}
      size="xl"
    />
  );
}
