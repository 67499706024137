import { Table } from 'react-bootstrap';
import uuid from 'react-uuid';
import { displayPriceWithCurrency } from '~/shared/string-utils';
import { Tbody, Th, Thead, Tr, Td, TdNoActivity, DataTableContainer } from './styles';

interface IDataTable {
  columns: string[];
  lines: any[][];
  shouldUseCheckDataType?: boolean;
}

export function DataTable({ columns, lines, shouldUseCheckDataType = true, ...props }: IDataTable) {
  const renderLineValue = (lineValue: any, indexValue: number) => {
    const shouldCheckDataType = shouldUseCheckDataType && indexValue !== 0;

    if (shouldCheckDataType && typeof lineValue === 'string') {
      return lineValue;
    }

    if (shouldCheckDataType && typeof lineValue === 'number') {
      return displayPriceWithCurrency(lineValue);
    }

    return lineValue;
  };
  return (
    <DataTableContainer {...props}>
      <Table responsive striped bordered hover size="sm">
        <Thead>
          <Tr>
            {columns.map((column) => (
              <Th key={uuid()}>{column}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {lines.length === 0 ? (
            <Tr>
              <TdNoActivity colSpan={columns.length}>No Activity</TdNoActivity>
            </Tr>
          ) : (
            lines.map((line) => (
              <Tr key={uuid()}>
                {line.map((lineValue, index) => (
                  <Td key={uuid()}>{renderLineValue(lineValue, index)}</Td>
                ))}
              </Tr>
            ))
          )}
        </Tbody>
      </Table>
    </DataTableContainer>
  );
}
