import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';
import { darken } from 'polished';
import { BootstrapDropdown } from '../ui/Dropdown';
import { HIGLAN_BLUE_01, HIGLAN_GREEN_04 } from '~/style/constants';

const DROPDOWN_BUTTON_CSS = css`
  background-color: transparent !important;
  color: #7159c1 !important;
  font-weight: bold !important;
  border-color: #7159c1 !important;
`;

export const Container = styled.div<{ scroll: number }>`
  background: #fff;
  margin: 0 auto;
  width: 100%;
  position: fixed;
  z-index: 500;
  margin-bottom: 15px;
  padding: 0 10px;
  border-bottom: 1px solid #7159c1;
`;

export const Content = styled.div<{ mobileMenu: boolean }>`
  height: 84px;
  max-width: 1024px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  nav {
    display: flex;
    align-items: center;

    img {
      margin-right: 20px;
      padding-right: 20px;
      border-right: 1px solid #eee;
      width: 80px;
    }

    a {
      font-weight: bold;
      color: #7159c1;
      padding: 0 15px;
      border-right: 1px solid #eee;
      text-align: center;

      &:hover {
        color: ${darken(0.1, '#3b9eff')};
      }
    }

    a.admin {
      border-right: 0;
    }
  }
  aside {
    display: flex;
    align-items: center;

    a {
      &:hover {
        color: ${darken(0.1, '#3b9eff')};
      }
    }
  }

  .menu-toogle {
    display: none;
  }

  @media (max-width: 1024px) {
    .menu-item {
      display: none;
    }

    .menu-toogle {
      width: 40px;
      height: 38px;
      z-index: 10;
      display: block;
      cursor: pointer;
    }

    .one,
    .two,
    .three {
      background-color: ${(props) => (props.mobileMenu ? '#3b9eff' : '#7159c1')};
      height: 5px;
      width: 100%;
      margin: 6px auto;

      transition-duration: ${(props) => props.mobileMenu && '0.3s'};
    }

    aside {
      div:first-child {
        border-left: 0;
        margin-right: 5px;
      }
    }

    nav {
      position: relative;
    }

    .menu-mobile {
      position: absolute;
      width: 100%;
      height: auto;
      padding: 5px;
      top: 95px;
      left: 0px;
      display: ${(props) => (props.mobileMenu ? 'flex' : 'none')};
      flex-direction: column;

      background-color: #ecedef;

      a {
        padding: 10px;
        background-color: #7159c1;
        margin-bottom: 5px;
        border-radius: 5px;
        color: #fff;

        &:active {
          background-color: ${darken(0.1, '#3b9eff')};
        }
        &:hover {
          color: #fff;
        }
      }

      .triangle {
        position: absolute;
        top: -13px;
        left: 25px;

        .arrow-up {
          width: 0;
          height: 0;
          border-left: 14px solid transparent;
          border-right: 14px solid transparent;
          border-bottom: 14px solid #ecedef;
        }
      }
    }
  }
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-left: 20px;
  padding-left: 20px;
  border-left: 1px solid #eee;

  div {
    div {
      display: flex;
      align-items: center;
      justify-content: space-around;

      div {
        display: flex;
        flex-direction: column;
        padding: 5px;

        small {
          font-weight: bold;
        }
      }

      div.balance {
        color: ${HIGLAN_GREEN_04};
        margin-right: 0;
      }

      div.bonus {
        color: ${HIGLAN_BLUE_01};
      }
    }

    a {
      margin-top: 2px;
      font-size: 12;
      color: #999;
    }
  }
`;

export const StyledBootstrapDropdown = styled(BootstrapDropdown)`
  > button {
    ${DROPDOWN_BUTTON_CSS}

    &:active {
      ${DROPDOWN_BUTTON_CSS}
    }
  }

  div a {
    border: 0;
    background-color: transparent !important;
    padding: 0;
  }

  div a a {
    display: block;
    padding: 0 !important;
  }
`;

export const StyledNavLink = styled(NavLink)`
  position: relative;
`;
