import { useState } from 'react';
import uuid from 'react-uuid';
import { emptyFunction } from '~/shared/string-utils';
import { Modal } from '../ui/Modal';
import { ModalBodyContainer, TermsDescription, RuleText, TermsContainer, TermsText } from './styles';

const TERMS_TITLE = 'By using Higlan website you agree with the following simple rules:';
const RULES = [
  'It is not possible to withdraw funds from Higlan. All your rewards are automatically send to your own wallet. If you have balance/bonus in your account, you can transfer the funds to a different Higlan account.',
  `Higlan is not responsible for third part's issues, such as the rules from the coin's network/server providers.`,
  // `Don't try to abuse the free giveaway promotions by creating multiple accounts.`,
];

export function TermsContent() {
  return (
    <>
      <TermsDescription>{TERMS_TITLE}</TermsDescription>
      {RULES.map((rule, index) => {
        const ruleNumber = index + 1;

        return <RuleText key={uuid()}>{`${ruleNumber}. ${rule}`}</RuleText>;
      })}
      {/* <TermsDescription>
        If you are found breaking any of the above rules or trying to gain an unfair advantage to abuse the
        service, your account may be deleted and your account balance/bonus forfeited.
      </TermsDescription> */}
    </>
  );
}
function ModalBody() {
  return (
    <ModalBodyContainer>
      <TermsContent />
    </ModalBodyContainer>
  );
}

export function Terms() {
  const [shouldShowTermsModal, setShouldShowTermsModal] = useState(false);
  const handleShowTermsOfService = () => {
    setShouldShowTermsModal(true);
  };
  const handleHideModal = () => {
    setShouldShowTermsModal(false);
  };
  return (
    <TermsContainer>
      <TermsText onClick={handleShowTermsOfService}>TERMS OF SERVICE</TermsText>
      {shouldShowTermsModal && (
        <Modal
          show={true}
          title="TERMS OF SERVICE"
          bodyContent={<ModalBody />}
          onHide={handleHideModal}
          hasConfirmation={false}
          handleModalSubmit={emptyFunction}
          size="lg"
        />
      )}
    </TermsContainer>
  );
}
