import styled from 'styled-components/macro';

import {
  DASHED_BORDER,
  DISPLAY_FLEX_COLUMN_DEFAULT,
  DISPLAY_FLEX_LINE_DEFAULT,
  HIGLAN_BLUE_01,
  HIGLAN_PURPLE_01,
} from '~/style/constants';
import { mobileOnly } from '~/style/mixins';

export const FaucetContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  gap: 5px;
  text-align: center;
  padding: 5px;
`;

export const FaucetTitle = styled.div`
  font-size: 30px;
  color: ${HIGLAN_PURPLE_01};
  ${DISPLAY_FLEX_LINE_DEFAULT};
  gap: 10px;
`;

export const FaucetDescription = styled.div`
  margin-top: 30px;
  font-size: 18px;
`;

export const BonusText = styled.span`
  color: ${HIGLAN_BLUE_01};
`;

export const CaptchaContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT};
  gap: 17px;
`;

export const FreeBonusHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  gap: 5px;
`;

export const Earned = styled.div`
  color: ${HIGLAN_BLUE_01};
  ${DISPLAY_FLEX_LINE_DEFAULT};
  gap: 5px;
`;

export const CaptchaText = styled.div``;

export const FreeBonusRate = styled.div``;

export const FreeBonusContainer = styled.div`
  ${DASHED_BORDER};
  padding: 10px;
  width: 500px;
  height: 500px;
  margin-top: 15px;
  ${DISPLAY_FLEX_COLUMN_DEFAULT};
  justify-content: space-between;

  ${mobileOnly`
    width: 100%;
  `}
`;

export const CountdownContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT};
  gap: 7px;
`;

export const TimeLeftText = styled.div`
  font-weight: bold;
  font-size: 16px;
`;

export const AdSenseContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT};
  height: 100%;
  width: 100%;
`;
