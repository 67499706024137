import { NodeOutputs } from '~/components/NodeOutputs';
import { MainContent } from '../Home';
import { PublicToolsContainer } from './styles';

export function PublicTools() {
  return (
    <MainContent>
      <PublicToolsContainer>
        <NodeOutputs />
      </PublicToolsContainer>
    </MainContent>
  );
}
