import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  height: auto;
  min-height: 100%;
  background: linear-gradient(-90deg, #7159c1, #ab59c1);
`;

export const Placeholder = styled.div`
  min-height: 25px;
`;

export const Container = styled.div<{ larger?: boolean }>`
  max-width: ${({ larger }) => larger ? '1100px' : '900px'};
  min-height: 650px;
  margin: 0px auto;
  margin-bottom: 15px;
  margin-bottom: 15px;
  background: linear-gradient(to bottom, #fff 5%, #ecedef 100%);
  padding: 15px;
  border-radius: 10px;
`;

export const Main = styled.div`
  padding: 0 10px;
  margin-top: 80px;
`;
