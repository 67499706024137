import { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Tabs, Tab, Table, Spinner } from 'react-bootstrap';
import { isBefore, isAfter, subDays, subWeeks, subMonths } from 'date-fns';
import Calendar from 'react-calendar';

import api from 'services/api';

import { Container } from 'pages/_layouts/default/styles';
import { Content } from './styles';
import {
  displayDefaultDateFormat,
  displayNodeAlias,
  displayPriceWithCurrency,
  formatDate,
  reduceKey,
  renderCellData,
} from '~/shared/string-utils';
import { ISupportedCoinsNames } from '~/store/modules/interfaces';
import uuid from 'react-uuid';

type IButtonValues = 'oneDay' | 'oneWeek' | 'customRange' | 'oneMonth' | 'allTime';

interface IMnDataSelected {
  fromDate: string | Date;
  toDate: string | Date;
}

interface IDeletedNodes {
  coin: ISupportedCoinsNames;
  alias: string;
  private_key_used: string;
  created_at: string;
}

interface IOnchainDeposit {
  hosted_url: string;
  network: string;
}

export default function History() {
  const [mnData, setMnData] = useState<any>([]);
  const [depositData, setDepositData] = useState<any>([]);
  const [deletedNodes, setDeletedNodes] = useState<IDeletedNodes[]>([]);
  const [loading, setLoading] = useState(true);
  const [calendarValues, setCalendarValues] = useState<any>();
  const [mnDataSelected, setMndDataSelected] = useState<any>([]);
  const [buttonSelected, setButtonSelected] = useState('allTime');
  const [showCalendar, setShowCalendar] = useState(false);
  const [dateSelected, setDateSelected] = useState<any>();

  const handleDateSelected = useCallback(
    ({ fromDate, toDate }: IMnDataSelected) => {
      const dateToUse: string[] = [];

      const fromDateObject = new Date(fromDate);
      const toDateObject = new Date(toDate);

      mnData.forEach((item: any) => {
        let { created_at } = item;
        created_at = new Date(created_at);

        if (isAfter(created_at, fromDateObject) && isBefore(created_at, toDateObject)) {
          dateToUse.push(item);
        }
      });

      setDateSelected({
        fromDate: displayDefaultDateFormat(fromDateObject),
        toDate: displayDefaultDateFormat(toDateObject),
      });
      setMndDataSelected(dateToUse);
    },
    [mnData]
  );

  useEffect(() => {
    const getHistoryData = async () => {
      try {
        const [chargeHistory, deletedNodeHistory, depositHistory] = await Promise.all([
          await api.get('history/masternodes'),
          await api.get('history/deleted_nodes'),
          await api.get('/history/deposits'),
        ]);
        setMnData(chargeHistory.data);
        setMndDataSelected(chargeHistory.data);
        setFromAndToDate(chargeHistory.data);

        setDeletedNodes(deletedNodeHistory.data);

        setDepositData(depositHistory.data);
      } catch (err) {
      } finally {
        setLoading(false);
      }
    };

    getHistoryData();
  }, []);

  function setFromAndToDate(chargesData: any) {
    let fromDate = new Date();
    const toDate = new Date();

    chargesData.forEach((item: any) => {
      let { created_at } = item;
      created_at = new Date(created_at);

      if (isBefore(created_at, fromDate)) fromDate = created_at;
    });

    setDateSelected({
      fromDate: displayDefaultDateFormat(fromDate),
      toDate: displayDefaultDateFormat(toDate),
    });
  }

  useEffect(() => {
    if (calendarValues && calendarValues.length > 0) {
      const [fromDate, toDate] = calendarValues;

      setShowCalendar(false);
      handleDateSelected({ fromDate, toDate });
    }
  }, [calendarValues, handleDateSelected]);

  function handleClickButton(button: IButtonValues) {
    const rightNow = new Date();

    if (showCalendar && button !== 'customRange') {
      setShowCalendar(false);
    }

    if (button === 'oneDay') {
      setButtonSelected(button);
      handleDateSelected({
        fromDate: subDays(rightNow, 1),
        toDate: rightNow,
      });
    } else if (button === 'oneWeek') {
      setButtonSelected(button);
      handleDateSelected({
        fromDate: subWeeks(rightNow, 1),
        toDate: rightNow,
      });
    } else if (button === 'oneMonth') {
      setButtonSelected(button);
      handleDateSelected({
        fromDate: subMonths(rightNow, 1),
        toDate: rightNow,
      });
    } else if (button === 'allTime') {
      setMndDataSelected(mnData);
      setButtonSelected(button);
      setFromAndToDate(mnData);
    } else if (button === 'customRange') {
      setButtonSelected(button);
      setShowCalendar(!showCalendar);
    }
  }

  const displayMethod = (coin: string, onchain_deposit: IOnchainDeposit | null) => {
    if (!onchain_deposit) {
      return String(coin).toUpperCase();
    }

    const { network } = onchain_deposit;

    return String(`${coin} (${network})`).toUpperCase();
  };

  return (
    <Container>
      <Content>
        <Tabs defaultActiveKey="charges">
          <Tab eventKey="charges" title="Charges">
            {mnData.length !== 0 ? (
              <>
                <div className="dateTime">
                  <div className="dateButtons">
                    <button
                      className={buttonSelected === 'oneDay' ? 'activeButton' : 'normalButton'}
                      onClick={() => handleClickButton('oneDay')}
                    >
                      1 Day
                    </button>
                    <button
                      className={buttonSelected === 'oneWeek' ? 'activeButton' : 'normalButton'}
                      onClick={() => handleClickButton('oneWeek')}
                    >
                      1 Week
                    </button>
                    <button
                      className={buttonSelected === 'oneMonth' ? 'activeButton' : 'normalButton'}
                      onClick={() => handleClickButton('oneMonth')}
                    >
                      1 Month
                    </button>
                    <button
                      className={buttonSelected === 'allTime' ? 'activeButton' : 'normalButton'}
                      onClick={() => handleClickButton('allTime')}
                    >
                      All Time
                    </button>
                    <button
                      className={buttonSelected === 'customRange' ? 'activeButton' : 'normalButton'}
                      onClick={() => handleClickButton('customRange')}
                    >
                      Custom Range
                    </button>
                  </div>
                  {buttonSelected === 'customRange' && showCalendar && (
                    <Calendar
                      value={calendarValues}
                      onChange={setCalendarValues}
                      selectRange
                      className="calendar"
                      maxDate={new Date()}
                    />
                  )}
                </div>
                <div>
                  <strong>{dateSelected && `${dateSelected.fromDate} - ${dateSelected.toDate}`}</strong>
                </div>
                <Table striped size="sm" responsive>
                  <thead>
                    <tr>
                      <th>Coin</th>
                      <th>Alias</th>
                      <th>Price</th>
                      <th>Months</th>
                      <th>Created at</th>
                    </tr>
                  </thead>
                  <tbody>
                    {mnDataSelected.length > 0 ? (
                      mnDataSelected.map((h: any) => (
                        <tr key={h.id + Math.random()}>
                          <td>{`${String(h.masternode.coin).toUpperCase()}`}</td>
                          <td>{`node${h.masternode.alias}`}</td>
                          <td>{displayPriceWithCurrency(h.price_paid)}</td>
                          <td>{h.months}</td>
                          <td>{displayDefaultDateFormat(new Date(h.created_at))}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={5}>No activity.</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </>
            ) : loading ? (
              <Spinner animation="border" />
            ) : (
              <small>
                You haven&lsquo;t built any node yet. Fast and simple, <Link to="/nodes">start now!</Link>
              </small>
            )}
          </Tab>
          <Tab eventKey="deposits" title="Deposits">
            {depositData.length !== 0 ? (
              <Table striped size="sm" responsive>
                <thead>
                  <tr>
                    <th>Method</th>
                    {/* <th>Address</th> */}
                    <th>Amount</th>
                    <th>Credited</th>
                    <th>Received at</th>
                  </tr>
                </thead>
                <tbody>
                  {depositData.map((h: any) => (
                    <tr key={h.address.id + Math.random()}>
                      <td>{displayMethod(h.address.coin, h.address.onchain_deposit)}</td>
                      <td>
                        {`${h.address.coin === 'PayPal' ? '-' : `${h.address.amount_received} ${h.address.coin}`}`}
                      </td>
                      <td>{displayPriceWithCurrency(h.address.dollar_amount)}</td>
                      <td>{formatDate(new Date(h.created_at))}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <small>
                You haven&lsquo;t made any deposits yet. Fast and simple, <Link to="/addfunds">start now!</Link>
              </small>
            )}
          </Tab>
          <Tab eventKey="deleted-nodes" title="Deleted Nodes">
            {deletedNodes.length !== 0 ? (
              <Table striped size="sm" responsive>
                <thead>
                  <tr>
                    <th>Coin</th>
                    <th>Alias</th>
                    <th>Node Key</th>
                    <th>Deleted at</th>
                  </tr>
                </thead>
                <tbody>
                  {deletedNodes.map((deletedNode) => (
                    <tr key={uuid()}>
                      <td>{renderCellData(deletedNode.coin.toUpperCase())}</td>
                      <td>{renderCellData(displayNodeAlias(deletedNode.alias))}</td>
                      <td>{renderCellData(reduceKey(deletedNode.private_key_used))}</td>
                      <td>{renderCellData(displayDefaultDateFormat(new Date(deletedNode.created_at)))}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <small>No activity.</small>
            )}
          </Tab>
        </Tabs>
      </Content>
    </Container>
  );
}
