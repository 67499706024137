import { Tabs, Tab } from 'react-bootstrap';

import { Container } from 'pages/_layouts/default/styles';

import { PaymentMethods, SUPPORTED_PAYMENT_METHODS } from './PaymentMethods';

const getDefaultActiveKey = () => {
  let activeKey = '';

  const paymentMethodsValues = Object.values(SUPPORTED_PAYMENT_METHODS);

  for (const paymentMethod of paymentMethodsValues) {
    const { isDisabled, title } = paymentMethod;

    if (!isDisabled) {
      activeKey = title;
      break;
    }
  }

  return activeKey;
};
export default function Addfunds() {
  return (
    <Container>
      <Tabs defaultActiveKey={getDefaultActiveKey()}>
        <Tab
          eventKey={SUPPORTED_PAYMENT_METHODS.onchain.title}
          title={SUPPORTED_PAYMENT_METHODS.onchain.title}
          disabled={SUPPORTED_PAYMENT_METHODS.onchain.isDisabled}
        >
          <PaymentMethods currencyData={SUPPORTED_PAYMENT_METHODS.onchain} />
        </Tab>
        <Tab
          eventKey={SUPPORTED_PAYMENT_METHODS.btc.title}
          title={SUPPORTED_PAYMENT_METHODS.btc.title}
          disabled={SUPPORTED_PAYMENT_METHODS.btc.isDisabled}
        >
          <PaymentMethods currencyData={SUPPORTED_PAYMENT_METHODS.btc} />
        </Tab>
        <Tab
          eventKey={SUPPORTED_PAYMENT_METHODS.ltc.title}
          title={SUPPORTED_PAYMENT_METHODS.ltc.title}
          disabled={SUPPORTED_PAYMENT_METHODS.ltc.isDisabled}
        >
          <PaymentMethods currencyData={SUPPORTED_PAYMENT_METHODS.ltc} />
        </Tab>
        <Tab
          eventKey={SUPPORTED_PAYMENT_METHODS.bdx.title}
          title={SUPPORTED_PAYMENT_METHODS.bdx.title}
          disabled={SUPPORTED_PAYMENT_METHODS.bdx.isDisabled}
        >
          <PaymentMethods currencyData={SUPPORTED_PAYMENT_METHODS.bdx} />
        </Tab>
        <Tab
          eventKey={SUPPORTED_PAYMENT_METHODS.prcy.title}
          title={SUPPORTED_PAYMENT_METHODS.prcy.title}
          disabled={SUPPORTED_PAYMENT_METHODS.prcy.isDisabled}
        >
          <PaymentMethods currencyData={SUPPORTED_PAYMENT_METHODS.prcy} />
        </Tab>

        <Tab
          eventKey={SUPPORTED_PAYMENT_METHODS.paypal.title}
          title={SUPPORTED_PAYMENT_METHODS.paypal.title}
          disabled={SUPPORTED_PAYMENT_METHODS.paypal.isDisabled}
        >
          <PaymentMethods currencyData={SUPPORTED_PAYMENT_METHODS.paypal} />
        </Tab>
      </Tabs>
    </Container>
  );
}
