import styled from 'styled-components/macro';
import { DISPLAY_FLEX_COLUMN_DEFAULT, HIGLAN_WHITE_01 } from 'style/constants';

export const Form = styled.form`
  margin-top: 10px;
  color: ${HIGLAN_WHITE_01};
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  gap: 7px;

  button {
    width: 100%;
  }
`;

export const EmailText = styled.strong``;

export const Title = styled.strong`
  font-size: 20px;
`;
