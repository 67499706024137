import styled from 'styled-components/macro';
import { DISPLAY_FLEX_LINE_DEFAULT } from '~/style/constants';

export const TermsContainer = styled.div`
  ${DISPLAY_FLEX_LINE_DEFAULT}
  margin-top: 10px;
`;

export const TermsText = styled.div`
  color: #fff;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    text-decoration: underline;
  }
`;

export const ModalBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  gap: 25px;
`;

export const TermsDescription = styled.div`
  text-align: center;
`;

export const RuleText = styled.div`
  text-align: left;
  width: 100%;
`;
