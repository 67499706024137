import { AiFillGithub } from 'react-icons/ai';
import { FiMonitor } from 'react-icons/fi';
import createDisplay from 'number-display';

import {
  Container,
  CoinName,
  CoinInfoContainer,
  ItemContainer,
  ItemLabel,
  ItemDescription,
  Header,
  Footer,
  IconLink,
  FrozenWrapper,
  FrozenText,
  IconWrapper,
  TextWithLine,
  PriceContainer,
  NormalText,
  FreeNodeText,
  MonthLabelContainer,
  FreeNodeContainer,
} from './styles';
import { CoinLogo } from 'components/CoinLogo';
import { LabelNew } from '~/components/LabelNew';
import { displayPriceWithCurrency } from 'shared/string-utils';
import { ISupportedCoins } from '~/store/modules/interfaces';
import { Tooltip } from '../Tooltip';

interface INodeCard {
  className?: string;
  onClick?: (value: any) => void;
  supportedCoin: ISupportedCoins;
  shouldUseWhiteHover?: boolean;
}

interface IDisplayTotalPrice {
  hasMonthLabel?: boolean;
  oldPrice: number;
  newPrice: number;
  isFreePrice: boolean;
}

export const displayTotalPrice = ({ hasMonthLabel = false, oldPrice, newPrice, isFreePrice }: IDisplayTotalPrice) => {
  if (hasMonthLabel && isFreePrice) {
    return (
      <PriceContainer>
        <TextWithLine>{displayPriceWithCurrency(oldPrice)}</TextWithLine>
        <NormalText>/</NormalText>
        <NormalText isGreen>{`${displayPriceWithCurrency(newPrice)}`}</NormalText>
        {hasMonthLabel && (
          <MonthLabelContainer>
            <NormalText isGreen>{`(First Month)`}</NormalText>
          </MonthLabelContainer>
        )}
      </PriceContainer>
    );
  }

  if (!hasMonthLabel && isFreePrice) {
    return (
      <PriceContainer>
        <TextWithLine>{displayPriceWithCurrency(oldPrice)}</TextWithLine>
        <NormalText>/</NormalText>
        <NormalText isGreen>{`${displayPriceWithCurrency(newPrice)}`}</NormalText>
      </PriceContainer>
    );
  }

  return <NormalText>{displayPriceWithCurrency(newPrice)}</NormalText>;
};

export function NodeCard({
  className = 'masternode-card',
  supportedCoin,
  onClick,
  shouldUseWhiteHover = false,
}: INodeCard) {
  const { coin, collateral, display_name, github_url, is_active, is_new, website_url, price, is_free_price } =
    supportedCoin;

  const displayNumbers = createDisplay({
    length: 4,
    decimal: 0,
  });

  const onIconClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
  };

  return (
    <Container onClick={onClick} className={className} isFrozen={!is_active} shouldUseWhiteHover={shouldUseWhiteHover}>
      {!is_active && (
        <FrozenWrapper>
          <FrozenText>
            {'Frozen due to'}
            <br />
            {'Blockchain issues'}
          </FrozenText>
        </FrozenWrapper>
      )}
      {is_new && <LabelNew />}
      <Header>
        <CoinLogo coin={coin} />
        <CoinName>{display_name}</CoinName>
      </Header>
      <FreeNodeContainer isVisible={is_free_price}>
        <FreeNodeText>FREE NODES IN THE FIRST MONTH!</FreeNodeText>
      </FreeNodeContainer>
      <CoinInfoContainer>
        <ItemContainer>
          <ItemLabel>Collateral</ItemLabel>
          <ItemDescription>{displayNumbers(collateral)}</ItemDescription>
        </ItemContainer>
        <ItemContainer>
          <ItemLabel>Price</ItemLabel>

          {is_free_price ? (
            <>
              {displayTotalPrice({
                oldPrice: Number(price),
                newPrice: 0,
                isFreePrice: is_free_price,
              })}
            </>
          ) : (
            <ItemDescription>{displayPriceWithCurrency(price)}</ItemDescription>
          )}
        </ItemContainer>
      </CoinInfoContainer>
      <Footer>
        <IconWrapper>
          <Tooltip content="Github">
            <IconLink onClick={onIconClick} href={github_url} target="_blank">
              <AiFillGithub size={25} />
            </IconLink>
          </Tooltip>
        </IconWrapper>
        <IconWrapper>
          <Tooltip content="Website">
            <IconLink onClick={onIconClick} href={website_url} target="_blank">
              <FiMonitor size={25} />
            </IconLink>
          </Tooltip>
        </IconWrapper>
      </Footer>
    </Container>
  );
}
