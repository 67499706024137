import uuid from 'react-uuid';
import { ItemContainer, ItemValue, LineDataContainer } from './styles';

interface IItem {
  itemLabel: string;
  itemValue: string | number;
}

interface ILineData {
  items: IItem[];
}

export function LineData({ items }: ILineData) {
  return (
    <>
      {items.map(({ itemLabel, itemValue }, index) => {
        const hasBackground = index % 2 === 0;

        if (!itemLabel || !itemValue) {
          return null;
        }

        return (
          <LineDataContainer hasBackground={hasBackground} key={uuid()}>
            <ItemContainer>
              <ItemValue>{itemLabel}</ItemValue>
              <ItemValue>{itemValue}</ItemValue>
            </ItemContainer>
          </LineDataContainer>
        );
      })}
    </>
  );
}
