import socketio from 'socket.io-client';
import { IAdminData, IFaucetHistory, INodeData } from 'store/modules/interfaces';
import {
  IReceiveFunds,
  receiveFunds as receiveFundsAction,
  setAdminData,
  setNodeData,
  setUserBalances,
  showToastMessageAction,
} from 'store/modules/user/actions';
import { setAllTimeEarned, setFaucetBalance, setFaucetHistory } from '~/store/modules/faucet/actions';

import { API_URL } from './api';

const socket = socketio(API_URL, {
  autoConnect: false,
});
interface ISocketEventNames {
  UPDATE_ADMIN_DATA: string;
  UPDATE_NODE_DATA: string;
  UPDATE_BALANCES: string;
  RECEIVE_FUNDS: string;
  UPDATE_FAUCET_BALANCE: string;
  UPDATE_FAUCET_HISTORY: string;
  UPDATE_FAUCET_ALL_TIME_EARNED: string;
  SHOW_TOAST_MESSAGE: string;
}

const SOCKET_EVENT_NAMES: ISocketEventNames = {
  UPDATE_ADMIN_DATA: 'update-admin-data',
  UPDATE_NODE_DATA: 'update-node-data',
  UPDATE_BALANCES: 'update-balances',
  RECEIVE_FUNDS: 'receive-funds',
  UPDATE_FAUCET_BALANCE: 'update-faucet-balance',
  UPDATE_FAUCET_HISTORY: 'update-faucet-history',
  UPDATE_FAUCET_ALL_TIME_EARNED: 'update-faucet-all-time-earned',
  SHOW_TOAST_MESSAGE: 'show-toast-message',
};

interface IUpdateBalancesData {
  balance: number;
  bonus: number;
}

export interface IShowToastMessage {
  message: string;
  hasError: boolean;
  autoClose?: number;
}

interface IConnectData {
  userId: number;
  userName: string;
  userEmail: string;
  isAdmin: boolean;
  lastActive: number;
  dispatch: Function;
}

function connect({ userId, userName, userEmail, lastActive, dispatch, isAdmin }: IConnectData) {
  if (!socket.connected) {
    socket.io.opts.query = {
      userId,
      userName,
      isAdmin: isAdmin || false,
      userEmail,
      lastActive,
    };

    socket.on('connect', () => {
      const socketId = socket.id;
      startSocketListeners(dispatch, isAdmin, socketId);
    });

    socket.connect();
  } else {
    disconnect();
  }
}

function startSocketListeners(dispatch: Function, isAdmin: boolean, socketId: string) {
  updateBalances(dispatch);
  updateNodeData(dispatch);
  receiveFunds(dispatch);
  updateUserFaucetBalance(dispatch);
  updateUserFaucetHistory(dispatch);
  updateFaucetAllTimeEarned(dispatch);
  showToastMessage(dispatch, socketId);

  if (isAdmin) {
    updateAdminData(dispatch);
  }
}

function disconnect() {
  if (socket.connected) {
    socket.removeAllListeners();
    socket.disconnect();
  }
}

function updateAdminData(dispatch: Function) {
  socket.on(SOCKET_EVENT_NAMES.UPDATE_ADMIN_DATA, (data: IAdminData) => {
    if (data) {
      dispatch(setAdminData(data));
    }
  });
}

function updateNodeData(dispatch: Function) {
  socket.on(SOCKET_EVENT_NAMES.UPDATE_NODE_DATA, (data: INodeData) => {
    const routeLocation = window.location.pathname;
    const isAtAdminRoute = routeLocation === '/admin_v2';
    const isAtProfileRoute = routeLocation === '/profile';
    const shouldUpdateNodeData = !isAtAdminRoute && !isAtProfileRoute;
    if (data && shouldUpdateNodeData) {
      dispatch(setNodeData(data));
    }
  });
}

function disconnectNodeData() {
  socket.off(SOCKET_EVENT_NAMES.UPDATE_NODE_DATA);
}

function updateBalances(dispatch: Function) {
  socket.on(SOCKET_EVENT_NAMES.UPDATE_BALANCES, ({ balance, bonus }: IUpdateBalancesData) => {
    dispatch(setUserBalances({ balance, bonus }));
  });
}

function updateUserFaucetBalance(dispatch: Function) {
  socket.on(SOCKET_EVENT_NAMES.UPDATE_FAUCET_BALANCE, (faucetBalance: number) => {
    dispatch(setFaucetBalance(faucetBalance));
  });
}

function updateFaucetAllTimeEarned(dispatch: Function) {
  socket.on(SOCKET_EVENT_NAMES.UPDATE_FAUCET_ALL_TIME_EARNED, (faucetAllTimeEarned: number) => {
    dispatch(setAllTimeEarned(faucetAllTimeEarned));
  });
}

function updateUserFaucetHistory(dispatch: Function) {
  socket.on(SOCKET_EVENT_NAMES.UPDATE_FAUCET_HISTORY, (faucetHistory: IFaucetHistory[]) => {
    dispatch(setFaucetHistory(faucetHistory));
  });
}

function showToastMessage(dispatch: Function, socketId: string) {
  const namedMessageForToastMessage = `${SOCKET_EVENT_NAMES.SHOW_TOAST_MESSAGE}-${socketId}`;
  socket.on(namedMessageForToastMessage, (showToastMessageData: IShowToastMessage) => {
    dispatch(showToastMessageAction(showToastMessageData));
  });
}

export function receiveFunds(dispatch: Function) {
  socket.on(
    SOCKET_EVENT_NAMES.RECEIVE_FUNDS,
    ({ currencyName, currencyAmount, payment_id, valueReceived }: IReceiveFunds) => {
      dispatch(
        receiveFundsAction({
          currencyName,
          payment_id,
          valueReceived,
          currencyAmount,
        })
      );
    }
  );
}

export { connect, disconnect, updateAdminData, updateNodeData, disconnectNodeData, updateBalances };
