import { IState } from 'store/modules/interfaces';

export const getUserNodeData = ({ user }: IState) => user.nodeData;

export const getUserProfile = ({ user }: IState) => user.profile;

export const isLoadingUser = ({ user }: IState) => user.loading;

export const isSendingPromoCode = ({ user }: IState) => user.profile.isSendingPromoCode;

export const getUserBalances = ({ user }: IState) => user.profile.balances;

export const getAdminData = ({ user }: IState) => user.adminData;

export const getAdminCode = ({ user }: IState) => user.adminCode;

export const getIsAdminCode = ({ user }: IState) => user.isAdminCodeValid;

export const getIsSubmittingAdminCode = ({ user }: IState) => user.isSubmittingAdminCode;

export const getIsLoadingForResetIndexToDelete = ({ user }: IState) => user.isLoadingForResetIndexToDelete;

export const getIsRefreshingAdminDataSelector = ({ user }: IState) => user.isRefreshingAdminData;

export const getIsLoadingDifferentAccountVerification = ({ user }: IState) =>
  user.isLoadingDifferentAccountVerification;

export const getIsLoadingConfirmTransferFunds = ({ user }: IState) => user.isLoadingConfirmTransferFunds;

export const getIsLoadingChargeNodes = ({ user }: IState) => user.isLoadingChargeNodes;

export const getIsLoadingNodeDeletion = ({ user }: IState) => user.isLoadingNodeDeletion;

export const getIsLoadingReadyNodeDeletion = ({ user }: IState) => user.isLoadingReadyNodeDeletion;

export const getIsLoadingChangeEmail = ({ user }: IState) => user.isLoadingChangeEmail;

export const getEmailChangeValues = ({ user }: IState) => {
  const { newEmail, confirmNewEmail } = user;

  return {
    newEmail,
    confirmNewEmail,
  };
};

export const getIsSomeMountingRunning = ({ user }: IState) => {
  const isSomeMountingRunning = user.adminData.allServers.servers.find((server) => server.is_mounting);

  return !!isSomeMountingRunning;
};

export const getDifferentAccountToTransferFunds = ({ user }: IState) => user.differentAccountToTransferFunds;

export const getShouldShowTransferFundsCodeField = ({ user }: IState) => user.shouldShowTransferFundsCodeField;

export const getShouldShowEmailChangeCodeField = ({ user }: IState) => user.shouldShowEmailChangeCodeField;

export const getRestorationLoadingId = ({ user }: IState) => user.restorationLoadingId;

export const getIsDeletingDecommissionData = ({ user }: IState) => user.isDeletingDecommissionData;

export const getIsLoadingOnchainPaymentLink = ({ user }: IState) => user.isLoadingOnchainPaymentLink;

export const getOnchainPaymentLinkData = ({ user }: IState) => {
  const { onchainPaymentLinkData } = user;

  if (!onchainPaymentLinkData) {
    return {
      hosted_url: '',
      expires_at: '',
    };
  }

  return onchainPaymentLinkData;
};

export const getLoadingIdsForDecommissionItems = ({ user }: IState) => user.loadingIdsForDeletingDecommissionItems;

export const getNodesThatCanBeRestored = ({ user }: IState) => {
  const { nodesThatCanBeRestored } = user.nodeData;

  return {
    allNodesThatCanBeRestored: nodesThatCanBeRestored,
    hasNodesThatCanBeRestored: nodesThatCanBeRestored.length > 0,
  };
};

export const getShouldShowPromotionModalForSignedUser = ({ user }: IState) => user.nodeData.shouldShowPromotionModal;

export const getShouldShowPromotionModalForNotSignedUser = ({ app }: IState) => {
  const { supportedCoins } = app;

  const hasAnyPromotionActive = supportedCoins.find((coin) => coin.is_free_price);

  return !!hasAnyPromotionActive;
};

export const getAnyPromotionActiveForFreeNodes = ({ app }: IState) => {
  const { supportedCoins } = app;

  const anyPromotionActive = supportedCoins.find((coin) => coin.is_free_price);

  if (anyPromotionActive) {
    const { free_price_code } = anyPromotionActive;

    return free_price_code;
  }

  return '';
};
