import { MainContent } from '../Home';
import { FreeNodeRules } from '../NodeSteps/CreateOrder';
import { FreeNodesRulesContainer, RulesTitle } from './styles';

export function FreeNodesRules() {
  return (
    <MainContent>
      <FreeNodesRulesContainer>
        <RulesTitle>FREE NODE RULES</RulesTitle>
        <FreeNodeRules />
      </FreeNodesRulesContainer>
    </MainContent>
  );
}
