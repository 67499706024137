import { useEffect, useState } from 'react';
import { Chart, DatasetItem } from '~/components/ui/Chart';
import { CURRENCY_SYMBOL, displayPriceWithCurrency, getDistinctRGBAColors } from '~/shared/string-utils';
import { IAnnualDeposits } from '~/store/modules/interfaces';
import { DepositChartContainer, ItemSelectorContainer, ChartItemSelector } from './styles';

interface DepositChartProps {
  annualDeposits: IAnnualDeposits[];
}

type IDatasetType = 'coins' | 'worthTotals';

export function DepositChart({ annualDeposits }: DepositChartProps) {
  const currentYear = new Date().getFullYear();
  const [chartYear, setChartYear] = useState(currentYear);
  const [chartDatasetItems, setChartDatasetItems] = useState<IDatasetType>('worthTotals');
  const [chartDatasetType, setChartDatasetType] = useState<'fiat' | 'cryptoAmount'>('fiat');
  const [allChartYears, setAllChartYears] = useState<number[]>([]);
  const scaleYTextInitialValue = `${CURRENCY_SYMBOL} Amount`;
  const [scaleYText, setScaleYText] = useState(scaleYTextInitialValue);
  const scaleXText = 'Months of The Year';

  const getSelectedYear = () => {
    const selectedYear = annualDeposits.find((item) => item.year === chartYear);
    return selectedYear;
  };

  const getChartTitle = () => {
    const selectedYear = getSelectedYear();
    const yearTotal = displayPriceWithCurrency(selectedYear!.yearTotal);
    return `${chartYear} Deposits | Worth Total: ${yearTotal}`;
  };

  const getChartCoinDatasets = () => {
    const selectedYear = getSelectedYear();
    const allChartItemLabels: string[] = [];
    const datasets: DatasetItem[] = [];

    selectedYear!.months.forEach((monthItem) => {
      monthItem.deposits.forEach((depositItem) => {
        const { coin } = depositItem;
        const isCoinAlreadyAdded = allChartItemLabels.indexOf(coin) !== -1;
        if (!isCoinAlreadyAdded) {
          allChartItemLabels.push(coin);
        }
      });
    });

    allChartItemLabels.forEach((coinItem, index) => {
      const allValuesForCoinItem: any[] = [];

      selectedYear!.months.forEach((monthItem, monthItemIndex) => {
        const { deposits } = monthItem;

        deposits.forEach((depositItem) => {
          const { coin, coinTotal, worthTotal } = depositItem;

          if (coinItem === coin) {
            const valueToPush = chartDatasetType === 'fiat' ? worthTotal : coinTotal;
            allValuesForCoinItem[monthItemIndex] = valueToPush;
          }
        });
      });
      const itemToPush = {
        label: coinItem,
        data: allValuesForCoinItem,
        backgroundColor: getDistinctRGBAColors(index),
      };

      datasets.push(itemToPush);
    });
    return datasets;
  };

  const getChartMonthTotalsDatasets = () => {
    const selectedYear = getSelectedYear();
    const UNIQUE_LABEL = `Total Received (${CURRENCY_SYMBOL})`;
    const allChartItemLabels: string[] = [UNIQUE_LABEL];
    const datasets: DatasetItem[] = [];

    allChartItemLabels.forEach((_, index) => {
      const allValuesForCoinItem: any = [];
      const backgroundColor = getDistinctRGBAColors(index);

      selectedYear!.months.forEach((monthItem) => {
        const { monthTotal } = monthItem;
        allValuesForCoinItem.push(monthTotal);
      });

      const itemToPush = {
        label: UNIQUE_LABEL,
        data: allValuesForCoinItem,
        backgroundColor,
      };

      datasets.push(itemToPush);
    });
    return datasets;
  };

  const getChartLabels = () => {
    const selectedYear = getSelectedYear();
    const labels = selectedYear!.months.map((month) => month.month);
    return labels;
  };

  useEffect(() => {
    const allYearsForChart = annualDeposits.map((item) => {
      const { year } = item;
      return year;
    });

    setAllChartYears(allYearsForChart);
  }, []);

  useEffect(() => {
    if (chartDatasetType === 'cryptoAmount') {
      setScaleYText(`Crypto Amount`);
    } else {
      setScaleYText(scaleYTextInitialValue);
    }
  }, [chartDatasetType]);

  const onSetDatasetType = (datasetType: IDatasetType) => {
    switch (datasetType) {
      case 'coins': {
        setChartDatasetItems('coins');
        break;
      }
      case 'worthTotals': {
        setChartDatasetItems('worthTotals');
        setScaleYText(scaleYTextInitialValue);
        break;
      }
    }
  };

  return (
    <DepositChartContainer>
      <ItemSelectorContainer>
        {allChartYears.map((year) => (
          <ChartItemSelector key={year} onClick={() => setChartYear(year)} isSelected={chartYear === year}>
            {year}
          </ChartItemSelector>
        ))}
      </ItemSelectorContainer>
      <ItemSelectorContainer>
        <ChartItemSelector isSelected={chartDatasetItems === 'coins'} onClick={() => onSetDatasetType('coins')}>
          COINS
        </ChartItemSelector>
        <ChartItemSelector
          isSelected={chartDatasetItems === 'worthTotals'}
          onClick={() => onSetDatasetType('worthTotals')}
        >
          WORTH TOTALS
        </ChartItemSelector>
      </ItemSelectorContainer>
      {chartDatasetItems === 'coins' && (
        <ItemSelectorContainer>
          <ChartItemSelector isSelected={chartDatasetType === 'fiat'} onClick={() => setChartDatasetType('fiat')}>
            FIAT VALUE
          </ChartItemSelector>
          <ChartItemSelector
            isSelected={chartDatasetType === 'cryptoAmount'}
            onClick={() => setChartDatasetType('cryptoAmount')}
          >
            CRYPTO VALUE
          </ChartItemSelector>
        </ItemSelectorContainer>
      )}
      <Chart
        chartTitle={getChartTitle()}
        labels={getChartLabels()}
        datasets={chartDatasetItems === 'coins' ? getChartCoinDatasets() : getChartMonthTotalsDatasets()}
        scaleXText={scaleXText}
        scaleYText={scaleYText}
      />
    </DepositChartContainer>
  );
}
