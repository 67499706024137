import { ReactNode } from 'react';
import { StyledUnformForm } from './styles';

interface IUnformForm {
  children: ReactNode;
}

export function UnformForm({ children, ...props }: IUnformForm) {
  // @ts-ignore
  return <StyledUnformForm {...props}>{children}</StyledUnformForm>;
}
