import { ButtonHTMLAttributes, ReactNode } from 'react';
import { Spinner } from 'react-bootstrap';
import { Button } from './styles';

interface ISubmitButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  isLoading: boolean;
}

export function SubmitButton({ children, isLoading, ...props }: ISubmitButton) {
  return <Button {...props}>{isLoading ? <Spinner size="sm" animation="grow" /> : children}</Button>;
}
