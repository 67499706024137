import { toast } from 'react-toastify';
import { takeLatest, all, call, put } from 'redux-saga/effects';
import { pushLoadingAppDataRequest, spliceLoadingAppDataRequest } from '../app/actions';
import { IFaucetHistory } from '../interfaces';

import {
  faucetActionTypes,
  ISendFaucetCaptcha,
  IWithdraw,
  setAllTimeEarned,
  setFaucetBalance,
  setFaucetBaseValue,
  setFaucetHistory,
  setMinValueForWithdraw,
  setTimeForNextRound,
  startFaucetApiLoading,
  stopFaucetApiLoading,
  toggleWithdrawLoading,
} from './actions';
import { sendFaucetCaptchaRequest, getFaucetValuesRequest, withdrawRequest } from './api';

function* sendFaucetCaptchaSaga({ payload: captchaToken }: ISendFaucetCaptcha) {
  try {
    yield put(startFaucetApiLoading());
    const timeForNextRound: number = yield call(sendFaucetCaptchaRequest, captchaToken);
    yield put(setTimeForNextRound(timeForNextRound));
    toast.success('Successfully claimed!');
  } catch (err) {
    yield put(setTimeForNextRound(0));
    const response: any = { err };

    const error = response.err.response?.data?.error;

    toast.error(error);
  } finally {
    yield put(stopFaucetApiLoading());
  }
}

function* getFaucetBaseValueSaga() {
  const requestName = 'getFaucetBaseValueSaga';
  try {
    yield put(pushLoadingAppDataRequest(requestName));
    const faucetValues: {
      faucetBaseValue: number;
      faucetBalance: number;
      timeForNextRound: number;
      allTimeEarned: number;
      faucetHistory: IFaucetHistory[];
      minValueForWithdraw: number;
    } = yield call(getFaucetValuesRequest);
    const { faucetBalance, faucetBaseValue, timeForNextRound, faucetHistory, minValueForWithdraw, allTimeEarned } =
      faucetValues;
    yield put(setFaucetBaseValue(faucetBaseValue));
    yield put(setFaucetBalance(faucetBalance));
    yield put(setFaucetHistory(faucetHistory));
    yield put(setAllTimeEarned(allTimeEarned));
    yield put(setMinValueForWithdraw(minValueForWithdraw));
    const timeForNextRoundTimestamp = new Date(timeForNextRound).getTime();
    yield put(setTimeForNextRound(timeForNextRoundTimestamp));
  } catch (err) {
  } finally {
    yield put(spliceLoadingAppDataRequest(requestName));
  }
}

function* withdrawSaga({ payload }: IWithdraw) {
  const { onHideModal } = payload;
  try {
    yield put(toggleWithdrawLoading());
    yield call(withdrawRequest);
    toast.success('Successfully executed the withdraw!');
    onHideModal();
  } catch (err) {
    const response: any = { err };

    const error = response.err.response?.data?.error;

    toast.error(error);
  } finally {
    yield put(toggleWithdrawLoading());
  }
}

export default all([
  takeLatest(faucetActionTypes.SEND_FAUCET_CAPTCHA, sendFaucetCaptchaSaga),
  takeLatest(faucetActionTypes.GET_FAUCET_VALUES, getFaucetBaseValueSaga),
  takeLatest(faucetActionTypes.WITHDRAW, withdrawSaga),
]);
