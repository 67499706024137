import { toast } from 'react-toastify';
import { displayPriceWithCurrency } from '~/shared/string-utils';
import { IReceiveFunds } from '../actions';

import { MessageContainer, NormalText, BolderText, ResetIndexMessageContainer, DivElement } from './styles';

interface IResetIndexMessage {
  allNodes: number;
  resetCount: number;
}

function ReceiveFundsMessage({ currencyAmount, currencyName, valueReceived }: IReceiveFunds) {
  return (
    <MessageContainer>
      <NormalText>
        {'We successfully received your payment: '}
        <BolderText>{`${currencyAmount} ${currencyName}.`}</BolderText>
      </NormalText>
      <NormalText>
        <BolderText>{displayPriceWithCurrency(valueReceived)}</BolderText>
        {` has been credited to your balance.`}
      </NormalText>
      <NormalText>{`Thank you very much!`}</NormalText>
    </MessageContainer>
  );
}

function ResetIndexMessage({ allNodes, resetCount }: IResetIndexMessage) {
  return (
    <ResetIndexMessageContainer>
      <NormalText>Successfully Reset!</NormalText>
      <NormalText>
        All Nodes: <BolderText>{allNodes}</BolderText>
      </NormalText>
      <NormalText>
        Reset Count: <BolderText>{resetCount}</BolderText>
      </NormalText>
    </ResetIndexMessageContainer>
  );
}

export const toastMessageWithElement = ({ currencyAmount, currencyName, valueReceived }: IReceiveFunds) => {
  toast.success(
    <ReceiveFundsMessage currencyAmount={currencyAmount} currencyName={currencyName} valueReceived={valueReceived} />,
    {
      autoClose: 15000,
    }
  );
};

export const resetIndexToastMessage = ({ allNodes, resetCount }: IResetIndexMessage) => {
  toast.success(<ResetIndexMessage allNodes={allNodes} resetCount={resetCount} />, {
    autoClose: 7000,
  });
};

export const transferredFundsMessage = (differentAccountEmail: string) => {
  toast.success(
    <>
      Successfully transferred funds to: <strong>{differentAccountEmail}</strong>
    </>,
    {
      autoClose: 7000,
    }
  );
};

export const displayToastMessageWithElements = (message: string) => {
  return <DivElement dangerouslySetInnerHTML={{ __html: message }} />;
};

