import styled from 'styled-components/macro';

export const Container = styled.div``;

export const InputElement = styled.input`
  width: 15px;
  height: 15px;
  accent-color: green;

  cursor: pointer;
  margin-right: 5px;
`;

export const LabelElement = styled.label`
  font-size: 18px;
  cursor: pointer;
`;
