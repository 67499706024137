import { Table } from 'react-bootstrap';
import prettyMilliseconds from 'pretty-ms';
import { displayDefaultDateFormat, displayPriceWithCurrency, emptyFunction, formatDate } from 'shared/string-utils';
import { MdDelete, MdDateRange } from 'react-icons/md';
import { AiFillPauseCircle } from 'react-icons/ai';
import { HiCommandLine } from 'react-icons/hi2';
import { Button } from 'react-bootstrap';
import { FaRegQuestionCircle } from 'react-icons/fa';
import Status from '~/components/Status';
import NodeStatus from '~/components/NodeStatus';
import {
  Container,
  Tbody,
  Thead,
  Td,
  TdImageContainer,
  TdImage,
  TdActiveTime,
  Th,
  ThStatus,
  Tr,
  TdButtonContainer,
  TdSmaller,
  TdActions,
  ActionsContainer,
} from './styles';
import { SUPORTED_COINS } from 'shared/constants';
import { Tooltip } from 'components/ui/Tooltip';
import { EditType } from '..';
import uuid from 'react-uuid';
import { INodeStatus } from '~/store/modules/interfaces';
import { getStatusIcon, renderNodeKey } from '../shared';
import { getUserProfile } from '~/store/modules/user/selectors';
import { useSelector } from 'react-redux';

export interface INodeToEdit {
  id: number;
  coin: string;
  alias: string;
  private_key: string;
  editType: EditType;
  activationCommand: string;
  status: INodeStatus;
  wallet_address: string;
}

export interface INodeToChangeChargeDate {
  id: number;
  coin: string;
  alias: string;
  price: number;
  private_key: string;
  charged_at: string;
  next_charge: string;
}

export enum DATA_TABLE_TYPE {
  coin_list = 'coin_list',
  node_details = 'node_details',
}

interface IDataTable {
  coinList: Array<any>;
  handleSelectDetails: (index: number) => void;
  handleNodeToChangeChargeDate: (node: INodeToChangeChargeDate) => void;
  nodeDetails: Array<any>;
  latestBlock: number;
  setEditedNode: (value: INodeToEdit | undefined) => void;
  type: DATA_TABLE_TYPE;
}

const NODE_DETAIL_COLUMNS = [
  'Coin',
  'Alias',
  'Price',
  'Node Key',
  'Blocks',
  'Charged at',
  'Next Charge',
  'Active Time',
  'Status',
  'Actions',
];

export default function DataTable({
  type,
  handleSelectDetails,
  nodeDetails,
  coinList,
  setEditedNode,
  latestBlock,
  handleNodeToChangeChargeDate,
}: IDataTable) {
  const userProfile = useSelector(getUserProfile);
  const { balances } = userProfile;
  const { balance } = balances;

  const handleEditedNode = (nodeToEdit: INodeToEdit) => {
    setEditedNode(nodeToEdit);
  };

  const renderActions = (node: any) => {
    const { is_paused, paused_until, coin, status } = node;
    const { hasActivationCommandOption, hasDeleteOption, hasPauseOption } = SUPORTED_COINS[coin].features;
    const pausedFormattedDate = formatDate(new Date(paused_until));
    const pausedText = !is_paused ? 'Pause node' : `This node is currently paused until: ${pausedFormattedDate}`;
    const allActions = [];
    if (status === 'mounting') return '-';

    if (hasActivationCommandOption) {
      allActions.push(
        <Tooltip content="Activation command" key={uuid()}>
          <HiCommandLine
            size={17}
            cursor="pointer"
            onClick={() =>
              handleEditedNode({
                id: node.id,
                coin: SUPORTED_COINS[node.coin].name,
                alias: node.alias,
                private_key: node.private_key,
                activationCommand: node.activation_command,
                editType: 'getActivationCommand',
                status: node.status,
                wallet_address: node.wallet_address,
              })
            }
          />
        </Tooltip>
      );
    }

    if (status === 'activated' && hasPauseOption) {
      allActions.push(
        <Tooltip content={pausedText} key={uuid()}>
          <AiFillPauseCircle
            size={17}
            cursor="pointer"
            className="pause-button"
            onClick={() =>
              !is_paused
                ? handleEditedNode({
                    id: node.id,
                    coin: SUPORTED_COINS[node.coin].name,
                    alias: node.alias,
                    private_key: node.private_key,
                    activationCommand: node.activation_command,
                    editType: 'pause',
                    status: node.status,
                    wallet_address: node.wallet_address,
                  })
                : emptyFunction
            }
          />
        </Tooltip>
      );
    }

    allActions.push(
      <Tooltip content="Charge date" key={uuid()}>
        <MdDateRange cursor="pointer" size={17} onClick={() => handleNodeToChangeChargeDate(node)} />
      </Tooltip>
    );

    if (hasDeleteOption) {
      allActions.push(
        <Tooltip content="Delete node" key={uuid()}>
          <MdDelete
            size={17}
            cursor="pointer"
            onClick={() =>
              handleEditedNode({
                id: node.id,
                coin: SUPORTED_COINS[node.coin].name,
                alias: node.alias,
                private_key: node.private_key,
                activationCommand: node.activation_command,
                editType: 'delete',
                status: node.status,
                wallet_address: node.wallet_address,
              })
            }
          />
        </Tooltip>
      );
    }

    return <>{allActions.map((action) => action)}</>;
  };

  const renderTbody = () => {
    if (type === DATA_TABLE_TYPE.coin_list && coinList) {
      return coinList.map((item, index) => {
        const { overdueCharges } = item;
        const coinName = item.coin;
        const { total, mounting, ready, activated } = item.status;
        const isOverdue = balance < overdueCharges.balanceNeeded;
        return (
          <Tr key={item.coin} isOverdue={isOverdue}>
            <TdImageContainer>
              <TdImage coin={coinName} />
              {SUPORTED_COINS[coinName].name}
            </TdImageContainer>
            <Td>
              <NodeStatus total={total} mounting={mounting} ready={ready} activated={activated} allNodesOnly={false} />
            </Td>
            <TdButtonContainer>
              <Button variant="primary" onClick={() => handleSelectDetails(index)} size="sm">
                SHOW DATA
              </Button>
            </TdButtonContainer>
          </Tr>
        );
      });
    } else if (type === DATA_TABLE_TYPE.node_details && nodeDetails) {
      return nodeDetails.map((node) => {
        const { is_paused } = node;
        const isMounting = node.status === 'mounting';
        const isOverdue = node.is_overdue_charge && balance < node.price;
        return (
          <Tr key={node.id} isOverdue={isOverdue}>
            <Td>{SUPORTED_COINS[node.coin].name}</Td>
            <Td>{`node${node.alias}`}</Td>
            <Td>{displayPriceWithCurrency(node.price)}</Td>
            <TdSmaller>{renderNodeKey(node.coin, node.private_key)}</TdSmaller>
            <Td>{node.block ? `${node.block} / ${latestBlock}` : '-'}</Td>
            <Td>{displayDefaultDateFormat(new Date(node.charged_at))}</Td>
            <Td>{node.next_charge ? displayDefaultDateFormat(new Date(node.next_charge)) : '-'}</Td>
            <TdActiveTime>
              {node.activetime && node.status === 'activated'
                ? prettyMilliseconds(node.activetime * 1000, {
                    unitCount: 3,
                  })
                : '-'}
            </TdActiveTime>
            <Td style={{ textAlign: 'center' }}>{getStatusIcon(node, false)}</Td>
            <TdActions>
              <ActionsContainer isMounting={isMounting} isPaused={is_paused}>
                {renderActions(node)}
              </ActionsContainer>
            </TdActions>
          </Tr>
        );
      });
    }
    return;
  };

  return (
    <Container>
      <Table responsive striped bordered hover size="sm">
        <Thead>
          <Tr>
            {type === DATA_TABLE_TYPE.coin_list ? (
              <>
                <Th>Coin</Th>
                <Th>
                  Status
                  <Tooltip content={<Status />}>
                    <FaRegQuestionCircle style={{ marginLeft: 5 }} />
                  </Tooltip>
                </Th>
                <Th>Data Management</Th>
              </>
            ) : (
              NODE_DETAIL_COLUMNS.map((columnName) =>
                columnName === 'Status' ? (
                  <ThStatus key={uuid()}>
                    {columnName}
                    <Tooltip content={<Status />}>
                      <FaRegQuestionCircle style={{ marginLeft: 5 }} />
                    </Tooltip>
                  </ThStatus>
                ) : (
                  <Th key={uuid()}>{columnName}</Th>
                )
              )
            )}
          </Tr>
        </Thead>
        <Tbody>{renderTbody()}</Tbody>
      </Table>
    </Container>
  );
}
