import React from 'react';
import { MdQueryBuilder } from 'react-icons/md';
import { FaCheckCircle } from 'react-icons/fa';
import { FiAlertTriangle } from 'react-icons/fi';
import { AiOutlineNodeIndex, AiFillWarning } from 'react-icons/ai';

import { NodeStatusContainer, StatusItem, TotalItemContainer, StatusItemContainer, AllNodesContainer } from './styles';
import { NormalText, BolderText } from '../../pages/Dashboard/styles';

interface INodeStatus {
  total: number;
  mounting: number;
  ready: number;
  activated: number;
  decommissioned?: number;
  allNodesOnly?: boolean;
}
const AiOutlineNodeIndexReplacement = (props: any) => {
  const {
    props: {
      attr: { pId, ...rest },
      children: [defs, ...paths],
    },
  } = AiOutlineNodeIndex({});

  const SAFE_PATHS = paths.map(
    // @ts-ignore
    ({ type, props: { pId: pathId, ...pathProps } }) => React.createElement(type, pathProps)
  );
  const SAFE_CHILDREN = React.Children.toArray([defs, ...SAFE_PATHS]);
  const SAFE_PROPS = { attr: rest, ...props };

  return React.cloneElement(AiOutlineNodeIndex({}), SAFE_PROPS, SAFE_CHILDREN);
};

export default function NodeStatus({
  total,
  mounting,
  ready,
  activated,
  decommissioned = 0,
  allNodesOnly,
}: INodeStatus) {
  const shouldDisplayDecommission = decommissioned > 0;
  return (
    <NodeStatusContainer>
      {allNodesOnly ? (
        <TotalItemContainer>
          <StatusItem>
            <BolderText>{total}</BolderText>
            <AllNodesContainer>
              <AiOutlineNodeIndexReplacement size={25} color="black" />
              All Nodes
            </AllNodesContainer>
          </StatusItem>
        </TotalItemContainer>
      ) : (
        <>
          <TotalItemContainer>
            <StatusItem>
              <BolderText>{total}</BolderText>
              <NormalText>Total</NormalText>
            </StatusItem>
          </TotalItemContainer>
          <StatusItemContainer>
            <StatusItem>
              <BolderText>{mounting}</BolderText>
              <MdQueryBuilder color="grey" title="Mounting node." />
            </StatusItem>
            <StatusItem>
              <BolderText>{ready}</BolderText>
              <FiAlertTriangle color="orange" title="Node is ready." />
            </StatusItem>
            {shouldDisplayDecommission && (
              <StatusItem>
                <BolderText>{decommissioned}</BolderText>
                <AiFillWarning color="orange" title="Node is decommissioned" />
              </StatusItem>
            )}
            <StatusItem>
              <BolderText>{activated}</BolderText>
              <FaCheckCircle color="green" title="Node successfully enabled!" />
            </StatusItem>
          </StatusItemContainer>
        </>
      )}
    </NodeStatusContainer>
  );
}
