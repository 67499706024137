export const SUPORTED_COINS: any = {
  daps: {
    name: 'DAPS',
    port: 53572,
    features: {
      hasDataToMyWallet: true,
      hasDeleteOption: true,
      hasPauseOption: true,
      hasActivationCommandOption: false,
    }
  },
  prcy: {
    name: 'PRivaCY',
    port: 59682,
    features: {
      hasDataToMyWallet: true,
      hasDeleteOption: true,
      hasPauseOption: true,
      hasActivationCommandOption: false,
    }
  },
  blkc: {
    name: 'BlackHat',
    port: 7147,
    features: {
      hasDataToMyWallet: true,
      hasDeleteOption: true,
      hasPauseOption: true,
      hasActivationCommandOption: false,
    }
  },
  bdx: {
    name: 'Beldex',
    port: undefined,
    features: {
      hasDataToMyWallet: false,
      hasDeleteOption: true,
      hasPauseOption: false,
      hasActivationCommandOption: true,
    },
  },
};

export const BELDEX_EXPLORER_LINK = `https://explorer.beldex.io`;
