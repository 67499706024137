import { useRef, useState } from 'react';
import { ButtonProps, Overlay, Spinner, Tooltip } from 'react-bootstrap';
import uuid from 'react-uuid';
import { StyledBootstrapButton } from './styles';

interface IBootstrapButton extends ButtonProps {
  shouldShowTooltip?: boolean;
  shouldUseDisabledLoading?: boolean;
  tooltipText?: string;
}

export const BootstrapButton = ({
  children,
  disabled,
  shouldShowTooltip,
  shouldUseDisabledLoading = true,
  tooltipText,
  onClick,
  ...props
}: IBootstrapButton) => {
  const target = useRef(null);
  const [showTooltip, setShowTooltip] = useState(false);

  const handleOnclick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (onClick) {
      onClick(event);
    }

    if (shouldShowTooltip) {
      setShowTooltip(true);
      setTimeout(() => setShowTooltip(false), 800);
    }
  };

  return (
    <StyledBootstrapButton disabled={disabled} ref={target} {...props} onClick={handleOnclick}>
      {disabled && shouldUseDisabledLoading ? (
        <Spinner size="sm" animation="grow" />
      ) : (
        <>
          {children}
          {shouldShowTooltip && (
            <Overlay target={target.current} show={showTooltip} placement="right">
              {(props) => (
                <Tooltip id={uuid()} {...props} show={showTooltip}>
                  {tooltipText}
                </Tooltip>
              )}
            </Overlay>
          )}
        </>
      )}
    </StyledBootstrapButton>
  );
};
