import styled from 'styled-components/macro';

export const Content = styled.div`
  margin: 0px auto;
  text-align: center;

  nav {
    display: flex;
    justify-content: space-between;

    a {
      width: 33%;
    }
  }

  div {
    margin-top: 15px;
  }

  table {
    position: static;
  }

  table thead tr th {
    border: 0px;
  }

  .calendar {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    /* z-index: -50; */
  }

  .dateTime {
    margin-top: 25px;
    position: relative;
    z-index: 1;

    .dateButtons {
      button {
        text-align: center;
        padding: 5px;
        margin: 10px;
        border: 1px solid black;
        border-radius: 5px;
        font-size: 12px;

        &:hover {
          background-color: #17a2b8;
          color: white;
        }
      }
      .activeButton {
        background-color: #17a2b8;
        color: white;
      }
    }
  }
`;
