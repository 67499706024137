import styled, { css } from 'styled-components/macro';

const BASE_STYLES = css`
  background: rgba(0, 0, 0, 0.1);
  border: 0;
  border-radius: 4px;
  min-height: 44px;
  padding: 15px;
  width: 100%;
`;

export const Input = styled.input`
  ${BASE_STYLES}
`;
