import { format } from 'date-fns';
import prettyMilliseconds from 'pretty-ms';
import { useLocation } from 'react-router-dom';

export const CURRENCY_SYMBOL = '$';

export const displayPriceWithCurrency = (price: number | string, decimal = 2) =>
  `${CURRENCY_SYMBOL}${Number(price).toFixed(decimal)}`;

export const displayNodeAlias = (alias: string) => `node${alias}`;

export const reduceKey = (key: string) => {
  if (!key) {
    return '-';
  }
  const chNumber = key.length;

  const subKey = `${key.substring(0, 6)}...${key.substring(chNumber - 6, chNumber)}`;

  return subKey;
};

export const emptyFunction = () => {};

export const useQuery = () => new URLSearchParams(useLocation().search);

export const timeToWait = (time: number) => {
  console.log(`Waiting ${time} seconds...`);
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(time);
    }, time * 1000); // seconds
  });
};

export const formatDate = (date: Date) => {
  const formattedDate = format(date, 'dd/MM/yyyy HH:mm');
  return formattedDate;
};

export const displayDefaultDateFormat = (date: Date) => {
  const formattedDate = format(date, 'dd/MM/yyyy');
  return formattedDate;
};

export const renderCellData = (data: any) => {
  if (!data) {
    return '-';
  }

  return data;
};

export const copyTextToClipboard = (text: string) => {
  const textArea = document.createElement('textarea');

  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.select();
  textArea.setSelectionRange(0, 99999); // For mobile devices

  navigator.clipboard?.writeText(textArea.value);
  textArea.remove();
};

export const removeExtraSpaces = (text: string) => {
  const cleanedString = text.replace(/\s+/g, ' ').trim();
  return cleanedString;
};

export const getDistinctRGBAColors = (index: number, alpha = 0.5) => {
  const hue = (index * 137.508) % 360; // Use a prime number for better distribution
  const saturation = 0.7; // Adjust as needed
  const lightness = 0.6; // Adjust as needed
  const color = `hsla(${hue}, ${saturation * 100}%, ${lightness * 100}%, ${alpha})`;
  return color;
};

export const displayFormattedTime = (time?: number) => {
  if (time) {
    return prettyMilliseconds(time * 1000, {
      unitCount: 3,
    });
  }

  return '-';
};

export const formatDecimal = (number: number, decimalPlaces: number) => {
  // Convert number to string
  let numStr = number.toString();

  // Split the string into integer and decimal parts
  let parts = numStr.split('.');

  // If no decimal part exists, add it
  if (parts.length === 1) {
    parts.push('');
  }

  // If the decimal part is shorter than desired decimal places, pad it with zeros
  while (parts[1].length < decimalPlaces) {
    parts[1] += '0';
  }

  // Truncate the decimal part if it's longer than desired decimal places
  parts[1] = parts[1].slice(0, decimalPlaces);

  const numberStringResult = parts.join('.');

  return numberStringResult;
};
