import { createGlobalStyle, css } from 'styled-components/macro';

import 'react-toastify/dist/ReactToastify.css';
import { HIGLAN_BLACK_01, HIGLAN_PURPLE_02, HIGLAN_WHITE_01, HIGLAN_WHITE_02 } from './constants';

const SCROLL_BAR = css`
  scrollbar-width: thin;

  &::-webkit-scrollbar-thumb {
    background: linear-gradient(to bottom, ${HIGLAN_WHITE_01} 5%, ${HIGLAN_PURPLE_02} 100%);
    border-radius: 7px;
    outline: 1px solid ${HIGLAN_BLACK_01};
  }

  &::-webkit-scrollbar-track {
    background: ${HIGLAN_WHITE_02};
  }

  &::-webkit-scrollbar {
    width: 7px;
  }
`;

export default createGlobalStyle`
  * {
      margin: 0;
      padding: 0;
      outline: 0;
      box-sizing: border-box;
  }

  *:focus {
      outline: 0;
  }

  html, body, #root {
    height: 100%;
    background: linear-gradient(-90deg, #7159c1, #ab59c1);
  }

  body {
      -webkit-font-smoothing: antialiased;
      // Because react-bootstrap modal creates a padding here
      padding-right: 0 !important;
  }

  #root, body, input {
    font: 15px 'Roboto', sans-serif;
  }

  a {
      text-decoration: none;
  }

  ul {
      list-style: none;
  }

  button {
      cursor: pointer;
  }

  /* hide arrows Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield !important;
  }
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
  }

  ${SCROLL_BAR};
`;
