import { useDispatch, useSelector } from 'react-redux';
import { FaCartArrowDown } from 'react-icons/fa';
import { useEffect, useState } from 'react';

import { Header, PaymentTitle, StyledBootstrapButton } from '../styles';
import coinBaseWalletLogo from './assets/coinbase-wallet.svg';
import coinBaseLogo from './assets/coinbase.svg';
import metaMaskLogo from './assets/meta-mask.svg';
import walletConnector from './assets/wallet-connector.svg';
import {
  BolderText,
  Description,
  ImageContainer,
  OnchainContainer,
  Image,
  PaymentContainer,
  DescriptionContainer,
} from './styles';
import { getIsLoadingOnchainPaymentLink, getOnchainPaymentLinkData } from '~/store/modules/user/selectors';
import { createOnchainPaymentLink, setOnchainPaymentLinkData } from '~/store/modules/user/actions';
import { PaymentModal } from './PaymentModal';

export function Onchain() {
  const isLoadingOnchainPaymentLink = useSelector(getIsLoadingOnchainPaymentLink);
  const onchainPaymentLinkData = useSelector(getOnchainPaymentLinkData);
  const { hosted_url } = onchainPaymentLinkData;
  const [showPaymentLinkModal, setShowPaymentLinkModal] = useState(false);

  const dispatch = useDispatch();

  const handleCreateOnchainPaymentLink = () => {
    if (hosted_url) {
      togglePaymentLinkModal();
      return;
    }
    dispatch(
      createOnchainPaymentLink({
        showPaymentLinkModalFunction,
      })
    );
  };

  const showPaymentLinkModalFunction = () => {
    setShowPaymentLinkModal(true);
  };

  const togglePaymentLinkModal = () => {
    setShowPaymentLinkModal(!showPaymentLinkModal);
  };

  useEffect(() => {
    dispatch(setOnchainPaymentLinkData({ hosted_url: '', expires_at: '' }));
  }, []);

  return (
    <OnchainContainer>
      <Header>
        <PaymentTitle>Onchain Payment Method</PaymentTitle>
        <ImageContainer>
          <Image src={coinBaseWalletLogo} alt="Coinbase wallet logo" />
          <Image src={coinBaseLogo} alt="Coinbase logo" />
          <Image src={metaMaskLogo} alt="Metamask logo" />
          <Image src={walletConnector} alt="Wallet Connector logo" />
        </ImageContainer>
      </Header>
      <DescriptionContainer>
        <Description>
          Payments with <BolderText>low transaction fees</BolderText> using{' '}
          <BolderText>hundreds of currencies</BolderText>!
        </Description>
        <Description>
          Available on <BolderText>Base</BolderText>, <BolderText>Ethereum</BolderText> and{' '}
          <BolderText>Polygon</BolderText> network. More network coming soon.
        </Description>
      </DescriptionContainer>
      <PaymentContainer>
        <StyledBootstrapButton
          disabled={isLoadingOnchainPaymentLink}
          onClick={handleCreateOnchainPaymentLink}
          variant="success"
        >
          <FaCartArrowDown size={20} />
          PAY
        </StyledBootstrapButton>
      </PaymentContainer>
      {showPaymentLinkModal && <PaymentModal handleHideModal={togglePaymentLinkModal} url={hosted_url} />}
    </OnchainContainer>
  );
}
