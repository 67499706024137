import styled, { css } from 'styled-components/macro';
import { DISPLAY_FLEX_COLUMN_DEFAULT, DISPLAY_FLEX_LINE_DEFAULT, HIGLAN_PURPLE_01 } from '~/style/constants';
import { SECTION_DEFAULT_STYLES } from '../styles';

const SECTION_STYLES = css`
  ${SECTION_DEFAULT_STYLES}
  ${DISPLAY_FLEX_LINE_DEFAULT}
  gap: 5px;
`;

export const UserFundsContainer = styled.div`
  ${SECTION_STYLES}
`;

export const AllUserContainer = styled.div`
  ${SECTION_STYLES}
`;

export const AllAddressesContainer = styled.div`
  ${SECTION_STYLES}
`;

export const DataTableContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  gap: 10px;
  margin-top: 20px;
  padding-top: 10px;
`;

export const ButtonsContainer = styled.div`
  ${DISPLAY_FLEX_LINE_DEFAULT}
  gap: 5px;
  flex-wrap: wrap;
  max-width: 800px;
  margin: 0 auto;
`;

export const ItemsInLineContainer = styled.div`
  ${DISPLAY_FLEX_LINE_DEFAULT}
  gap: 10px;
`;

export const DataTableSection = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  width: 100%;
  gap: 7px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 10px;
`;

export const ActionContainer = styled.div`
  ${DISPLAY_FLEX_LINE_DEFAULT}
  min-height: 20px;

  svg {
    :hover {
      color: ${HIGLAN_PURPLE_01};
    }
  }
`;

export const ExplorerLink = styled.a``;
