import styled, { css } from 'styled-components/macro';
import { mobileOnly } from 'style/mixins';
import { CoinLogo } from 'components/CoinLogo';
import { DISPLAY_FLEX_COLUMN_DEFAULT, DISPLAY_FLEX_LINE_DEFAULT } from '~/style/constants';
import { WARNING_BACKGROUND } from '../styles';

const IS_PAUSED_CSS = css`
  cursor: auto;
  color: #7159c1;
`;

export const Thead = styled.thead``;

export const Tr = styled.tr`
  text-align: center;
`;

export const Th = styled.th`
  vertical-align: middle !important;
  font-size: 13.7px;
`;

export const ThStatus = styled(Th)`
  ${mobileOnly`
    svg {
      display: none;
    }
  `}
`;

export const Tbody = styled.tbody`
  text-align: center;
  font-size: 14px;
`;

export const Td = styled.td``;

export const ActionsContainer = styled.div<{ isPaused: boolean; isMounting: boolean }>`
  ${DISPLAY_FLEX_LINE_DEFAULT};
  gap: 7px;
  justify-content: ${({ isMounting }) => (isMounting ? 'center' : 'flex-end')};

  svg {
    &:hover {
      color: #7159c1;
    }
  }

  svg.pause-button {
    ${({ isPaused }) => isPaused && IS_PAUSED_CSS}
  }
`;

export const TdSmaller = styled.td`
  font-size: smaller;
`;

export const TdImageContainer = styled.td`
  text-align: center;
  font-size: 12px;
  font-weight: bold;
`;

export const TdButtonContainer = styled.td`
  text-align: center;
`;

export const TdImage = styled(CoinLogo)`
  width: 25px;
  margin-right: 5px;
`;

export const TdActiveTime = styled.td`
  min-width: 100px;
`;

export const OrderedTextContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT};
  gap: 5px;
`;

export const WarningItem = styled.div<{ isWarning: boolean }>`
  background-color: ${({ isWarning }) => isWarning && `${WARNING_BACKGROUND}`};
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
`;

export const WarningText = styled.small``;

export const ServerIpContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
`;

export const StatusContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  gap: 5px;
  color: red;
`;
