import styled from 'styled-components/macro';

export const Content = styled.div`
  #mounting {
    cursor: progress;
    color: gray;
  }

  .ready {
    color: orange;
  }

  .activated {
    color: green;
  }
  header {
    display: flex;
    flex-direction: column;

    .total-masternodes {
      display: flex;
      border-top: 1px solid rgba(222, 226, 230);
      /* border-bottom: 1px solid rgba(222, 226, 230); */
      justify-content: center;
      align-items: center;
      padding: 20px;

      div {
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
      }
      .status-masternode {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        width: 200px;
      }
    }

    .user-data {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      justify-items: center;
      text-align: center;
      margin: 0 auto;
      margin-bottom: 20px;

      .items-data {
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        padding: 5px;
      }
    }

    .all-coins {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      div {
        display: flex;
        flex-direction: column;
        padding: 10px;
      }
    }

    .delayed-masternodes {
      margin: 10px auto;

      p {
        display: inline-block;
        padding: 10px;
        font-weight: bold;
        background-color: #ff9d00;
        text-align: center;

        small {
          display: block;
        }
      }

      p + p {
        margin-left: 10px;
      }
    }

    .buttons {
      display: flex;
      flex-direction: row;
      margin: 10px auto;
      align-items: center;
      text-align: center;

      > button {
        margin-right: 10px;
      }

      .copy-data {
        display: flex;
        align-items: center;

        svg {
          margin-left: 5px;
        }
      }
    }
  }

  main {
    margin-top: 10px;
    text-align: center;

    @media (max-width: 550px) {
      #status svg {
        display: none;
      }
    }

    table {
      position: static;

      th,
      td {
        display: table-cell;
        text-align: center;
        vertical-align: middle;
      }

      td small {
        display: block;
      }

      td.item {
        font-size: 11.5px !important;
      }

      td.item.ip {
        max-width: 150px;
        word-break: break-all;
      }

      #status {
        svg {
          margin-left: 5px;
          cursor: help;
        }
      }

      .info {
        font-weight: bold;
        background-color: white;

        p {
          margin: 0;
          padding: 10px;
        }

        dl {
          display: flex;
          font-weight: normal;
          align-items: center;
          justify-content: center;

          dt {
            font-weight: bold;
            margin-right: 2px;
          }

          dd {
            margin-bottom: 0;
            margin-right: 20px;
          }
        }

        .status-masternode {
          div {
            display: flex;
            margin-right: 30px;
            align-items: center;
            justify-content: center;
            margin: 0;

            div {
              align-items: center;
              justify-content: center;
              flex-direction: column;
              padding: 15px;
            }
          }
        }
      }

      .warning {
        background-color: #ff9d00;
      }
    }

    .inline-multiple-data {
      background: rgba(0, 0, 0, 0.1);
      font-size: 13px;
      text-align: left;
      word-wrap: break-word;
    }
  }
`;
