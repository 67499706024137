import { darken } from 'polished';
import styled from 'styled-components/macro';
import { HIGLAN_PURPLE_03, HIGLAN_WHITE_01 } from '~/style/constants';

export const Button = styled.button`
  height: 44px;
  width: 100%;
  background: ${HIGLAN_PURPLE_03};
  font-weight: bold;
  color: ${HIGLAN_WHITE_01};
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  transition: background 0.2s;

  &:hover {
    background: ${darken(0.3, HIGLAN_PURPLE_03)};
  }

  &:disabled {
    cursor: not-allowed;

    &:hover {
      background: ${HIGLAN_PURPLE_03};
    }
  }
`;
