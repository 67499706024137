import { useDispatch, useSelector } from 'react-redux';
import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';

import { ItemStrong } from '../../styles';
import { TextArea } from 'components/ui/TextArea';
import { sendMessage } from 'store/modules/app/actions';
import { InputContainer, SuggestCoinContainer, StyledTextInput, ButtonsContainer } from './styles';
import { BootstrapButton } from '~/components/ui/BootstrapButton';
import { getIsSendingMessage } from '~/store/modules/app/selectors';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import HCaptchaConfig from '~/config/HCaptcha';

interface ISuggestCoin {
  hasCancellation?: () => void;
  executeAfterSend?: () => void;
  isPublicMessage: boolean;
}
export function SuggestCoin({ hasCancellation, executeAfterSend, isPublicMessage, ...props }: ISuggestCoin) {
  const [coinInputValue, setCoinInputValue] = useState('');
  const [coinWebsite, setCoinWebsite] = useState('');
  const [hasCoinReasonError, setHasCoinReasonError] = useState(true);
  const [coinReasonValue, setCoinReasonValue] = useState('');
  const isSubmitting = useSelector(getIsSendingMessage);
  const [captchaToken, setCaptchaToken] = useState<string>();
  const dispatch = useDispatch();
  const captchaRef = useRef(null);

  const handleCoinReasonError = (value: boolean) => {
    setHasCoinReasonError(value);
  };

  const handleCoinReason = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const textAreaValue = event.target.value;
    setCoinReasonValue(textAreaValue);
  };

  const handleCoinInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value.toUpperCase();
    setCoinInputValue(inputValue);
  };

  const handleCoinWebsite = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value.toUpperCase();
    setCoinWebsite(inputValue);
  };

  const resetCaptcha = () => {
    // @ts-ignore
    captchaRef.current?.resetCaptcha();
    setCaptchaToken('');
  };

  const handleModalSubmit = () => {
    const canSubmitSuggestion =
      !!coinInputValue.trim() && !!coinReasonValue.trim() && !!coinWebsite.trim() && !hasCoinReasonError;

    if (!canSubmitSuggestion) {
      toast.error(`Please, fill in correctly all fields.`);
      return;
    }

    if (!captchaToken) {
      toast.error(`Please resolve the captcha.`, {
        pauseOnFocusLoss: false,
      });
      return;
    }
    dispatch(
      sendMessage({
        feedbackMessage: `Thanks for your suggestion! We will analyze this coin.`,
        isPublicMessage,
        captchaToken,
        resetCaptcha,
        message: `Coin: ${coinInputValue}  | Website: ${coinWebsite} | Message: ${coinReasonValue}`,
        ...(executeAfterSend && { executeAfterSend }),
      })
    );
  };

  const handleCaptchaChange = (captchaToken: string) => {
    setCaptchaToken(captchaToken);
  };

  return (
    <SuggestCoinContainer {...props}>
      <InputContainer>
        <ItemStrong>Which is the coin?</ItemStrong>
        <StyledTextInput value={coinInputValue} onChange={handleCoinInput} type="text" />
      </InputContainer>
      <InputContainer>
        <ItemStrong>What is the coin's website?</ItemStrong>
        <StyledTextInput value={coinWebsite} onChange={handleCoinWebsite} type="text" />
      </InputContainer>
      <InputContainer>
        <ItemStrong>Tell the reason you like this coin</ItemStrong>
        <TextArea
          defaultValue={coinReasonValue}
          minLength={100}
          value={coinReasonValue}
          onChange={handleCoinReason}
          handleError={handleCoinReasonError}
        />
      </InputContainer>
      <HCaptcha ref={captchaRef} size="normal" sitekey={HCaptchaConfig.siteKey} onVerify={handleCaptchaChange} />
      <ButtonsContainer>
        {hasCancellation && <Button onClick={hasCancellation}>CLOSE</Button>}
        <BootstrapButton variant="success" onClick={handleModalSubmit} disabled={isSubmitting}>
          SEND
        </BootstrapButton>
      </ButtonsContainer>
    </SuggestCoinContainer>
  );
}
