import { Spinner } from 'react-bootstrap';
import { HiglanLogo, LoadingContainer, LoadingTextContainer } from './styles';

import logo from 'assets/higlan_logo_no_text.svg';

export function Loading() {
  return (
    <LoadingContainer>
      <HiglanLogo src={logo} alt="Higlan" />
      <LoadingTextContainer>
        Loading...
        <Spinner animation="border" variant="white" />
      </LoadingTextContainer>
    </LoadingContainer>
  );
}
