import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

import './config/ReactotronConfig';
import PayPal from './config/PayPal';
import Adsense from '~/config/Adsense';

import Routes from './routes';
import history from './services/history';

import { store, persistor } from './store';

import GlobalStyle from './style/global';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-calendar/dist/Calendar.css';
import { useEffect } from 'react';

function App() {
  const ref = window.document.getElementsByTagName('script')[0];
  const loadSdks = () => {
    const payPalClientId = process.env.NODE_ENV === 'development' ? 'sb' : PayPal.client_id;
    const script = window.document.createElement('script');
    script.src = `https://www.paypal.com/sdk/js?client-id=${payPalClientId}&currency=USD&disable-funding=credit,card`;
    if (ref?.parentNode) {
      ref.parentNode.insertBefore(script, ref);
    }
  };
  const loadAdsense = () => {
    const script = window.document.createElement('script');
    script.src = `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${Adsense.client}`;
    script.async = true;
    script.crossOrigin = 'anonymous';
    if (ref?.parentNode) {
      ref.parentNode.insertBefore(script, ref);
    }
  };
  useEffect(() => {
    loadSdks();
    loadAdsense();
  }, []);

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router history={history}>
          <Routes />
          <GlobalStyle />
          <ToastContainer autoClose={3000} position="top-center" />
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
