import styled, { css } from 'styled-components/macro';
import { DISPLAY_FLEX_COLUMN_DEFAULT, DISPLAY_FLEX_LINE_DEFAULT } from '~/style/constants';

const ITEM_SELECTED_CSS = css`
  text-decoration: underline;
  font-weight: bold;
`;

export const DepositChartContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  width: 100%;
  gap: 15px;
`;

export const ItemSelectorContainer = styled.div`
  ${DISPLAY_FLEX_LINE_DEFAULT}
  gap: 15px;
`;

export const ChartItemSelector = styled.div<{ isSelected: boolean }>`
  cursor: pointer;

  :hover {
    ${ITEM_SELECTED_CSS}
  }

  ${({ isSelected }) => isSelected && ITEM_SELECTED_CSS}
`;
