import styled from 'styled-components/macro';
import { DISPLAY_FLEX_COLUMN_DEFAULT, HIGLAN_BLACK_01, HIGLAN_YELLOW_01 } from '~/style/constants';

export const PromoBannerContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  padding: 7px;
  font-size: small;
  max-width: 200px;
  gap: 7px;
  padding: 7px;
  color: ${HIGLAN_YELLOW_01};
  border: 7px outset ${HIGLAN_YELLOW_01};
  background-color: ${HIGLAN_BLACK_01};
`;

export const NormalText = styled.div``;
