import { useEffect, useState } from 'react';
import { GoCheck, GoX } from 'react-icons/go';
import uuid from 'react-uuid';
import { BolderText } from '~/pages/Dashboard/styles';
import { BootstrapButton } from '../ui/BootstrapButton';
import {
  Description,
  InputsForm,
  NodeOutputsContainer,
  InputContainer,
  TextAreaElement,
  AllInputsContainer,
  ButtonsContainer,
  NewTxidsContainer,
  NewTxidItem,
  StatusErrorContainer,
  StatusValidContainer,
} from './styles';

interface NodeOutput {
  txhash: string;
  outputidx: string;
}

export function NodeOutputs() {
  const [newTxids, setNewTxids] = useState<NodeOutput[] | null>();
  const initialMasternodeData = localStorage.getItem('higlanMasternodes');
  const [conf, setConf] = useState(initialMasternodeData && initialMasternodeData !== '' ? initialMasternodeData : '');
  const [confLines, setConfLines] = useState<number>();
  const [outputs, setOutputs] = useState('');
  const [outputsObject, setOutputsObject] = useState<NodeOutput[] | null>();
  const [invalidConf, setInvalidConf] = useState(true);
  const [invalidOutput, setInvalidOutput] = useState(true);

  const renderNodeConfStatus = () => {
    if (conf === '') {
      return (
        <StatusErrorContainer>
          <BolderText>*Required</BolderText>
        </StatusErrorContainer>
      );
    }

    if (invalidConf) {
      return (
        <StatusErrorContainer>
          <GoX />
          <BolderText>Invalid</BolderText>
        </StatusErrorContainer>
      );
    }

    if (conf !== '') {
      return (
        <StatusValidContainer>
          <GoCheck />
          <BolderText>{`Valid - ${confLines} lines`}</BolderText>
        </StatusValidContainer>
      );
    }

    return null;
  };

  const renderOutputsStatus = () => {
    if (outputs === '') {
      return (
        <StatusErrorContainer>
          <BolderText>*Required</BolderText>
        </StatusErrorContainer>
      );
    }

    if (invalidOutput) {
      return (
        <StatusErrorContainer>
          <GoX />
          <BolderText>Invalid</BolderText>
        </StatusErrorContainer>
      );
    }

    if (outputs !== '' && outputsObject) {
      return (
        <StatusValidContainer>
          <GoCheck />
          <BolderText>{`Valid - ${outputsObject.length} outputs`}</BolderText>
        </StatusValidContainer>
      );
    }

    return null;
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // @ts-ignore
    const conf = event.target[0].value.trim();
    // @ts-ignore
    const outputs: NodeOutput[] = JSON.parse(event.target[1].value.trim());

    const newTxids: NodeOutput[] = [];

    outputs.forEach((item) => {
      const { txhash, outputidx } = item;
      const regex = new RegExp(txhash, 'g');

      if (regex.test(conf)) {
        // console.log('txhash being used', txhash);
      } else {
        newTxids.push({
          txhash,
          outputidx,
        });
      }
    });

    setNewTxids(newTxids);
  };

  const handleConf = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setConf(event.target.value);
  };

  const handleOutputs = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setOutputs(event.target.value);
  };

  const cleanAll = () => {
    setConf('');
    setOutputs('');
    setNewTxids(null);
  };

  useEffect(() => {
    if (outputs !== '') {
      try {
        const jsonResult = JSON.parse(outputs);

        if (Number.isInteger(jsonResult) || !Array.isArray(jsonResult)) {
          setInvalidOutput(true);
        } else {
          setInvalidOutput(false);
          setOutputsObject(jsonResult);
        }
      } catch (err) {
        setInvalidOutput(true);
      }
    }
  }, [outputs]);

  useEffect(() => {
    if (conf !== '') {
      try {
        const splitResult = conf.split('\n');

        splitResult.forEach((line) => {
          if (!line.match(/#/g) && line.trim() !== '') {
            const fields = line.split(' ');

            if (fields.length !== 5) {
              throw new Error('Invalid conf!');
            }
          }
        });

        setInvalidConf(false);

        let count = 0;

        const lines = conf.split('\n');

        lines.forEach((line) => {
          line = line.trim();

          if (line !== '') count += 1;
        });

        setConfLines(count);
      } catch (err) {
        setInvalidConf(true);
      }
    }
  }, [conf]);

  return (
    <NodeOutputsContainer>
      <Description>
        <BolderText>
          Have you created new masternodes in your wallet and now you need to know which ones the TXIDs are?
        </BolderText>
        <BolderText>This tool can identify them for you.</BolderText>
      </Description>
      <InputsForm onSubmit={handleSubmit}>
        <AllInputsContainer>
          <InputContainer>
            <BolderText>masternode.conf</BolderText>
            <TextAreaElement
              name="conf"
              onChange={handleConf}
              rows={10}
              value={conf}
              placeholder="Content of the file masternode.conf from your wallet folder."
              required
            />
            {renderNodeConfStatus()}
          </InputContainer>
          <InputContainer>
            <BolderText>masternode outputs</BolderText>
            <TextAreaElement
              name="outputs"
              onChange={handleOutputs}
              rows={10}
              value={outputs}
              placeholder="Result of the command masternode outputs from your wallet console."
              required
            />
            {renderOutputsStatus()}
          </InputContainer>
        </AllInputsContainer>
        <ButtonsContainer>
          <BootstrapButton variant="info" onClick={cleanAll}>
            Clean
          </BootstrapButton>
          <BootstrapButton
            shouldUseDisabledLoading={false}
            type="submit"
            variant="primary"
            disabled={invalidConf || invalidOutput}
          >
            Submit
          </BootstrapButton>
        </ButtonsContainer>
        {newTxids && (
          <NewTxidsContainer>
            <BolderText>{`${newTxids.length} New TXIDs found:`}</BolderText>
            {newTxids.map(({ txhash, outputidx }) => (
              <NewTxidItem key={uuid()}>{`${txhash} ${outputidx}`}</NewTxidItem>
            ))}
          </NewTxidsContainer>
        )}
      </InputsForm>
    </NodeOutputsContainer>
  );
}
