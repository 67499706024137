import styled from 'styled-components/macro';
import { Instructions } from '~/components/ui/Instructions';
import { DISPLAY_FLEX_COLUMN_DEFAULT, HIGLAN_BLUE_02, HIGLAN_WHITE_01 } from '~/style/constants';

export const FaqTitle = styled.div`
  color: ${HIGLAN_WHITE_01};
  font-size: 20px;
`;

export const FaqContainer = styled.div`
  width: 80%;
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  gap: 10px;

  .card-body a {
    color: ${HIGLAN_BLUE_02};
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const StyledInstructions = styled(Instructions)`
  text-align: left;

  .card-header {
    text-align: center;

    button {
      text-align: center;
    }
  }
`;
