import styled from 'styled-components/macro';
import { DISPLAY_FLEX_COLUMN_DEFAULT, DISPLAY_FLEX_LINE_DEFAULT, HIGLAN_GREEN_01 } from '~/style/constants';

export const BodyContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT};
  gap: 15px;
`;

export const NormalText = styled.div`
  font-size: 16px;
`;

export const AllTimeEarned = styled.div`
  padding: 15px;
  ${DISPLAY_FLEX_LINE_DEFAULT}
  background-color: ${HIGLAN_GREEN_01};
  color: white;
`;
