import { css, FlattenSimpleInterpolation, CSSObject } from 'styled-components/macro';
import {
  DESKTOP_MIN_WIDTH_WITH_UNITS,
  MOBILE_MAX_WIDTH_WITH_UNITS,
} from '../constants';

const makeMediaQuery = (
  fn: <T extends any[] = any[]>(
    first: TemplateStringsArray | CSSObject,
    ...args: T
  ) => FlattenSimpleInterpolation | string,
) => fn;

export const desktopOnly = makeMediaQuery(
  (...args) => css`
    @media (min-width: ${DESKTOP_MIN_WIDTH_WITH_UNITS}) {
      ${css(...args)}
    }
  `,
);

export const mobileOnly = makeMediaQuery(
  (...args) => css`
    @media (max-width: ${MOBILE_MAX_WIDTH_WITH_UNITS}) {
      ${css(...args)}
    }
  `,
);
