import { CheckboxProps } from '@radix-ui/react-checkbox';
import uuid from 'react-uuid';
import { Container, StyledCheckboxRoot, StyledCheckboxIndicator, StyledCheckIcon, Label } from './styles';

interface ICheckbox extends CheckboxProps {
  label: string;
  checked: boolean;
}

export function Checkbox({ label, checked, ...props }: ICheckbox) {
  const idForElement = uuid();

  return (
    <Container>
      <StyledCheckboxRoot checked={checked} id={idForElement} {...props}>
        <StyledCheckboxIndicator>
          <StyledCheckIcon />
        </StyledCheckboxIndicator>
      </StyledCheckboxRoot>
      <Label htmlFor={idForElement}>{label}</Label>
    </Container>
  );
}
