import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';

import { Container } from 'pages/_layouts/default/styles';
import {
  AllCoins,
  AllServers,
  CoinContainer,
  CoinItem,
  CoinItemTotals,
  GeneralButtons,
  GeneralTotals,
  ItemContainer,
  ItemsContainer,
  NormalItem,
  EnabledNotificationIcon,
  ResetIndexButton,
  ServerItem,
  ShowNodesText,
  StrongItem,
  TimeContainer,
  TimeText,
  TodayTotals,
  TotalEarnings,
  WarningContainer,
  WarningItem,
  DisabledNotificationIcon,
  InfoItem,
  ServerInfoContainer,
  ServerButtonsContainer,
  ServerButtonItem,
  ServerButtonText,
  ToggleOnButton,
  ToggleOffButton,
  GeneralDeletion,
  ChargeNodesButton,
  ExecuteDeletionButton,
  NoMountingRunningWarning,
  StorageContainer,
  StorageItem,
  StorageItemTitle,
  HighestEarning,
} from './styles';
import { displayFormattedTime, displayPriceWithCurrency, formatDate } from '~/shared/string-utils';
import {
  getAdminData,
  getIsAdminCode,
  getIsLoadingChargeNodes,
  getIsLoadingForResetIndexToDelete,
  getIsLoadingNodeDeletion,
  getIsLoadingReadyNodeDeletion,
  getIsRefreshingAdminDataSelector,
  getIsSomeMountingRunning,
} from '~/store/modules/user/selectors';
import uuid from 'react-uuid';
import NodeStatus from '~/components/NodeStatus';
import { CoinLogo } from '~/components/CoinLogo';
import { BootstrapButton } from '~/components/ui/BootstrapButton';
import { ExtraDataModal } from './ExtraDataModal';
import { UserData } from './UserData';
import { AdminCodeModal } from './AdminCodeModal';
import {
  resetIndexToDelete,
  submitAdminCode,
  getAdminData as getAdminDataAction,
  toggleNotificationWarningMail,
  INotificationWarningType,
  toggleServerMounting,
  toggleServerDeletion,
  executeChargeNodes,
  executeNodeDeletion,
} from '~/store/modules/user/actions';
import { NodesProblemModal } from './NodesProblemModal';
import { FiAlertOctagon } from 'react-icons/fi';
import { LineData } from '~/components/ui/LineData';
import { FaCrown } from 'react-icons/fa6';

export interface INodeDetailsToShow {
  user_id: number;
  coinDataIndexes: number[];
}

export interface IHandleToggleSingleData {
  user_id: number;
  coinIndex: number;
}

export type INodeProblems =
  | 'delayedBlockNodes'
  | 'overdueNodes'
  | 'decommissionedNodes'
  | 'delayedScripts'
  | 'delayedServersPing';

export function Admin() {
  const adminAllData = useSelector(getAdminData);
  const isLoadingForResetIndexToDelete = useSelector(getIsLoadingForResetIndexToDelete);
  const isRefreshingAdminData = useSelector(getIsRefreshingAdminDataSelector);
  const isChargeNodesLoading = useSelector(getIsLoadingChargeNodes);
  const isNodeDeletionLoading = useSelector(getIsLoadingNodeDeletion);
  const isReadyNodeDeletionLoading = useSelector(getIsLoadingReadyNodeDeletion);
  const isSomeMountingRunning = useSelector(getIsSomeMountingRunning);
  const [nodeDetailsToShow, setNodeDetailsToShow] = useState<INodeDetailsToShow[]>([]);
  const [isAllNodeDataShown, setIsAllNodeDataShown] = useState(false);
  const [shouldShowUserFundsModal, setShouldShowUserFundsModal] = useState(false);
  const [shouldShowAdminCodeModal, setShouldShowAdminCodeModal] = useState(false);
  const [shouldShowNodeProblemsModal, setShouldShowNodeProblemsModal] = useState(false);
  const [nodeProblemsModalRenderType, setNodeProblemsModalRenderType] = useState<INodeProblems>();
  const [nodeProblemsModalTitle, setNodeProblemsModalTitle] = useState('');
  const {
    generalTotals,
    allCoins,
    allUserNodes,
    allServers,
    userFunds,
    allUsers,
    nodesEnabledLast30Days,
    allAddresses,
    deletedNodes,
    nodesCreated,
    userActivity,
    faucetHistory,
    requestTime,
    decommissionHistory,
    warnings,
    nodeRestoration,
    fundsTransfers,
  } = adminAllData;
  const { statusTotal, coins } = allCoins;
  const { is_decommission_nodes_warning_mail_enabled } = warnings.decommissionedNodes;
  const { is_delayed_blocks_warning_mail_enabled } = warnings.delayedBlockNodes;
  const { is_script_warning_mail_enabled, data: scriptWarningsData } = warnings.scriptWarnings;
  const { is_servers_ping_warning_mail_enabled, data: serversPingWarningData } = warnings.serversPingWarning;
  const {
    servers,
    should_run_node_charges,
    is_running_general_deletion,
    should_allow_user_deletion,
    is_charge_warning_mail_enabled,
  } = allServers;
  const isAdminCodeValid = useSelector(getIsAdminCode);
  const dispatch = useDispatch();

  const getAllIndexesToShow = () => {
    const allUserNodeDataIndexes = adminAllData.allUserNodes.map((userData) => {
      const { user_id, coinData } = userData;

      const coinDataIndexes = coinData.map((_, index) => index);

      return { user_id, coinDataIndexes };
    });
    return allUserNodeDataIndexes;
  };

  const handleShowAllData = () => {
    if (isAllNodeDataShown) {
      setNodeDetailsToShow([]);
      setIsAllNodeDataShown(false);
      return;
    }
    const allUserNodeDataIndexes = getAllIndexesToShow();

    setNodeDetailsToShow(allUserNodeDataIndexes);
    setIsAllNodeDataShown(true);
  };

  const handleToggleSingleData = ({ user_id, coinIndex }: IHandleToggleSingleData) => {
    const currentNodeDetailsToShow = [...nodeDetailsToShow];
    const userCoinData = currentNodeDetailsToShow.find((nodeDetailsItem) => nodeDetailsItem.user_id === user_id);

    if (userCoinData) {
      if (userCoinData.coinDataIndexes.includes(coinIndex)) {
        const coinIndexToDelete = userCoinData.coinDataIndexes.findIndex((itemIndex) => itemIndex === coinIndex);
        userCoinData.coinDataIndexes.splice(coinIndexToDelete, 1);
      }
      setNodeDetailsToShow(currentNodeDetailsToShow);
    }
  };

  const toggleNodesProblemModal = () => {
    setShouldShowNodeProblemsModal(!shouldShowNodeProblemsModal);
  };

  const handleUserFundsModal = () => {
    setShouldShowUserFundsModal(!shouldShowUserFundsModal);
  };

  const toggleAdminCodeModal = () => {
    setShouldShowAdminCodeModal(!shouldShowAdminCodeModal);
  };

  const handleResetIndexToDelete = () => {
    dispatch(resetIndexToDelete());
  };

  const handleAdminCodeModalSubmit = () => {
    dispatch(submitAdminCode());
  };

  const showNodeProblems = (nodeProblem: INodeProblems) => () => {
    switch (nodeProblem) {
      case 'delayedBlockNodes': {
        setNodeProblemsModalRenderType('delayedBlockNodes');
        setNodeProblemsModalTitle('DELAYED BLOCKS');
        break;
      }
      case 'overdueNodes': {
        setNodeProblemsModalRenderType('overdueNodes');
        setNodeProblemsModalTitle('OVERDUE NODES');
        break;
      }
      case 'decommissionedNodes': {
        setNodeProblemsModalRenderType('decommissionedNodes');
        setNodeProblemsModalTitle('DECOMMISSIONED NODES');
        break;
      }
      case 'delayedScripts': {
        setNodeProblemsModalRenderType('delayedScripts');
        setNodeProblemsModalTitle('DELAYED SCRIPTS');
        break;
      }
      case 'delayedServersPing': {
        setNodeProblemsModalRenderType('delayedServersPing');
        setNodeProblemsModalTitle('DELAYED PING SERVERS');
        break;
      }
    }
    toggleNodesProblemModal();
  };

  const refreshAdminData = () => {
    dispatch(getAdminDataAction({ shouldShowToastMessage: true }));
  };

  const toggleNotification = (warningMailType: INotificationWarningType) => () => {
    dispatch(toggleNotificationWarningMail({ warningMailType }));
  };

  const handleServerMountingToggle = (node_base: string, shouldMount: boolean) => {
    dispatch(toggleServerMounting({ node_base, should_mount: shouldMount }));
  };

  const handleServerDeletionToggle = (node_base: string, shouldDelete: boolean) => {
    dispatch(toggleServerDeletion({ node_base, should_delete: shouldDelete }));
  };

  const handleChargeNodes = () => {
    dispatch(executeChargeNodes());
  };

  const handleNodesDeletion = (shouldDeleteOnlyReadyNodes: boolean) => {
    dispatch(executeNodeDeletion({ shouldDeleteOnlyReadyNodes }));
  };

  return (
    <Container style={{ maxWidth: 1100 }}>
      <TimeContainer>
        <TimeText>{`Request Time: ${formatDate(new Date(requestTime))}`}</TimeText>
        <BootstrapButton disabled={isRefreshingAdminData} variant="success" onClick={refreshAdminData}>
          REFRESH
        </BootstrapButton>
      </TimeContainer>
      <GeneralTotals>
        <ItemsContainer>
          <HighestEarning>
            <FaCrown size={20} />
            <StrongItem>{displayPriceWithCurrency(generalTotals.highestEarning)}</StrongItem>
            <NormalItem>Highest Earning</NormalItem>
          </HighestEarning>
        </ItemsContainer>
        <ItemsContainer>
          <ItemContainer>
            <StrongItem>{displayPriceWithCurrency(generalTotals.total)}</StrongItem>
            <NormalItem>Total Earning</NormalItem>
          </ItemContainer>
          <ItemContainer>
            <StrongItem>{displayPriceWithCurrency(generalTotals.totalPerActivatedNodes)}</StrongItem>
            <NormalItem>
              Total Earning <br /> (Per Activated Nodes)
            </NormalItem>
          </ItemContainer>
          <ItemContainer>
            <StrongItem>{displayPriceWithCurrency(generalTotals.totalToBeCharged)}</StrongItem>
            <NormalItem>
              Total to be Charged <br /> (This Month)
            </NormalItem>
          </ItemContainer>
          <ItemContainer>
            <StrongItem>{displayPriceWithCurrency(generalTotals.alreadyCharged)}</StrongItem>
            <NormalItem>
              Already Charged <br /> (This Month)
            </NormalItem>
          </ItemContainer>
          <ItemContainer>
            <StrongItem>{displayPriceWithCurrency(generalTotals.monthlyChargesLeft)}</StrongItem>
            <NormalItem>
              Remaining Charges <br /> (This Month)
            </NormalItem>
          </ItemContainer>
          <ItemContainer>
            <StrongItem>{generalTotals.totalUsers}</StrongItem>
            <NormalItem>Total Users</NormalItem>
          </ItemContainer>
        </ItemsContainer>
        <ItemsContainer>
          <ItemContainer>
            <StrongItem>{displayPriceWithCurrency(generalTotals.allCosts)}</StrongItem>
            <NormalItem>All Costs</NormalItem>
          </ItemContainer>
          <ItemContainer>
            <StrongItem>{displayPriceWithCurrency(generalTotals.net)}</StrongItem>
            <NormalItem>Net</NormalItem>
          </ItemContainer>
        </ItemsContainer>
        <WarningContainer>
          <WarningItem isWarning={generalTotals.totalOverdueCharges > 0}>
            <StrongItem>{generalTotals.totalOverdueCharges}</StrongItem>
            <NormalItem>Overdue Charges</NormalItem>
            {generalTotals.totalOverdueCharges > 0 && (
              <ShowNodesText onClick={showNodeProblems('overdueNodes')}>Show Data</ShowNodesText>
            )}
          </WarningItem>
          <WarningItem isWarning={generalTotals.totalDelayedNodes > 0}>
            {is_delayed_blocks_warning_mail_enabled ? (
              <EnabledNotificationIcon size={25} onClick={toggleNotification('delayed_blocks_warning_mail')} />
            ) : (
              <DisabledNotificationIcon size={25} onClick={toggleNotification('delayed_blocks_warning_mail')} />
            )}

            <StrongItem>{generalTotals.totalDelayedNodes}</StrongItem>
            <NormalItem>Delayed Blocks</NormalItem>
            {generalTotals.totalDelayedNodes > 0 && (
              <ShowNodesText onClick={showNodeProblems('delayedBlockNodes')}>Show Data</ShowNodesText>
            )}
          </WarningItem>
          <WarningItem isWarning={statusTotal.decommissioned > 0}>
            {is_decommission_nodes_warning_mail_enabled ? (
              <EnabledNotificationIcon size={25} onClick={toggleNotification('decommision_warning_mail')} />
            ) : (
              <DisabledNotificationIcon size={25} onClick={toggleNotification('decommision_warning_mail')} />
            )}

            <StrongItem>{statusTotal.decommissioned}</StrongItem>
            <NormalItem>Decommissioned Nodes</NormalItem>
            {statusTotal.decommissioned > 0 && (
              <ShowNodesText onClick={showNodeProblems('decommissionedNodes')}>Show Data</ShowNodesText>
            )}
          </WarningItem>
          <WarningItem isWarning={serversPingWarningData.isPingDelayed}>
            {is_servers_ping_warning_mail_enabled ? (
              <EnabledNotificationIcon size={25} onClick={toggleNotification('servers_ping_mail')} />
            ) : (
              <DisabledNotificationIcon size={25} onClick={toggleNotification('servers_ping_mail')} />
            )}

            <StrongItem>{serversPingWarningData.delayedPingServers.length}</StrongItem>
            <NormalItem>Delayed Ping Servers</NormalItem>
            {serversPingWarningData.delayedPingServers.length > 0 && (
              <ShowNodesText onClick={showNodeProblems('delayedServersPing')}>Show Data</ShowNodesText>
            )}
          </WarningItem>
          <WarningItem isWarning={scriptWarningsData.isScriptPingDelayed}>
            {is_script_warning_mail_enabled ? (
              <EnabledNotificationIcon size={25} onClick={toggleNotification('script_warning_mail')} />
            ) : (
              <DisabledNotificationIcon size={25} onClick={toggleNotification('script_warning_mail')} />
            )}

            <StrongItem>{scriptWarningsData.delayedScriptPingServers.length}</StrongItem>
            <NormalItem>Delayed Script Pings</NormalItem>
            {scriptWarningsData.delayedScriptPingServers.length > 0 && (
              <ShowNodesText onClick={showNodeProblems('delayedScripts')}>Show Data</ShowNodesText>
            )}
          </WarningItem>
        </WarningContainer>
      </GeneralTotals>
      <TodayTotals>
        <ItemContainer>
          <StrongItem>{displayPriceWithCurrency(generalTotals.chargesForToday)}</StrongItem>
          <NormalItem>Charges Today</NormalItem>
        </ItemContainer>
        <ItemContainer>
          <StrongItem>{displayPriceWithCurrency(allAddresses.earnedToday)}</StrongItem>
          <NormalItem>Earned Today</NormalItem>
        </ItemContainer>
        <ItemContainer>
          <StrongItem>{generalTotals.nodesCreatedToday}</StrongItem>
          <NormalItem>Created Today</NormalItem>
        </ItemContainer>
        <ItemContainer>
          <StrongItem>{deletedNodes.nodesDeletedToday.length}</StrongItem>
          <NormalItem>Deleted Today</NormalItem>
        </ItemContainer>
        <ItemContainer>
          <StrongItem>{allUsers.newUsersToday}</StrongItem>
          <NormalItem>New Users Today</NormalItem>
        </ItemContainer>
        <ItemContainer>
          <StrongItem>{generalTotals.totalUnlockInitiated}</StrongItem>
          <NormalItem>Unlock Initiated</NormalItem>
        </ItemContainer>
      </TodayTotals>
      <AllCoins>
        <NodeStatus
          allNodesOnly={false}
          total={statusTotal.total}
          mounting={statusTotal.mounting}
          ready={statusTotal.ready}
          activated={statusTotal.activated}
          decommissioned={statusTotal.decommissioned}
        />
        <CoinContainer>
          {coins.map((coinItem) => {
            const {
              coin,
              status,
              total,
              display_name,
              latestBlock,
              totalNodes,
              totalEarnings,
              avgBlockTime,
              latestBlockTime,
            } = coinItem;

            return (
              <CoinItem key={uuid()}>
                <TotalEarnings>
                  <ItemContainer shouldUseBorder={false}>
                    <StrongItem>{displayPriceWithCurrency(totalEarnings)}</StrongItem>
                    <NormalItem>Earning</NormalItem>
                  </ItemContainer>
                </TotalEarnings>
                <CoinLogo coin={coin} shouldUseBorderRadius width={40} />
                <StrongItem>{display_name}</StrongItem>
                <CoinItemTotals>
                  <LineData
                    items={[
                      { itemLabel: 'Latest Block', itemValue: latestBlock },
                      {
                        itemLabel: 'Latest Block Time',
                        itemValue: latestBlockTime && formatDate(new Date(latestBlockTime)),
                      },
                      {
                        itemLabel: 'Avg Block Time',
                        itemValue: avgBlockTime,
                      },
                      { itemLabel: 'Total Nodes', itemValue: totalNodes },
                    ]}
                  />
                </CoinItemTotals>
                <NodeStatus
                  allNodesOnly={false}
                  total={total}
                  mounting={status.mounting}
                  ready={status.ready}
                  activated={status.activated}
                  decommissioned={status.decommissioned}
                />
              </CoinItem>
            );
          })}
        </CoinContainer>
      </AllCoins>
      <GeneralDeletion>
        <ServerButtonItem>
          <ServerButtonText>NODE CHARGES</ServerButtonText>
          {should_run_node_charges ? (
            <ToggleOnButton size={25} onClick={toggleNotification('should_run_node_charges')} />
          ) : (
            <ToggleOffButton size={25} onClick={toggleNotification('should_run_node_charges')} />
          )}
        </ServerButtonItem>
        <ServerButtonItem>
          <ServerButtonText>GENERAL DELETION</ServerButtonText>
          {is_running_general_deletion ? (
            <ToggleOnButton size={25} onClick={toggleNotification('is_running_general_deletion')} />
          ) : (
            <ToggleOffButton size={25} onClick={toggleNotification('is_running_general_deletion')} />
          )}
        </ServerButtonItem>
        <ServerButtonItem>
          <ServerButtonText>USER DELETION</ServerButtonText>
          {should_allow_user_deletion ? (
            <ToggleOnButton size={25} onClick={toggleNotification('should_allow_user_delete_nodes')} />
          ) : (
            <ToggleOffButton size={25} onClick={toggleNotification('should_allow_user_delete_nodes')} />
          )}
        </ServerButtonItem>
        <ServerButtonItem>
          <ServerButtonText>CHARGE WARNING MAIL</ServerButtonText>
          {is_charge_warning_mail_enabled ? (
            <ToggleOnButton size={25} onClick={toggleNotification('charge_warning_mail')} />
          ) : (
            <ToggleOffButton size={25} onClick={toggleNotification('charge_warning_mail')} />
          )}
        </ServerButtonItem>
      </GeneralDeletion>
      {!isSomeMountingRunning && (
        <NoMountingRunningWarning>
          <FiAlertOctagon size={40} />
          <StrongItem>NO MOUNTING RUNNING!</StrongItem>
        </NoMountingRunningWarning>
      )}
      <AllServers>
        {servers.map((serverItem) => {
          const {
            node_base,
            total,
            coins,
            alias,
            current_uptime,
            largest_uptime,
            server_price,
            is_mounting,
            is_deleting,
            model_name,
            storage,
          } = serverItem;

          return (
            <ServerItem key={uuid()}>
              <StrongItem>{`${alias} - ${model_name}`}</StrongItem>
              <StrongItem>{`Node Base: ${node_base} - ${total} nodes`}</StrongItem>
              <ServerButtonsContainer>
                <ServerButtonItem>
                  <ServerButtonText>MOUNTING</ServerButtonText>
                  {is_mounting ? (
                    <ToggleOnButton size={25} onClick={() => handleServerMountingToggle(node_base, false)} />
                  ) : (
                    <ToggleOffButton size={25} onClick={() => handleServerMountingToggle(node_base, true)} />
                  )}
                </ServerButtonItem>
                <ServerButtonItem>
                  <ServerButtonText>DELETING</ServerButtonText>
                  {is_deleting ? (
                    <ToggleOnButton size={25} onClick={() => handleServerDeletionToggle(node_base, false)} />
                  ) : (
                    <ToggleOffButton size={25} onClick={() => handleServerDeletionToggle(node_base, true)} />
                  )}
                </ServerButtonItem>
              </ServerButtonsContainer>
              <StorageContainer>
                {storage?.map((storageItem) => (
                  <StorageItem key={storageItem.disk}>
                    <StorageItemTitle>{storageItem.disk}</StorageItemTitle>
                    <InfoItem>
                      <NormalItem>{`Size:`}</NormalItem>
                      <StrongItem>{storageItem.size}</StrongItem>
                    </InfoItem>
                    <InfoItem>
                      <NormalItem>{`Used:`}</NormalItem>
                      <StrongItem>{storageItem.used}</StrongItem>
                    </InfoItem>
                    <InfoItem>
                      <NormalItem>{`Avail:`}</NormalItem>
                      <StrongItem>{storageItem.avail}</StrongItem>
                    </InfoItem>
                    <InfoItem>
                      <NormalItem>{`Use:`}</NormalItem>
                      <StrongItem>{storageItem.use}</StrongItem>
                    </InfoItem>
                  </StorageItem>
                ))}
              </StorageContainer>
              <ServerInfoContainer>
                <InfoItem>
                  <NormalItem>{`Current Uptime:`}</NormalItem>
                  <StrongItem>{displayFormattedTime(current_uptime)}</StrongItem>
                </InfoItem>
                <InfoItem>
                  <NormalItem>{`Largest Uptime:`}</NormalItem>
                  <StrongItem>{displayFormattedTime(largest_uptime)}</StrongItem>
                </InfoItem>
                <InfoItem>
                  <NormalItem>{`Server Price:`}</NormalItem>
                  <StrongItem>{displayPriceWithCurrency(server_price)}</StrongItem>
                </InfoItem>
              </ServerInfoContainer>
              {coins.map((coinItem) => {
                const { total, status, display_name } = coinItem;

                return (
                  <CoinItem key={uuid()}>
                    <StrongItem>{display_name}</StrongItem>
                    <NodeStatus
                      allNodesOnly={false}
                      total={total}
                      mounting={status.mounting}
                      ready={status.ready}
                      activated={status.activated}
                      decommissioned={status.decommissioned}
                    />
                  </CoinItem>
                );
              })}
            </ServerItem>
          );
        })}
      </AllServers>
      <GeneralButtons>
        <BootstrapButton onClick={handleShowAllData}>
          {isAllNodeDataShown ? 'HIDE ALL DATA' : 'SHOW ALL DATA'}
        </BootstrapButton>
        <BootstrapButton onClick={handleUserFundsModal}>EXTRA DATA</BootstrapButton>
        <ResetIndexButton
          disabled={isLoadingForResetIndexToDelete}
          variant="success"
          onClick={handleResetIndexToDelete}
        >
          RESET INDEX TO DELETE
        </ResetIndexButton>
        <ChargeNodesButton disabled={isChargeNodesLoading} variant="success" onClick={handleChargeNodes}>
          CHARGE NODES
        </ChargeNodesButton>
        <ExecuteDeletionButton
          disabled={isNodeDeletionLoading}
          variant="danger"
          onClick={() => handleNodesDeletion(false)}
        >
          EXECUTE DELETION
        </ExecuteDeletionButton>
        <ExecuteDeletionButton
          disabled={isReadyNodeDeletionLoading}
          variant="danger"
          onClick={() => handleNodesDeletion(true)}
        >
          DELETE READY NODES
        </ExecuteDeletionButton>
      </GeneralButtons>
      <UserData
        allUserNodes={allUserNodes}
        handleToggleSingleData={handleToggleSingleData}
        isAllNodeDataShown={isAllNodeDataShown}
        nodeDetailsToShow={nodeDetailsToShow}
        toggleAdminCodeModal={toggleAdminCodeModal}
      />
      {shouldShowUserFundsModal && (
        <ExtraDataModal
          handleHideModal={handleUserFundsModal}
          userFunds={userFunds}
          allUsers={allUsers}
          nodesEnabledLast30Days={nodesEnabledLast30Days}
          nodesCreated={nodesCreated}
          allAddresses={allAddresses}
          deletedNodes={deletedNodes}
          userActivity={userActivity}
          faucetHistory={faucetHistory}
          decommissionHistory={decommissionHistory}
          nodeRestoration={nodeRestoration}
          fundsTransfers={fundsTransfers}
        />
      )}
      {shouldShowNodeProblemsModal && (
        <NodesProblemModal
          warnings={warnings}
          toggleModal={toggleNodesProblemModal}
          modalTitle={nodeProblemsModalTitle}
          nodeProblemsModalRenderType={nodeProblemsModalRenderType}
        />
      )}
      {shouldShowAdminCodeModal && !isAdminCodeValid && (
        <AdminCodeModal handleHideModal={toggleAdminCodeModal} handleSubmit={handleAdminCodeModalSubmit} />
      )}
    </Container>
  );
}
