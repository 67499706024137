import { ReactElement } from 'react';
import Dropdown, { DropdownProps } from 'react-bootstrap/Dropdown';
import uuid from 'react-uuid';
import { StyledDropdownItem } from './styles';

interface IBootstrapDropdown extends DropdownProps {
  title: string;
  children: ReactElement | ReactElement[];
}

export function BootstrapDropdown({ title, children, ...props }: IBootstrapDropdown) {
  const isChildrenArray = Array.isArray(children);
  return (
    <Dropdown {...props}>
      <Dropdown.Toggle id={uuid()}>{title}</Dropdown.Toggle>

      <Dropdown.Menu>
        {isChildrenArray ? (
          children.map((child) => (
            <StyledDropdownItem key={uuid()}>
              {child}
            </StyledDropdownItem>
          ))
        ) : (
          <StyledDropdownItem key={uuid()}>
            {children}
          </StyledDropdownItem>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}
