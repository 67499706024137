import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isLoadingAppData as isLoadingAppDataSelector } from '~/store/modules/app/selectors';

export const useExistScrollBar = () => {
  const isLoadingAppData = useSelector(isLoadingAppDataSelector);
  const [isThereYScrollBar, setIsThereYScrollBar] = useState(false);

  const checkYScrollExists = () => {
    const screenHeight = window.innerHeight;
    const scrollHeight = document.querySelector('body')!.scrollHeight;
    const scrollExists = screenHeight < scrollHeight;
    setIsThereYScrollBar(scrollExists);
  };

  useEffect(() => {
    document.addEventListener('scroll', checkYScrollExists);
    document.addEventListener('click', checkYScrollExists);

    return () => {
      document.removeEventListener('scroll', checkYScrollExists);
      document.addEventListener('click', checkYScrollExists);
    };
  }, []);

  useEffect(() => {
    checkYScrollExists();
  }, [isLoadingAppData]);

  return {
    isThereYScrollBar,
  };
};
