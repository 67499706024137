import styled, { css } from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { TextInput } from '~/components/ui/TextInput';
import { DISPLAY_FLEX_COLUMN_DEFAULT, DISPLAY_FLEX_LINE_DEFAULT } from '~/style/constants';

export const CONTAINER_MIN_HEIGHT = '510px';

const SEPARATOR_BORDER = css`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;
export const Header = styled.header<{ shouldUseSeparatorBorder: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${({ shouldUseSeparatorBorder }) => shouldUseSeparatorBorder && SEPARATOR_BORDER}
`;

export const CoinNameText = styled.strong`
  font-size: 25px;
`;

export const LoadingContainer = styled.div`
  margin-top: 5px;
  min-height: 25px;
`;

export const ItemStrong = styled.strong``;

export const ItemNormal = styled.span``;

export const Footer = styled.footer`
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const LinkButton = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

export const BootstrapButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 7px;
`;

export const ModalWalletAddressContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  margin-top: 25px;
`;

export const StyledTextInput = styled(TextInput)`
  text-align: center;
`;

export const MainContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT};
  gap: 30px;
  justify-content: space-between;
  width: 100%;
  min-height: ${CONTAINER_MIN_HEIGHT};
`;

export const ModalFooterContainer = styled.div`
  ${DISPLAY_FLEX_LINE_DEFAULT}
  gap: 7px;
`;

export const DivElement = styled.div``;