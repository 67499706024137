import styled from 'styled-components/macro';
import { HIGLAN_WHITE_01 } from '~/style/constants';

export const LogoContainer = styled.div``;

export const HiglanLogoElement = styled.img`
  cursor: pointer;
  border-bottom: 1px solid transparent;
  padding-bottom: 5px;
  &:hover {
    border-bottom: 1px solid ${HIGLAN_WHITE_01};
    padding-bottom: 5px;
  }
`;
