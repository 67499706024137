import styled from 'styled-components/macro';
import { DISPLAY_FLEX_COLUMN_DEFAULT } from '~/style/constants';

export const BodyContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  gap: 20px;
  text-align: center;
  font-size: 16px;
`;

export const DescriptionText = styled.div``

export const FaucetBalanceText = styled.div`
  font-weight: bold;
`;
