import { TermsContent } from '~/components/Terms';
import { MainContent } from '../Home';
import { TermsContainer, TermsTitle } from './styles';

export function Terms() {
  return (
    <MainContent>
      <TermsContainer>
        <TermsTitle>TERMS OF SERVICE</TermsTitle>
        <TermsContent />
      </TermsContainer>
    </MainContent>
  );
}
