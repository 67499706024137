import { ReactNode } from 'react';
import { TextRenderContainer } from './styles';

interface ITextRender extends React.HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  isBold?: boolean;
}

export function TextRender({ children, ...props }: ITextRender) {
  return <TextRenderContainer {...props}>{children}</TextRenderContainer>;
}
