import { HiglanLogoElement, LogoContainer } from './styles';
import logo from 'assets/logo.svg';
import history from 'services/history';

export function HiglanLogo() {
  const onLogoClick = () => {
    history.push('/home');
  };
  return (
    <LogoContainer>
      <HiglanLogoElement src={logo} alt="Higlan" onClick={onLogoClick} />
    </LogoContainer>
  );
}
