import { Modal } from '~/components/ui/Modal';
import {
  AccountToTransferForm,
  BalanceContainer,
  BalanceText,
  BalancesContainer,
  BolderText,
  BonusContainer,
  BonusText,
  ConfirmTransferButton,
  DifferentAccountDataContainer,
  FundsTextContainer,
  HiglanAccountToTransferContainer,
  InputContainer,
  MaxText,
  NormalText,
  StyledFundsInput,
  StyledTextInput,
  TransferFundsCodeContainer,
  TransferFundsCodeInput,
  TransferFundsDescription,
  TransferFundsModalContent,
  UserAccountContainer,
  UserAccountDescription,
  VerificationButton,
} from './styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDifferentAccountToTransferFunds,
  getIsLoadingConfirmTransferFunds,
  getIsLoadingDifferentAccountVerification,
  getShouldShowTransferFundsCodeField,
  getUserProfile,
} from '~/store/modules/user/selectors';
import { displayPriceWithCurrency, removeExtraSpaces } from '~/shared/string-utils';
import { FaMoneyBillTransfer } from 'react-icons/fa6';
import {
  confirmTransferFunds,
  resetDifferentAccountToTransferFunds,
  verifyDifferentAccountEmailToTransferFunds,
} from '~/store/modules/user/actions';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';

interface ITransferFundsModal {
  toggleModal: () => void;
}

interface IModalContent {
  toggleTransferFundsModal: () => void;
}

const NUMBER_REGEX = /^-?\d+(\.\d+)?$/;

const ModalContent = ({ toggleTransferFundsModal }: IModalContent) => {
  const profile = useSelector(getUserProfile);
  const isLoadingDifferentAccountVerification = useSelector(getIsLoadingDifferentAccountVerification);
  const isLoadingConfirmTransferFunds = useSelector(getIsLoadingConfirmTransferFunds);
  const differentAccountToTransferFunds = useSelector(getDifferentAccountToTransferFunds);
  const shouldShowTransferFundsCodeField = useSelector(getShouldShowTransferFundsCodeField);
  const [differentAccountEmail, setDifferentAccountEmail] = useState(differentAccountToTransferFunds?.email || '');
  const [balanceToTransfer, setBalanceToTransfer] = useState<number | string>(0);
  const [bonusToTransfer, setBonusToTransfer] = useState<number | string>(0);
  const [transferFundsCode, setTransferFundsCode] = useState('');
  const shouldDisableDifferentAccountInput = !!differentAccountToTransferFunds?.email;
  const canChangeFunds = !shouldShowTransferFundsCodeField;

  const dispatch = useDispatch();
  const { balance, bonus } = profile.balances;

  const handleDifferentAccountInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const cleanedValue = removeExtraSpaces(value);
    setDifferentAccountEmail(cleanedValue);
  };

  const handleVerifyDifferentAccount = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!differentAccountEmail) {
      toast.error(`Please insert the email of the different Higlan account!`);
      return;
    }
    dispatch(verifyDifferentAccountEmailToTransferFunds(differentAccountEmail));
  };

  const removeDifferentAccountToTransferFunds = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(resetDifferentAccountToTransferFunds());
    setDifferentAccountEmail('');
    setBalanceToTransfer(0);
    setBonusToTransfer(0);
  };

  const handleMaxAmount = (type: 'balance' | 'bonus') => {
    if (type === 'balance') {
      const value = Number(balance.toFixed(2));
      setBalanceToTransfer(value);
      return;
    }

    if (type === 'bonus') {
      const value = Number(bonus.toFixed(2));
      setBonusToTransfer(value);
      return;
    }
  };

  const onTransferFundsCodeChange = (event: React.FormEvent<HTMLInputElement>) => {
    const eventValue = String(event.currentTarget.value).toUpperCase();
    setTransferFundsCode(eventValue);
  };

  const onBalanceChange = (event: React.FormEvent<HTMLInputElement>, type: 'balance' | 'bonus') => {
    const eventValue = event.currentTarget.value;
    if (!NUMBER_REGEX.test(eventValue)) {
      if (type === 'balance') {
        setBalanceToTransfer('');
        return;
      }
      if (type === 'bonus') {
        setBonusToTransfer('');
        return;
      }
    }
    const userInputValue = Number(eventValue);

    if (type === 'balance') {
      const valueToSet = userInputValue > balance ? balance : userInputValue;
      const formattedValue = Number(valueToSet.toFixed(2));
      setBalanceToTransfer(formattedValue);
      return;
    }

    if (type === 'bonus') {
      const valueToSet = userInputValue > bonus ? bonus : userInputValue;
      const formattedValue = Number(valueToSet.toFixed(2));
      setBonusToTransfer(formattedValue);
      return;
    }
  };

  const handleConfirmTransfer = () => {
    if (!balanceToTransfer && !bonusToTransfer) {
      toast.error(`Please select the funds to transfer.`);
      return;
    }

    if (shouldShowTransferFundsCodeField && !transferFundsCode) {
      toast.error(`Please insert the transfer funds code.`);
      return;
    }

    dispatch(
      confirmTransferFunds({
        balance: Number(balanceToTransfer),
        bonus: Number(bonusToTransfer),
        differentAccountEmail,
        toggleTransferFundsModal,
        ...(transferFundsCode && { code: transferFundsCode }),
      })
    );
  };

  useEffect(() => {
    return () => {
      dispatch(resetDifferentAccountToTransferFunds());
    };
  }, []);

  return (
    <TransferFundsModalContent>
      <TransferFundsDescription>
        Here you can transfer the <BolderText>balance/bonus</BolderText> from your{' '}
        <BolderText>Higlan account</BolderText> to a <BolderText>different Higlan account</BolderText>.
      </TransferFundsDescription>
      <UserAccountContainer>
        <UserAccountDescription>
          Email: <BolderText>{profile.email}</BolderText>
        </UserAccountDescription>
        <UserAccountDescription>
          Username: <BolderText>{profile.name}</BolderText>
        </UserAccountDescription>
        <BalancesContainer>
          <BalanceContainer>
            <NormalText>{displayPriceWithCurrency(balance)}</NormalText>
            <NormalText>Balance</NormalText>
          </BalanceContainer>
          <BonusContainer>
            <NormalText>{displayPriceWithCurrency(bonus)}</NormalText>
            <NormalText>Bonus</NormalText>
          </BonusContainer>
        </BalancesContainer>
      </UserAccountContainer>

      <HiglanAccountToTransferContainer>
        <TransferFundsDescription>
          What is the <BolderText>different Higlan account</BolderText> you would like to transfer your funds?
        </TransferFundsDescription>
        <AccountToTransferForm
          onSubmit={
            shouldDisableDifferentAccountInput ? removeDifferentAccountToTransferFunds : handleVerifyDifferentAccount
          }
        >
          <StyledTextInput
            placeholder="EMAIL"
            name="differentAccountEmail"
            onChange={handleDifferentAccountInputChange}
            value={differentAccountEmail}
            type="email"
            disabled={shouldDisableDifferentAccountInput}
          />
          {shouldDisableDifferentAccountInput ? (
            <VerificationButton type="submit" variant="danger">
              REMOVE
            </VerificationButton>
          ) : (
            <VerificationButton type="submit" variant="success" disabled={isLoadingDifferentAccountVerification}>
              OK
            </VerificationButton>
          )}
        </AccountToTransferForm>
      </HiglanAccountToTransferContainer>

      {differentAccountToTransferFunds && (
        <DifferentAccountDataContainer>
          <UserAccountContainer>
            <UserAccountDescription>
              Email: <BolderText>{differentAccountToTransferFunds.email}</BolderText>
            </UserAccountDescription>
            <UserAccountDescription>
              Username: <BolderText>{differentAccountToTransferFunds.username}</BolderText>
            </UserAccountDescription>
          </UserAccountContainer>
          <NormalText>
            Please select the funds you would like to transfer to the account{' '}
            <BolderText>{differentAccountToTransferFunds.email}</BolderText>
          </NormalText>
          <BalancesContainer>
            <BalanceContainer>
              <NormalText>Balance</NormalText>
              <InputContainer>
                <StyledFundsInput
                  value={balanceToTransfer}
                  type={balanceToTransfer === '' ? 'text' : 'number'}
                  onChange={(event) => onBalanceChange(event, 'balance')}
                  disabled={!canChangeFunds}
                />
                {canChangeFunds && <MaxText onClick={() => handleMaxAmount('balance')}>MAX</MaxText>}
              </InputContainer>
            </BalanceContainer>
            <BonusContainer>
              <NormalText>Bonus</NormalText>
              <InputContainer>
                <StyledFundsInput
                  value={bonusToTransfer}
                  type={bonusToTransfer === '' ? 'text' : 'number'}
                  onChange={(event) => onBalanceChange(event, 'bonus')}
                  disabled={!canChangeFunds}
                />
                {canChangeFunds && <MaxText onClick={() => handleMaxAmount('bonus')}>MAX</MaxText>}
              </InputContainer>
            </BonusContainer>
          </BalancesContainer>
          <NormalText>
            You are about to transfer to the account <BolderText>{differentAccountToTransferFunds.email}</BolderText>{' '}
            these funds:
          </NormalText>
          <FundsTextContainer>
            <BalanceText>
              Balance: <BolderText>{displayPriceWithCurrency(balanceToTransfer)}</BolderText>
            </BalanceText>
            {` | `}
            <BonusText>
              Bonus: <BolderText>{displayPriceWithCurrency(bonusToTransfer)}</BolderText>
            </BonusText>
          </FundsTextContainer>
          {shouldShowTransferFundsCodeField && (
            <TransferFundsCodeContainer>
              <BolderText>Transfer Funds Code</BolderText>
              <TransferFundsCodeInput onChange={onTransferFundsCodeChange} value={transferFundsCode} maxLength={6} />
            </TransferFundsCodeContainer>
          )}
          <ConfirmTransferButton
            variant="success"
            onClick={handleConfirmTransfer}
            disabled={isLoadingConfirmTransferFunds}
          >
            <FaMoneyBillTransfer size={30} />
            CONFIRM
          </ConfirmTransferButton>
        </DifferentAccountDataContainer>
      )}
    </TransferFundsModalContent>
  );
};

export function TransferFundsModal({ toggleModal }: ITransferFundsModal) {
  return (
    <Modal
      bodyContent={<ModalContent toggleTransferFundsModal={toggleModal} />}
      title="TRANSFER FUNDS"
      show={true}
      onHide={toggleModal}
      hasConfirmation={false}
      size="lg"
      submitButtonIcon={<FaMoneyBillTransfer size={30} />}
    />
  );
}
