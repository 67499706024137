import styled, { css } from 'styled-components/macro';
import { DISPLAY_FLEX_COLUMN_DEFAULT, DISPLAY_FLEX_LINE_DEFAULT, HIGLAN_BLACK_01, HIGLAN_GRAY_01, HIGLAN_GREEN_01, HIGLAN_WHITE_01 } from '~/style/constants';

const MONTH_AMOUNT_ACTIVE_CSS = css`
  background-color: ${HIGLAN_GREEN_01};
  color: ${HIGLAN_WHITE_01};
  border: 1px solid ${HIGLAN_BLACK_01};
`;

export const MonthAmountContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  gap: 7px;
`;

export const MonthAmountItemsContainer = styled.div`
  ${DISPLAY_FLEX_LINE_DEFAULT}
  flex-wrap: wrap;
  width: 100%;
  gap: 7px;
`;

export const MonthAmount = styled.div<{ isSelected: boolean }>`
  width: 100px;
  height: 40px;
  padding: 5px;
  ${DISPLAY_FLEX_LINE_DEFAULT}
  border: 1px solid ${HIGLAN_GRAY_01};
  font-weight: bold;
  border-radius: 7px;
  cursor: pointer;

  :hover {
    ${MONTH_AMOUNT_ACTIVE_CSS}
  }
  ${({ isSelected }) => isSelected && MONTH_AMOUNT_ACTIVE_CSS}
`;