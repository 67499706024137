import styled from 'styled-components/macro';
import { DISPLAY_FLEX_LINE_DEFAULT, HIGLAN_GRAY_04 } from '~/style/constants';

export const LineDataContainer = styled.div<{ hasBackground: boolean }>`
  ${DISPLAY_FLEX_LINE_DEFAULT}
  text-align: center;
  border: 1px solid #ddd;
  border-left: 0;
  border-right: 0;
  padding: 10px;
  width: 100%;
  background-color: ${({ hasBackground }) => hasBackground && HIGLAN_GRAY_04};
`;

export const ItemContainer = styled.div`
  ${DISPLAY_FLEX_LINE_DEFAULT}
  width: 100%;
  justify-content: space-between;
  gap: 20px;
`;

export const ItemValue = styled.div``;
