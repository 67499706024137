import { ISupportedCoinsNames } from '../interfaces';
import { AnyAction } from 'redux';

const prefix = '@coin-chain-data';

interface ICoinChainDataTypes {
  GET_LATEST_BLOCK_AND_TOTAL_NODES: string;
  SET_LATEST_BLOCK_AND_TOTAL_NODES: string;
}

export const coinChainDataTypes: ICoinChainDataTypes = {
  GET_LATEST_BLOCK_AND_TOTAL_NODES: `${prefix}/GET_LATEST_BLOCK_AND_TOTAL_NODES`,
  SET_LATEST_BLOCK_AND_TOTAL_NODES: `${prefix}/SET_LATEST_BLOCK_AND_TOTAL_NODES`,
};

export type ICoinChainDataAction = ISetBlockAndTotalNodesAction | AnyAction;

export interface ISetBlockAndTotalNodesAction {
  payload: ICoinDataItem[];
  type: typeof coinChainDataTypes.SET_LATEST_BLOCK_AND_TOTAL_NODES;
}

export interface ICoinDataItem {
  coin: ISupportedCoinsNames;
  latest_block: number;
  total_nodes: number;
}

export interface IGetBlockAndTotalNodesAction {
  payload: IGetBlockAndTotalNodesPayload;
  type: typeof coinChainDataTypes.GET_LATEST_BLOCK_AND_TOTAL_NODES;
}

interface IGetBlockAndTotalNodesPayload {
  shouldUseLoadingAppData: boolean;
}

export const setBlockAndTotalNodes = (coinData: ICoinDataItem[]) => ({
  payload: coinData,
  type: coinChainDataTypes.SET_LATEST_BLOCK_AND_TOTAL_NODES,
});

export const getBlockAndTotalNodes = ({ shouldUseLoadingAppData }: IGetBlockAndTotalNodesPayload) => ({
  payload: {
    shouldUseLoadingAppData,
  },
  type: coinChainDataTypes.GET_LATEST_BLOCK_AND_TOTAL_NODES,
});
