import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import history from 'services/history';
import { Container } from 'pages/_layouts/default/styles';

import { Content, Header, CoinSuggestion, CoinContainer, StyledModal } from './styles';
import { NodeCard } from 'components/ui/NodeCard';
import { getSupportedCoins } from 'store/modules/app/selectors';
import { emptyFunction, useQuery } from 'shared/string-utils';
import { getBlockAndTotalNodes } from '~/store/modules/coin-chain-data/actions';
import { ISupportedCoinsNames } from '~/store/modules/interfaces';
import { SuggestCoin } from './shared/SuggestCoin';

export function Nodes() {
  const queryParams = useQuery();
  const coinSuggestion = queryParams.get('coin_suggestion');
  const [showModal, setShowModal] = useState(!!coinSuggestion);
  const supportedCoins = useSelector(getSupportedCoins);
  const dispatch = useDispatch();

  const handleOnclick = (coinName: ISupportedCoinsNames) => () => {
    history.push(`/nodes/${coinName}`);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    dispatch(getBlockAndTotalNodes({ shouldUseLoadingAppData: false }));
  }, []);

  return (
    <Container>
      <Content>
        <Header>
          <h1>Select a coin</h1>
          <CoinSuggestion onClick={toggleModal}>Would you like to suggest a coin?</CoinSuggestion>
          {/* <StyledPromoBanner /> */}
        </Header>
        <CoinContainer>
          {supportedCoins.map((supportedCoin) => (
            <NodeCard
              key={supportedCoin.id}
              supportedCoin={supportedCoin}
              onClick={supportedCoin.is_active ? handleOnclick(supportedCoin.coin) : emptyFunction}
            />
          ))}
        </CoinContainer>
        {showModal && (
          <StyledModal
            show={true}
            onHide={toggleModal}
            title="SUGGEST A COIN"
            bodyContent={
              <SuggestCoin hasCancellation={toggleModal} isPublicMessage={false} executeAfterSend={toggleModal} />
            }
            shouldShowFooter={false}
          />
        )}
      </Content>
    </Container>
  );
}
